import React, { FC } from "react";
import SayHelloCustom from "../sayHelloCustom";
import SayHelloScrollbar from "../sayHelloScrollbar";
import SayHelloShortCard from "../sayHelloShortCard";
import useSayHi from "../../context/SayHiContext";

type Props = {
  recipientImg: string;
  recipientUsername: string;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
  onSettled?: () => void;
};

const SayHelloShort: FC<Props> = ({
  recipientImg,
  recipientUsername,
  setCurrentPageIndex,
  onSettled,
}) => {
  const { shortResponses } = useSayHi();
  return (
    <SayHelloCustom
      recipientImg={recipientImg}
      recipientUsername={recipientUsername}
      setCurrentPageIndex={setCurrentPageIndex}
    >
      <SayHelloScrollbar className="min-h-[330px] sm:min-h-[400px] mt-4">
        <div className="gridLayout max-w-[740px] gap-2 flex flex-wrap mb-4 pr-[10px]">
          {shortResponses.map((content, index) => (
            <SayHelloShortCard
              onSettled={onSettled}
              key={content.greetingId}
              size={content.message.length < 30 ? "small" : "large"}
              content={content}
              index={index}
            />
          ))}
        </div>
      </SayHelloScrollbar>
    </SayHelloCustom>
  );
};

export default SayHelloShort;
