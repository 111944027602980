import React, { FC } from "react";
import { placeholderProfilePicture } from "../messageBannerPicture/MessageBannerPicture";

type Props = {
  alt?: string;
  imgSrc?: string;
  onClick: () => void;
  testid?: string;
};

export const defaultAlt = "Placeholder profile picture";
export const defaultPlaceholderImgSrc = placeholderProfilePicture;

const ProfilePhotoCarouselItem: FC<Props> = ({
  alt = "Placeholder profile picture",
  imgSrc = placeholderProfilePicture,
  onClick,
  testid,
}) => {
  return (
    <button
      data-testid={testid}
      className="ProfilePhotoCarouselItem"
      onClick={onClick}
    >
      <img
        className="rounded w-40 h-[13.2rem] min-w-[160px] object-cover"
        alt={alt}
        src={imgSrc}
      />
    </button>
  );
};

export default ProfilePhotoCarouselItem;
