import { FC, useEffect, useState } from "react";
import classNames from "../../utils/twClassNames";
import ButtonBGBlue from "../buttonBGBlue";
import ButtonBlueBGWhite from "../buttonBlueBGWhite";
import Popup from "../popup";
import useCertification from "../../hooks/useCertification";
import useUser from "../../hooks/useUser";
import { PaymentData, VIPPlan } from "../../backendAPI/Subscription";
import useTranslation from "../../context/TranslationContext";

import { PayPalButtons } from "@paypal/react-paypal-js";
import api from "../../backendAPI";
import { useRouter } from "next/router";
import StyledPopup from "../styledPopup";

// https://developer.paypal.com/tools/sandbox/card-testing/

// card number : 4032033113059898
// expiration date : 09/2028
// security code : 082

type Props = {
  index: number;
  planInfo: VIPPlan;
  brandName: string;
  planType: "vip" | "noAds";
  onClick?: () => void;
  additionalInformation: PaymentData["additionalInformation"];
};

const MembershipCard: FC<Props> = ({
  planInfo,
  index,
  brandName,
  planType,
  onClick,
  additionalInformation,
}) => {
  const router = useRouter();
  const { i } = useTranslation();
  const [openRequiredCertificationPopup, setOpenRequiredCertificationPopup] =
    useState(false);
  const [paypalPopup, setPaypalPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showForeignCurrency, setShowForeignCurrency] = useState(false);
  const [alreadyHasVIPPlan, setAlreadyHasVIPPlan] = useState(false);
  const [alreadyHasNoAdsPlan, setAlreadyHasNoAdsPlan] = useState(false);

  useEffect(() => {
    if (paypalPopup === false && isSuccess === true) {
      window.location.reload();
    }
  }, [paypalPopup]);

  const { userData } = useUser();

  if (!userData?.userid) return null;

  return (
    <div className="MembershipCard w-full max-w-sm m-auto">
      <StyledPopup open={alreadyHasVIPPlan} onClick={setAlreadyHasVIPPlan}>
        <AlreadyHaveSubscriptionsPopupContent
          title={i(
            "You currently have an active subscription for 'V.I.P. Member' ."
          )}
          button_text={i("Manage my subscriptions")}
          icon={"/app/assets/vipcard.svg"}
        />
      </StyledPopup>
      <StyledPopup
        open={alreadyHasNoAdsPlan}
        onClick={setAlreadyHasNoAdsPlan}
        maxWidth={580}
      >
        <AlreadyHaveSubscriptionsPopupContent
          title={i(
            "You currently have an active subscription to browse the site without advertising."
          )}
          button_text={i("Manage my subscriptions")}
          icon={"/app/assets/noads.svg"}
        />
      </StyledPopup>
      <StyledPopup
        open={openRequiredCertificationPopup}
        onClick={setOpenRequiredCertificationPopup}
        maxWidth={580}
      >
        <CerficationIntroContainer
          open={openRequiredCertificationPopup}
          setOpen={setOpenRequiredCertificationPopup}
        />
      </StyledPopup>
      <StyledPopup open={paypalPopup} onClick={setPaypalPopup} maxWidth={580}>
        <PaypalPopupContent
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
          plan={planInfo}
          planType={planType}
          brandName={brandName}
          userId={userData?.userid.toString()}
        />
      </StyledPopup>
      <StyledPopup open={showForeignCurrency} onClick={setShowForeignCurrency}>
        <div className="h-fit flex flex-col items-center justify-center text-custom-black">
          <p className="text-xl mt-6 text-center text-custom-orange mb-8">
            {additionalInformation["foreign-currency"]["popup_title"]}
          </p>
          <div className="flex flex-col gap-4 justify-start text-left p-4 rounded-2xl bg-[#F1F0F5]">
            <p>{planInfo["data-popup_line_1"]}</p>
            <p>{additionalInformation["foreign-currency"]["popup_line_2"]}</p>
          </div>
        </div>
      </StyledPopup>
      <div
        className={classNames(
          index === 1 || planType === "noAds" ? " h-[334px]" : "h-[268px]",
          "flex relative flex-col w-full min-h-fit py-[14px] items-center justify-center bg-custom-orange-100 rounded-[15px] overflow-hidden"
        )}
      >
        {planInfo?.["data-image"] && (
          <img
            src={planInfo["data-image"]}
            alt="vidamora vip logo"
            className="w-[73px] h-[46px]"
          />
        )}

        {planInfo.isPromo && planInfo.isPromoTextBanner!="" && (
          <span className="absolute right-0 top-4 px-2 py-2 text-lg font-bold leading-none bg-gradient-to-r from-custom-gradient-orange to-custom-red text-white rounded-tl rounded-bl">
            {" "}
            {planInfo.isPromoTextBanner}
          </span>
        )}

        <p
          className={classNames(
            index === 1 ? "text-[28px]" : "text-[22px]",
            planInfo["isPromoNewPrice"]!="" ? "line-through !text-[16px]" : "",
            "mt-[14px] text-custom-orange font-semibold"
          )}
        >
          {planInfo["data-title"]}
        </p>
        {planInfo.isPromoNewPrice!="" && (
          <p
              className={classNames(
                index === 1 ? "text-[28px]" : "text-[22px]",
                "text-custom-orange font-semibold"
              )}
          >
              {planInfo["isPromoNewPriceMonthly"]}
          </p>
        )}

        {additionalInformation["foreign-currency"]["show-lines"] && (
          <p className="text-xs text-center mt-1 font-light z-10">
            {additionalInformation["foreign-currency"]["card_line_1"]}
            <br />
            <a
              onClick={() => setShowForeignCurrency(true)}
              className="text-custom-blue hover:underline hover:cursor-pointer"
            >
              {additionalInformation["foreign-currency"]["card_line_2"]}
            </a>
          </p>
        )}

        <p className="text-lg mt-[14px] font-semibold">
          {planInfo["data-subtitle_line_1"]}
        </p>

        <p className="text-lg font-semibold"
          dangerouslySetInnerHTML={{
            __html: 
            planInfo["isPromoNewPrice"]!="" ? "<span class='line-through'>"+planInfo["data-subtitle_line_2"]+"</span>"+" <span class='!no-underline'>"+planInfo["isPromoNewPrice"]+"</span>" : planInfo["data-subtitle_line_2"],
          }}
        >        
        </p>
        {planInfo["isPromoNewPrice"]!="" && ( 
            <p className="text-sm text-center mt-2" 
              dangerouslySetInnerHTML={{
                __html: 
                planInfo["isPromoNote"],
              }}
            
            >
            </p>  
        )}

        <div className="z-10 mt-6">
          <ButtonBGBlue
            testid="membership-order-button"
            innerText={planInfo["data-button-text"]}
            onClick={() => {
              if (!userData) return;
              if (planType === "vip" && !userData.isCertified)
                return setOpenRequiredCertificationPopup(true);
              if (userData.isVIP && planType === "vip")
                return setAlreadyHasVIPPlan(true);
              if (userData.isPremiumNoAds && planType === "noAds")
                return setAlreadyHasNoAdsPlan(true);
              onClick ? onClick() : setPaypalPopup(true);
            }}
          />
        </div>

        <img
          src="/app/assets/lightOrangeVidamoraLogo.svg"
          alt="vidamora logo"
          className="absolute -bottom-4 -right-[70px] z-[5]"
        />
      </div>
    </div>
  );
};

const PaypalPopupContent = ({
  brandName,
  userId,
  isSuccess,
  setIsSuccess,
  plan,
  planType,
}: {
  brandName: string;
  userId: string;
  isSuccess: boolean;
  setIsSuccess: (value: boolean) => void;
  plan: VIPPlan;
  planType: "vip" | "noAds";
}) => {
  const { i, cdnUrl } = useTranslation();

  const [isError, setIsError] = useState(false);

  if (isError) {
    return (
      <div className="h-fit flex flex-col items-center justify-center text-custom-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="50"
          viewBox="0 -960 960 960"
          width="50"
          className="text-red-500"
        >
          <path
            fill="rgb(239,68,68)"
            d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>

        <p className="text-xl font-semibold mt-6 text-center mb-8">
          {i(
            "Oops... there seems to have been a problem with your credit card"
          )}
        </p>
        <div>
          <ButtonBGBlue
            innerText={i("Try again")}
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div className="h-fit flex flex-col items-center justify-center text-custom-black">
        <div className="wrapper">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <p className="text-4xl font-semibold text-custom-blue mt-6 text-center">
          {i("You are now a V.I.P.")}
        </p>
        <div className="relative flex justify-center items-center flex-col mt-6">
          <img src={plan["data-image"]} alt="Plan Image" />
          <p className="text-xl font-semibold mt-1 text-center mb-8">
            {plan["data-subtitle_line_1"]}
          </p>
        </div>

        {/* <p className="text-center mb-8">{plan["data-subtitle_line_2"]}</p> */}
        <div>
          <ButtonBGBlue
            innerText={i("Refresh the page")}
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="membership-paypal-popup"
      className="h-fit flex flex-col items-center justify-center text-custom-black"
    >
      <img src={plan["data-image"]} alt="vidamora logo" className="w-24 mb-3" />
      <p className="text-4xl font-semibold text-custom-orange">
        {plan["data-title"]}
      </p>
      <p className="text-xl mt-6 text-center font-semibold">
        {plan["data-subtitle_line_1"]}
      </p>

      <p className="text-lg text-center font-semibold mb-8">
        {plan["data-subtitle_line_2"]}
      </p>

      <PayPalButtons
        onError={(err) => {
          setIsError(true);
        }}
        onApprove={(data, actions) => {
          return api.subscription
            .getSuccessUrl({
              subscriptionId: data.subscriptionID,
              subscriptionGroup: planType,
              provider: "paypal",
            })
            .then((res) => {
              if (res.status !== "SUCCESS")
                throw new Error("Error while fetching success url");

              window.location.href = res.data.url;
            })
            .catch((err) => {
              // TODO: Check if manually redirecting to warning page is what is wanted. response messages dont really give any info
              // Handle Paypal success but API fetch error
              window.location.href = "settings?tab=vip-warning";
            });
        }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: plan.paymentsMethod.paypal["data-paypal-plan-Id"],
            custom_id: userId,
            application_context: {
              brand_name: brandName,
              shipping_preference: "NO_SHIPPING",
            },
          });
        }}
      />
    </div>
  );
};

const CerficationIntroContainer = ({
  setOpen,
  open,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { i, cdnUrl } = useTranslation();
  const { userData } = useUser();
  const { start } = useCertification();
  return (
    <div
      data-testid="membership-required-certification-popup"
      className="h-fit py-12 flex flex-col items-center justify-center text-custom-black"
    >
      <img
        src={`${cdnUrl}/app/assets/vidamora-logo.svg`}
        alt="vidamora logo"
        className="w-36 mb-3"
      />
      <p className="text-4xl font-semibold ">{i("Becoming VIP")}</p>
      <p className="text-lg mt-6">
        {i("You must first certify your profile.")}
      </p>
      <p className="text-lg">{i("It's free and fast!")}</p>
      <div className="flex w-full space-x-3 justify-center mt-6">
        <div className="w-fit">
          <ButtonBlueBGWhite
            innerText={i("Cancel")}
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="w-fit">
          <ButtonBGBlue
            innerText={i("Certify my profile")}
            onClick={(e) => {
              e.preventDefault();
              if (!userData.isCertified) {
                setOpen(false);
                setTimeout(start, 350);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
const AlreadyHaveSubscriptionsPopupContent = ({
  title = "",
  button_text = "",
  icon = "",
}) => {
  const router = useRouter();

  return (
    <div className="h-fit flex flex-col items-center justify-center text-custom-black">
      <img src={icon} alt="vidamora logo" className="w-20 mb-3" />
      <p className="text-xl mt-6 text-center font-semibold mb-8">{title}</p>
      <div>
        <ButtonBGBlue
          innerText={button_text}
          onClick={() => {
            router.push("/settings?tab=manage-subscriptions");
          }}
        />
      </div>
    </div>
  );
};
export default MembershipCard;
