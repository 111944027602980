import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  chatMessage: string;
  onSendClick: () => void;
};

const MessageChatBoxSend: FC<Props> = ({ chatMessage, onSendClick }) => {
  const { i } = useContext(TranslationContext);
  return (
    <div
      data-testid="messenger-send-button"
      className={`MessageChatBoxSend w-6 h-6 min-h-[24px] relative tooltip min-w-[24px] ${chatMessage.length && "hover:cursor-pointer"
        }`}
      onClick={onSendClick}
    >
      <img
        alt={sendMessageIconAltAttribute}
        src={
          chatMessage.length
            ? "/app/assets/activeSendMessageIcon.svg"
            : "/app/assets/sendMessageIcon.svg"
        }
      />
      {/* <div className="absolute -left-1 -top-12">
        <p className="tooltiptext w-32">{i("Send message")}</p>
      </div> */}
    </div>
  );
};

export const sendMessageIconAltAttribute = "Send message icon";

export default MessageChatBoxSend;
