import { RootState } from "../store";

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../backendAPI";

import { checkIfHasMore } from "../utils";

import getGenderId from "../../utils/getGenderId";

import { logout, login, getLoginInfo } from "./authSlice";
import { searchResultsChangedSubject$ } from "../../context/NavigationContext";

export interface State {
  results: any[];
  pageIndex: number;
  hasMore: boolean;
  isLoading: boolean;
  error: Error | null;
  type: "update" | "read";
  sortBy: "all" | "mostpopular" | "newest";
  selectedGenderName: Gender;
  selectedMinAge: number;
  selectedMaxAge: number;
  distance: number;

  shouldReload: boolean;
  fetchingResults: boolean;
  preferencesLoaded: boolean;
}

type Gender = "Man" | "Woman" | "Both";

const initialState: State = {
  results: [],
  pageIndex: 1,
  hasMore: false,
  isLoading: false,
  error: null,
  type: "update",
  sortBy: "all",

  selectedGenderName: "Woman",

  selectedMinAge: 18,

  selectedMaxAge: 45,

  distance: 10,
  shouldReload: false,
  fetchingResults: false,
  preferencesLoaded: false,
};

export const fetchResults = createAsyncThunk(
  "onlineNow/fetchResults",

  async (shouldReset: boolean = false, { getState }) => {
    const state = getState() as RootState;

    const pageIndex = shouldReset ? 1 : state.onlineNow.pageIndex;

    const selectedGenderId = getGenderId(state.onlineNow.selectedGenderName);
    const response = await api.onlineNow.getOnlineNow({
      pageIndex,
      pageSize: 32,
      sortBy: state.onlineNow.sortBy,
      type: shouldReset ? "update" : state.search.type,
      ageMin: state.onlineNow.selectedMinAge,
      ageMax: state.onlineNow.selectedMaxAge,
      distance: state.onlineNow.distance,
      genderId: selectedGenderId,
    });

    return response;
  }
);

export const resetIndex = createAsyncThunk(
  "onlineNow/resetIndex",
  async ({}, { getState }) => {
    const state = getState() as RootState;

    const selectedGenderId = getGenderId(state.onlineNow.selectedGenderName);

    const response = await api.onlineNow.getOnlineNow({
      pageIndex: 1,
      pageSize: 32,
      sortBy: state.onlineNow.sortBy,
      type: "read",
      ageMin: state.onlineNow.selectedMinAge,
      ageMax: state.onlineNow.selectedMaxAge,
      distance: state.onlineNow.distance,
      genderId: selectedGenderId,
    });

    return response;
  }
);

export const onlineNowSlice = createSlice({
  name: "onlineNow",
  initialState,
  reducers: {
    reset: () => initialState,

    setFetchingMoreResults: (state, action: PayloadAction<boolean>) => {
      state.fetchingResults = action.payload;
    },
    setSelectedMinAge: (state, action: PayloadAction<number>) => {
      state.selectedMinAge = action.payload;
    },
    setSelectedMaxAge: (state, action: PayloadAction<number>) => {
      state.selectedMaxAge = action.payload;
    },
    setSelectedGenderName: (state, action: PayloadAction<Gender>) => {
      state.selectedGenderName = action.payload;
    },
    setDistance: (state, action: PayloadAction<number>) => {
      state.distance = action.payload;
    },

    setSortBy(state, action: PayloadAction<"all" | "mostpopular" | "newest">) {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      const reset = action.meta.arg;
      state.fetchingResults = false;

      state.pageIndex += 1;
      state.type = "read";
      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;
      if (reset) {
        state.results = action.payload.data.list;
      } else {
        state.results = state.results.concat(action.payload.data.list);
      }

      searchResultsChangedSubject$.next();
    });

    builder.addCase(fetchResults.pending, (state, action) => {
      state.shouldReload = false;

      const reset = action.meta.arg;

      if (reset) {
        state.pageIndex = 1;
        state.results = [];
      }
      state.isLoading = true;
    });

    builder.addCase(fetchResults.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchingResults = false;
      state.error = action.payload as Error; // fix
    });

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.status === "ERROR") return;

      const { OnlineDefaultParams } = action.payload.data.infosProfile;

      state.selectedGenderName =
        OnlineDefaultParams.genderId == 2 ? "Woman" : "Man";
      state.selectedMaxAge = OnlineDefaultParams.ageMax;
      state.selectedMinAge = OnlineDefaultParams.ageMin;

      state.distance = OnlineDefaultParams.distance;

      state.preferencesLoaded = true;
    });

    builder.addCase(logout.fulfilled, () => initialState);

    builder.addCase(resetIndex.pending, (state) => {
      state.isLoading = true;
      state.pageIndex = 1;
      state.results = [];
    });

    builder.addCase(resetIndex.fulfilled, (state, action) => {
      state.fetchingResults = false;

      state.pageIndex += 1;
      state.type = "read";
      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;

      state.results = action.payload.data.list;
    });
  },
});

export const {
  reset,
  setSelectedMinAge,
  setSelectedMaxAge,
  setSelectedGenderName,
  setDistance,
  setSortBy,

  setFetchingMoreResults,
} = onlineNowSlice.actions;

export default onlineNowSlice.reducer;
