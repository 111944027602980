import Api, { HTTPResponse } from "./Api";

export default class Search extends Api {
  public async getSearch(queryParams: {
    pageIndex: number;
    pageSize: number;
    ageMin: number;
    ageMax: number;
    distance: number;
    lookingFor: number;
    sortBy: "all" | "mostpopular" | "newest";
    type: "update" | "read";
    hasPictureOnly: number;
    isCertifiedOnly: number;
    relationshipType: string;
    minHeight: number;
    maxHeight: number;
    AppPhysiqueId: string;
    cheveuxId: string;
    yeuxId: string;
    ethnieId: string;
    signeZodiaqueId: string;
    etatCivileId: string;
    enfantActuelId: string;
    enfantDesiresId: string;
    occupationId: string;
    voitureId: string;
    fumeurId: string;
    alcoolId: string;
    drogueId: string;
    lastVisitedDate: number;
    selectedPlaceName: string;

    countryId?: number;
    provinceId?: number;
    departementId?: number;
    cityId?: number;
    //
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any[];
      };
    }
  > {
    return this.get("/search", queryParams);
  }

  public async resetSearch(): Promise<HTTPResponse> {
    return this.put("/search/reset", {});
  }
}
