import Link from "next/link";
import React, { FC } from "react";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

type Props = {
  userImgSrc?: string;
  alt?: string;
  userName: string;
};

export const placeholderProfilePicture =
  "/assets/messages/blankProfilePicture.webp";

export const placeholderAltAttribute = "User without a profile picture";

const MessageBannerPicture: FC<Props> = ({
  userImgSrc = placeholderProfilePicture,
  alt = placeholderAltAttribute,
  userName,
}) => (
  <div className="MessageBannerPicture cursor-pointer">
    {userName ? (
      <OpenProfileLink username={userName}>
        <img
          className="w-[57px] min-w-[57px] h-[57px] rounded-[18px] border-2 border-custom-orange object-cover"
          alt={alt}
          src={userImgSrc}
        />
      </OpenProfileLink>
    ) : (
      <img
        className="w-[57px] min-w-[57px] h-[57px] rounded-[18px] border-2 border-custom-orange object-cover"
        alt={alt}
        src={userImgSrc}
      />
    )}
  </div>
);

export default MessageBannerPicture;
