import Api, { HTTPResponse } from "./Api";

export type ProfileInfos = {
  profilId: number;
  userId: number;
  userName: string;
  genre: string;
  genderId: number;
  mainImage: string;
  age: number;
  city: string;
  cityId: number;
  isVIP: boolean;
  isCertified: boolean;
  typeRecherche: string;
  taille: string;
  appPhysique: string;
  cheveux: string;
  yeux: string;
  ethnie: string;
  etatCivil: string;
  enfantActuel: string;
  enfantDesire: string;
  fumeur: string;
  alcool: string;
  drogue: string;
  occupation: string;
  profession: string;
  voiture: string;
  religion: string;
  signeZodiaque: string;
  maDescription: string;
  nbVues: number;
  dateInscrit: string;
  derniereVisite: string;
  isProfileActive: true;
  msgHeader: string;
  readyToRelocate: string;
  totalVoteProfil: number;
  prenom: string;
  firstDate: string;
  dob: string;
  sexeRecherche: string;
  countryId: number;
  paysDescription: string;
  provinceDescription: string;
  departementDescription: string;
  NbVisitedMyProfile: string;
  LastUpdate: string;
  isOnline: true;
  isVoted: true;
  isFavorited: true;
  isDateMeVoted: true;
  OptionsMessagerie: {
    sexeId: string;
    maxDistance: number;
    countriesAccepted: string;
    ageRange: string;
    DoNotSmoke: false;
    DoNotAlcool: false;
    DoNotTakeDrugs: false;
  };
  photos: [
    {
      id: number;
      path: string;
    }
  ];
  photos_grandFormat: [
    {
      id: number;
      path: string;
    }
  ];
  activities: [
    {
      id: number;
      description: string;
    }
  ];
  musics: [
    {
      id: number;
      description: string;
    }
  ];
  qualities: [
    {
      id: number;
      description: string;
    }
  ];
  entrevueFaceAFace: [
    {
      question: string;
      reponse: string;
    }
  ];
  animaux: [
    {
      id: number;
      animal: string;
    }
  ];
};

export type ProfileRequestSuccess = {
  status: "SUCCESS";
  http_code: 200;
  message: string;
  data: {
    informations: {
      allowedMessaging: boolean;
      allowedGreetings: boolean;
      allowedDateMe: boolean;
    };
    profileInfos: ProfileInfos;
  };
};

type ProfileRequestError = {
  status: "ERROR";
  http_code: 400;
  message:
    | "PROFILE_IS_DELETED"
    | "BLOCKED_BY_ME"
    | "BLOCKED_BY_THE_USER"
    | "NOT_ALLOWED_TO_VIEW_PROFILE";
  data: {
    informations: {
      allowedMessaging: false;
      allowedGreetings: false;
      allowedDateMe: false;
    };
    profileInfos: {
      profilId: -102;
      userId: -1;
      userName: "";
      genre: "";
      genderId: -1;
      mainImage: "";
      age: -1;
      city: "";
      cityId: -1;
      isVIP: false;
      isCertified: false;
      typeRecherche: "";
      taille: "";
      appPhysique: "";
      cheveux: "";
      yeux: "";
      ethnie: "";
      etatCivil: "";
      enfantActuel: "";
      enfantDesire: "";
      fumeur: "";
      alcool: "";
      drogue: "";
      occupation: "";
      profession: "";
      voiture: "";
      religion: "";
      signeZodiaque: "";
      maDescription: "";
      nbVues: 0;
      dateInscrit: "";
      derniereVisite: "";
      isProfileActive: false;
      msgHeader: "";
      readyToRelocate: "";
      totalVoteProfil: 0;
      prenom: "";
      firstDate: "";
      dob: "";
      sexeRecherche: "";
      countryId: -1;
      paysDescription: "";
      provinceDescription: "";
      departementDescription: "";
      NbVisitedMyProfile: "";
      LastUpdate: "";
      isOnline: false;
      isVoted: false;
      isFavorited: false;
      isDateMeVoted: false;
      OptionsMessagerie: {
        sexeId: "";
        maxDistance: "";
        countriesAccepted: "";
        ageRange: "";
        DoNotSmoke: false;
        DoNotAlcool: false;
        DoNotTakeDrugs: false;
      };
      photos: [];
      photos_grandFormat: [];
      activities: [];
      musics: [];
      qualities: [];
      entrevueFaceAFace: [];
      animaux: [];
    };
  };
};

export default class Profile extends Api {
  public async getProfile(queryParams: {
    username: string;
  }): Promise<ProfileRequestSuccess | ProfileRequestError> {
    return this.get("/profile", queryParams);
  }

  public async getIsAllowedToSendMessages(queryParams: {
    username: string;
  }): Promise<
    HTTPResponse & {
      data: {
        allowedToSendMessage: boolean;
        allowedToSendGreetings: boolean;
      };
    }
  > {
    return this.get("/profile/isAllowedToSendMessages", queryParams);
  }
}
