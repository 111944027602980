import React, { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import VerifPopupCheckList from "../verifPopupCheckList";
import VerifPopupSteps from "../verifPopupStep";

type Props = {};

const VerifPopupStep3: FC<Props> = () => {
  const { i } = useContext(TranslationContext);
  return (
    <div className="VerifPopupStep3 w-full flex flex-col items-end">
      <VerifPopupSteps
        currentStep={3}
        currentStepText={i(
          "Afterwards, you have to take two photos of yourself, while holding the sheet in one of your hands."
        )}
      />
      <div className="w-11/12 flex flex-col">
        <div className="md:flex items-center">
          <VerifPopupCheckList
            checkListText={i(
              "In the first picture, you have to make the number 1 with your fingers, as shown in the first picture."
            )}
          />
          <img
            src="/app/assets/confirmationImg1.png"
            alt="check mark icon"
            className="w-[136px] h-[126px] ml-6"
          />
        </div>
        <div className="md:flex items-center">
          <VerifPopupCheckList
            checkListText={i(
              "In the second picture, you have to make the number 2 with your fingers, as shown in the second picture."
            )}
          />
          <img
            src="/app/assets/confirmationImg.png"
            alt="check mark icon"
            className="w-[136px] h-[126px] ml-6 object-cover"
          />
        </div>
        <div className="flex items-center">
          <VerifPopupCheckList
            checkListText={i(
              "You can take selfie photos, sit in front of a mirror or ask a friend to help you."
            )}
          />
        </div>
        <div className="flex items-center">
          <VerifPopupCheckList
            checkListText={i(
              "For your photos to be accepted, it is very important that we can clearly see your hand holding the sheet of paper, the number you make with your fingers, your face and what is written on your paper."
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifPopupStep3;
