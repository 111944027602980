import { FC, useEffect, useRef } from "react";
import useUser from "../../hooks/useUser";

type Props = {
  googleid?: string;
};

/**
 * Make sure to wrap this component in an AdWrapper component to hide for VIP users
 * @component
 */
const SideBarAdContainer: FC<Props> = ({ googleid }) => {
  const hasPushedAdRef = useRef(false);
  const {
    userData: { isPremiumNoAds },
  } = useUser();

  useEffect(() => {
    if (isPremiumNoAds) return;
    if (hasPushedAdRef.current) return;

    hasPushedAdRef.current = true;

    const timeoutid = setTimeout(() => {
      try {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      } catch (_e) {}
    }, 350);

    return () => clearTimeout(timeoutid);
  }, [isPremiumNoAds]);

  if (isPremiumNoAds) return null;

  return (
    <div
      data-testid="sidebar-ad-container"
      className="SideBarAdContainer rounded-md bg-transparent w-300 h-600 shadow-custom-shadow"
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block", width: 300, height: 600 }}
        data-ad-client="ca-pub-6005108637849729"
        data-ad-slot={googleid || "6183297913"}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default SideBarAdContainer;
