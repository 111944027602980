import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import api from "../../backendAPI";
import { DOMAIN } from "../../backendAPI/Api";
import { DetailedMember, Member } from "../../backendProxies";
import useTranslation, {
  TranslationContext,
} from "../../context/TranslationContext";
import useToast from "../../hooks/useToast";
import useUser from "../../hooks/useUser";
import useWindowSize from "../../hooks/useWindowSize";
import { SettingsLinkRoutes } from "../../pages/settings";
import AdWrapper from "../AdWrapper";
import BodyAdContainerFlexible from "../bodyAdContainerFlexible";
import BodyAdContainerMobile from "../bodyAdContainerMobile";
import ButtonBGBlue from "../buttonBGBlue";
import NoMatchFound from "../noMatchFound";
import ProfileAboutMeContainer from "../profileAboutMeContainer";
import ProfileBlock from "../profileBlock";
import ProfileDate from "../profileDate";
import ProfileFullCard from "../profileFullCard";
import ProfileInterestContainer from "../profileInterestContainer";
import ProfileInterviewContainer from "../profileInterviewContainer";
import ProfileMailSettingsContainer from "../profileMailSettingsContainer";
import ProfilePhotosContainer from "../profilePhotosContainer";
import ProfileStatsContainer from "../profileStatsContainer";
import ShareButtons from "../shareButtons";
import SugarDaddyAd from "../SugarDaddyAd";

type ProfileModalScreen =
  | "profile"
  | "block"
  | "report"
  | "pictures"
  | "message"
  | "sayHello"
  | "mailSettings"
  | `report-pictures`
  | `unblock-${number}`;

export default function ProfileModal() {
  const [isOpen, setIsOpen] = useState(false);

  const [username, setUsername] = useState<string | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    //@ts-ignore
    let url = new URL(window.location);
    url.searchParams.delete("profile");
    history.replaceState({}, "", url);
  };

  useEffect(() => {
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handleUrlChange();
    };

    history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handleUrlChange();
    };

    const handleUrlChange = () => {
      //@ts-ignore
      let url = new URL(window.location);
      if (url.searchParams.get("profile")) {
        setUsername(url.searchParams.get("profile"));
        setIsOpen(true);
      } else {
        setUsername(null);
        setIsOpen(false);
      }
    };

    window.addEventListener("popstate", handleUrlChange);

    setTimeout(() => {
      handleUrlChange();
    }, 300);

    return () => {
      window.removeEventListener("popstate", handleUrlChange);
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
    };
  }, []);

  if (!username) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={(val) => {
          return false;
          handleClose();
        }}
      >
        <div
          onClick={() => handleClose()}
          className="fixed inset-0 bg-black/25 pointer-events-auto"
        />
        <div className="fixed w-screen h-[100dvh] ignore-google-ads-observer inset-0 overflow-hidden pointer-events-none">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300 delay-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex w-screen h-[100dvh] ignore-google-ads-observer xl:max-w-[1100px] xl:m-auto items-center justify-center p-3 text-center">
              <Dialog.Panel
                key={isOpen ? "open" : "closed"}
                className="flex min-h-full min-w-full max-h-full max-w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all pointer-events-auto"
              >
                <ProfileModal username={username} handleClose={handleClose} />
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );

  function ProfileModal({
    username,
    handleClose,
  }: {
    username: string;
    handleClose: () => void;
  }) {
    const [yScrollPosition, setYScrollPosition] = useState(0);
    const [screen, setScreen] = useState<ProfileModalScreen>("profile");
    const { i } = useTranslation();
    const { userData } = useUser();
    const { openTimedToast } = useToast();

    const profileUserIdRef = useRef<number | null>(null);

    const [detailedMember, setDetailedMember] =
      useState<DetailedMember | undefined>(undefined);

    const [isBlocked, setIsBlocked] = useState(false);
    const [youAreBlocked, setYouAreBlocked] = useState(false);
    const [isNotAllowToView, setIsNotAllowToView] = useState(false);
    const [isError, setIsError] = useState(false);

    const [isDeleted, setIsDeleted] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!scrollRef.current) return;
      if (screen === "profile") {
        scrollRef.current.scrollTop = yScrollPosition;
      } else {
        return;
      }

      const handleScroll = () => {
        setYScrollPosition(scrollRef.current?.scrollTop ?? 0);
      };

      scrollRef.current.addEventListener("scroll", handleScroll);

      return () => {
        scrollRef.current?.removeEventListener("scroll", handleScroll);
      };
    }, [screen]);

    useEffect(() => {
      api.profile.getProfile({ username }).then((res) => {
        // todo (investigate) why is this happening?
        if (!res || !res.data) throw new Error("No data");
        profileUserIdRef.current =
          res.data.profileInfos.userId > 0
            ? res.data.profileInfos.userId
            : null;
        if (res.status === "ERROR") {
          switch (res.message) {
            case "PROFILE_IS_DELETED":
              setIsDeleted(true);
              break;
            case "BLOCKED_BY_ME":
              setIsBlocked(true);
              break;
            case "BLOCKED_BY_THE_USER":
              setYouAreBlocked(true);
              break;
            case "NOT_ALLOWED_TO_VIEW_PROFILE":
              setIsNotAllowToView(true);
              break;
          }
          res.data.profileInfos.userName = username;
          setIsError(true);
        } else {
          setIsError(false);
        }
        setDetailedMember(
          new DetailedMember(res.data.profileInfos, res.data.informations)
        );
      });
    }, [username]);

    const handleAction = () => {
      if (profileUserIdRef.current === null) return;
      const userId = profileUserIdRef.current;
      if (youAreBlocked) {
        setScreen(`block`);
      } else if (isBlocked) {
        setScreen(`unblock-${userId}`);
      }
    };

    const unblockMember = (userId: number) => {
      api.blocked.deleteBlocked({ userId }).then(() => {
        window.location.reload();
      });
    };

    const blockMember = (userId: number) => {
      api.blocked
        .postBlocked({ userId })
        .then(() => {
          setIsBlocked(true);
        })
        .catch((e) => {
          openTimedToast({
            title: i("Error"),
            description: i("Unable to save changes."),
            type: "error",
          });
        });
    };

    return (
      <div className="max-h-full max-w-full w-full overflow-hidden flex flex-col ignore-google-ads-observer">
        {screen === "profile" ? (
          <div className="w-full flex justify-start shrink-0 p-6 pb-0">
            {/* <ClosePopUp onClick={handleClose} /> */}
            <button
              onClick={handleClose}
              className="pr-4 py-4 focus:outline-none"
            >
              <img src="/app/assets/backArrow.svg" alt="Back arrow" />
            </button>
          </div>
        ) : (
          <div className="flex flex-row items-center w-full border-b p-6 shrink-0 pb-4">
            <button
              onClick={() =>
                setScreen(screen === "report-pictures" ? "pictures" : "profile")
              }
              className="pr-4 py-4 focus:outline-none"
            >
              <img src="/app/assets/backArrow.svg" alt="Back arrow" />
            </button>
            {!isError && detailedMember && (
              <>
                <div className="relative z-0 mr-4">
                  <img
                    className="w-[48px] min-w-[48px] h-[48px] rounded-[18px] border-2 border-custom-orange object-cover"
                    alt={detailedMember.info.userName}
                    src={detailedMember.info.mainImage}
                  />
                </div>
                <div className="flex flex-col">
                  <MessageBannerUserInfo
                    name={detailedMember.info.prenom}
                    username={detailedMember.info.userName}
                    age={detailedMember.info.age}
                  />
                  <p className="text-custom-black text-sm">
                    {detailedMember.getLocation()}
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        {/* <CustomScrollBar disabled={screen === "message"} right={8} top={80}> */}
        <div
          ref={scrollRef}
          className={`overflow-y-auto justify-start custom-scrollbar-2 shrink overflow-x-hidden flex grow lg:flex-row lg:gap-[25px] pb-6 pl-6 pr-4 pt-3.5 mr-2 lg:pt-4 flex-col`}
        >
          {!isBlocked &&
            detailedMember &&
            (!isError || screen === "block") &&
            !isDeleted && (
              <LoadedProfilePageContent
                screen={screen}
                setScreen={setScreen}
                myProfile={userData}
                member={detailedMember}
              />
            )}
          {!youAreBlocked &&
            !isBlocked &&
            !detailedMember &&
            !isDeleted &&
            !isNotAllowToView && <LoadingProfilePageContent />}
          {isDeleted ? (
            <div className="w-full text-custom-black  text-center flex flex-col items-center">
              <NoMatchFound
                removeMargin
                description={
                  <span>
                    {i(
                      "The user's profile has been deleted. Therefore, you are unable to see the profile of"
                    )}
                    <span className="underline pl-2">{username}</span>
                  </span>
                }
                suggestion=" "
                imageSrc="/app/assets/deleteIcon.svg"
              />
              <Link href="/" passHref>
                <ButtonBGBlue
                  innerText={i("Return to home page")}
                  className="!w-fit mt-5"
                  onClick={() => {}}
                />
              </Link>
            </div>
          ) : isBlocked ? (
            screen.startsWith("unblock-") ? (
              <div className="flex flex-col w-full justify-start items-center">
                <ConfirmDialogContent
                  title={""}
                  action={""}
                  description={i(
                    isBlocked
                      ? "Are you sure you want to unblock this user?"
                      : "Are you sure you want to block this user?"
                  )}
                  confirmText={i("Yes I do")}
                  iconDescription={{
                    iconAlt: "delete icon",
                    iconSrc: "/app/assets/blueDeleteIcon.svg",
                  }}
                  handleConfirm={() => {
                    const userId = +screen.split("-")[1];
                    isBlocked ? unblockMember(userId) : blockMember(userId);
                    setScreen("profile");
                  }}
                  closePopup={() => setScreen("profile")}
                />
              </div>
            ) : (
              <div className="w-full text-custom-black  text-center flex flex-col items-center">
                <NoMatchFound
                  removeMargin={true}
                  description={
                    <span>
                      {i(
                        "You have blocked this user. Therefore, you are unable to see the profile of"
                      )}
                      <span className="underline pl-2">{username}.</span>
                    </span>
                  }
                  suggestion=" "
                  imageSrc="/app/assets/lockIcon.png"
                />
                <div className="mt-2 grid gap-6">
                  <button
                    onClick={handleAction}
                    className={`text-custom-blue items-center text-base font-medium rounded-[10px] text-center w-full`}
                  >
                    {i("Remove from list of blocked users")}
                  </button>
                  <Link
                    href={`/settings?tab=${SettingsLinkRoutes.BlockedMembers}`}
                    passHref
                  >
                    <a>
                      <ButtonBGBlue
                        innerText={i("Manage my blocked profiles")}
                        onClick={() => {}}
                      />
                    </a>
                  </Link>
                </div>
              </div>
            )
          ) : screen !== "block" && (youAreBlocked || isNotAllowToView) ? (
            <div className="w-full text-custom-black  text-center flex flex-col items-center">
              <NoMatchFound
                removeMargin={true}
                description={
                  <span>{i("Impossible to see the profile for now.")}</span>
                }
                suggestion=" "
                imageSrc="/app/assets/lockIcon.png"
              />
              {!isNotAllowToView && (
                <div className="mt-2 grid gap-6">
                  <button
                    onClick={handleAction}
                    className={`text-custom-blue items-center text-base font-medium rounded-[10px] text-center w-full`}
                  >
                    {i("Block this user")}
                  </button>

                  <ButtonBGBlue innerText={i("Close")} onClick={handleClose} />
                </div>
              )}
            </div>
          ) : null}
        </div>
        {/* </CustomScrollBar> */}
      </div>
    );
  }

  function LoadingProfilePageContent() {
    const { i } = useContext(TranslationContext);
    return (
      <div className="flex flex-row">
        <div className="w-full g:mb-[60px] flex flex-col gap-[25px]">
          {/* <div className="w-full">
            <BackButton />
          </div> */}
          <div className="w-full animate-pulse blur flex flex-col gap-[25px]">
            <ProfileFullCard
              isPlaceholder={true}
              member={{
                info: {
                  genre: i("Woman"),
                  genderId: "2",
                  age: 27,
                  mainImage:
                    "https://cdn.celibatairesduweb.com/img/Vignettes/AucuneImageFemme.gif",
                  isCertified: true,
                  userName: "Lorem Ipsum",
                  isVIP: true,
                  derniereVisite: "2020-01-01",
                  city: "Lorem Ipsum",
                  etatCivil: "Celibataire",
                  totalVoteProfil: 78,
                  dotColor: "green",
                },
                onSayHiClick: () => {},
                onSendMessageClick: () => {},
                onLikeClick: () => {},
              }}
            />
            <ProfileAboutMeContainer
              info={{
                maDescription:
                  "Bacon ipsum dolor amet shankle pancetta ground round doner alcatra, boudin ham pork flank jowl tenderloin rump tail kielbasa.",
                prenom: "Bacon",
                appPhysique: "Kielbasa turducken tongue tempor",
                sexeRecherche: "Magna corned beef",
                typeRecherche: "Lorem ipsum",
              }}
            />
            <ProfileStatsContainer
              info={{
                enfantActuel: "Lorem",
                enfantDesire: "Bacon",
                fumeur: "Lorem ipsum",
                alcool: "Shankle id bresaola",
                drogue: "Lorem Ipsum",
                occupation: "Nisi prosciutto consectetur est",
                firstDate:
                  "Pig venison cupidatat capicola pariatur velit ut pork meatball ribeye lorem frankfurter irure",
                ethnie: "Lorem ipsum",
                religion: "Lorem ipsum",
                signeZodiaque: "Bacon ipsum",
                voiture: "Lorem",
                readyToRelocate: "Lorem ipsum",
                profession: "Bacon ipsum dore",
              }}
            />
          </div>
        </div>
        <div className="blur animate-pulse hidden lg:flex flex-col w-[300px] gap-4">
          <BodyAdContainerMobile googleid="4422697526" />
          <ProfileDate
            label={i("Registered on")}
            formattedDate="17 Janvier 2021"
          />
          <ProfileDate label={i("Born on")} formattedDate="23 Janvier 2000" />
          <ShareButtons />

          {/*
          <ProfileAffinitiesContainer
            nbCommonMusicalTastes={1}
            nbCommonQualities={2}
            nbCommonActivitiesAndLeisure={3}
            affinityPercentage={86}
            userImgSrc={"/assets/match1.png"}
            otherUserImgSrc={"/assets/match.png"}
            />*/}

          <ProfileMailSettingsContainer
            mailSettings={{
              sexeId: "1",
              ageRange: "18;100",
              countriesAccepted: "18;",
              DoNotSmoke: false,
              DoNotAlcool: false,
              DoNotTakeDrugs: false,
            }}
          />
        </div>
      </div>
    );
  }

  function LoadedProfilePageContent({
    myProfile,
    member,
    screen,
    setScreen,
  }: {
    myProfile: any;
    member: DetailedMember;
    screen: ProfileModalScreen;
    setScreen: (screen: ProfileModalScreen) => void;
  }) {
    const { i } = useContext(TranslationContext);
    const [defaultCarouselIndex, setDefaultCarouselIndex] = useState(0);
    const sayHiState = useContext(SayHiContext);
    const { info } = member;
    const { userData } = useUser();
    const isModerator = userData?.role !== "";
    const handleClick = () => router.back();
    const router = useRouter();
    const { windowWidth } = useWindowSize();

    if (screen === "sayHello") {
      return (
        <SayHelloPopup
          imageSrc={sayHiState?.promptedUserImage ?? ""}
          imageAlt={sayHiState?.imageAlt ?? ""}
          onSettled={() => setScreen("profile")}
        />
      );
    } else if (screen === "message") {
      return (
        <ProfileModalMessageContent
          recipientUserId={+member.info.userId}
          recipientUserName={member.info.userName}
          onSend={() => setScreen("profile")}
        />
      );
    } else if (screen === "pictures") {
      return (
        <ProfilePhotoViewer
          isVIP={member.info.isVIP}
          isCertified={member.info.isCertified}
          member={member}
          imgSrcs={member.info.photos_grandFormat.map((photo) => photo.path)}
          age={member.info.age}
          city={member.getLocation()}
          defaultIndex={defaultCarouselIndex}
          onReport={() => setScreen("report-pictures")}
        />
      );
    } else if (screen === "block") {
      return (
        <div className="flex flex-col w-full justify-start items-center">
          <ConfirmDialogContent
            title={""}
            action={""}
            description={i("Do you really want to block this user?[username]", {
              username: "\n" + member.info.userName,
            })}
            confirmText={i("Yes")}
            iconDescription={{ iconAlt: "", iconSrc: "" }}
            handleConfirm={() => {
              member.addToBlockList();
              setScreen("profile");
            }}
            closePopup={() => setScreen("profile")}
          />
        </div>
      );
    } else if (screen === "report") {
      return (
        <ReportPopupContent
          profileId={+member.info.profilId}
          username={member.info.userName}
          type="profile"
          setIsOpen={(value) =>
            value ? setScreen("report") : setScreen("profile")
          }
        />
      );
    } else if (screen === "report-pictures") {
      return (
        <ReportPopupContent
          profileId={+member.info.profilId}
          username={member.info.userName}
          type="picture"
          setIsOpen={(value) =>
            value ? setScreen("report-pictures") : setScreen("pictures")
          }
        />
      );
    } else if (screen === "mailSettings") {
      return (
        <div className="flex flex-row justify-center w-full">
          <ProfileMailSettingsPopupContent
            mailSettings={member.info.OptionsMessagerie}
            username={member.info.userName}
            onClose={() => setScreen("profile")}
          />
        </div>
      );
    }

    return (
      <>
        <div className="lg:max-w-[calc(100%-325px)] w-full h-fit lg:pb-[60px] mb-4 flex flex-col gap-[25px] ignore-google-ads-observer">
          {isModerator && (
            <div className="flex justify-between cursor-pointer">
              <a
                href={DOMAIN + "/pages/profil_infos.php?n=" + info.userName}
                className="rounded h-fit border-2 shadow-sm px-2 py-1 text-base font-medium text-white border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
              >
                Modérateur
              </a>
            </div>
          )}
          <ProfileFullCard
            member={member}
            onOpenMailSettings={() => setScreen("mailSettings")}
            onSendMessage={() => setScreen("message")}
            onOpenGreetings={() => setScreen("sayHello")}
          />
          <div className="flex lg:hidden ignore-google-ads-observer">
            {windowWidth && windowWidth < 1024 && (
              <BodyAdContainerFlexible googleid="9616532964" />
            )}
          </div>
          <ProfilePhotosContainer
            member={member}
            onItemClick={(index: number) => {
              setDefaultCarouselIndex(index);
              setScreen("pictures");
            }}
          />
          <SugarDaddyAd />
          <ProfileAboutMeContainer info={info} />
          <ProfileStatsContainer info={info} />
          <ProfileInterestContainer
            title={i("Leisure and activities")}
            type="activities"
            tags={info.activities}
          />
          <ProfileInterestContainer
            title={i("Musical interests")}
            type="musics"
            tags={info.musics}
          />
          <ProfileInterestContainer
            title={i("Top 5 qualities")}
            type="qualities"
            tags={info.qualities}
          />
          <ProfileInterestContainer
            title={i("Animals")}
            type="animals"
            tags={info.animaux}
          />
          <ProfileInterviewContainer interviewItems={info.entrevueFaceAFace} />
        </div>
        <div className="flex flex-col lg:w-[300px] w-full gap-4">
          <AdWrapper>
            <div className="hidden lg:flex">
              {windowWidth && windowWidth >= 1024 && (
                <BodyAdContainerMobile googleid="4422697526" />
              )}
            </div>
            <div className="flex lg:hidden">
              {windowWidth && windowWidth < 1024 && (
                <BodyAdContainerFlexible googleid="3480031389" />
              )}
            </div>
          </AdWrapper>

          <ProfileDate
            label={i("Registered on")}
            formattedDate={info.dateInscrit}
          />
          {info.dob && (
            <ProfileDate label={i("Born on")} formattedDate={info.dob} />
          )}
          <ShareButtons
            shareUrl={i("https://vidarmora.com/profile/[username]", {
              username: info.userName,
            })}
            EmailButtonProps={{
              subject: i("Come see the profile of [username]", {
                username: info.userName,
              }),
              body: i(
                `Hello,${"\n"}I found a profile that might interest you. Here is the link:`
              ),
            }}
          />
          <ProfileBlock
            member={member}
            onBlock={() => setScreen("block")}
            onReport={() => setScreen("report")}
          />

          {myProfile && (
            <>
              {
                //todo: implement real affinity percentage (hide for the moment)
                /* 
              <ProfileAffinitiesContainer
                nbCommonMusicalTastes={3}
                nbCommonQualities={2}
                nbCommonActivitiesAndLeisure={1}
                affinityPercentage={86}
                userImgSrc={myProfile.mainImage}
                otherUserImgSrc={info.mainImage}
              /> */
              }
              <ProfileMailSettingsContainer
                mailSettings={info.OptionsMessagerie}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

import { ReportPopupContent } from "../../context/ReportPopupContext";
import { SayHiContext } from "../../context/SayHiContext";
import {} from "../../context/SendMessagePopupContext";
import { ConfirmDialogContent } from "../confirmDialog/ConfirmDialog";
import DateMeNavArrow from "../dateMeNavArrow";
import DateMePictureCounter from "../dateMePictureCounter";
import DateMeProfileInfo from "../dateMeProfileInfo";
import MessageBannerUserInfo from "../messageBannerUserInfo";
import ProfileMailSettingsPopupContent from "../profileMailSettingsPopupContent";
import ReportPictureButton from "../reportPictureButton";
import SayHelloPopup from "../sayHelloPopup";
import { ProfileModalMessageContent } from "./ProfileModalMessageContent";

const ProfilePhotoViewer: FC<{
  member: Member;
  imgSrcs: string[];
  age: number;
  city: string;
  isVIP?: boolean;
  isCertified?: boolean;
  defaultIndex?: number;
  onReport?: () => void;
}> = ({
  member,
  imgSrcs,
  age,
  city,
  isVIP = false,
  isCertified = false,
  defaultIndex = 0,
  onReport,
}) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);

  const onLeftArrowClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (currentIndex === 0) {
      setCurrentIndex(imgSrcs.length - 1);
    }
  };

  const onRightArrowClick = () => {
    if (currentIndex < imgSrcs.length - 1) {
      setCurrentIndex((currentIndex) => currentIndex + 1);
    }
    if (currentIndex === imgSrcs.length - 1) {
      setCurrentIndex(0);
    }
  };

  if (!member) return <></>;

  return (
    <div
      data-testid="carousel-popup"
      className="DateMeCarousel grow md:h-[600px] h-[432px] bg-custom-light-gray relative"
    >
      {/* <div className="absolute top-[20px] md:left-[40px] left-[28px] z-10">
          <DateMeProfileInfo
            username={member.info.userName}
            age={age}
            city={city}
            isVip={isVIP}
            isCertified={isCertified}
          />
        </div> */}

      <div className="absolute top-[20px] md:right-[40px] right-[28px] z-10 bg-custom-light-gray/50 p-1 rounded">
        <DateMePictureCounter
          currentPictureIndex={currentIndex + 1}
          maxNumberOfPictures={imgSrcs.length}
        />
      </div>

      <div className="absolute bottom-[20px] right-[28px] z-10">
        <ReportPictureButton
          profileId={+member.info.profilId}
          username={member.info.userName}
          setOpen={() => {}}
          onReport={onReport}
        />
      </div>
      <button
        className="absolute md:left-[25px] left-[10px] md:bottom-[292px] bottom-[204px] z-10"
        onClick={onLeftArrowClick}
      >
        <DateMeNavArrow direction="left" />
      </button>
      <img
        src={imgSrcs[currentIndex]}
        className="object-cover md:h-[600px] h-[432px] mx-auto"
        alt="Matched user image"
      />
      <button
        className="absolute md:right-[25px] right-[10px] md:bottom-[292px] bottom-[204px] z-10"
        onClick={onRightArrowClick}
      >
        <DateMeNavArrow direction="right" />
      </button>
    </div>
  );
};
