import React, { FC } from "react";
import { useContext } from "react";

import { ReportPopupContext } from "../../context/ReportPopupContext";

type Props = {
  profileId: number;
  username: string;
  onReport?: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportPictureButton: FC<Props> = ({
  profileId,
  username,
  setOpen,
  onReport,
}) => {
  const { openPopup } = useContext(ReportPopupContext);

  const handleClick = () => {
    if (onReport) return onReport();
    setOpen(false);
    openPopup(profileId, username, "picture");
  };

  return (
    <div
      data-testid="report-profile-photo-icon"
      onClick={handleClick}
      className="ReportPictureButton"
    >
      <img src="/app/assets/messages/reportIcon.svg" alt="report icon" />
    </div>
  );
};

export default ReportPictureButton;
