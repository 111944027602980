import { FC, useState } from "react";
import classNames from "../../utils/twClassNames";
import StyledTextAreaCounter from "../styledTextAreaCounter";

type Props = {
  placeholder?: string;
  defaultContent?: string;
  maxLength?: number;
  minLength?: number;
  onValueChange?: (value: string) => void;
};

const StyledTextArea: FC<Props> = ({
  placeholder,
  defaultContent = "",
  maxLength,
  minLength,
  onValueChange,
}) => {
  defaultContent = defaultContent.substring(0, maxLength);
  const [content, setContent] = useState(defaultContent);
  const [letterCount, setLetterCount] = useState(content.length);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!maxLength || letterCount <= maxLength) {
      if (onValueChange) onValueChange(event.target.value);
      setContent(event.target.value);
      setLetterCount(event.target.value.length);
    }
  };

  return (
    <div className="StyledTextArea flex w-full h-full relative">
      <textarea
        value={content}
        onChange={handleChange}
        maxLength={maxLength}
        className="text-area w-full h-full border border-custom-gray rounded-[4px] p-4 placeholder:text-base placeholder:text-custom-black-200"
        placeholder={placeholder}
      />
      {maxLength != undefined && minLength != undefined && (
        <div className="right-[15px] bottom-[15px] absolute">
          <StyledTextAreaCounter
            letterCount={letterCount}
            maxLength={maxLength}
          />
        </div>
      )}
      {minLength != undefined && letterCount < minLength && (
        <div className="right-[15px] bottom-[15px] absolute">
          <div
            className={classNames(
              "StyledTextAreaCounter text-custom-light-gray-600 text-xs",
              letterCount < minLength ? "text-red-500" : ""
            )}
          >
            {minLength > 0 && `${letterCount} / ${minLength}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default StyledTextArea;
