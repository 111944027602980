import React, { FC, Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ClosePopUp from "../closePopUp";
import SayHelloUserImage from "../sayHelloUserImage";
import SayHelloHeader from "../sayHelloHeader";
import SayHelloCard from "../sayHelloCard";
import SayHelloLong from "../sayHelloLong";
import SayHelloShort from "../sayHelloShort";
import SayHelloCustomPrompts from "../sayHelloCustomPrompts";
import SayHelloCustomMessage from "../sayHelloCustomMessage";
import ButtonBGBlue from "../buttonBGBlue";
import SayHelloCustom from "../sayHelloCustom";
import useSayHi from "../../context/SayHiContext";

import useToast from "../../hooks/useToast";

import { Greeting } from "../../context/SayHiContext";
import { TranslationContext } from "../../context/TranslationContext";
import api from "../../backendAPI";
import SayHelloScrollbar from "../sayHelloScrollbar";

type Props = {
  imageSrc: string;
  imageAlt: string;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSettled?: () => void;
};

const SayHelloPopup: FC<Props> = ({
  imageSrc,
  imageAlt,
  open,
  setOpen,
  onSettled,
}) => {
  const {
    recipientUserID,
    recipientUsername,
    currentPageIndex,
    setCurrentPageIndex,
  } = useSayHi();

  const { i } = useContext(TranslationContext);

  const [customMessage, setCustomMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState<Greeting | null>(null);

  const { openTimedToast } = useToast();

  const handleSubmitCustomMessage = async () => {
    if (!selectedOption || customMessage.length === 0 || !recipientUserID)
      return;

    const payload = {
      userId: recipientUserID,
      greetingId: 0,
      Personnaliser_imageId: selectedOption.Personnaliser_imageId,
      personnaliser_message: customMessage,
    };

    const response = await api.greetings.postGreeting(payload);

    if (response.status === "SUCCESS") {
      setCustomMessage("");
      setSelectedOption(null);
      if (setOpen) setOpen(false);
      if (onSettled) onSettled();

      openTimedToast({
        type: "success",
        title: i("Success"),
        description: response.data.message,
      });

      return;
    }

    if (response.status === "ERROR") {
      setCustomMessage("");
      setSelectedOption(null);
      if (setOpen) setOpen(false);
      if (onSettled) onSettled();
      openTimedToast({
        type: "error",
        title: i("Error"),
        description: response.message,
      });

      return;
    }
  };

  const currentPopUpPage = [
    <SayHelloLong
      recipientImg={imageSrc}
      recipientUsername={imageAlt}
      key={0}
      setCurrentPageIndex={setCurrentPageIndex}
      onSettled={onSettled}
    />,
    <SayHelloShort
      recipientImg={imageSrc}
      recipientUsername={imageAlt}
      key={1}
      setCurrentPageIndex={setCurrentPageIndex}
      onSettled={onSettled}
    />,

    <>
      <SayHelloCustom setCurrentPageIndex={setCurrentPageIndex}>
        <div className="SayHelloCustom w-full pb-10 flex flex-1 flex-col min-h-[335px]">
          <div className="mt-2 mb-4">
            <SayHelloHeader
              title={i("Choose your picture")}
              textAlign="center"
            />
          </div>
          <SayHelloCustomPrompts
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
          <div className="mt-4 mb-4">
            <SayHelloHeader title={i("Your message")} />
          </div>
          <SayHelloCustomMessage
            recipientUsername={recipientUsername}
            setValue={setCustomMessage}
          />
          <div className="pt-[18px] w-[139px] h-[50px]">
            <ButtonBGBlue
              innerText={i("Send")}
              onClick={() => {
                handleSubmitCustomMessage();
              }}
            />
          </div>
        </div>
      </SayHelloCustom>
    </>,
  ];
  const content =
    currentPageIndex === -1 ? (
      <>
        <div className="flex flex-col items-center">
          {/* <SayHelloUserImage imageSrc={imageSrc} /> */}
          <div className="mt-[22px] mb-[25px] text-center sm:text-left">
            <SayHelloHeader
              title={i("Choose the type of greeting you want to send")}
            />
          </div>
        </div>
        <div className="SayHelloCards flex sm:justify-center pb-4 xs:mb-6 flex-col w-full sm:flex-row sm:space-x-6 space-y-4 sm:space-y-0 items-center">
          <SayHelloCards setCurrentPageIndex={setCurrentPageIndex} />
        </div>
      </>
    ) : (
      <>{currentPopUpPage[currentPageIndex]}</>
    );

  return (
    <div
      className={`SayHelloPopup ${
        setOpen ? "" : "w-full flex flex-col max-w-[740px] mx-auto"
      }`}
    >
      {setOpen ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-[100] inset-0 overflow-y-auto"
            onClose={setOpen}
          >
            <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="flex overflow-hidden max-w-[800px] sm:min-h-[450px] sm:max-h-[650px] align-bottom bg-white top-10 rounded-lg px-4 pt-5 pb-3 text-left shadow-xl transform transition-all w-full sm:p-6 items-center">
                  <div className="flex flex-col h-full min-w-full">
                    {setOpen && (
                      <div className="w-full flex h-6 justify-end">
                        <ClosePopUp onClick={() => setOpen(false)} />
                      </div>
                    )}
                    {content}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        content
      )}
    </div>
  );
};

function SayHelloCards({
  setCurrentPageIndex,
}: {
  setCurrentPageIndex(index: number): void;
}) {
  const { i } = useContext(TranslationContext);

  const cards = [
    {
      id: 1,
      greetingText: i("Short"),
      iconSrc: "/app/assets/cardMember/wavingHandIcon.png",
      iconAlt: "Greeting icon",
      handleGoToNextPage: () => {
        setCurrentPageIndex(1);
      },
    },
    {
      id: 2,
      greetingText: i("Long"),
      iconSrc: "/app/assets/Icons/heartIcon.svg",
      iconAlt: "Heart icon",
      handleGoToNextPage: () => {
        setCurrentPageIndex(0);
      },
    },
    {
      id: 3,
      greetingText: i("Personalized"),
      iconSrc: "/app/assets/Icons/flower.png",
      iconAlt: "Flowers icon",
      handleGoToNextPage: () => {
        setCurrentPageIndex(2);
      },
    },
  ];

  return (
    <>
      {cards.map((card) => (
        <SayHelloCard key={card.id} {...card} />
      ))}
    </>
  );
}

export default SayHelloPopup;
