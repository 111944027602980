import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class HelpCenter extends Api {
  public getHelpCenter(): Promise<
    HTTPResponse & {
      status: string;
      http_code: number;
      message: string;
      data: {
        leftMenu: {
          title: string;
          url: string;
          children: {
            title: string;
            url: string;
          }[];
        }[];
        title: string;
        bodyHTML: string;
      }[];
    }
  > {
    return this.get("/help-center");
  }

  public getHelpCenterArticle(queryParamsMap: { urlId: string }): Promise<
    HTTPResponse & {
      status: string;
      http_code: number;
      message: string;
      data: {
        title: string;
        bodyHTML: string;
      }[];
    }
  > {
    return this.get("/help-center/article", queryParamsMap);
  }

  public getHelpCenterSeach(queryParamsMap: { q: string }): Promise<
    HTTPResponse & {
      status: string;
      http_code: number;
      message: string;
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: {
          title: string;
          body: string;
          url: string;
        }[];
      }[];
    }
  > {
    return this.get("/help-center/search", queryParamsMap);
  }
}
