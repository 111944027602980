import React, { HTMLAttributes } from "react";
import classNames from "../../utils/twClassNames";
import LoadingSpinner from "../loadingSpinner";

type Props = {
  isLoading?: boolean;
  innerText: any;
  onClick: (e?: any) => void | Promise<void>;
  testid?: string;
  disabled?: boolean;
  className?: string;
  type?: "submit" | "reset" | "button";
  style?: HTMLAttributes<HTMLButtonElement>["style"];
};

const ButtonBGBlue = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      innerText,
      onClick,
      testid,
      disabled,
      className = "",
      type = "button",
      style,
      isLoading = false,
    },
    ref
  ) => {
    return (
      <button
        type={type}
        disabled={disabled || isLoading}
        data-testid={testid}
        className={classNames(
          "ButtonBGBlue w-full inline-flex justify-center items-center rounded h-[50px] border-2 shadow-sm px-4 py-2  text-lg font-medium text-white",
          className,
          disabled
            ? "border-custom-light-blue bg-custom-light-blue"
            : "border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
        )}
        onClick={onClick}
        ref={ref}
        style={style}
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-8">
            <div className="animate-spin flex">
              <img src="/app/assets/spinner.png" alt="spinner" />
            </div>
          </div>
        ) : (
          innerText
        )}
      </button>
    );
  }
);

ButtonBGBlue.displayName = "ButtonBGBlue";

export default ButtonBGBlue;
