import Api, { HTTPResponse } from "./Api";

export default class OnlineNow extends Api {
  public async getOnlineNow(queryParams: {
    pageIndex: number;
    pageSize: number;
    ageMin: number;
    ageMax: number;
    distance: number;
    genderId: number;
    sortBy: "all" | "mostpopular" | "newest";
    type: "update" | "read";
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any[];
      };
    }
  > {
    return this.get("/online", queryParams);
  }
}
