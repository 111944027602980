import { FC, useEffect, useState } from "react";
import SettingsChangePasswordField from "../settingsChangePasswordField";
import ButtonBGBlue from "../buttonBGBlue";
import api from "../../backendAPI";
import useTranslation from "../../context/TranslationContext";
import useToast from "../../hooks/useToast";
import useAuth from "../../hooks/useAuth";

const SettingsPassword: FC = () => {
  const { i } = useTranslation();
  const { loadToken } = useAuth();
  const { openToast, closeToast } = useToast();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const [newPasswordError, setNewPasswordError] = useState(false);
  const [repeatNewPasswordError, setRepeatNewPasswordError] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (newPasswordError) {
      timeoutId = setTimeout(() => {
        setNewPasswordError(false);
      }, 10000);
      return;
    }

    if (repeatNewPasswordError) {
      timeoutId = setTimeout(() => {
        setRepeatNewPasswordError(false);
      }, 10000);
    }

    return () => clearTimeout(timeoutId);
  }, [newPasswordError, repeatNewPasswordError]);

  const isDisabled = () => !oldPassword || !newPassword || !repeatNewPassword;

  const clearForm = () => {
    setOldPassword("");
    setNewPassword("");
    setRepeatNewPassword("");
  };

  const handleSubmit = async () => {
    if (isDisabled()) return;

    if (oldPassword === newPassword) {
      setNewPasswordError(true);
      return;
    }

    if (newPassword !== repeatNewPassword) {
      setRepeatNewPasswordError(true);
      return;
    }

    const response = await api.editProfile.patchEditPassword({
      password: oldPassword,
      newpassword: newPassword,
      confirmNewpassword: repeatNewPassword,
    });

    if (response.status === "ERROR") {
      openToast({
        title: i("Error"),
        description: response.message,
        type: "error",
      });
    } else {
      loadToken(response.data.tokens);
      openToast({
        title: i("Success"),
        description: response.data.message,
        type: "success",
      });
      clearForm();
    }

    setTimeout(() => {
      closeToast();
    }, 5000);
  };

  return (
    <div className="SettingsPassword">
      <p className="text-lg font-semibold">{i("Modify password")}</p>
      <p className="mb-4 text-lg  pt-2">
        {i("Please fill out the following fields to change password")}
      </p>
      <div className="mb-7">
        <SettingsChangePasswordField
          label={i("Old password")}
          placeholder={i("Enter old password")}
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
        />
      </div>
      <div className="mb-7">
        <SettingsChangePasswordField
          label={i("New password")}
          placeholder={i("Enter new password")}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
      </div>
      <div className="mb-7">
        <SettingsChangePasswordField
          label={i("Repeat new password")}
          placeholder={i("Repeat new password")}
          value={repeatNewPassword}
          onChange={(event) => setRepeatNewPassword(event.target.value)}
        />
      </div>
      {newPasswordError && (
        <p
          data-testid="settings-password-same-as-old"
          className="text-custom-red font-semibold"
        >
          {i("The new password must be different than the old one.")}
        </p>
      )}

      {repeatNewPasswordError && (
        <p
          data-testid="settings-password-repeat-not-equal"
          className="text-custom-red font-semibold"
        >
          {i("The new password and the repeat new password are different.")}
        </p>
      )}

      <div className="w-fit mt-8">
        <ButtonBGBlue
          innerText={i("Change password")}
          disabled={isDisabled()}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SettingsPassword;
