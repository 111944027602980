import { ComponentProps } from "react";
import VerifPopupPictureContainer from "./VerifPopupPictureContainer";

export const docProps: ComponentProps<typeof VerifPopupPictureContainer> = {
  imageSrc: "/assets/confirmationImage.png",
  imageAlt: "confirmation user image",
  buttonText: "Ajouter ma photo #1",
};

export default VerifPopupPictureContainer;
