import React, { Fragment, FC } from "react";
import { useRouter } from "next/router";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import useTranslation, { Languages } from "../../context/TranslationContext";
import api from "../../backendAPI";
import classNames from "../../utils/twClassNames";
import {
  LoadingIndicatorLocks,
  showLoadingIndicator,
} from "../loadingIndicator/LoadingIndicator";
import { DEFAULT_LANGUAGE } from "../../context/TranslationContext/utils";

type Props = {
  availableLangs: ("English" | "French")[];
  buttonClassName?: string | false;
};

const FooterLangDropdown: FC<Props> = ({ buttonClassName = false }) => {
  const { lang, setLang, languages } = useTranslation();
  const router = useRouter();
  const currentLang = lang || DEFAULT_LANGUAGE;

  const handleLangChange = (value: keyof typeof languages) => {
    if (value === lang) return;
    showLoadingIndicator(LoadingIndicatorLocks.Redirection);
    setLang(value);
    api.editProfile.updateDisplayLang(value).finally(router.reload);
  };

  return (
    <div className="dropdown flex justify-center">
      <Menu as="div" className="relative inline-block text-left w-full">
        <Menu.Button
          data-testid="footer-lang-dropdown-button"
          className={classNames(
            "inline-flex justify-center items-center w-full rounded-md px-4 py-1 bg-transparent text-md font-medium hover:bg-transparent focus:outline-none",
            buttonClassName
          )}
        >
          {languages[currentLang]}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute bottom-full right-0 mt-2 w-full rounded-md shadow-lg drop-shadow-2xl border-1 bg-white ring-1 ring-black ring-opacity-10 focus:outline-none">
            {(Object.keys(languages) as Languages[]).map((langCode) => {
              return (
                <Menu.Item key={langCode}>
                  {({ active }) => (
                    <span
                      onClick={() => handleLangChange(langCode)}
                      className={classNames(
                        active
                          ? "bg-gray-100 text-black rounded-md"
                          : "text-black",
                        "block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
                      )}
                    >
                      {languages[langCode]}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default FooterLangDropdown;
