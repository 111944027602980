import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export type Article = {
  id: number;
  title: string;
  article: string;
  mainImage: string;
  postedDate: string;
  url: string;
};

export default class DatingAdvice extends Api {
  public async getAdvices(queryParams: {
    pageIndex: number;
    pageSize: number;
    q?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        previousSearch: string;
        items: number;
        total: number;
        list: Article[];
        message?: string;
      };
    }
  > {
    return this.get("/blogs", queryParams);
  }
}
