import React, { FC } from "react";
import { IDiscussion } from "../../backendAPI/Messages";

type Props = {
  name: string;
  username: string;
  age: number;
  conversation?: IDiscussion;
};

const MessageBannerUserInfo: FC<Props> = ({
  name,
  username,
  age,
  conversation,
}) => {
  const isSystem =
    conversation?.discussionId == -1 || conversation?.discussionId == -2;

  return (
    <div
      id={`messenger-banner-user-info-for-user-${username}`}
      data-testid={`messenger-banner-user-info-for-user-${username}`}
      className="MessageBannerUserInfo "
    >
      <div className="text-base leading-5 font-semibold text-custom-black-500 text-left">
        <div className="flex w-full ">
          <p className="truncate w-full">
            {conversation?.isVIP && (
              <img
                src="/app/assets/vipcard.svg"
                className="inline w-[30px] object-cover mr-1"
                alt="VIP"
              />
            )}
            {username}
            {`${isSystem ? "" : ","}`}
            {age ? ` ${age}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageBannerUserInfo;
