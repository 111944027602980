import Api, { HTTPResponseWithDataMessage } from "./Api";

export default class LiveAlerts extends Api {
  public async postLiveAlerts(body: {
    userId: number;
    discussionId: number;
    action: "isTyping" | "stopTyping" | "isReading" | "isCalling";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/liveAlerts", body);
  }
}
