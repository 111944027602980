import React, { FC, useContext } from "react";
import { AlertMessageContext } from "../../context/AlertMessageContext";
import { TranslationContext } from "../../context/TranslationContext";

type Props = { url: string; children: React.ReactNode };

const LinkShareButton: FC<Props> = ({ url, children }) => {
  const { i } = useContext(TranslationContext);
  const { pushAlert } = useContext(AlertMessageContext);
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(url).then((_) => {
          pushAlert({
            title: i("Success"),
            message: i("Link copied to clipboard!"),
            type: "success",
            time: 2000,
          });
        });
      }}
    >
      {children}
    </button>
  );
};

export default LinkShareButton;
