import { FC, useContext, useEffect, useRef } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import useCertification from "../../hooks/useCertification";
import ButtonBGBlue from "../buttonBGBlue";
import VerifPopupImage from "../verifPopupImage";

type Props = { closeModal: () => any };

const VerifConfirmationSection: FC<Props> = ({ closeModal }) => {
  const { i } = useContext(TranslationContext);

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);
  const {
    firstImageSrcNoWebcam,
    secondImageSrcNoWebcam,
    submittedImagePhoto1,
    submittedImagePhoto2,
  } = useCertification();

  return (
    <div className="VerifConfirmationSection text-lg text-center flex flex-col items-center">
      <div ref={ref} className="flex flex-row sm:space-y-0 sm:space-x-11">
        <VerifPopupImage
          imageAlt="first user confirmation image"
          imageSrc={submittedImagePhoto1 || firstImageSrcNoWebcam}
        />

        <VerifPopupImage
          imageSrc={submittedImagePhoto2 || secondImageSrcNoWebcam}
          imageAlt="second user confirmation image"
        />
      </div>
      <p className="font-semibold pt-10 pb-3">{i("Thank you!")}</p>
      <p className="">{i("Your photos will be verified shortly.")}</p>
      <div className="w-full max-w-[188px] mt-[22px]">
        <ButtonBGBlue
          innerText={i("Done")}
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </div>
  );
};

export default VerifConfirmationSection;
