import { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  children: React.ReactNode;
  className?: string;
};
/**
 * Chilren should be StyledListItem (s)
 */
const StyledList: FC<Props> = ({ children, className = "" }) => (
  <div
    className={classNames("StyledList flex flex-col space-y-[14px]", className)}
  >
    {children}
  </div>
);

export default StyledList;
