import Link from "next/link";
import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

const FooterLinks: FC = () => {
  const { i } = useContext(TranslationContext);
  const links = [
    {
      name: i("Help"),
      destination: "/help",
    },
    {
      name: i("Contact us"),
      destination: "/contact-us",
    },
  ];
  return (
    <div className="FooterLinks flex text-white flex-col my-6 lg:my-0 lg:flex-row w-full sm:w-1/2 space-y-4 lg:space-y-0 justify-end lg:space-x-5 mr-4">
      {links.map((link, index) => (
        <Link key={index} href={link.destination}>
          <a className="hover:underline">
            <p className="cursor-pointer">{link.name}</p>
          </a>
        </Link>
      ))}
    </div>
  );
};

export default FooterLinks;
