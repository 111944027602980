import { Languages } from "../context/TranslationContext";

export function checkIfHasMore(
  data: {
    items: number;
    pageIndex: number | string;
    total: number | string;
  },
  pageSize: number | string
): boolean {
  const hasMoreItems = data.items !== 0;

  const numberOfItemsIsNotEqualToTotal = data.items !== data.total;

  const pageSizeIsSmallerThanNumberOfItems = +pageSize < data.items;

  const lowerThanTotal = data.items * +data.pageIndex < +data.total;

  return (
    hasMoreItems &&
    numberOfItemsIsNotEqualToTotal &&
    (pageSizeIsSmallerThanNumberOfItems || lowerThanTotal)
  );
}
