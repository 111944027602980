import React, { FC, useContext, useRef, useEffect } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import VerifPictureContainer from "../verifPictureContainer";
import VerifPopupFooter from "../verifPopupFooter";

type Props = {
  handleGoToNextPage: () => any;
};

const VerifPopupImageConfirmationContainer: FC<Props> = ({
  handleGoToNextPage,
}) => {
  const { i } = useContext(TranslationContext);

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div className="VerifPopupImageConfirmationContainer pb-12">
      <p
        ref={ref}
        className="m-auto font-semibold text-lg max-w-[496px] text-center mb-10 pt-[76px]"
      >
        {i(
          "Now it's up to you. Certify your profile and get all these privileges 100% free"
        )}
      </p>
      <VerifPictureContainer handleGoToNextPage={handleGoToNextPage} />
      <VerifPopupFooter />
    </div>
  );
};

export default VerifPopupImageConfirmationContainer;
