import { FC, useContext } from "react";
import ButtonBGBlue from "../buttonBGBlue";
import PopUpHeader from "../popUpHeader";
import PopUpSubheader from "../popUpSubheader";
import { MailSettings } from "../profileMailSettingsContainer/ProfileMailSettingsContainer";
import StyledList from "../styledList";
import StyledListItem from "../styledListItem";
import { TranslationContext } from "../../context/TranslationContext";
import useUser from "../../hooks/useUser";

type Props = {
  mailSettings: MailSettings;
  username: string;
  onClose: () => void;
};
const sexe = {
  "-1": "man or woman",
  "1": "man",
  "2": "woman",
  "1;2": "man or woman",
} as const;

const ProfileMailSettingsPopupContent: FC<Props> = ({
  mailSettings,
  username,
  onClose,
}) => {
  const { i } = useContext(TranslationContext);
  const { userData } = useUser();

  const {
    sexeId,
    ageRange,
    countriesAccepted,
    DoNotSmoke,
    DoNotAlcool,
    DoNotTakeDrugs,
    area,
    maxDistance,
  } = mailSettings;

  const fromAge = ageRange.substring(0, ageRange.indexOf(";"));
  const toAge = ageRange.substring(ageRange.indexOf(";") + 1, ageRange.length);
  const showSexe = !!sexeId;
  const showAgeRange = !!ageRange;

  //@ts-ignore
  const mustBeSexe = sexe[sexeId];

  return (
    <div className="md:w-600 w-auto h-auto flex-col space-y-3 flex items-center pt-[57px] px-8 pb-8">
      <img
        className="pb-3"
        alt={noMatchIconAlt}
        src="/app/assets/profile/noMatchMailIcon.svg"
      />
      <PopUpHeader>
        {i("We are sorry, but you cannot send a message to [username]", {
          username: <b>{username}</b>,
        })}
      </PopUpHeader>
      <PopUpSubheader>
        {i(
          "To be able to send them a message, you must match their criterias:"
        )}
      </PopUpSubheader>
      <StyledList className="pb-3">
        {showSexe && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i("Be a <b>[gender]</b>", { gender: i(mustBeSexe) }),
              }}
            />
          </StyledListItem>
        )}
        {showAgeRange && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i(
                  "Be between <b>[fromAge]</b> and <b>[toAge]</b> years old",
                  { fromAge, toAge }
                ),
              }}
            />
          </StyledListItem>
        )}
        {area === "city" && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i(
                  "Be in the same city or within <b>[maxDistance] [distanceUnit]</b>",
                  { maxDistance, distanceUnit: userData.distanceUnit }
                ),
              }}
            />
          </StyledListItem>
        )}
        {countriesAccepted && (
          <StyledListItem>
            {i("Reside in one of these countries:")} <b>{countriesAccepted}</b>
          </StyledListItem>
        )}
        {DoNotSmoke && <StyledListItem>{i("Not smoke")}</StyledListItem>}
        {DoNotAlcool && <StyledListItem>{i("Not take alcool")}</StyledListItem>}
        {DoNotTakeDrugs && <StyledListItem>{i("Not do drugs")}</StyledListItem>}
      </StyledList>
      <div className="md:w-[155px] w-full">
        <ButtonBGBlue innerText="OK" onClick={onClose} />
      </div>
    </div>
  );
};

export const noMatchIconAlt = "no match icon";

export default ProfileMailSettingsPopupContent;
