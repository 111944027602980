import React, { FC, useContext, useState } from "react";
import api from "../../backendAPI";
import { AlertMessageContext } from "../../context/AlertMessageContext";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  initialNbVotes: number;
  userId: number;
  initiallyVoted: boolean;
};

const VoteActionButton: FC<Props> = ({
  initialNbVotes,
  userId,
  initiallyVoted,
}) => {
  const { i } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isVoted, setIsVoted] = useState(initiallyVoted);
  const [nbVotes, setNbVotes] = useState(initialNbVotes);
  const { pushAlert } = useContext(AlertMessageContext);

  const onClick = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const current = isVoted;
    setNbVotes((prev) => prev + (current ? -1 : 1));
    setIsVoted((prev) => !prev);
    const response = current
      ? await api.votes.deleteVote({
          userId,
        })
      : await api.votes.postVote({
          userId,
        });

    if (response.http_code !== 200) {
      setIsVoted((prev) => !prev);
      setNbVotes((prev) => prev + (current ? 1 : -1));
      pushAlert({
        title: i("Error"),
        message: i("Failed. Please try again later!"),
        type: "error",
        time: 2000,
      });
    }
    setIsLoading(false);
  };

  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      type="button"
      className="VoteActionButton tooltip cursor-pointer inline-flex h-[50px] bg-opacity-10 items-center px-[18px] py-1.5 border border-transparent text-lg font-medium rounded-xl text-custom-black-500 bg-custom-light-blue focus:outline-none"
    >
      <svg
        className="mr-2.5"
        width="30"
        height="27"
        viewBox="0 0 30 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!isVoted && (
          <path
            d="M28.4662 9.65148C27.8801 8.97599 27.1557 8.43433 26.342 8.06318C25.5283 7.69202 24.6443 7.50004 23.75 7.50023H18.7637L19.1837 4.94898C19.3323 4.05056 19.1531 3.12865 18.6786 2.3514C18.2041 1.57416 17.466 0.993369 16.599 0.714968C15.732 0.436566 14.7937 0.479104 13.9555 0.834822C13.1172 1.19054 12.4347 1.83574 12.0325 2.65273L9.64 7.50023H6.25C4.593 7.50222 3.00445 8.16133 1.83277 9.33301C0.661102 10.5047 0.00198482 12.0932 0 13.7502L0 20.0002C0.00198482 21.6572 0.661102 23.2458 1.83277 24.4175C3.00445 25.5891 4.593 26.2483 6.25 26.2502H22.875C24.3793 26.2441 25.8315 25.6981 26.9672 24.7117C28.103 23.7253 28.847 22.3639 29.0637 20.8752L29.945 14.6252C30.0691 13.7387 30.0013 12.8358 29.7462 11.9776C29.491 11.1195 29.0546 10.3263 28.4662 9.65148ZM2.5 20.0002V13.7502C2.5 12.7557 2.89509 11.8018 3.59835 11.0986C4.30161 10.3953 5.25544 10.0002 6.25 10.0002H8.75V23.7502H6.25C5.25544 23.7502 4.30161 23.3552 3.59835 22.6519C2.89509 21.9486 2.5 20.9948 2.5 20.0002ZM27.4637 14.274L26.5812 20.524C26.4522 21.4165 26.0071 22.233 25.327 22.8251C24.6468 23.4172 23.7767 23.7455 22.875 23.7502H11.25V9.66773C11.3678 9.5651 11.4653 9.4413 11.5375 9.30273L14.2737 3.75898C14.3764 3.57389 14.5214 3.41579 14.697 3.29768C14.8726 3.17957 15.0738 3.10481 15.2839 3.07955C15.494 3.05429 15.7071 3.07926 15.9057 3.15239C16.1043 3.22552 16.2827 3.34473 16.4262 3.50023C16.5491 3.64304 16.6388 3.81119 16.6892 3.99269C16.7395 4.17419 16.7492 4.36456 16.7175 4.55023L16.0575 8.55023C16.0285 8.72896 16.0387 8.91184 16.0874 9.08624C16.1361 9.26063 16.222 9.42237 16.3394 9.56027C16.4567 9.69817 16.6026 9.80893 16.7669 9.88489C16.9313 9.96086 17.1102 10.0002 17.2912 10.0002H23.75C24.2867 10.0002 24.8173 10.1153 25.3057 10.3379C25.7941 10.5605 26.229 10.8854 26.5811 11.2905C26.9332 11.6957 27.1942 12.1717 27.3464 12.6864C27.4987 13.2011 27.5387 13.7425 27.4637 14.274Z"
            fill="#2030F2"
          />
        )}

        {isVoted && (
          <g>
            <path d={filledD} fill="#2030F2"></path>
          </g>
        )}
      </svg>
      {nbVotes}
      <div className="relative -left-2 top-7">
        <p className="tooltiptext w-20 text-base font-normal">{i("Like")}</p>
      </div>
    </button>
  );
};

const filledD = `
M 28.4662 9.65148
C 27.8801 8.97599 27.1557 8.43433 26.342 8.06318
C 25.5283 7.69202 24.6443 7.50004 23.75 7.50023
H 18.7637
L 19.1837 4.94898
C 19.3323 4.05056 19.1531 3.12865 18.6786 2.3514
C 18.2041 1.57416 17.466 0.993369 16.599 0.714968
C 15.732 0.436566 14.7937 0.479104 13.9555 0.834822
C 13.1172 1.19054 12.4347 1.83574 12.0325 2.65273L9.64 7.50023
H 6.25
C 4.593 7.50222 3.00445 8.16133 1.83277 9.33301
C 0.661102 10.5047 0.00198482 12.0932 0 13.7502L0 20.0002
C 0.00198482 21.6572 0.661102 23.2458 1.83277 24.4175
C 3.00445 25.5891 4.593 26.2483 6.25 26.2502 
H 22.875
C 24.3793 26.2441 25.8315 25.6981 26.9672 24.7117
C 28.103 23.7253 28.847 22.3639 29.0637 20.8752L29.945 14.6252
C 30.0691 13.7387 30.0013 12.8358 29.7462 11.9776
C 29.491 11.1195 29.0546 10.3263 28.4662 9.65148
Z
M 2.5 20.0002V13.7502
C 2.5 12.7557 2.89509 11.8018 3.59835 11.0986
C 4.30161 10.3953 5.25544 10.0002 6.25 10.0002
H 8.75V23.7502
H 6.25
C 5.25544 23.7502 4.30161 23.3552 3.59835 22.6519
C 2.89509 21.9486 2.5 20.9948 2.5 20.0002
Z
`;

export default VoteActionButton;
