import { useContext } from "react";
import Link from "next/link";

import { NavigationContext } from "../../context/NavigationContext";
import { TranslationContext } from "../../context/TranslationContext";
import isVidamora from "../../utils/isVidamora";

export default function NavBarLinks() {
  const { currentPage } = useContext(NavigationContext);

  return (
    <>
      <div className="NavBarLinks flex items-center w-0 lg:w-full xl:w-full h-full justify-center lg:justify-end">
        <div className="hidden w-full h-full lg:flex justify-center">
          {pages.map((page, index) => (
            <NavBarLink
              pageName={page.name}
              destination={page.destination}
              key={index}
              isCurrentPage={page.destination === currentPage}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function NavBarLink({
  pageName,
  destination,
  isCurrentPage,
}: {
  pageName: PageName;
  destination: string;
  isCurrentPage: boolean;
}) {
  const { i } = useContext(TranslationContext);

  if (pageName === "Forum" && isVidamora()) return null;

  return (
    <div className="NavBarLink text-custom-blue inline-flex items-center px-2 pt-1 h-full text-base font-normal justify-center text-center">
      <Link href={destination}>
        <a
          data-testid={`top-navbar-link-for-${destination}`}
          className="flex h-full items-center"
        >
          <div className="flex flex-col h-full justify-center items-center overflow-x-hidden relative">
            <p className="min-w-24 px-2 pb-[2px] h-fit font-medium">
              {i(pageName)}
            </p>
            <div
              data-testid="is-current-page"
              className={`${
                isCurrentPage ? "" : "nav-fill"
              } border-2 m-auto w-full border-custom-blue absolute bottom-0`}
            />
          </div>
        </a>
      </Link>
    </div>
  );
}

type Page = {
  name: PageName;
  destination: string;
};

type PageName = "Online now" | "Search" | "Date me" | "Dating advice" | "Forum";

export const pages: Page[] = [
  { name: "Online now", destination: "/online-now" },
  { name: "Search", destination: "/search" },
  { name: "Date me", destination: "/date-me" },
  {
    name: "Dating advice",
    destination: "/dating-advice",
  },
  {
    name: "Forum",
    destination: "/forum",
  },
];
