import React, { FC, useContext } from "react";

import { Fragment, useState, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { PickerValue } from "../../types";
import FilterCategoryTitle from "../filterCategoryTitle";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  label?: string;
  placeholder?: string;
  request: (input: string) => Promise<PickerValue[]>;
  selected?: PickerValue | undefined;
  setSelected: React.Dispatch<React.SetStateAction<PickerValue | null>>;
  cbWhenNothingFound?: () => void;
  otherOptionsWhenNothingFoundText?: string;
  hasFallback?: boolean;
  alwaysShowAll?: boolean;
  hasError?: boolean;
};

const StyledAsyncCombobox: FC<Props> = ({
  label,
  placeholder,
  request,
  selected,
  setSelected,
  cbWhenNothingFound,
  otherOptionsWhenNothingFoundText,
  hasFallback = true,
  alwaysShowAll = false,
  hasError,
}) => {
  const [query, setQuery] = useState("");
  const [values, setValues] = useState<PickerValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { i } = useContext(TranslationContext);

  useDebounceEffect(() => {
    if (isLoading)
      request(query).then((newValues) => {
        setValues(newValues);
        setIsLoading(false);
      });
  }, 250);

  useEffect(() => {
    setIsLoading(true);
  }, [query, request]);

  const filteredValues = alwaysShowAll
    ? values
    : values.filter((value: PickerValue) =>
        value.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      );

  return (
    <Combobox value={selected} onChange={setSelected}>
      {label && (
        <Combobox.Label>
          <FilterCategoryTitle title={label} />
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Input
          className={`w-full h-[42px] border border-custom-gray rounded-[4px] p-2 sm:placeholder:text-base placeholder:text-sm placeholder:text-custom-black-200 text-sm sm:text-base ${
            hasError ? "border-[#CC0123] border-2" : ""
          }`}
          displayValue={(value: PickerValue) => value?.name || ""}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-60 rounded py-1 focus:outline-none overflow-auto text-sm sm:text-base">
            {filteredValues.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                {isLoading && i("Searching...")}
                {!isLoading && (
                  <p>{i("No city found. Verify the spelling.")}</p>
                )}
              </div>
            ) : (
              filteredValues.map((value) => (
                <Combobox.Option
                  key={value.id}
                  className={({ active }) =>
                    `cursor-default select-none focus:outline-none relative py-2 pl-3 pr-6 text-custom-blue ${
                      active && "bg-custom-light-blue bg-opacity-20"
                    }`
                  }
                  value={value}
                >
                  {({ selected }) => (
                    <>
                      <span className="block truncate font-normal">
                        {value.name}
                      </span>
                      {selected && (
                        <span className="text-custom-blue absolute inset-y-0 right-0 flex items-center pr-2">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
            {hasFallback && (
              <div className="relative cursor-default select-none py-2 px-4 bg-custom-gray/30">
                <p>
                  {i("or [clickHere] [option]", {
                    clickHere: (
                      <span
                        onClick={() =>
                          cbWhenNothingFound && cbWhenNothingFound()
                        }
                        id={
                          cbWhenNothingFound
                            ? ""
                            : "location-helper-list-trigger"
                        }
                        style={{ color: "#00a9e2" }}
                        className="min-w-24 pr-2 pb-[2px] h-fit font-medium cursor-pointer"
                      >
                        {i("Click here")}
                      </span>
                    ),
                    option:
                      otherOptionsWhenNothingFoundText ||
                      i("to select a city in a list"),
                  })}
                </p>
              </div>
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default StyledAsyncCombobox;
