import { FC } from "react";
import { Dialog } from "@headlessui/react";

import ButtonBGBlue from "../buttonBGBlue";

import Popup from "../popup";
import useNotificationDialog from "../../hooks/useNotificationDialog";
import isEmpty from "../../utils/isEmpty";
import StyledPopup from "../styledPopup";

const NotificationDialog: FC = () => {
  const {
    closePopup,
    isOpen,
    title,
    body,
    bottomActionButtonText,
    bottomActionButtonUrl,
  } = useNotificationDialog();

  const handleAction = () => {
    window.location.href = bottomActionButtonUrl;
    closePopup();
  };

  return (
    <div className="ConfirmDialog relative">
      <StyledPopup open={isOpen} onClick={closePopup} maxWidth={380}>
        <div className="flex w-full justify-center items-center h-fit p-4">
          <div className="sm:max-w-[378px]">
            <Dialog.Description className="text-lg text-black text-center">
              {title}
            </Dialog.Description>
            <Dialog.Description className="mt-2 text-lg text-black">
              {body}
            </Dialog.Description>
            {!isEmpty(bottomActionButtonText) && (
              <div className="my-6 flex">
                <ButtonBGBlue
                  innerText={bottomActionButtonText}
                  onClick={handleAction}
                />
              </div>
            )}
          </div>
        </div>
      </StyledPopup>
    </div>
  );
};

export default NotificationDialog;
