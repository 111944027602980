import React, { FC } from "react";
import useTranslation from "../../context/TranslationContext";
import VerifPopupCheckList from "../verifPopupCheckList";
import VerifPopupSteps from "../verifPopupStep";
import useUser from "../../hooks/useUser";

type Props = {};

const VerifPopupStep2: FC<Props> = () => {
  const { i } = useTranslation();
  const { userData } = useUser();
  return (
    <div className="VerifPopupStep2 w-full flex flex-col items-end">
      <VerifPopupSteps
        currentStep={2}
        currentStepText={
          <p>
            {i("Under the current date, enter this number: [number]", {
              number: (
                <span className="font-semibold">#{userData.userid}.</span>
              ),
            })}
          </p>
        }
      />
      <div className="w-11/12">
        <VerifPopupCheckList
          checkListText={
            <p
              dangerouslySetInnerHTML={{
                __html: i(
                  "Please note that everything must be <b>written legibly</b> and preferably in <b>block letters.</b>"
                ),
              }}
            ></p>
          }
        />
      </div>
    </div>
  );
};

export default VerifPopupStep2;
