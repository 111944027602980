import React, { FC } from "react";

type Props = { message: string; time: string };

const GreetingBubble: FC<Props> = ({ message, time }) => {
  if (!message) return null;

  const messageParagraph = message.substring(
    message.indexOf("<p"),
    message.indexOf("/p>") + 3
  );

  const messageImage = message.replace(
    message.substring(message.indexOf("<p"), message.indexOf("/p>") + 3),
    ""
  );
  return (
    <div className="w-full flex justify-start flex-col">
      <div dangerouslySetInnerHTML={{ __html: messageImage }} />
      <div className="bg-[#EFF1FE] flex flex-row py-2 pl-4 pr-3 space-x-2 rounded-[10px] items-end justify-end w-fit">
        <p dangerouslySetInnerHTML={{ __html: messageParagraph }} />
        <p className="text-custom-dark-gray text-xs leading-5">{time}</p>
      </div>
    </div>
  );
};

export default GreetingBubble;
