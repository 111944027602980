import React, { useEffect, useRef } from "react";

type CustomScrollbarProps = {
  children: React.ReactNode;
  right?: number;
  top?: number;
  disabled?: boolean;
};

const THUMB_MIN_SIZE = 20;

export default function CustomScrollBar({
  children,
  right,
  top,
  disabled,
}: CustomScrollbarProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const thumbYRef = useRef<HTMLDivElement>(null);
  const trackYRef = useRef<HTMLDivElement>(null);
  const thumbXRef = useRef<HTMLDivElement>(null);
  const trackXRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const init = () => {
      // TypeGuard
      if (
        !trackYRef.current ||
        !thumbYRef.current ||
        !trackXRef.current ||
        !thumbXRef.current ||
        !scrollContainerRef.current
      )
        return;
      // Track Displays
      trackYRef.current.style.display =
        scrollContainerRef.current.scrollHeight <=
        scrollContainerRef.current.clientHeight
          ? "none"
          : "";
      trackXRef.current.style.display =
        scrollContainerRef.current.scrollWidth <=
        scrollContainerRef.current.clientWidth
          ? "none"
          : "";
      // Vertical
      const thumbYSize = Math.max(
        Math.ceil(
          (scrollContainerRef.current.clientHeight /
            scrollContainerRef.current.scrollHeight) *
            trackYRef.current.clientHeight
        ),
        THUMB_MIN_SIZE
      );
      const thumbVertical =
        (scrollContainerRef.current.scrollTop /
          (scrollContainerRef.current.scrollHeight -
            scrollContainerRef.current.clientHeight)) *
        (trackYRef.current.clientHeight - thumbYSize);
      thumbYRef.current.style.height = thumbYSize + "px";
      thumbYRef.current.style.transform = `translateY(${thumbVertical}px)`;
      // Horizontal
      const thumbXSize = Math.max(
        Math.ceil(
          (scrollContainerRef.current.clientWidth /
            scrollContainerRef.current.scrollWidth) *
            trackXRef.current.clientWidth
        ),
        THUMB_MIN_SIZE
      );
      const thumbLeft =
        (scrollContainerRef.current.scrollLeft /
          (scrollContainerRef.current.scrollWidth -
            scrollContainerRef.current.clientWidth)) *
        (trackXRef.current.clientWidth - thumbXSize);
      thumbXRef.current.style.width = thumbXSize + "px";
      thumbXRef.current.style.transform = `translateX(${thumbLeft}px)`;
    };
    window.addEventListener("resize", init);
    const observer = new ResizeObserver(init);
    scrollContentRef.current && observer.observe(scrollContentRef.current);
    thumbYRef.current?.addEventListener("mousedown", handleVerticalDrag);
    thumbXRef.current?.addEventListener("mousedown", handleHorizontalDrag);
    thumbYRef.current?.addEventListener("touchstart", handleVerticalDrag);
    thumbXRef.current?.addEventListener("touchstart", handleHorizontalDrag);
    init();
    return () => {
      window.removeEventListener("resize", init);
      observer.disconnect();
    };
  }, []);

  const handleScroll = () => {
    if (
      !trackYRef.current ||
      !thumbYRef.current ||
      !trackXRef.current ||
      !thumbXRef.current ||
      !scrollContainerRef.current
    )
      return;
    const thumbSize = Math.max(
      Math.ceil(
        (scrollContainerRef.current.clientHeight /
          scrollContainerRef.current.scrollHeight) *
          trackYRef.current.clientHeight
      ),
      THUMB_MIN_SIZE
    );
    const thumbVertical =
      (scrollContainerRef.current.scrollTop /
        (scrollContainerRef.current.scrollHeight -
          scrollContainerRef.current.clientHeight)) *
      (trackYRef.current.clientHeight - thumbSize);
    thumbYRef.current.style.height = thumbSize + "px";
    thumbYRef.current.style.transform = `translateY(${thumbVertical}px)`;

    // Horizontal
    const thumbXSize = Math.max(
      Math.ceil(
        (scrollContainerRef.current.clientWidth /
          scrollContainerRef.current.scrollWidth) *
          trackXRef.current.clientWidth
      ),
      THUMB_MIN_SIZE
    );
    const thumbLeft =
      (scrollContainerRef.current.scrollLeft /
        (scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.clientWidth)) *
      (trackXRef.current.clientWidth - thumbXSize);
    thumbXRef.current.style.width = thumbXSize + "px";
    thumbXRef.current.style.transform = `translateX(${thumbLeft}px)`;
  };

  const handleVerticalDrag = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const startY = "touches" in e ? e.touches[0].clientY : e.clientY;
    const startScrollTop = scrollContainerRef.current.scrollTop;

    function handleMouseMove(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      const deltaY = e.clientY - startY;
      const contentHeight = container.scrollHeight;
      scrollContainerRef.current?.scrollTo({
        top: Math.min(Math.max(0, startScrollTop + deltaY), contentHeight),
      });
    }

    function handleMouseUp() {
      document.removeEventListener("pointermove", handleMouseMove);
      document.removeEventListener("pointerup", handleMouseUp);
    }

    document.addEventListener("pointermove", handleMouseMove);
    document.addEventListener("pointerup", handleMouseUp);
  };

  const handleHorizontalDrag = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const startX = "touches" in e ? e.touches[0].clientX : e.clientX;
    const startScrollLeft = scrollContainerRef.current.scrollLeft;

    function handleMouseMove(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      const deltaX = e.clientX - startX;
      const contentWidth = container.scrollWidth;
      scrollContainerRef.current?.scrollTo({
        left: Math.min(Math.max(0, startScrollLeft + deltaX), contentWidth),
      });
    }

    function handleMouseUp() {
      document.removeEventListener("pointermove", handleMouseMove);
      document.removeEventListener("pointerup", handleMouseUp);
    }

    document.addEventListener("pointermove", handleMouseMove);
    document.addEventListener("pointerup", handleMouseUp);
  };

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div
      className="scroll__container hide-native-scroll overflow-scroll flex flex-row relative max-h-[inherit]"
      ref={scrollContainerRef}
      onScroll={handleScroll}
    >
      <div
        ref={scrollContentRef}
        className="scroll__content flex-1 overflow-visible"
      >
        {children}
      </div>
      <div
        ref={trackYRef}
        className="scroll__track fixed"
        style={{
          top: top ?? 2,
          right: right ?? 2,
          bottom: 2,
          width: 8,
          backgroundColor: "#e9eafe",
          borderRadius: 10,
          marginTop: 12,
          marginBottom: 12,
        }}
      >
        <div
          ref={thumbYRef}
          className="scroll__thumb"
          style={{ width: 8, backgroundColor: "#636ef6", borderRadius: 10 }}
        />
      </div>
      <div
        ref={trackXRef}
        className="scroll__track fixed flex grow"
        style={{
          boxSizing: "content-box",
          right: 2,
          left: 2,
          bottom: 2,
          height: 8,
          backgroundColor: "#e9eafe",
          borderRadius: 10,
          marginLeft: 12,
          marginRight: 12,
        }}
      >
        <div
          ref={thumbXRef}
          className="scroll__thumb"
          style={{ height: 8, backgroundColor: "#636ef6", borderRadius: 10 }}
        />
      </div>
    </div>
  );
}
