import React from "react";
import StyledContainerHeader from "../styledContainerHeader";
import useTranslation from "../../context/TranslationContext";
import SettingsCertifiedButton from "../settingsCertifiedButton";
import useUser from "../../hooks/useUser";
import FooterLangDropdown from "../footerLangDropdown";

export default function SettingsAccount() {
  const { i } = useTranslation();
  const {
    userData: { EmailConfirmed, isCertified },
  } = useUser();

  return (
    <div className="SettingsAccount grid divide-y gap-4">
      <div className="grid gap-4">
        <div className="flex justify-between mt-4">
          <StyledContainerHeader>{i("Email")}</StyledContainerHeader>
          <SettingsCertifiedButton
            completed={EmailConfirmed}
            settingName="email address"
          />
        </div>
        <div className="flex justify-between">
          <StyledContainerHeader>{i("My profile")}</StyledContainerHeader>
          <SettingsCertifiedButton
            completed={isCertified}
            settingName="profile"
          />
        </div>
      </div>
      <div className="pt-4">
        <StyledContainerHeader>{i("Language Settings")}</StyledContainerHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-2">
          <h2 className="min-h-8 align-middle leading-8">
            {i("Change the display language")}
          </h2>
          <div className="border rounded-md mx-auto sm:mx-0 max-w-[12rem] w-full">
            <FooterLangDropdown
              availableLangs={["English", "French"]}
              buttonClassName="text-black w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
