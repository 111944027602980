import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../backendAPI";
import {
  Languages,
  Translation,
  assertLanguage,
} from "../../context/TranslationContext";
import {
  LoadingIndicatorLocks,
  hideLoadingIndicator,
} from "../../components/loadingIndicator/LoadingIndicator";
import { getLoginInfo, logout } from "./authSlice";
import { Greeting } from "../../context/SayHiContext";

export interface BootstrapState {
  complete: boolean;
  lang: Languages | null;
  translations: Translation | null;
  greetings: Greeting[];
  shouldUnload: boolean;
}

const initialState: BootstrapState = {
  complete: false,
  lang: null,
  translations: null,
  greetings: [],
  shouldUnload: false,
};

export const bootstrap = createAsyncThunk("_bootstrap", async (_, thunkApi) => {
  let lang: Languages = assertLanguage();
  if (window.localStorage.getItem("token")) {
    try {
      const loginResponse = await thunkApi.dispatch(getLoginInfo()).unwrap();
      lang = loginResponse.data.infosProfile.preferredDisplayLanguage ?? lang;
    } catch (error) {
      // TODO: handle Error
      console.log(error);
    }
  }
  try {
    const { translations, greetings } = (await api.general.getTranslation(lang))
      .data;
    return {
      translations,
      greetings,
      lang,
    };
  } catch (error) {
    return {
      translations: null,
      greetings: [],
      lang,
    };
  }
});

export const bootstrapSlice = createSlice({
  name: "bootstrap",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<Languages>) => {
      state.lang = action.payload;
    },
    setTranslations: (state, action: PayloadAction<Record<string, string>>) => {
      state.translations = action.payload;
    },
    completeBootstrap: (state) => {
      state.complete = true;
      hideLoadingIndicator(LoadingIndicatorLocks.Redirection);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(bootstrap.fulfilled, (state, action) => {
      state.translations = action.payload.translations;
      state.lang = action.payload.lang;
      state.greetings = action.payload.greetings;
      state.complete = true;
      hideLoadingIndicator(
        LoadingIndicatorLocks.Login,
        LoadingIndicatorLocks.Translations
      );
      return state;
    });
    builder.addCase(bootstrap.rejected, (state, action) => {
      // TODO: handle potential rejection, show error message?
    });
    builder.addCase(logout.fulfilled, logoutRedirectionAction),
      builder.addCase(logout.rejected, logoutRedirectionAction);
  },
});

const logoutRedirectionAction = (state: BootstrapState) => {
  state.shouldUnload = true;
};

export const { setTranslations, setLang, completeBootstrap } =
  bootstrapSlice.actions;

export default bootstrapSlice.reducer;
