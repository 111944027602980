import { useContext, Dispatch } from "react";

import {
  ConfirmDialogContext,
  IconDescription,
} from "../context/ConfirmDialogContext";

type UseConfirmDialog = () => {
  openPopup: (payload: {
    title?: string;
    action?: string;
    description: string;
    confirmText: string;
    confirmAction: () => void;
    iconSrc?: string;
    iconAlt?: string;
  }) => void;

  closePopup: () => void;
  isOpen: boolean;
  confirmAction: () => void;
  setConfirmAction: (action: () => void) => void;
  title: string;
  action: string;
  description: string;
  confirmText: string;
  iconDescription: IconDescription;
  setIconDescription: Dispatch<React.SetStateAction<IconDescription>>;
};

const useConfirmDialog: UseConfirmDialog = () => {
  const {
    openPopup,
    closePopup,
    isOpen,
    setConfirmAction,
    confirmAction,
    description,
    confirmText,
    iconDescription,
    setIconDescription,
    title,
    action,
  } = useContext(ConfirmDialogContext);

  return {
    openPopup,
    closePopup,
    isOpen,
    confirmAction,
    setConfirmAction,
    description,
    confirmText,
    iconDescription,
    setIconDescription,
    title,
    action,
  };
};

export default useConfirmDialog;
