import React, { createContext, useState } from "react";
import CustomMessagePopup from "../components/customMessagePopup";
interface Context {
  openPopup: (
    imageSrc: string,
    userId: number,
    userName: string,
    skip?: boolean
  ) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  imageSrc: string;
  setImageSrc: React.Dispatch<React.SetStateAction<string>>;
  recipientUserId: number;
  setRecipientUserId: React.Dispatch<React.SetStateAction<number>>;
  recipientUserName: string;
  setRecipientUserName: React.Dispatch<React.SetStateAction<string>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const SendMessagePopupContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function SendMessagePopupProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [recipientUserId, setRecipientUserId] = useState(0);
  const [recipientUserName, setRecipientUserName] = useState("");
  const [message, setMessage] = useState("");

  const openPopup = (
    imageSrc: string,
    userId: number,
    userName: string,
    skip?: boolean
  ) => {
    setImageSrc(imageSrc);
    setRecipientUserId(userId);
    setRecipientUserName(userName);
    if (!skip) setIsOpen(true);
  };

  return (
    <SendMessagePopupContext.Provider
      value={{
        openPopup,
        isOpen,
        setIsOpen,
        imageSrc,
        setImageSrc,
        recipientUserId,
        setRecipientUserId,
        recipientUserName,
        setRecipientUserName,
        message,
        setMessage,
      }}
    >
      {children}
      <CustomMessagePopup />
    </SendMessagePopupContext.Provider>
  );
}
