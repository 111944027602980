import React, { createContext, useEffect, useState, useRef } from "react";

import useQuery, { UseQuery } from "../hooks/useQuery";
import { Notification, NotificationList } from "../backendProxies";

interface Context extends UseQuery<Notification> {
  resetQuery: ({ pageSize, show }: { pageSize: number; show: "all" }) => void;
}

export const NotificationContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function NotificationProvider({ children }: Props) {
  const [shouldResetQuery, setShouldResetQuery] = useState(false);

  const {
    results,
    pinnedItem,
    hasMore,
    isLoading,
    setShouldLoadMore,
    resetQuery,
    error,
    initQuery,
  } = useQuery<Notification>(NotificationList, "notifications", {
    pageSize: 20,
    show: "all",
  });

  return (
    <NotificationContext.Provider
      value={{
        results,
        pinnedItem,
        hasMore,
        isLoading,
        setShouldLoadMore,
        setShouldResetQuery,
        resetQuery,
        error,
        initQuery,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
