import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { AlwaysFreeMessage } from "../settingsVIP/SettingsVIP";
import Link from "next/link";
import useUser from "../../hooks/useUser";
import { SettingsLinkRoutes } from "../../pages/settings";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import api from "../../backendAPI";
import { SubscriptionData } from "../../backendAPI/Subscription";
import { Dialog, Menu, Transition } from "@headlessui/react";
import useToast from "../../hooks/useToast";
import useTranslation from "../../context/TranslationContext";
import ButtonBGBlue from "../buttonBGBlue";
import useWindowSize from "../../hooks/useWindowSize";
import SayHelloScrollbar from "../sayHelloScrollbar";

type ManageSubscriptionsProps = {};

export default function ManageSubscriptions({}: ManageSubscriptionsProps) {
  const [subscriptions, setSubscriptions] = useState<SubscriptionData[]>([]);
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const currentSubRef = useRef<SubscriptionData | null>(null);
  const {
    userData: { isVIP, isPremiumNoAds, NomUsager },
  } = useUser();
  const { openTimedToast } = useToast();
  const { i } = useTranslation();
  const { windowWidth } = useWindowSize();

  const COLUMNS = useMemo(
    () => [
      {
        field: "description",
        header: i("Description"),
        cellClassName: "text-[16px] font-semibold",
        renderCell: (data: SubscriptionData) => (
          <div
            className="pt-2"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></div>
        ),
      },
      {
        field: "renewalDate",
        header: i("Renewal date"),
        cellClassName: "pt-2 text-[16px] text-gray-500",
      },
      {
        field: "endDate",
        header: i("End Date"),
        cellClassName: "pt-2 text-[16px] text-gray-500",
      },
      {
        field: "status",
        header: i("Status"),
        cellClassName: "pt-2 text-[16px] text-gray-500",
      },
      {
        field: "action",
        header: i("Action"),
        renderCell: (data: SubscriptionData) => (
          <SubscriptionActionMenu
            {...data}
            onCancel={(subscription) => {
              currentSubRef.current = subscription;
              setOpenCancel(true);
            }}
          />
        ),
      },
    ],
    []
  );

  const handleCancel = ({
    email,
    message,
  }: {
    email: string;
    message: string;
  }) => {
    if (!currentSubRef.current) return;
    setCancelLoading(true);
    api.subscription
      .deleteSubscription({
        email,
        message,
        username: NomUsager,
        id: currentSubRef.current.id,
      })
      .then((res) => {
        if (res.status === "ERROR") {
          openTimedToast({
            title: "Erreur",
            description: res.message,
            type: "error",
          });

          return;
        }
        setOpenCancel(true);
        setTimeout(() => {
          window.location.href = "/app/settings?tab=manage-subscriptions";
        }, 350);
      })
      .finally(() => {
        setCancelLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    api.subscription.getSubscription().then((res) => {
      setSubscriptions(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <AlwaysFreeMessage />
      <div className="flex justify-end py-3">
        <Link href={`?tab=${SettingsLinkRoutes.VIPMember}`}>
          <a
            data-testid={`navlink-for-vip`}
            className={`text-custom-blue flex px-2 py-1 items-center text-base font-medium rounded-[10px]`}
          >
            <span className="flex-1 text-lg">
              {i("View available subscriptions")}
            </span>
          </a>
        </Link>
      </div>
      <div className="border border-gray-200 rounded-t-2xl">
        <div className="bg-gray-50 border-b border-gray-200 text-custom-black text-xl font-medium p-5 rounded-t-2xl">
          {i("Current and Past Subscriptions")}
        </div>
        {windowWidth && windowWidth <= 1024 && (
          <p className="italic px-4 pt-4">
            {i(
              "This table can scroll to left if it is too big for your screen"
            )}
          </p>
        )}
        <ManageSubscriptionScrollBar height={subscriptions.length * 86 + 100}>
          {" "}
          {/** Needs fixed height or won't show, 100 is for padding + header */}
          <div className="p-4 w-full">
            <Table>
              <TableHead className="bg-gray-50 ">
                <TableRow>
                  {COLUMNS.map((col) => (
                    <TableCell
                      key={`sub-table-head-${col.field}`}
                      style={{ fontFamily: "unset" }}
                    >
                      <div className="font-medium text-[16px] text-custom-black uppercase whitespace-pre">
                        {col.header}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((sub) => (
                  <TableRow key={sub.id}>
                    {COLUMNS.map((col) => {
                      return (
                        <TableCell
                          key={`${col.field}-${sub.id}`}
                          style={{ fontFamily: "unset", whiteSpace: "pre" }}
                        >
                          <div className={col.cellClassName}>
                            {getTableCellContent(col, sub)} {/* TODO: fix any*/}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </ManageSubscriptionScrollBar>
      </div>
      <CancelModal
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        onCancel={handleCancel}
        isCancelLoading={cancelLoading}
      />
    </div>
  );
}

function getTableCellContent(
  col: {
    renderCell?: (data: SubscriptionData) => {};
    field: string;
    header: string;
    cellClassName?: string;
  },
  sub: SubscriptionData
) {
  return col.renderCell
    ? col.renderCell(sub)
    : col.field in sub
    ? (sub as any)[col.field]
    : null;
}

type SubscriptionActionMenuProps = {
  onCancel(subscription: SubscriptionData): void;
} & SubscriptionData;

function SubscriptionActionMenu({
  onCancel,
  status,
  ...other
}: SubscriptionActionMenuProps) {
  const {
    userData: {},
  } = useUser();
  const handleCancel = () => {
    onCancel({ status, ...other });
  };
  const { i } = useTranslation();

  if (!other?.showCancelButton) return null;

  return (
    <div className="dropdown flex justify-center">
      <Menu as="div" className="flex justify-center items-center relative">
        <Menu.Button className="h-8 w-8 bg-custom-light-blue bg-opacity-20 p-1 rounded">
          <img src="assets/Icons/moreVertical.svg" alt="show more" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right top-full -right-full mr-8 absolute mt-1 rounded-md shadow-lg drop-shadow-2xl border-1 bg-white ring-1 ring-black ring-opacity-10 focus:outline-none">
            <Menu.Item>
              <div
                onClick={handleCancel}
                className="p-2 hover:bg-custom-light-blue hover:bg-opacity-20 whitespace-pre hover:cursor-pointer"
              >
                {i("Cancel subscription")}
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

type CancelModalProps = {
  open: boolean;
  onClose(): void;
  onCancel({ email, message }: { email: string; message: string }): void;
  isCancelLoading: boolean;
};

function CancelModal({
  open,
  onClose,
  onCancel,
  isCancelLoading,
}: CancelModalProps) {
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const { i } = useTranslation();

  const handleSubmit = () => {
    if (isCancelLoading) return;
    onCancel({ email, message: comment });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[100] inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex justify-center h-full items-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4  sm:scale-95"
            enterTo="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="SettingsVIP text-custom-black max-w-2xl bg-white flex flex-col space-y-[14px] pt-7 lg:pt-9 pb-6 pl-4 lg:pl-5 rounded relative m-auto my-auto">
              <div className="pr-5 text-custom-black">
                <p className="mb-2 text-md">{i("Email")}</p>
                <input
                  type="email"
                  placeholder={i("Email used for your subscription")}
                  className="w-full outline-none border-custom-gray rounded"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <p className="font-bold pt-5 pb-2 text-lg ">
                  {i("Do you have any comments?")}
                </p>
                <p className="pb-3">
                  {i(
                    "You can also tell us the reason(s) why you wish to unsubscribe, this will help us improve the website!"
                  )}
                </p>
                <textarea
                  placeholder={i("Comments")}
                  rows={5}
                  className="w-full resize-none border-custom-gray rounded"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                />
              </div>
              <div className="flex justify-end px-5">
                <button
                  className="ButtonBGBlue disabled:opacity-50 w-fit inline-flex justify-center items-center rounded h-[50px] border-2 shadow-sm px-4 py-2 text-lg font-medium text-white border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
                  id="card-button"
                  disabled={isCancelLoading}
                  onClick={handleSubmit}
                >
                  {isCancelLoading ? (
                    <div className="LoadingSpinner w-20 justify-center flex">
                      <div className="flex items-center justify-center w-4">
                        <div className="animate-spin flex">
                          <img src="/app/assets/spinner.png" alt="spinner" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    i("Cancel my subscription")
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import Scrollbar from "react-scrollbars-custom";
import classNames from "../../utils/twClassNames";

type SayHelloScrollbarProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  containerStyle?: CSSStyleSheet;
  className?: string;
  children?: React.ReactNode;
};

function ManageSubscriptionScrollBar({
  height = "100%",
  width = "100%",
  className,
  containerStyle,
  children,
}: SayHelloScrollbarProps) {
  return (
    <Scrollbar
      style={{ width, height, ...containerStyle }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="trackY w-3"
              style={{ ...style, background: "#e9eafe", width: 8 }}
            />
          );
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="thumbY"
              style={{
                ...style,
                background: "#636ef6",
                position: "absolute",
              }}
            />
          );
        },
      }}
      trackXProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="trackX w-3"
              style={{ ...style, background: "#e9eafe", height: 8 }}
            />
          );
        },
      }}
      thumbXProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="thumbX"
              style={{
                ...style,
                background: "#636ef6",
                position: "absolute",
              }}
            />
          );
        },
      }}
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames("wrapper", className ?? false)}
              style={{ ...style, width }}
            />
          );
        },
      }}
      contentProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="wrapper"
              style={{ ...style, width, display: "flex" }}
            />
          );
        },
      }}
    >
      {children}
    </Scrollbar>
  );
}
