import api from "../../backendAPI";

export type NotificationInfo = {
  notificationId: number;
  type: "perfectMatch" | "default";
  icon: string;
  userName: string;
  userId: number;
  timestamp: string;
  imageSrc: string;
  content: string;
  action: {
    label: string;
    href: string;
    type: string;
  };
  popup: {
    label: string;
    title: string;
    body: string;
    bottomActionButtonLabel: string;
    bottomActionButtonURL: string;
  };
};

class Notification {
  public info: NotificationInfo;

  constructor(config: { info: NotificationInfo }) {
    this.info = config.info;
  }

  public delete = async () => {
    return api.notification.deleteOne({ id: this.info.notificationId });
  };
}
export default Notification;
