import { useState, useEffect } from "react";

import useAuth from "./useAuth";

import {
  Endpoint as MemberEndpoints,
  QueryParams as MemberQueryParams,
} from "../backendProxies/members/MemberList";

import {
  Endpoint as AdviceEndpoints,
  QueryParams as AdviceQueryParams,
} from "../backendProxies/advices/AdviceList";

import {
  Endpoint as NotificationEndpoints,
  QueryParams as NotificationQueryParams,
} from "../backendProxies/notifications/NotificationList";
import { PinnedItem } from "../backendProxies/utils/BackendProxy";

export interface UseQuery<T> {
  results: T[];
  pinnedItem: PinnedItem;
  hasMore: boolean;
  isLoading: boolean;
  initQuery: () => void;
  setShouldResetQuery: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  error: Error | null;
}

export default function useQuery<T>(
  proxy: any,
  endpoint: MemberEndpoints | AdviceEndpoints | NotificationEndpoints,
  queryParams: MemberQueryParams | AdviceQueryParams | NotificationQueryParams
) {
  const { isAuth } = useAuth();

  const [pinnedItem, setPinnedItem] = useState<PinnedItem>(null);
  const [results, setResults] = useState<T[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [hasMore, setHasMore] = useState(false);

  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const [error, setError] = useState<Error | null>(null);

  const [list, setList] = useState(
    // @ts-ignore
    new proxy(endpoint, queryParams)
  );

  useEffect(() => {
    if (!isAuth) return;

    if (!isLoading) return;

    setResults([]);

    fetchItems();

    async function fetchItems() {
      const [results, hasMore, pinnedItem] = await list.getElements();

      setIsLoading(false);
      setHasMore(hasMore);
      setResults(results);
      setPinnedItem(pinnedItem);

      setError(list.getError());
    }
  }, [isLoading, isAuth]);

  useEffect(() => {
    if (!isAuth) return;

    if (!shouldLoadMore) return;

    fetchMoreItems();

    async function fetchMoreItems() {
      setIsLoading(true);

      await list.loadMore();

      const [members, hasMore] = await list.getElements();

      setIsLoading(false);
      setHasMore(hasMore);
      setResults(members);

      setError(list.getError());
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore, isAuth]);

  const resetQuery = (newConfig: MemberQueryParams | AdviceQueryParams) => {
    if (isLoading) return;
    setIsLoading(true);

    //@ts-ignore
    setList(new proxy(endpoint, newConfig));
  };

  return {
    results,
    pinnedItem,
    hasMore,
    isLoading,
    setShouldLoadMore,
    resetQuery,
    error,
    initQuery: () => setIsLoading(true),
  };
}
