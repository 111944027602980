class Advice {
  public id: string;
  public title: string;
  public article: string;
  public mainImage: string;
  public postedDate: string;
  public url: string;

  constructor(config: Advice) {
    this.id = config.id;
    this.title = config.title;
    this.article = config.article;
    this.mainImage = config.mainImage;
    this.postedDate = config.postedDate;
    this.url = config.url;
  }
}

export default Advice;
