import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  setTopBannerVisibility,
  removeNotification,
  setFirstName,
  setDotNotification,
  clearDotNotification,
  State,
  setShowAddImagesPopupRules,
  clearWarnings,
  setPrefferedDisplayLanguage,
} from "../redux/slices/userSlice";
import { LoginNotification } from "../backendAPI/Authentication";
import { Languages } from "../context/TranslationContext";

type UseUser = () => State & {
  setTopBannerVisibility: (visibility: boolean) => void;
  removeNotification: (notificationToRemove: keyof LoginNotification) => void;
  setFirstName: (newFirstName: string) => void;
  setDotNotification: (notificationType: keyof LoginNotification) => void;
  clearDotNotification: (notificationType: keyof LoginNotification) => void;
  setShowAddImagesPopupRules: (value: boolean) => void;
  clearWarnings: () => void;
  setPrefferedDisplayLanguage(lang: Languages): void;
};

const useUser: UseUser = () => {
  const selectTopBannerVisibility = (state: RootState) =>
    state.user.topBannerVisibility;

  const topBannerVisibility = useSelector(selectTopBannerVisibility);

  const selectTopBannerText = (state: RootState) => state.user.topBannerText;

  const topBannerText = useSelector(selectTopBannerText);

  const selectUserId = (state: RootState) => state.user.userData.userid;

  const userId = useSelector(selectUserId);

  const selectUserData = (state: RootState) => state.user.userData;

  const userData = useSelector(selectUserData);

  const selectNotifications = (state: RootState) => state.user.notifications;

  const notifications = useSelector(selectNotifications);

  const selectCertificationProcess = (state: RootState) =>
    state.user.certificationProcess;

  const certificationProcess = useSelector(selectCertificationProcess);

  const feedback = useSelector((state: RootState) => state.user.feedback);
  const homeCta = useSelector((state: RootState) => state.user.homeCta);

  const actions = useActions({
    setTopBannerVisibility,
    removeNotification,
    setFirstName,
    setDotNotification,
    setShowAddImagesPopupRules,
    clearWarnings,
    clearDotNotification,
    setPrefferedDisplayLanguage,
  });

  return {
    topBannerVisibility,
    topBannerText,
    userId,
    userData,
    notifications,
    certificationProcess,
    feedback,
    homeCta,
    ...actions,
  };
};

export default useUser;
