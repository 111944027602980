import React, { HTMLAttributes } from "react";
import { ServerAction } from "../navBarBanner/NavBarBanner";
import classNames from "../../utils/twClassNames";

type Props = {
  innerText: string;
  testid?: string;
  className?: string;
  onClick?: () => void;
  serverAction?: ServerAction;
  style?: HTMLAttributes<HTMLButtonElement>["style"];
  disabled?: boolean;
};

const ButtonBlueBGWhite = React.forwardRef<HTMLButtonElement, Props>(
  (
    { innerText, onClick, className, testid, serverAction, style, disabled },
    ref
  ) => {
    return (
      <button
        type="button"
        data-testid={testid}
        className={classNames(
          "ButtonBlueBgWhite disabled:opacity-50 w-full inline-flex justify-center items-center rounded h-[50px] border-2 text-custom-blue border-custom-blue shadow-sm px-4 py-2 bg-white text-lg font-medium transition hover:duration-150 hover:bg-custom-blue hover:text-white",
          className || false
        )}
        onClick={onClick}
        data-server-action={serverAction}
        ref={ref}
        style={style}
        disabled={disabled}
      >
        {innerText}
      </button>
    );
  }
);

ButtonBlueBGWhite.displayName = "ButtonBlueBGWhite";

export default ButtonBlueBGWhite;
