import React, { createContext, useContext, useEffect, useState } from "react";

import useUser from "../hooks/useUser";
import useAuth from "../hooks/useAuth";
import api from "../backendAPI";
import { EditProfileInfo, MyProfileInfo } from "../types";
import { AlertMessageContext } from "./AlertMessageContext";
import useTranslation from "./TranslationContext";
import useToast from "../hooks/useToast";

type Context = {
  isMyProfileLoaded: boolean;
  editProfileInfo: EditProfileInfo | undefined;
  myProfileInfo: MyProfileInfo | undefined;
  setMyProfileInfo: (info: MyProfileInfo) => void;
  completionLevel: number;
  completedActions: Array<number>;
  saveEditProfileValues: (newValues: any) => Promise<void>;
  handleCancel: () => void;
};

export const EditProfileContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function EditProfileProvider({ children }: Props) {
  const { i, locale } = useTranslation();

  const [editProfileInfo, setEditProfileInfo] = useState<EditProfileInfo>();
  const [myProfileInfo, setMyProfileInfo] = useState<MyProfileInfo>(); // TODO: myProfileInfo make a default for loading
  const [completionLevel, setCompletionLevel] = useState(0);
  const [completedActions, setCompletedActions] = useState<Array<number>>([0]);
  const isMyProfileLoaded = !!(editProfileInfo && myProfileInfo);

  const { openTimedToast } = useToast();

  const [shouldReload, setShouldReload] = useState(false);

  const { setFirstName } = useUser();
  const { pushAlert } = useContext(AlertMessageContext);

  const { NomUsager } = useUser().userData; // TODO: Remove when API is updated (no username required to fetch my-profile)
  function computeCompletionLevel() {
    let result: number = 20;
    let actions: Array<number> = [0];
    if (editProfileInfo && myProfileInfo) {
      const {
        interview,
        firstDate,
        musics,
        activities,
        qualities,
        mailSettings,
      }: EditProfileInfo = editProfileInfo;
      const { isCertified, photos }: MyProfileInfo = myProfileInfo;
      if (isCertified) {
        result += 15;
        actions.push(1);
      }
      if (photos) {
        if (photos.length > 2) {
          result += 25;
          actions.push(2);
        } else if (photos.length > 1) result += 16;
        else if (photos.length > 0) result += 8;
      }
      if (interview && interview.filter((i) => i.answer).length > 0) {
        result += 10;
        actions.push(3);
      }
      if (activities && activities.length > 0) {
        result += 5;
        actions.push(4);
      }
      if (musics && musics.length > 0) {
        result += 5;
        actions.push(5);
      }
      if (qualities && qualities.length > 0) {
        result += 5;
        actions.push(6);
      }
      if (firstDate) {
        result += 10;
        actions.push(7);
      }
      if (
        mailSettings.minAge != 18 ||
        mailSettings.maxAge != 100 ||
        mailSettings.maxDistance ||
        mailSettings.area != "countries" ||
        mailSettings.noSmoking ||
        mailSettings.noAlcohol ||
        mailSettings.noDrug ||
        !mailSettings.applyMailSettingToNotification
      ) {
        result += 5;
        actions.push(8);
      }
    }
    setCompletionLevel(result);
    setCompletedActions(actions);
  }

  const { userData } = useUser();
  const { accessToken, loginInfoLoaded } = useAuth();

  useEffect(() => {
    if (!loginInfoLoaded) return;

    if (accessToken || (accessToken && shouldReload)) {
      // setMyProfileInfo(undefined);
      console.log("re-fetching...");
      api.editProfile.getMyProfile(NomUsager).then((response) => {
        if (response.status === "SUCCESS") {
          setMyProfileInfo(response.data);
          setShouldReload(false);
        } else {
          setMyProfileInfo(undefined);
        }
      });
    }
  }, [accessToken, userData, shouldReload, loginInfoLoaded]);

  useEffect(() => {
    computeCompletionLevel();
  }, [editProfileInfo]);

  useEffect(() => {
    if (myProfileInfo) {
      const txtVille = /,/.test(myProfileInfo.locationToDisplay)
        ? myProfileInfo.locationToDisplay.split(",")[0]
        : myProfileInfo.locationToDisplay;

      setEditProfileInfo({
        locale,
        oldcountry: myProfileInfo.country.id,

        oldaffichage: myProfileInfo.locationToDisplay,
        cmbpays_autocomplet: myProfileInfo.country.cmbpays_autocomplet,
        txtville: txtVille,
        txtcityid: myProfileInfo.city.id,
        autocomplete: "",
        lat: "",
        lng: "",
        locality: "",
        country: myProfileInfo.country.id,
        administrative_area_level_1: "",
        firstName: myProfileInfo.firstName,
        birthDate: myProfileInfo.birthDate,
        showBirthDate: myProfileInfo.showBirthDate,
        genderId: myProfileInfo.gender.id,
        description: myProfileInfo.description.valueTextArea,
        quote: myProfileInfo.quote.valueTextArea,
        firstDate: myProfileInfo.firstDate.valueTextArea,
        profession:
          myProfileInfo.profession.pending || myProfileInfo.profession.current,
        heightId: myProfileInfo.height.id,
        appearanceId: myProfileInfo.appearance.id,
        lookingForId: myProfileInfo.lookingFor.id,
        hereFor: myProfileInfo.hereFor.map((i) => i.id),
        hairId: myProfileInfo.hair.id,
        eyesId: myProfileInfo.eyes.id,
        civilianStatusId: myProfileInfo.civilianStatus.id,
        hasChildrenId: myProfileInfo.hasChildren.id,
        wantsChildrenId: myProfileInfo.wantsChildren.id,
        smokingId: myProfileInfo.smoking.id,
        alcoholId: myProfileInfo.alcohol.id,
        drugId: myProfileInfo.drug.id,
        occupationId: myProfileInfo.occupation.id,
        readyToRelocateId: myProfileInfo.readyToRelocate.id,
        hasCarId: myProfileInfo.hasCar.id,
        religionId: myProfileInfo.religion.id,
        ethnicityId: myProfileInfo.ethnicity.id,
        zodiacSignId: myProfileInfo.zodiacSign.id,
        activities: myProfileInfo.activities
          .filter((tag) => tag.selected && tag.description)
          .map((tag) => (tag.id ? tag.id.toString() : tag.description)),
        musics: myProfileInfo.musics
          .filter((tag) => tag.selected && tag.description)
          .map((tag) => (tag.id ? tag.id.toString() : tag.description)),
        qualities: myProfileInfo.qualities
          .filter((tag) => tag.selected && tag.description)
          .map((tag) => (tag.id ? tag.id.toString() : tag.description)),
        animals: myProfileInfo.animals
          .filter((tag) => tag.selected && tag.description)
          .map((tag) => (tag.id ? tag.id.toString() : tag.description)),
        interview: myProfileInfo.interview.map((i) =>
          Object.assign(
            {},
            {
              questionId: i.questionId,
              answer: i.answer,
            }
          )
        ),
        mailSettings: {
          visibility: myProfileInfo.mailSettings.visibility,
          enabled: myProfileInfo.mailSettings.enabled,
          genderId: myProfileInfo.mailSettings.genderId,
          minAge: myProfileInfo.mailSettings.minAge,
          maxAge: myProfileInfo.mailSettings.maxAge,
          maxDistance: myProfileInfo.mailSettings.maxDistance,
          area: myProfileInfo.mailSettings.area,
          acceptedCountriesId: myProfileInfo.mailSettings.acceptedCountries.map(
            (i) => i.id
          ),
          noDrug: myProfileInfo.mailSettings.noDrug,
          noSmoking: myProfileInfo.mailSettings.noSmoking,
          noAlcohol: myProfileInfo.mailSettings.noAlcohol,
          applyMailSettingToNotification:
            myProfileInfo.mailSettings.applyMailSettingToNotification,
        },
      });
    }
  }, [myProfileInfo]);

  async function saveEditProfileValues(newValues: any) {
    if (editProfileInfo) {
      const newEditProfileInfo = Object.assign(editProfileInfo, newValues);
      setEditProfileInfo(newEditProfileInfo);
      const response = await api.editProfile.putProfile(newEditProfileInfo);

      if (response.status === "SUCCESS") {
        pushAlert({
          title: i("Success"),
          message: i("Your profile has been updated!"),
          type: "success",
          time: 2000,
        });
        computeCompletionLevel();
        if (newValues.firstName) {
          setFirstName(newValues.firstName);
        }

        setShouldReload(true);

        // needed to check if can still edit city (max 3 times per day)
        // api.editProfile.getMyProfile(NomUsager).then((response) => {
        //   if (response.status === "SUCCESS") {
        //     setMyProfileInfo(response.data);
        //   }
        // });
      } else {
        openTimedToast({
          title: i("Error"),
          description: response.message,
          type: "error",
          timeout: 5000,
        });
        setShouldReload(true);
      }
    }
  }

  function handleCancel() {
    setShouldReload(true);
  }

  return (
    <EditProfileContext.Provider
      value={{
        isMyProfileLoaded,
        editProfileInfo,
        myProfileInfo,
        setMyProfileInfo,
        saveEditProfileValues,
        completionLevel,
        completedActions,
        handleCancel,
      }}
    >
      {children}
    </EditProfileContext.Provider>
  );
}
