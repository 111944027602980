import React, { FC, useState } from "react";
import DateMeNavArrow from "../dateMeNavArrow";
import DateMePictureCounter from "../dateMePictureCounter";
import DateMeProfileInfo from "../dateMeProfileInfo";
import StyledPopup from "../styledPopup";
import { Member } from "../../backendProxies";

import ReportPictureButton from "../reportPictureButton";

type Props = {
  member: Member;
  imgSrcs: string[];
  firstName: string;
  age: number;
  city: string;
  open?: boolean;
  onClick: (open: boolean) => void;
  isVIP?: boolean;
  isCertified?: boolean;
  defaultIndex?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfilePhotoViewerPopup: FC<Props> = ({
  member,
  imgSrcs,
  firstName,
  age,
  city,
  open = false,
  onClick,
  isVIP = false,
  isCertified = false,
  defaultIndex = 0,
  setOpen,
}) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);

  const onLeftArrowClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (currentIndex === 0) {
      setCurrentIndex(imgSrcs.length - 1);
    }
  };

  const onRightArrowClick = () => {
    if (currentIndex < imgSrcs.length - 1) {
      setCurrentIndex((currentIndex) => currentIndex + 1);
    }
    if (currentIndex === imgSrcs.length - 1) {
      setCurrentIndex(0);
    }
  };

  if (!member) return <></>;

  return (
    <StyledPopup open={open} onClick={onClick} maxWidth={800}>
      <div
        data-testid="carousel-popup"
        className="DateMeCarousel grow md:h-[600px] h-[432px] bg-custom-light-gray relative"
      >
        <div className="absolute top-[20px] md:left-[40px] left-[28px] z-10">
          <DateMeProfileInfo
            username={member.info.userName}
            age={age}
            city={city}
            isVip={isVIP}
            isCertified={isCertified}
          />
        </div>
        <div className="absolute top-[20px] md:right-[40px] right-[28px] z-10 bg-custom-light-gray/50 p-1 rounded">
          <DateMePictureCounter
            currentPictureIndex={currentIndex + 1}
            maxNumberOfPictures={imgSrcs.length}
          />
        </div>

        <div className="absolute bottom-[20px] right-[28px] z-10">
          <ReportPictureButton
            profileId={+member.info.profilId}
            username={member.info.userName}
            setOpen={setOpen}
          />
        </div>
        <button
          className="absolute md:left-[25px] left-[10px] md:bottom-[292px] bottom-[204px] z-10"
          onClick={onLeftArrowClick}
        >
          <DateMeNavArrow direction="left" />
        </button>
        <img
          src={imgSrcs[currentIndex]}
          className="object-cover md:h-[600px] h-[432px] mx-auto"
          alt="Matched user image"
        />
        <button
          className="absolute md:right-[25px] right-[10px] md:bottom-[292px] bottom-[204px] z-10"
          onClick={onRightArrowClick}
        >
          <DateMeNavArrow direction="right" />
        </button>
      </div>
    </StyledPopup>
  );
};

export default ProfilePhotoViewerPopup;
