import React, { FC, useContext, useEffect, useRef } from "react";

import ButtonBGBlue from "../buttonBGBlue";
import VerifPopupSteps from "../verifPopupStep";
import VerifPopupStep3 from "../verifPopupStep3";
import VerifPopupStep2 from "../verifPopupStep2";
import VerifPopupStep4 from "../verifPopupStep4";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  handleGoToNextPage: () => void;
};

const VerifPopupNoWebcam: FC<Props> = ({ handleGoToNextPage }) => {
  const { i } = useContext(TranslationContext);

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div className="VerifPopupNoWebcam h-fit pb-[55px] text-custom-black">
      <div className="h-fit sm:max-h-[258px] sm:h-full border-b border-custom-black-200/50 flex flex-col items-center justify-between p-6">
        <div className="w-full pb-3 flex justify-end"></div>
        <img
          ref={ref}
          src="/app/assets/sheet-exclamation-point.svg"
          alt="document icon"
          className="h-[100px] w-[100px]"
        />
        <p className="my-6 max-w-[444px] text-center">
          {i(
            "We couldn't get access to webcam on your device. Please verify your identity with photos."
          )}
        </p>
      </div>
      <div className="px-4 lg:px-0 m-auto w-full flex flex-col items-end max-w-[651px]">
        <VerifPopupSteps
          currentStep={1}
          currentStepText={
            <p
              dangerouslySetInnerHTML={{
                __html: i(
                  `Firstly, <span class='font-semibold'>enter today's date</span> on a sheet.`
                ),
              }}
            />
          }
        />
        <VerifPopupStep2 />
        <VerifPopupStep3 />
        {/* <VerifPopupStep4 /> */}
        <div className="w-full max-w-[250px] m-auto">
          <ButtonBGBlue
            innerText={i("Upload my pictures")}
            onClick={() => handleGoToNextPage()}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifPopupNoWebcam;
