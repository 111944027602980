export interface ICanFail {
  setError(error: Error): void;
  getError(): Error | null;
}

class CanFail implements ICanFail {
  private error: Error | null = null;

  public setError(error: Error): void {
    this.error = error;

    // log stuff to sentry
  }

  public getError(): Error | null {
    return this.error;
  }
}

export default CanFail;
