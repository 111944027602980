import { Dialog } from "@headlessui/react";
import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const PopUpHeader: FC<Props> = ({ children, className = "" }) => {
  return (
    <div className={classNames("PopUpHeader", className)}>
      <Dialog.Title
        as="h3"
        className="text-lg leading-6 font-semibold text-gray-900"
      >
        {children}
      </Dialog.Title>
    </div>
  );
};

export default PopUpHeader;
