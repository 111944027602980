import { FC, useContext, useEffect, useState } from "react";
import api from "../../backendAPI";
import SmallCardMemberProfile from "../smallCardMemberProfile";

import { Member, MemberList } from "../../backendProxies";
import useWindowSize from "../../hooks/useWindowSize";
import classNames from "../../utils/twClassNames";
import { TranslationContext } from "../../context/TranslationContext";
import InfiniteScroller from "../infiniteScroller";
import useQuery from "../../hooks/useQuery";
import LoadingSpinner from "../loadingSpinner";

const SettingsBlockedMembers: FC = () => {
  const { i } = useContext(TranslationContext);

  const [blockedMembers, setBlockedMembers] = useState<Member[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pageSize, setPageSize] = useState(32);

  const handleBlockedUsers = async () => {
    const response = await api.blocked.getBlocked({
      pageIndex: 1,
      pageSize: pageSize,
    });

    setBlockedMembers(
      response.data.list.map((item: any) => {
        return new Member(item);
      })
    );

    setHasMore(response.data.items < response.data.total);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [pageSize]);

  useEffect(() => {
    if (!isLoading) return;

    handleBlockedUsers();
  }, [isLoading]);

  const { windowWidth } = useWindowSize();

  const [gridTemplate, setGridTemplate] = useState("");

  useEffect(() => {
    if (windowWidth) {
      if (windowWidth <= 568) {
        setGridTemplate("grid-cols-2");
      } else if (windowWidth <= 768) {
        setGridTemplate("grid-cols-3");
      } else if (windowWidth <= 970) {
        setGridTemplate("grid-cols-4");
      } else if (windowWidth <= 1023) {
        setGridTemplate("grid-cols-5 gap-32");
      } else if (windowWidth <= 1130) {
        setGridTemplate("grid-cols-3");
      } else if (windowWidth <= 1280) {
        setGridTemplate("grid-cols-4");
      } else if (windowWidth <= 1536) {
        setGridTemplate("grid-cols-3");
      } else {
        setGridTemplate("grid-cols-4");
      }
    } else {
      setGridTemplate("grid-cols-4");
    }
  }, [windowWidth]);

  return (
    <div className="SettingsBlockedMembers min-h-[896px] max-w-[750px] ">
      <p className="text-[18px] font-semibold">{i("Blocked Members")}</p>
      <p className="text-[18px] pb-6 pt-2">
        {i("Here is the list of all members you have blocked.")}
      </p>
      <div
        className={classNames(
          gridTemplate,
          "grid h-fit w-full gap-y-4 gap-x-2"
        )}
      >
        {blockedMembers.map((member, index) => {
          return (
            <SmallCardMemberProfile
              member={member}
              key={index}
              onRemove={() => member.removeFromBlockedList()}
            />
          );
        })}
        {isLoading && (
          <div className="w-full flex justify-center">
            <LoadingSpinner />
          </div>
        )}

        {hasMore && !isLoading && (
          <div>
            <InfiniteScroller
              rootMargin={200}
              setShouldLoad={() => {
                setPageSize((prevState) => prevState + 32);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsBlockedMembers;
