import { useSelector } from "react-redux";

import { RootState } from "../redux/store";

import {
  State,
  fetchGreetings,
  fetchConversations,
  fetchMessages,
  setUsernameFilter,
  addConversationToFavorite,
  removeConversationFromFavorite,
  sendMessage,
  deleteConversation,
  deleteGreeting,
  loadGreetingAsActiveConversation,
  messengerSlice,
  setMyLastMessageHasBeenViewed,
  unreadConversation,
  reset,
  setIsReloading,
  addMessageFromLiveAlert,
  setShowActiveConvoOnMobile,
  sendPhoto,
  setShouldScrollBot,
  addGreetingsFromLiveAlert,
  setIsEditingIndex,
  editMessage,
  deleteMessage,
  FetchMessagesProps,
  SendMessageProps,
  setChatMessage,
  returnToMessengerHome,
} from "../redux/slices/messengerSlice";
import useActions from "../redux/useActions";
import { HTTPResponse } from "../backendAPI/Api";
import { PayloadAction } from "@reduxjs/toolkit";

type UseMessenger = () => State &
  typeof messengerSlice.actions & {
    isUserIdLoaded: boolean;
    fetchGreetings: (config: {}) => void;
    loadGreetingAsActiveConversation: (greetingIndex: number) => void;

    unreadConversation: (conversationID: number) => void;

    fetchConversations: (config: {
      increasePageSize?: boolean;
      typeFilter?: "inbox" | "favorites" | "unread" | "security" | "welcome";
      resetPageSize?: boolean;
    }) => void;

    addConversationToFavorite: (conversationId: string) => void;
    removeConversationFromFavorite: (conversationId: string) => void;
    handleUnFavorite: (conversationId: string) => void;

    deleteGreeting: (greetingId: number) => void;

    fetchMessages: (config: FetchMessagesProps) => void;

    sendMessage: (chatMessage: SendMessageProps) => Promise<
      PayloadAction<
        HTTPResponse & {
          data: {
            messageId: string;
            time: `${number}${number}:${number}${number}`;
            message: string;
            messageTextArea: string;
            isEditable: boolean;
          };
        }
      >
    >;

    sendPhoto: (file: File) => void;

    setIsEditing: (isEditing: boolean) => void;
    // TODO: change for messageId when available
    editMessage: (data: { messageId: string; newMessage: string }) => void;
    deleteMessage: (messageId: string) => void;
    deleteConversation: (conversationId: string) => void;

    // chatMessage
    setChatMessage(message: string): void;
  };

const useMessenger: UseMessenger = () => {
  const chatMessage = useSelector(
    (state: RootState) => state.messenger.chatMessage
  );
  const shouldScrollBot = useSelector(
    (state: RootState) => state.messenger.shouldScrollBot
  );

  const greetings = useSelector(
    (state: RootState) => state.messenger.greetings
  );
  const conversations = useSelector(
    (state: RootState) => state.messenger.conversations
  );

  const currentConversation = useSelector(
    (state: RootState) => state.messenger.currentConversation
  );

  const currentGreeting = useSelector(
    (state: RootState) => state.messenger.currentGreeting
  );

  const isReloading = useSelector(
    (state: RootState) => state.messenger.isReloading
  );

  const showActiveConvoOnMobile = useSelector(
    (state: RootState) => state.messenger.showActiveConvoOnMobile
  );

  const error = useSelector((state: RootState) => state.messenger.error);

  const isEditingIndex = useSelector(
    (state: RootState) => state.messenger.isEditingIndex
  );

  const isUserIdLoaded = useSelector(
    (state: RootState) => !!state.messenger.userid
  );

  const actions = useActions({
    fetchGreetings,
    fetchConversations,
    fetchMessages,
    setUsernameFilter,
    addConversationToFavorite,
    removeConversationFromFavorite,
    sendMessage,
    deleteGreeting,
    loadGreetingAsActiveConversation,
    setMyLastMessageHasBeenViewed,
    unreadConversation,
    reset,
    setIsReloading,
    setShowActiveConvoOnMobile,
    addMessageFromLiveAlert,
    sendPhoto,
    setShouldScrollBot,
    addGreetingsFromLiveAlert,
    setIsEditingIndex,
    editMessage,
    deleteMessage,
    deleteConversation,
    setChatMessage,
    returnToMessengerHome,
  });

  return {
    greetings,
    conversations,
    currentConversation,
    currentGreeting,
    error,
    isReloading,
    showActiveConvoOnMobile,
    shouldScrollBot,
    isEditingIndex,
    isUserIdLoaded,
    chatMessage,
    ...actions,
  };
};

export default useMessenger;
