import { FC, useContext } from "react";
import StyledList from "../styledList";
import StyledListItem from "../styledListItem";
import StyledContainer from "../styledContainer";
import { TranslationContext } from "../../context/TranslationContext";
import useUser from "../../hooks/useUser";

const SEXE = {
  "-1": "man or woman",
  "1": "man",
  "2": "woman",
  "1;2": "man or woman",
} as const;

export type MailSettings = {
  sexeId: keyof typeof SEXE;
  ageRange: string;
  countriesAccepted: string;
  DoNotSmoke: boolean;
  DoNotAlcool: boolean;
  DoNotTakeDrugs: boolean;
  area?: "city" | "all";
  maxDistance: number;
};

type Props = {
  mailSettings: MailSettings;
};

const ProfileMailSettingsContainer: FC<Props> = ({ mailSettings }) => {
  const { i } = useContext(TranslationContext);

  const { userData } = useUser();

  const {
    sexeId,
    ageRange,
    countriesAccepted,
    DoNotSmoke,
    DoNotAlcool,
    DoNotTakeDrugs,
    area,
    maxDistance,
  } = mailSettings;
  if (isInvalidAgeRange(ageRange)) {
    console.error("ageRange prop must be in the format 'dd;dd'");
  }

  const fromAge = ageRange.substring(0, ageRange.indexOf(";"));
  const toAge = ageRange.substring(ageRange.indexOf(";") + 1, ageRange.length);
  const showSexe = !!sexeId && (sexeId == "1" || sexeId == "2");
  const showAgeRange = !!ageRange;
  //@ts-ignore
  const mustBeSexe = SEXE[sexeId];
  if (
    !countriesAccepted &&
    !DoNotSmoke &&
    !DoNotAlcool &&
    !DoNotTakeDrugs &&
    area === "all" &&
    sexeId === "1;2"
  )
    return null;

  return (
    <StyledContainer className="ProfileMailSettingsContainer w-full lg:w-[300px] flex flex-col lg:p-[18px] p-[15px]">
      <p className="font-bold text-custom-black text-[22px] leading-[30px]">
        {i("Mail settings")}
      </p>
      <p className="text-custom-black-500 text-base pb-[18px]">
        {i("To send a message to this member, you must")}:
      </p>
      <StyledList>
        {showSexe && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i("Be a <b>[gender]</b>", { gender: i(mustBeSexe) }),
              }}
            />
          </StyledListItem>
        )}
        {showAgeRange && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i(
                  "Be between <b>[fromAge]</b> and <b>[toAge]</b> years old",
                  { fromAge, toAge }
                ),
              }}
            />
          </StyledListItem>
        )}

        {area === "city" && (
          <StyledListItem>
            <span
              dangerouslySetInnerHTML={{
                __html: i(
                  "Be in the same city or within <b>[maxDistance] [distanceUnit]</b>",
                  {
                    maxDistance,
                    distanceUnit: userData.distanceUnit,
                  }
                ),
              }}
            />
          </StyledListItem>
        )}
        {countriesAccepted && (
          <StyledListItem>
            {i("Reside in one of these countries")}:{"  "}
            <b>{countriesAccepted}</b>
          </StyledListItem>
        )}
        {DoNotTakeDrugs && (
          <StyledListItem>{i("Does not take drugs")}</StyledListItem>
        )}
        {DoNotSmoke && <StyledListItem>{i("Is not a smoker")}</StyledListItem>}
        {DoNotAlcool && <StyledListItem>{i("Does not drink")}</StyledListItem>}
      </StyledList>
    </StyledContainer>
  );
};

function isInvalidAgeRange(ageRange: string) {
  return ageRange && !/\d{2}\;\d{2}/.test(ageRange);
}

export default ProfileMailSettingsContainer;
