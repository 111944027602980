import React, { FC, useContext, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { ROOT } from "../../backendAPI/Api";
import useCertification from "../../hooks/useCertification";
import ButtonBGBlue from "../buttonBGBlue";
import LoadingSpinner from "../loadingSpinner";
import VerifPopupPictureContainer from "../verifPopupPictureContainer";
import useTranslation from "../../context/TranslationContext";

import useToast from "../../hooks/useToast";

type Props = {
  handleGoToNextPage: () => any;
};

const VerifPictureContainer: FC<Props> = ({ handleGoToNextPage }) => {
  const { openToast } = useToast();
  const { i, locale } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [firstFile, setFirstFile] = useState<File>();
  const [firstImgSrc, setFirstImgSrc] = useState(
    "/app/assets/confirmationImg1.png"
  );

  const [secondFile, setSecondFile] = useState<File>();
  const [secondImgSrc, setSecondImgSrc] = useState(
    "/app/assets/confirmationImg.png"
  );

  const { accessToken } = useAuth();

  const {
    setFirstImageSrc,
    setSecondImageSrc,
    setIsPendingCertification,
    setChosenMethod,
  } = useCertification();

  const handleImageSubmit = async () => {
    if (!firstFile || !secondFile || isLoading) return;

    setIsLoading(true);

    const formData = new FormData();

    formData.append("locale", locale);
    formData.append("type", "certify");

    formData.append("uploadfile", firstFile);
    formData.append("uploadfile_2", secondFile);
    formData.append("certificationChosenMethod", "photos");

    const response = await fetch(
      ROOT + "/image",

      {
        method: "POST",
        headers: {
          Authorization: accessToken,
        },
        body: formData,
      }
    );
    const jsonResponse = (await response.json()) as {
      status: string;
      message: string;
      data: { imageURL_1: string; imageURL_2: string };
    };

    if (jsonResponse.status === "ERROR") {
      openToast({
        title: "Error",
        description: jsonResponse.message,
        type: "error",
      });
    }

    if (jsonResponse.status === "SUCCESS") {
      setFirstImageSrc(jsonResponse.data.imageURL_1);
      setSecondImageSrc(jsonResponse.data.imageURL_2);
      setChosenMethod("photos");
      handleGoToNextPage();
      setIsPendingCertification(true);
    }

    setIsLoading(false);
  };

  return (
    <div className="VerifPictureContainer">
      <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 w-fit m-auto">
        <VerifPopupPictureContainer
          buttonText={i("Upload my certification picture 1")}
          imageSrc={firstImgSrc}
          imageAlt="first confirmation image"
          setImage={setFirstImgSrc}
          setFile={setFirstFile}
          editImageText={i("Modify")}
        />
        <VerifPopupPictureContainer
          buttonText={i("Upload my certification picture 2")}
          imageSrc={secondImgSrc}
          imageAlt="second confirmation image"
          setImage={setSecondImgSrc}
          setFile={setSecondFile}
          editImageText={i("Modify")}
        />
      </div>

      {!/assets/.test(firstImgSrc) && !/assets/.test(secondImgSrc) && (
        <div className="max-w-[250px] w-full m-auto mt-8 flex items-center justify-center">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <ButtonBGBlue
              innerText={i("Post my certification pictures")}
              onClick={() => {
                handleImageSubmit();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VerifPictureContainer;
