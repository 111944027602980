import { FC } from "react";

type Props = {
  quote: string;
};

const ProfileAboutMeQuote: FC<Props> = ({ quote }) => {
  return (
    <div className="ProfileAboutMeQuote flex sm:mt-[21px]">
      <div className="sm:ml-[41px] ml-[15px] pr-[12px]">
        <img
          src="/app/assets/quote.svg"
          alt={quotationMarkIconAlt}
          className="sm:h-[25px] h-[45px]"
        />
      </div>

      <div className="flex items-center">
        <div
          dangerouslySetInnerHTML={{ __html: quote.replace(/\n/g, "<br/>") }}
          className="w-full sm:mr-[105px] mr-[15px] sm:pt-[5px] pt-[13px] text-custom-black font-normal italic  text-xl break-words"
        ></div>
      </div>
    </div>
  );
};

export const quotationMarkIconAlt = "Quotation marks icon";

export default ProfileAboutMeQuote;
