import { RootState } from "../store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../backendAPI";

import { checkIfHasMore } from "../utils";

import { login, logout } from "./authSlice";

export interface State {
  results: any[];
  pageIndex: number;
  hasMore: boolean;
  isLoading: boolean;
  error: Error | null;
}

const initialState: State = {
  results: [],
  pageIndex: 1,
  hasMore: true,
  isLoading: false,
  error: null,
};

export const fetchResults = createAsyncThunk(
  "favorites/fetchResults",
  async (shouldReset: boolean = false, { getState }) => {
    const state = getState() as RootState;

    const pageIndex = shouldReset ? 1 : state.favorites.pageIndex;

    const response = await api.favorites.getFavorites({
      pageIndex: pageIndex,
      pageSize: 20,
    });

    return response;
  }
);

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      const reset = action.meta.arg;

      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;

      if (reset) {
        state.results = action.payload.data.list;
      } else {
        state.results = state.results.concat(action.payload.data.list);
      }
      state.pageIndex += 1;
    });

    builder.addCase(fetchResults.pending, (state, action) => {
      const reset = action.meta.arg;

      if (reset) {
        state.pageIndex = 1;
        state.results = [];
      }

      state.isLoading = true;
    });

    builder.addCase(fetchResults.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as Error;
    });

    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { reset } = favoritesSlice.actions;

export default favoritesSlice.reducer;
