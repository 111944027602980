import React, { useContext, useEffect } from "react";
import ButtonBGBlue from "../buttonBGBlue";

import { PaymentData, VIPPlan } from "../../backendAPI/Subscription";
import useUser from "../../hooks/useUser";
import useTranslation, {
  TranslationContext,
} from "../../context/TranslationContext";
import MembershipBenefits from "../membershipBenefits";
import MembershipCard from "../membershipCard";
import { SettingsLinkRoutes } from "../../pages/settings";
import { useRouter } from "next/router";
import classNames from "../../utils/twClassNames";
import useAuth from "../../hooks/useAuth";
import Link from "next/link";
import PaymentSection from "../paymentSection";

/* 



// Make sure all the steps work both in settings and /memberships

1) If a member is not certified yet and he clicks one of the buttons, 
he should see a popup similar to the one on the first photo. Clicking on "ok"
closes the popup. Clicking on "certifier mon profil" should start the certification.

Ex user that is not certified -> Breshay


2) If a member is already certified but not vip yet, clicking on the subscribe 
button should open a popup with the same fields as the popup from image number 2 

The amount should correspond to the button clicked 

Ex of user that is vip -> Kannik



3) If a member is already VIP, you need to populate the information correctly about 
the subscription. You can get that information from the api : GET /subscription


4) If a member confirms to delete is subscription, you should call the expected api endpoint 
DELETE /subscription


Ex of user that is vip -> Pascal

*/

export type SubscriptionType = "vip" | "ad-free";

export default function SettingsVIP({
  paypalData,
  defaultTab,
  onTabChange,
  currentTab,
  planToBuy,
  setPlanToBuy,
}: {
  paypalData: PaymentData;
  defaultTab: SettingsLinkRoutes.VIPMember | SettingsLinkRoutes.AdFree;
  onTabChange(hash: SettingsLinkRoutes): void;
  currentTab: string;
  planToBuy: VIPPlan | null;
  setPlanToBuy(plan: VIPPlan | null): void;
}) {
  const { i } = useTranslation();
  const router = useRouter();
  const handleClick = (
    hash: SettingsLinkRoutes.VIPMember | SettingsLinkRoutes.AdFree
  ) => {
    router.replace(`?tab=${hash}`);
    onTabChange(hash);
  };

  const type = currentTab as SubscriptionType;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [planToBuy]);

  return (
    <div className="grid mt-2 gap-4">
      {!planToBuy && (
        <>
          <AlwaysFreeMessage />
          <AlreadyMemberMessage tab={defaultTab} />
          {paypalData.mainPromoMessage && (
            <div className=" bg-custom-gradient-blue w-fit items-center p-2 rounded-md m-auto">
              <p
                className="text-center text-lg text-white"
                dangerouslySetInnerHTML={{
                  __html: paypalData.mainPromoMessage,
                }}
              />
            </div>
          )}
          <div
            className="flex flex-wrap w-full justify-center items-center relative gap-4"
            style={{ rowGap: 8 }}
          >
            <p className="text-lg text-custom-black font-medium w-full text-center md:w-fit md:text-left md:absolute left-0">
              {i("Choose your type of plan")}:
            </p>
            <SubsriptionButton
              label="V.I.P"
              icon="/app/assets/vipcard.png"
              onClick={() => handleClick(SettingsLinkRoutes.VIPMember)}
              active={defaultTab === SettingsLinkRoutes.VIPMember}
            />
            <SubsriptionButton
              label={i("Ad-Free")}
              icon="/app/assets/noads.svg"
              onClick={() => handleClick(SettingsLinkRoutes.AdFree)}
              active={defaultTab === SettingsLinkRoutes.AdFree}
            />
          </div>
        </>
      )}
      {planToBuy ? (
        <PaymentSection
          planType={type === "vip" ? "vip" : "noAds"}
          paymentMethodPageInfo={paypalData.paymentMethodPage}
          additionalInformation={paypalData.additionalInformation}
          planInfo={planToBuy}
          onClick={() => setPlanToBuy(null)}
        />
      ) : defaultTab === SettingsLinkRoutes.VIPMember ? (
        <VIPBenefits
          setPlanToBuy={setPlanToBuy}
          paypalData={paypalData}
          type={type}
        />
      ) : (
        <AdFreeBenefits
          setPlanToBuy={setPlanToBuy}
          paypalData={paypalData}
          type={type}
        />
      )}
      <div className="text-custom-black text-left">
        <p className="mb-4">
          {i(
            "* Your subscription will automatically renew for the same price and duration of the package until you cancel. By subscribing, you authorize us to debit your card now and upon renewal."
          )}
        </p>
        <p>
          {i(
            "By pressing Order, I accept the terms and conditions of Vidamora.com"
          )}
        </p>
        {paypalData?.addTaxeMessage && (
          <p className="mt-4 mb-8">{paypalData?.addTaxeMessage}</p>
        )}
      </div>
    </div>
  );
}

function VIPBenefits({
  paypalData,
  type = "vip",
  setPlanToBuy,
}: {
  paypalData: PaymentData;
  type: SubscriptionType;
  setPlanToBuy: (plan: VIPPlan) => void;
}) {
  return (
    <div className=" rounded-md border-2 border-gray-100 p-4 mb-4">
      <div className="flex items-center md:flex-row flex-col space-y-5 md:space-y-0 md:space-x-5 lg:space-x-2 my-8 w-full lg:w-10/12 md:px-1 m-auto">
        {paypalData &&
          paypalData.plans.vip.map((info, index) => {
            return (
              <MembershipCard
                key={index}
                planType="vip"
                planInfo={info}
                additionalInformation={paypalData.additionalInformation}
                index={index}
                brandName={paypalData["brand_name"]}
                onClick={() => setPlanToBuy(info)}
              />
            );
          })}
      </div>
      <MembershipBenefits type={type} />
    </div>
  );
}

const AdFreeBenefits = ({
  paypalData,
  type = "vip",
  setPlanToBuy,
}: {
  paypalData: PaymentData;
  type: SubscriptionType;
  setPlanToBuy: (plan: VIPPlan) => void;
}) => {
  return (
    <div className=" rounded-md border-2 border-gray-100 p-4 mb-4">
      <div className="flex items-center md:flex-row flex-col space-y-5 md:space-y-0 md:space-x-5 lg:space-x-2 my-8 w-full lg:w-10/12 md:px-1 m-auto">
        {paypalData &&
          paypalData.plans.noAds.map((info, index) => {
            return (
              <MembershipCard
                key={index}
                planType="noAds"
                planInfo={info}
                additionalInformation={paypalData.additionalInformation}
                index={index}
                brandName={paypalData["brand_name"]}
                onClick={() => setPlanToBuy(info)}
              />
            );
          })}
      </div>
      <MembershipBenefits type={type} />
    </div>
  );
};

export function VipSuccess({ type }: { type: SubscriptionType }) {
  const { i } = useContext(TranslationContext);
  const { getLoginInfo } = useAuth();
  const router = useRouter();

  useEffect(() => {
    getLoginInfo();
  }, []);

  return (
    <div className="bg-white w-full text-custom-black flex flex-col items-center pb-5 px-4">
      <div>
        <div className="wrapper h-[80px] w-full">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-center items-center mb-8 md:mb-12">
          <img
            src={
              type === "vip"
                ? "/app/assets/vipcard.svg"
                : "/app/assets/noads.svg"
            }
            alt="vidamora vip logo"
            className="w-[73px] h-[46px] mt-4 md:mt-0"
          />

          <h2 className="sm:text-2xl text-[22px] text-center md:text-start font-bold text-custom-black">
            {type === "vip"
              ? i("You are now a V.I.P.")
              : i("You are now subscribed to the “No Ads” plan!")}
          </h2>
        </div>
        <div className="mb-12">
          <p className="font-semibold text-[22px] leading-[30px] mb-5 text-center">
            {type === "vip"
              ? i(
                  "Thank you for your trust! You are now a V.I.P. member and have access to all these privileges."
                )
              : i(
                  "Thank you for your trust! You can now browse the site without advertising."
                )}
          </p>
        </div>
        <div className="flex flex-col md:flex-row mb-12 items-center md:justify-around mx-4">
          <div className="w-[220px] mb-8 md:mb-0">
            <ButtonBGBlue
              onClick={() => {
                router.push("/");
              }}
              innerText={i("Continue")}
            />
          </div>
          <div className="w-[220px]">
            <ButtonBGBlue
              onClick={() => {
                window.location.href = "/app/settings?tab=manage-subscriptions";
              }}
              innerText={i("See my subscription")}
            />
          </div>
        </div>
      </div>

      <div>
        <MembershipBenefits type={type} />
      </div>
    </div>
  );
}

export function VipWarning({
  onTryAgain,
  type = "vip",
}: {
  onTryAgain(): void;
  type?: SubscriptionType;
}) {
  const { i } = useContext(TranslationContext);
  const router = useRouter();

  return (
    <div className="bg-white w-full text-custom-black flex flex-col items-center pt-[30px] pb-5 px-4">
      <div>
        <div className="flex justify-center items-center mb-12">
          <img
            src="/app/assets/vipcard.svg"
            alt="vidamora vip logo"
            className="w-[73px] h-[46px]"
          />

          <h2 className="sm:text-2xl text-[22px] font-bold text-custom-black ml-4">
            {i(
              "Oops... there seems to have been a problem with your credit card"
            )}
          </h2>
        </div>

        <div className="flex mb-12 justify-around mx-4">
          <div className="w-[220px]">
            <ButtonBGBlue onClick={onTryAgain} innerText={i("Try again")} />
          </div>
          <div className="w-[220px]">
            <ButtonBGBlue
              onClick={() => {
                router.push("/contact-us");
              }}
              innerText={i("Contact us")}
            />
          </div>
        </div>
      </div>

      <div>
        <MembershipBenefits type={type} />
      </div>
    </div>
  );
}

type SubscriptionButtonProps = {
  label: string;
  icon: string;
  active: boolean;
  onClick(): void;
};

const SubsriptionButton = ({
  label,
  icon,
  active,
  onClick,
}: SubscriptionButtonProps) => {
  return (
    <div
      className={classNames(
        "flex h-8 justify-center items-center rounded-xl gap-2 p-4",
        "cursor-pointer hover:bg-gray-300 ease-in-out duration-150",
        active && "bg-gray-200"
      )}
      onClick={onClick}
    >
      <img
        src={icon}
        className="relative"
        alt={`${label} Plan`}
        height={24}
        width={24}
      />
      <p>{label}</p>
    </div>
  );
};

export const AlwaysFreeMessage = () => {
  const { i } = useTranslation();
  return (
    <div className="bg-gray-200 w-full items-center p-2 rounded-md">
      <p
        className="text-left text-lg text-custom-black"
        dangerouslySetInnerHTML={{
          __html: i(
            "We want to reassure you: the site is functional, 100% free, and will remain so forever. However, with our “<strong>[vip]</strong>” subscription, you will be able to promote your profile and gain privileges and advantages that will help you meet more people, even faster. Our “<strong>[noAds]</strong>” subscription which will allow you to enjoy an even better user experience. See for yourself all the advantages and privileges that our currently available packages can bring you.",
            { vip: i("VIP"), noAds: i("Advertising-Free") }
          ),
        }}
      />
    </div>
  );
};

const PLAN_MESSAGES = {
  vip: "You currently have an active subscription for 'V.I.P. Member' .",
  "ad-free":
    "You currently have an active subscription to browse the site without advertising.",
} as const;

const AlreadyMemberMessage = ({
  tab,
}: {
  tab: SettingsLinkRoutes.VIPMember | SettingsLinkRoutes.AdFree;
}) => {
  const {
    userData: { isVIP, isPremiumNoAds },
  } = useUser();

  const { i } = useTranslation();

  if (tab === SettingsLinkRoutes.VIPMember && !isVIP) return null;
  if (tab === SettingsLinkRoutes.AdFree && !isPremiumNoAds) return null;

  return (
    <div
      className="flex items-center rounded-xl bg-custom-blue text-white px-4 py-3"
      role="alert"
    >
      <svg
        className="fill-current w-8 h-4 mr-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
      </svg>
      <div className="flex flex-row flex-wrap items-center gap-2">
        {i(PLAN_MESSAGES[tab])}
        <Link href={`?tab=${SettingsLinkRoutes.ManageSubscriptions}`}>
          <a
            data-testid={`navlink-for-vip`}
            className={`text-white flex px-2 py-1 items-center text-base font-medium rounded-[10px] underline`}
          >
            <span className="flex-1 text-lg">
              {i("Manage my subscriptions")}
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};
