import { FC, useContext, useEffect, useState } from "react";
import api from "../../backendAPI";
import { TranslationContext } from "../../context/TranslationContext";
import useToast from "../../hooks/useToast";
import MessageActiveConvo from "../messageActiveConvo";
import MessageChatBoxSend from "../messageChatBoxSend";
import { IMessage } from "../../backendAPI/Messages";
import useMessenger from "../../hooks/useMessenger";

export function ProfileModalMessageContent({
  recipientUserId,
  recipientUserName,
  onSend,
}: {
  recipientUserId: number;
  recipientUserName: string;
  onSend: () => void;
}) {
  const { setIsReloading } = useMessenger();
  const [items, setItems] = useState<IMessage[]>([]);
  const [message, setMessage] = useState("");

  const fetchMessages = (userId: string) => {
    api.messages
      .getDiscussion({
        pageIndex: 1,
        pageSize: 30,
        discussionId: -5,
        userId,
      })
      .then((res) => {
        if (res.status === "ERROR") {
          console.log(res.message);
        } else {
          setItems(res.data.items);
        }
      });
  };

  const { openToast, openTimedToast } = useToast();
  const { i } = useContext(TranslationContext);

  useEffect(() => {
    fetchMessages(recipientUserId.toString());
    setMessage("");
  }, [recipientUserId]);

  const handleSend = (_message: string, userId: number, username: string) => {
    const response = api.messages.postMessage({
      discussionId: -1,
      message: _message,
      username: username,
      userId: userId,
    });

    response.then((res) => {
      if (res.status === "ERROR") {
        openToast({
          title: i("Error"),
          description: res.message,
          type: "error",
        });
      } else {
        openTimedToast({
          title: i("Success"),
          description: i("Message sent!"),
          type: "success",
          timeout: 4000,
        });
        setMessage("");
        onSend();
      }
    });
  };

  return (
    <div className="SayHelloCustom h-full min-w-72 xs:min-w-80 w-full z-[60]">
      <div className={`h-full flex flex-col w-full items-center`}>
        <div className="w-full h-full flex flex-col">
          <div className="h-full overflow-hidden">
            <MessageActiveConvo messages={items} allowModifications={false} />
          </div>

          <div className={`pb-[18px] h-fit pt-[12px] pr-5`}>
            <div className="MessageChatBox flex flex-row w-full max-w-full space-x-5 items-center">
              <MessageChatBoxText
                onSendClick={() =>
                  handleSend(message, recipientUserId, recipientUserName)
                }
                setChatMessage={setMessage}
                chatMessage={message}
              />
              <MessageChatBoxSend
                onSendClick={() =>
                  handleSend(message, recipientUserId, recipientUserName)
                }
                chatMessage={message}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type Props = {
  setChatMessage(message: string): void;
  chatMessage: string;
  onSendClick: () => void;
};

const MessageChatBoxText: FC<Props> = ({
  setChatMessage,
  chatMessage,
  onSendClick,
}) => {
  const { i } = useContext(TranslationContext);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.shiftKey && event.key === "Enter") {
      event.stopPropagation();
    } else if (event.key === "Enter") {
      event.preventDefault();
      onSendClick();
    }
  };

  return (
    <div className="MessageChatBoxText w-full relative">
      <div className="overflow-x-hidden flex items-stretch max-h-64">
        <textarea
          data-testid="messenger-textarea"
          id="messenge-textarea"
          rows={5}
          value={chatMessage}
          onKeyDown={handleKeyDown}
          onChange={(event) => setChatMessage(event.target.value)}
          className={`messageBox relative w-full outline-none border  border-custom-light-gray-600 rounded-[4px] resize-none h-full p-3.5 pt-2.5 pl-[22px] pr-10 bg-white text-base text-custom-black placeholder-custom-black-500/50 focus:outline-none focus:shadow-outline `}
          placeholder={i("Type your message...")}
        />
      </div>
    </div>
  );
};
