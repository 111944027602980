import { FC, useState } from "react";
import { DOMAIN } from "../../backendAPI/Api";

import Dialog from "@mui/material/Dialog";
import useTranslation from "../../context/TranslationContext";

type Props = {
  message: string;
  imageSrc: string;
  imageSrc_thumbs: string;
  isDeleted?: boolean;
};

const MessageBubbleText: FC<Props> = ({
  message,
  imageSrc,
  imageSrc_thumbs,
  isDeleted,
}) => {
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  if (isDeleted) return <DeletedChatBubble />;

  return (
    <>
      <div className="MessageBubbleText w-full max-w-full">
        {imageSrc ? (
          <img
            className="rounded-lg cursor-pointer"
            onClick={() => setIsImagePreviewOpen(true)}
            src={DOMAIN + imageSrc_thumbs}
            alt="Messenger image"
          />
        ) : (
          <p
            dangerouslySetInnerHTML={{ __html: message }}
            className="custom-black-500 text-base break-words"
          ></p>
        )}
      </div>
      <Dialog
        onClose={() => setIsImagePreviewOpen(false)}
        open={isImagePreviewOpen}
      >
        <div className="bg-white p-4 rounded-lg">
          <img src={DOMAIN + imageSrc} alt="Messenger image" />
        </div>
      </Dialog>
    </>
  );
};

export default MessageBubbleText;

export function DeletedChatBubble() {
  const { i } = useTranslation();
  return (
    <div className="MessageBubbleText w-full max-w-full">
      <p className="text-custom-black-500/90 text-base break-words italic">
        {i("Message deleted")}
      </p>
    </div>
  );
}
