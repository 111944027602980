import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

const icons = {
  check: (
    <path d="M2.16724 4.45378L2.16722 4.45376C2.01603 4.30933 1.81285 4.22971 1.6028 4.22971C1.39276 4.22971 1.18958 4.30933 1.03839 4.45376L1.03834 4.4538C0.88688 4.59856 0.8 4.79687 0.8 5.00568C0.8 5.21448 0.88688 5.41279 1.03834 5.55756L1.03837 5.55758L3.40123 7.81525C3.53217 7.94041 3.68715 8.03923 3.85708 8.10651C4.02701 8.17378 4.20884 8.2083 4.39228 8.2083C4.57571 8.2083 4.75755 8.17378 4.92747 8.10651C5.0974 8.03924 5.25236 7.94042 5.38329 7.81527C5.3833 7.81526 5.38331 7.81526 5.38332 7.81525L10.9616 2.48529L10.9617 2.48527C11.1131 2.3405 11.2 2.14219 11.2 1.93339C11.2 1.72458 11.1131 1.52627 10.9617 1.38151L10.9616 1.38147C10.8104 1.23705 10.6072 1.15742 10.3972 1.15742C10.1872 1.15742 9.98398 1.23705 9.83278 1.38147L9.83276 1.38148L4.39198 6.57947L2.16724 4.45378Z" />
  ),
  notChecked: (
    <path d="M7 4.49999L10.3352 1.18865C10.5549 0.968911 10.5549 0.384519 10.3352 0.164802C10.1155 -0.0549338 9.60269 -0.0549338 9.38297 0.164802L5.99999 3.5L2.73549 0.164802C2.51576 -0.0549338 1.88452 -0.0549338 1.6648 0.164802C1.44508 0.384537 1.44507 0.968928 1.6648 1.18865L5 4.49999L1.6648 7.92393C1.44507 8.14366 1.44507 8.61548 1.6648 8.8352C1.88454 9.05492 2.51577 9.05493 2.73549 8.8352L5.99999 5.5L9.38297 8.8352C9.6027 9.05493 10.1155 9.05493 10.3352 8.8352C10.5549 8.61546 10.5549 8.14365 10.3352 7.92393L7 4.49999Z" />
  ),
};

type Props = {
  children: React.ReactNode;
  icon?: "check" | "notChecked";
  iconClassName?: string;
  className?: string;
};

const StyledListItem: FC<Props> = ({
  children,
  icon = "check",
  className,
  iconClassName = "fill-black",
}) => {
  return (
    <div className="StyledListItem flex flex-row items-start">
      <div className="px-2.5 pt-2">
        <svg
          className={iconClassName}
          width="12"
          height="9"
          viewBox="0 0 12 9"
          xmlns="http://www.w3.org/2000/svg"
        >
          {icons[icon]}
        </svg>
      </div>
      <p
        className={classNames(
          "text-custom-black-500 text-base text-left leading-[22px]",
          className || false
        )}
      >
        {children}
      </p>
    </div>
  );
};

export default StyledListItem;
