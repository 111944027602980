import React, { useState } from "react";
import ChatBox from "./ChatBox";
import ButtonBGBlue from "../../components/buttonBGBlue";
import { useVideoChat } from "../contexts/VideoChatContext";
import VideoChatControls from "./VideoChatControls";
import VideoPreview from "./VideoPreview";
import VideoContainer from "./VideoContainer";

export default function Main() {
  const { close, remoteStream, isDialing } = useVideoChat();

  return (
    <div
      data-page-name="videochat"
      className="fixed flex inset-0 overflow-hidden"
    >
      <div className="bg-black p-8 flex-grow ">
        <div className="bg-custom-semi-black h-full text-white flex items-center flex-col justify-between py-6 relative">
          {!remoteStream && !isDialing && (
            <div className="cursor-pointer absolute left-6 top-6 p-4 z-10">
              <img
                className="h-5"
                src="/app/assets/backArrow.svg"
                alt="Back arrow"
                onClick={close}
              />
            </div>
          )}
          <VideoPreview />
          <VideoContainer />
          <VideoChatControls />
        </div>
      </div>
      <ChatBox />
      <style>{`
        #google_pedestal_container {
        display: none !important;
      }
        div[data-page-name="videochat"] {
        z-index: 1000;
      }
       `}</style>
    </div>
  );
}

/**
 * VideoChat Flow Plan
 * On call someone:
 *  active is true, displays overlay
 *  currently dialing, callref is null
 *  we create a peer and send a message to other user
 *
 */
