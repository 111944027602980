import { FC, useContext, useEffect, useState } from "react";

import StyledContainerHeader from "../styledContainerHeader/StyledContainerHeader";
import SettingsCertifiedButton from "../settingsCertifiedButton";
import StyledToggle from "../styledToggle";

import useUser from "../../hooks/useUser";

import { NotificationOption } from "../../types";
import { TranslationContext } from "../../context/TranslationContext";
import api from "../../backendAPI";
import useToast from "../../hooks/useToast";

const SettingsEmail: FC = () => {
  const { i } = useContext(TranslationContext);

  const { openToast, closeToast } = useToast();

  const { userData } = useUser();

  const { EmailConfirmed, isCertified } = userData;

  const [shouldLoad, setShouldLoad] = useState(true);

  const options = {
    optAlerteMessage: i(
      "I want to receive an alert on my personal email when I receive a new message"
    ),
    optListeEnvoi: i(
      "I want my personal email to appear on the vidamora.com automatic mailing list sent every month"
    ),
    /*optfavorite: i(
      "I want to receive an email alert when a member adds me to their favorites"
    ),*/
    optnotification: i(
      "I want to receive an email alert informing me of notifications when my last visit was over 3 days ago"
    ),
    optproximite: i(
      "I want to receive an email alert when a member likes my profile and wants to date me (via the 'Date me' section)"
    ),
  };
  /*//optfavorite: false,*/
  const [notificationOptions, setNotificationOptions] =
    useState<NotificationOption>({
      optAlerteMessage: false,
      optListeEnvoi: false,
      optnotification: false,
      optproximite: false,
    });

  useEffect(() => {
    if (!shouldLoad) return;

    const handleEmailOptions = async () => {
      api.editProfile.getAlertEmail().then((res) => {
        setShouldLoad(false);
        setNotificationOptions(res.data);
      });
    };

    handleEmailOptions();
  }, [shouldLoad]);

  const notificationsOptionsList = Object.entries(notificationOptions).map(
    (entry) => ({ id: entry[0], value: entry[1] })
  ) as { id: keyof NotificationOption; value: boolean }[];

  const handleChange = async (id: keyof NotificationOption) => {
    api.editProfile
      .patchAlertEmail({
        ...notificationOptions,
        [id]: !notificationOptions[id],
      })
      .then((res) => {
        if (res.status === "SUCCESS") {
          openToast({
            title: i("Success"),
            description: i("Your preferences has been updated!"),
            type: "success",
          });

          setTimeout(() => {
            closeToast();
          }, 2000);

          setShouldLoad(true);

          return;
        }
      });
  };

  return (
    <div className="SettingsEmail divide-y">
      <div>
        <StyledContainerHeader>
          <div>
            <h2>{i("Email Notifications")}</h2>
          </div>
        </StyledContainerHeader>
        <div className="divide-y">
          {notificationsOptionsList.map((option, index) => {
            return (
              <div key={index} className="flex justify-between py-4">
                <div>
                  <p className="leading-7 pr-1 lg:pr-0">{options[option.id]}</p>
                </div>
                <div>
                  <StyledToggle
                    checked={option.value}
                    handleChange={() => handleChange(option.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SettingsEmail;
