import { useRouter } from "next/router";
import React, { FC, useContext, useEffect, useState } from "react";
import api from "../../backendAPI";
import { TranslationContext } from "../../context/TranslationContext";
import ButtonBGBlue from "../buttonBGBlue";
import ErrorLabel from "../errorLabel";
import LoadingSpinner from "../loadingSpinner";
import SettingsActionCard from "../settingsActionCard";
import SettingsChangePasswordField from "../settingsChangePasswordField";
import SettingsFeedbackTextField from "../settingsFeedbackTextField";
import SettingsUnsubscribeCheckBox from "../settingsUnsubscribeCheckBox";

import Dialog from "@mui/material/Dialog";
import { timer } from "rxjs";

type Props = {
  disableOption: string;
  setDisableOption: React.Dispatch<React.SetStateAction<string>>;
  action?: "delete" | "disable";
};

const DeactivatingAccountOptions: FC<Props> = ({
  disableOption,
  setDisableOption,
  action = "disable",
}) => {
  const { i } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonId, setReasonId] = useState<number>();
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(false);

  const [isDeactivatedOrDeletedMessage, setIsDeactivatedOrDeletedMessage] =
    useState<string | null>(null);

  useEffect(() => {
    if (isDeactivatedOrDeletedMessage === null) return;

    timer(5000).subscribe(() => {
      router.push("/logout");
    });
  }, [isDeactivatedOrDeletedMessage]);

  const router = useRouter();

  const handleConfirm = async () => {
    if (!reasonId || isLoading || !password) return;
    setError("");
    setIsLoading(true);

    const response = await api.editProfile.disableDeleteProfile({
      password: password,
      choix: action == "disable" ? 1 : 2,
      message: message,
      unsubscribeFromNewsletter: unsubscribe,
      raisonId: reasonId,
    });

    if (response.status == "SUCCESS") {
      setIsDeactivatedOrDeletedMessage(response.data.message);
    } else setError(response.message);
    setIsLoading(false);
  };
  return (
    <div className="DeactivatingAccountOptions">
      <div>
        <p className="mb-6">
          {i("Please choose why you want to [action]", {
            action: (
              <span className="font-bold">
                {i(
                  action === "delete"
                    ? "delete your profile"
                    : "disable your profile"
                )}
              </span>
            ),
          })}
        </p>
      </div>

      <div>
        <div>
          <SettingsActionCard
            label={i("I get too much junk mail")}
            checked={reasonId == 1}
            setChecked={() => setReasonId(1)}
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            description={i("Do you know that you can prevent that")}
          >
            <br />
            <br />
            <a
              onClick={() => window.open("/app/profile/my-profile")}
              className="text-custom-blue cursor-pointer hover:underline"
            >
              {" " + i("Mail settings")}
            </a>
          </SettingsActionCard>
        </div>

        <div>
          <SettingsActionCard
            label={i("My account has been hacked")}
            checked={reasonId === 2}
            setChecked={() => setReasonId(2)}
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            description={i(
              "Do not lose your chance to find love because of that"
            )}
          >
            <br />
            <br />
            <a
              onClick={() => window.open("/app/settings?tab=password")}
              className="text-custom-blue cursor-pointer hover:underline"
            >
              {" " + i("Change my password")}
            </a>
          </SettingsActionCard>
        </div>
        <div>
          <SettingsActionCard
            label={i("I met someone through the site")}
            checked={reasonId === 3}
            disableOption={disableOption}
            setChecked={() => setReasonId(3)}
            setDisableOption={setDisableOption}
            description={i(
              "Congratulations! We are very happy for you! Can we ask you for a service? We would really like you to write us a short text, only a few lines, that we could post in our testimonials section! Thank you in advance! I'ts very nice!"
            )}
          >
            <br />
            <br />
            <a
              onClick={() =>
                window.open(i("https://www.vidamora.com/post-a-testimonial"))
              }
              className="text-custom-blue cursor-pointer hover:underline"
            >
              {" " + i("Write a testimonial")}
            </a>
          </SettingsActionCard>
        </div>

        <div>
          <SettingsActionCard
            label={i("Nobody interests me")}
            checked={reasonId === 4}
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            setChecked={() => setReasonId(4)}
            description={i(
              "It may be that for the moment nobody meets your criteria, but"
            )}
          />
        </div>

        <div>
          <SettingsActionCard
            label={i("Other reasons")}
            checked={reasonId === 5}
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            setChecked={() => setReasonId(5)}
            description={i(
              "Whatever your reason, it is surely valid! If you have a problem or a problem with the site [link]",
              {
                link: (
                  <>
                    <br />
                    <br />
                    <a
                      onClick={() => window.open("/app/contact-us")}
                      className="text-custom-blue cursor-pointer hover:underline mx-1"
                    >
                      {i("Contact Us2")}
                    </a>
                  </>
                ),
              }
            )}
          />
        </div>
      </div>

      <div>
        <p className="text-lg mt-7 mb-3">
          {i(
            "We would appreciate your comments on what you like about the site or what should be improved. You can also use this to explain your reasons if you chose 'other reason' in the choices above!"
          )}
        </p>
      </div>

      <div>
        <SettingsFeedbackTextField handleChange={(str) => setMessage(str)} />
      </div>
      <div className="my-7">
        <SettingsChangePasswordField
          label={i("Password")}
          placeholder={i("Enter password")}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <div>
        <div className="flex mt-5">
          <SettingsUnsubscribeCheckBox
            onClick={(checked) => setUnsubscribe(checked)}
            text={i("Unsubscribe from newsletter")}
          />
        </div>
      </div>

      <ErrorLabel errorMessage={error} />

      <div className="w-300 lg:w-330 mt-7">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ButtonBGBlue
            innerText={
              action == "delete"
                ? i("Confirm and delete my profile")
                : i("Confirm and disable my profile")
            }
            disabled={!reasonId || !password || isLoading}
            onClick={handleConfirm}
          />
        )}
      </div>
      <Dialog open={isDeactivatedOrDeletedMessage !== null}>
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden relative z-80 px-5 m-auto w-72 lg:w-96">
          <div className="items-end w-full flex flex-col p-6 px-1 h-[384px]">
            <div className="w-full h-full pb-5 flex items-center flex-col justify-around">
              <div className="flex flex-col items-center my-6">
                <img src="/app/assets/vidamora-logo.svg" alt="vidamora logo" />
              </div>

              <div>
                <p className="text-custom-black text-xl font-semibold w-full text-center">
                  {isDeactivatedOrDeletedMessage}
                </p>
              </div>

              <div className="mt-8">
                <LoadingSpinner />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeactivatingAccountOptions;
