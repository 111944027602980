import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Blocked extends Api {
  public async getBlocked(queryParams: {
    pageIndex: number;
    pageSize: number;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any;
      };
    }
  > {
    return this.get("/blocked", queryParams);
  }

  public async postBlocked(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/blocked", body);
  }

  public async deleteBlocked(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/blocked", body);
  }
}
