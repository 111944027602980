import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class NewMembers extends Api {
  public async getNewMembers(queryParams: {
    pageIndex: number;
    pageSize: number;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any;
      };
    }
  > {
    return this.get("/newMembers", queryParams);
  }
}
