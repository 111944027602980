import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as Sentry from "@sentry/browser";
import { useSelector } from "react-redux";
import {
  setTranslations as setTranslationsAction,
  setLang as setLangAction,
} from "../../redux/slices/bootstrapSlice";
import { RootState } from "../../redux/store";
import useActions from "../../redux/useActions";
import { AVAILABLE_LANGUAGES, Languages, assertLanguage } from ".";
import { DEFAULT_LANGUAGE } from "./utils";

type ExtractInterpolation<P> =
  P extends `${any}[${infer Interpolation}]${infer Rest}`
    ? [Interpolation, ...ExtractInterpolation<Rest>]
    : [];

type NewType = typeof AVAILABLE_LANGUAGES;

interface Context {
  lang: Languages | null;
  locale: Languages;
  i: <S extends string, D>(
    textToTranslate: S,
    interpolations?: S extends `${any}[${infer Interpolation}]${infer Rest}`
      ? Record<
          [Interpolation, ...ExtractInterpolation<Rest>][number],
          D[keyof D] extends React.ReactNode ? React.ReactNode : string | number
        >
      : undefined
  ) => D extends Element ? Element : string;
  setLang: React.Dispatch<React.SetStateAction<Languages | null>>;
  cdnUrl: string;
  isVidamora: boolean;
  languages: NewType;
}

export const TranslationContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function TranslationProvider({ children }: Props) {
  const { setLang, setTranslations } = useActions({
    setLang: setLangAction,
    setTranslations: setTranslationsAction,
  });
  const lang = useSelector((state: RootState) => state._bootstrap.lang);
  const locale = lang || DEFAULT_LANGUAGE;
  const translations = useSelector(
    (state: RootState) => state._bootstrap.translations
  );

  const [isVidamora, setIsVidamora] = useState(false);

  const [cdnUrl, setCdnUrl] = useState<string>(
    "https://cdn.celibatairesduweb.com"
  );

  const changeLanguage = useCallback(
    (value: any, fallback?: Languages | null) => {
      let selectedLang = assertLanguage(value);
      if (selectedLang !== value) {
        // If we go to a default language, we first use fallback arg if given, then currently selected language if it is set
        // This is to prevent from going to a default language from a valid one when an invalid value is given
        selectedLang = fallback ?? selectedLang;
      }
      setLang(selectedLang);
      window.localStorage.setItem("lang", selectedLang);
    },
    [setLang]
  );

  useEffect(() => {
    const isVidamora = /vidamora/.test(window.location.href);
    if (isVidamora) {
      setCdnUrl("https://cdn.vidamora.com");
      setIsVidamora(true);
    }
  }, []);

  const i = <S extends string, D>(
    textToTranslate: string,
    interpolations?: S extends `${any}[${infer Interpolation}]${infer Rest}`
      ? Record<
          [Interpolation, ...ExtractInterpolation<Rest>][number],
          D[keyof D] extends React.ReactNode ? React.ReactNode : string | number
        >
      : undefined
  ): D[keyof D] extends React.ReactNode ? React.ReactNode : string => {
    if (lang === null) return "";
    if (!(lang in AVAILABLE_LANGUAGES)) {
      // This could set default language instead
      // Sentry.captureException(new Error(`Wrong language: ${lang}`));
      // throw new Error("Translation error");
    }
    if (!translations?.[textToTranslate]) {
      // Sentry.captureException(
      // new Error("Missing translation for text:" + textToTranslate)
      // );
    }
    let translatedText = translations?.[textToTranslate] ?? textToTranslate;
    if (interpolations) {
      const nodes: Record<string, React.ReactNode> = {};
      Object.entries(interpolations).forEach(([k, v]) => {
        if (typeof v === "object") {
          nodes[`[${k}]`] = v;
          return;
        }

        if (translatedText["replaceAll"]) {
          translatedText = translatedText.replaceAll(`[${k}]`, String(v));
        } else {
          const re = new RegExp(`[${k}]`, "g");

          translatedText = translatedText.replace(re, String(v));
        }
      });
      if (!!Object.entries(nodes).length) {
        const element: React.ReactNode[] = translatedText
          .split(/(\[\w+\])/g)
          .map((x) => (x in nodes ? nodes[x] : x));
        return <>{element}</>;
      }
    }

    return translatedText;
  };

  if (!translations) return null; // Prevent loading text until we have translations

  return (
    <TranslationContext.Provider
      value={{
        // Not sure how to make TS happy. It is working well enough
        // @ts-ignore
        i,
        setLang: changeLanguage,
        lang,
        locale,
        cdnUrl:
          window.location.href.includes("localhost") ||
          window.location.href.includes("dev.")
            ? ""
            : cdnUrl,
        isVidamora,
        languages: AVAILABLE_LANGUAGES,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export default function useTranslation() {
  const context = useContext(TranslationContext);
  if (!("lang" in context))
    throw new Error(
      "TranslationContext must be used in a translation provider"
    );
  return context;
}
