import React, { FC, useContext } from "react";
import { Member } from "../../backendProxies";
import { SayHiContext } from "../../context/SayHiContext";
import { TranslationContext } from "../../context/TranslationContext";
import useUser from "../../hooks/useUser";
import ProfileActionButton from "../profileActionButton";

type Props = { member?: Member; onOpenGreetings?: () => void };

const GreetingActionButton: FC<Props> = ({ member, onOpenGreetings }) => {
  const { i } = useContext(TranslationContext);
  const { userData } = useUser();
  const {
    openPopup: startGreeting,
    setRecipientUserID,
    setRecipientUsername,
  } = useContext(SayHiContext);
  const onClick = () => {
    if (member) {
      setRecipientUsername(member.info.userName);
      setRecipientUserID(+member.info.userId);
      startGreeting(member.info.mainImage, !!onOpenGreetings);
    } else {
      setRecipientUsername(userData?.NomUsager);
      setRecipientUserID(+userData?.userid);
      startGreeting(userData?.mainImage, !!onOpenGreetings);
    }
    if (onOpenGreetings) onOpenGreetings();
  };
  return (
    <ProfileActionButton
      onClick={onClick}
      icon={{
        alt: i("Send greeting"),
        src: "/app/assets/profile/greetingIcon.svg",
      }}
    />
  );
};

export default GreetingActionButton;
