import Api, { HTTPResponseWithDataMessage } from "./Api";

export default class Votes extends Api {
  public async postVote(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/voteProfile", body);
  }

  public async deleteVote(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/voteProfile", body);
  }
}
