import { FC, useContext } from "react";
import { useRouter } from "next/router";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  onClick?: () => void;
  showReturnText?: boolean;
};

const BackButton: FC<Props> = ({ onClick, showReturnText = true }) => {
  const { i, cdnUrl } = useContext(TranslationContext);
  const router = useRouter();

  const handleClick = () => (onClick ? onClick() : router.back());

  return (
    <div onClick={handleClick} className="BackButton flex cursor-pointer">
      <img src={`${cdnUrl}/app/assets/backArrow.svg`} alt="Back arrow" />
      {!showReturnText && (
        <p className="text-custom-blue font-bold ml-3 md:mr-20">{i("back")}</p>
      )}
    </div>
  );
};

export default BackButton;
