import React, { FC } from "react";

type Props = { imageSrc?: string };

const SayHelloIcon: FC<Props> = ({ imageSrc }) => (
  <img
    src={imageSrc ? imageSrc : "/app/assets/Icons/heartIcon.svg"}
    alt="Vidamora logo"
    className="SayHelloIcon h-[45px] sm:h-[60px] py-[10px] pr-2 sm:pr-2.5"
  />
);

export default SayHelloIcon;
