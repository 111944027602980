export default function getGenderId(
  genderName: "Man" | "Woman" | "Both"
): 1 | 2 | -1 {
  switch (genderName) {
    case "Man":
      return 1;
    case "Woman":
      return 2;
    default:
      return -1;
  }
}

export function getGenderName(genderId: 1 | 2 | -1): "Man" | "Woman" | "Both" {
  switch (+genderId) {
    case 1:
      return "Man";
    case 2:
      return "Woman";
    default:
      return "Both";
  }
}
