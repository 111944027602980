import React, { FC } from "react";
import Footer from "../footer";
import NavBar from "../navBar";
import NavBarBanner from "../navBarBanner";
import SideNav from "../sideNav";

type Props = {
  children: React.ReactNode;
  currentPage: string;
  pagesWithNotification: string[];
  hasSmallSideNavAd?: boolean;
  altSideNav?: React.ReactNode;
};

const PageContainer: FC<Props> = ({
  children,
  pagesWithNotification,
  currentPage,
  hasSmallSideNavAd,
  altSideNav,
}) => {
  return (
    <div className="PageContainer bg-custom-background flex flex-col min-h-screen">
      <div className="sticky top-0 z-50 h-fit">
        <NavBarBanner />
        <NavBar hasNotifications={true} />
      </div>
      <div className="flex flex-col h-full justify-between flex-1">
        <div className="lg:pt-5 pt-4 pl-5 pr-5 xl:pr-0 w-full flex mb-[40px] lg:mb-[60px]">
          <div className=" w-full max-w-[1400px] flex m-auto">
            <div className="xl:pr-6 pr-0 lg:w-fit">
              {altSideNav ? (
                altSideNav
              ) : (
                <SideNav
                  currentPage={currentPage}
                  pagesWithNotification={pagesWithNotification}
                  hasSmallAd={hasSmallSideNavAd}
                />
              )}
            </div>
            <div className="h-full xl:w-[73.5%] 2xl:w-[76%] w-full">
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PageContainer;
