import { IconBadge } from "../../components/profileIconBadge/ProfileIconBadge";
import FetchBehavior from "./FetchBehavior";

export type PinnedItem = {
  showPinnedItem: boolean;
  infos: {
    action: {
      label: string;
      type: "" | "feedback";
    };
    title: string;
    description: string;
    icon: IconBadge;
  };
} | null;

interface IBackendProxy<Data> {
  getElements(): Promise<[Data[], boolean, PinnedItem]>;
  loadMore(): Promise<void>;
  getError(): Error | null;
}

abstract class BackendProxy<Data, Endpoint, QueryParams>
  implements IBackendProxy<Data>
{
  private wrapperClass: any;

  protected data: Data[] = [];

  protected pinned: PinnedItem = null;

  protected endpoint: Endpoint;

  protected config: QueryParams;

  protected fetchBehavior: FetchBehavior<Data, Endpoint, QueryParams> =
    new FetchBehavior();

  protected error: Error | null = null;

  protected pageIndex: number = 1;

  public hasMore: boolean = true;

  constructor(endpoint: Endpoint, config: QueryParams, wrapperCLass: any) {
    this.endpoint = endpoint;
    this.config = config;
    this.wrapperClass = wrapperCLass;
  }

  protected async fetchFromServer(): Promise<Data[]> {
    const [data, canLoadMore, pinned] =
      await this.fetchBehavior.fetchFromServer(
        this.endpoint,
        this.pageIndex,
        this.config
      );

    this.hasMore = canLoadMore;
    if (this.data.length === 0) {
      this.pinned = pinned;
    }

    return data;
  }

  public async getElements(): Promise<[Data[], boolean, PinnedItem]> {
    if (this.data.length === 0) {
      const dataFromServer = await this.fetchFromServer();
      this.data = dataFromServer.map((data) => {
        data = new this.wrapperClass(data);

        return data;
      });
    }

    return [this.data, this.hasMore, this.pinned];
  }

  public async loadMore(): Promise<void> {
    //@ts-ignore
    this.config.type = "read";

    return new Promise(async (resolve) => {
      this.pageIndex += 1;

      const moreMembers = await this.fetchFromServer();

      this.data = [
        ...this.data,
        ...moreMembers.map((member) => new this.wrapperClass(member)),
      ];

      resolve();
    });
  }

  public getError(): Error | null {
    return this.fetchBehavior.getError();
  }
}

export default BackendProxy;
