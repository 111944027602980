import { FC, useState } from "react";

type Props = {
  onClick: (checked: boolean) => void;
  text?: string;
};

const SettingsUnsubscribeCheckBox: FC<Props> = ({ onClick, text }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    onClick(!checked);
    setChecked(!checked);
  };

  return (
    <div className="SettingsUnsubscribeCheckBox flex" onClick={handleClick}>
      <input
        className="accent-custom-blue h-[26px] w-[26px] text-custom-blue border-gray-300 rounded md:mb-2.5 mb-0"
        type="checkbox"
        checked={checked}
      />
      {text && <p className="ml-3 font-bold cursor-pointer">{text}</p>}
    </div>
  );
};

export default SettingsUnsubscribeCheckBox;
