import { IMessage } from "../../backendAPI/Messages";
import MessageBubbleTimestamp from "../messageBubbleTimestamp";
import MessageBubbleText from "../messageBubbleText";

import { PencilIcon, XIcon } from "@heroicons/react/solid";
import { useMemo, useState } from "react";
import useTranslation from "../../context/TranslationContext";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import useMessenger from "../../hooks/useMessenger";
import classNames from "../../utils/twClassNames";

type MessageBubbleProps = IMessage & {
  index: number;
  allowModifications?: boolean;
};

const MessageBubble = (props: MessageBubbleProps) => {
  switch (props.type) {
    case "incoming":
      return <MessageIncomingBubble {...props} />;

    case "outgoing":
      return (
        <MessageOutgoingBubble
          {...props}
          allowModifications={props?.allowModifications ?? true}
        />
      );
  }

  return <></>;
};

export default MessageBubble;

function MessageIncomingBubble({
  message,
  pathPhoto = "",
  pathPhoto_thumbs = "",
  isDeleted,
  isInfoVideoCall,
  index,
  time,
  messageHasBeenEdited,
}: MessageBubbleProps) {
  const [selected, setSelected] = useState(false);
  return (
    <div
      className="MessageIncomingBubble w-full flex justify-start"
      onClick={() => setSelected((prev) => !prev)}
    >
      <div className="relative w-fit max-w-full sm:max-w-[85%]">
        <div
          className={classNames(
            "flex flex-col py-2 pl-4 pr-3 space-x-2 rounded-[10px] items-end justify-end w-full relative",
            isDeleted || isInfoVideoCall
              ? "border border-custom-black-200/60"
              : "bg-[#EFF1FE]"
          )}
        >
          {!isDeleted && (
            <MessageBubbleTimestamp
              hour_tz={time}
              index={index}
              selected={selected || messageHasBeenEdited}
            />
          )}
          <MessageBubbleText
            isDeleted={isDeleted}
            message={message}
            imageSrc={pathPhoto}
            imageSrc_thumbs={pathPhoto_thumbs}
          />
        </div>
      </div>
    </div>
  );
}

function MessageOutgoingBubble({
  messageId,
  message,
  isEditable,
  isDeleted,
  isInfoVideoCall,
  isPhoto,
  pathPhoto = "",
  pathPhoto_thumbs = "",
  index,
  time,
  allowModifications,
  messageHasBeenEdited,
}: MessageBubbleProps) {
  const { i } = useTranslation();

  const [editedMessage, setEditedMessage] = useState(message);

  const { isEditingIndex, setIsEditingIndex, deleteMessage, editMessage } =
    useMessenger();

  const [selected, setSelected] = useState(false);

  const { openPopup } = useConfirmDialog();

  const isEditing = isEditingIndex === index;
  const editable = isEditable && !isPhoto;

  const editableMessage = useMemo(() => {
    return message.replace(/<br \/>/g, "\n").replace(/<a.+>(.*?)<\/a>/g, "$1");
  }, [message]);

  const handleEdit = () => {
    setIsEditingIndex(index);
    setEditedMessage(editableMessage);
  };

  const handleCancel = () => {
    setIsEditingIndex(null);
  };

  const handleSave = () => {
    // Rewrite Links
    const newMessage = editedMessage
      .replace(/\n/g, "<br />")
      .replace(/(?=https:)(.*?)(\s|$)/g, '<a href="$1" target="_blank">$1</a>');
    editMessage({ messageId, newMessage });
  };

  const handleRemove = () => {
    openPopup({
      action: i("Delete this message?"),
      description: isPhoto ? "Photo" : editableMessage,
      confirmText: i("Yes"),
      confirmAction: () => {
        deleteMessage(messageId);
      },
      iconAlt: "delete icon",
      iconSrc: "/app/assets/blueDeleteIcon.svg",
    });
  };
  return (
    <div
      data-testid={`message-id-${messageId}`}
      className="MessageOutgoingBubble w-full flex justify-end group"
      onClick={() => setSelected((prev) => !prev)}
    >
      <div className="relative w-fit max-w-full sm:max-w-[85%] flex flex-row-reverse">
        {isEditing ? (
          <div className="bg-custom-black-200/10 flex flex-col py-2 px-3.5 space-x-2 rounded-[10px] items-end justify-end w-max">
            <div className="w-full text-xs leading-5 pb-1">
              {i("Escape to [cancel]", {
                cancel: (
                  <span
                    className="text-blue-600 underline cursor-pointer"
                    onClick={handleCancel}
                  >
                    {i("Cancel")}
                  </span>
                ),
              })}
              .{" "}
              {i("Enter to [save]", {
                save: (
                  <span
                    className="text-blue-600 underline cursor-pointer"
                    onClick={handleSave}
                  >
                    {i("Save")}
                  </span>
                ),
              })}
            </div>
            <textarea
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.stopPropagation();
                  handleSave();
                }
                if (e.key === "Escape") {
                  e.stopPropagation();
                  handleCancel();
                }
              }}
              className="sm:w-400 lg:w-[600px] resize-none h-[5.5rem]"
              autoFocus
            />
          </div>
        ) : (
          <div
            className={classNames(
              "flex flex-col py-2 px-3.5 space-x-2 rounded-[10px] items-end justify-end w-full",
              isDeleted || isInfoVideoCall
                ? "border border-custom-black-200/60"
                : "bg-custom-black-200/10"
            )}
          >
            {!isDeleted && (
              <MessageBubbleTimestamp
                hour_tz={time}
                index={index}
                selected={selected || messageHasBeenEdited}
              />
            )}
            <MessageBubbleText
              message={message}
              isDeleted={isDeleted}
              imageSrc={pathPhoto}
              imageSrc_thumbs={pathPhoto_thumbs}
            />
          </div>
        )}
        {!isEditing && !isDeleted && allowModifications && !isInfoVideoCall && (
          <div className=" grid-cols-1 gap-1 absolute group-hover:grid hidden top-0 left-[-20px] w-[24px]">
            {editable && (
              <div
                className="text-custom-dark-gray w-4 h-4 cursor-pointer mb-[2px]"
                title={i("Edit")}
                onClick={handleEdit}
              >
                <PencilIcon height={16} width={16} />
              </div>
            )}
            <div
              data-testid={`delete-message-id-${messageId}`}
              className="text-custom-dark-gray w-4 h-4 p-[2px] cursor-pointer"
              title={i("Delete")}
              onClick={handleRemove}
            >
              <XIcon height={16} width={16} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
