import { IconButton } from "@mui/material";
import { DataConnection, MediaConnection, Peer } from "peerjs";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Toaster, toast } from "sonner";
import api from "../../backendAPI";
import { polyfillMediaDevices } from "../../components/certification/Certification";
import { LiveAlert, SocketIoContext } from "../../context/SocketIoContext";
import Main from "../components/Main";

import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import useTranslation from "../../context/TranslationContext";
import useToast from "../../hooks/useToast";
import useUser from "../../hooks/useUser";

declare global {
  interface Window {
    videoCallStream?: MediaStream;
  }
}
if (typeof navigator !== "undefined") {
  var Peerjs = require("peerjs").default;
}

type VideoChatContext = {
  active: boolean;
  isDialing: boolean;
  minimized: boolean;
  toggleMinimize(): void;
  useMic: boolean;
  toggleMic(): void;
  useCamera: boolean;
  toggleCamera(): void;
  showChat: boolean;
  toggleChat(): void;
  showPreview: boolean;
  togglePreview(): void;
  localStream: MediaStream | null;
  remoteStream: MediaStream | null;
  callStart: Date | null;
  call: (
    userId: number,
    discussionId: number,
    username: string,
    image: string,
    onErrorMessage?: (message: string) => void
  ) => void;
  answerCall(call: MediaConnection): void;
  hangUpCall(): void;
  convo: {
    userId: number;
    discussionId: number;
    username: string;
    image: string;
  } | null;
  close(): void;
  message: string;
  setMessage(message: string): void;
};

const CONTEXT = React.createContext<VideoChatContext | null>(null);

enum Status {
  PENDING,
  BUSY,
  CALLING,
  IN_CALL,
  EXIT,
}

/**
 * Listens for incoming video calls and display overlay for the video chat feature
 * @context
 */
export default function VideoChatProvider({
  children,
}: PropsWithChildren<unknown>) {
  // Controls
  const [active, setActive] = useState(false);
  const [isDialing, setIsDialing] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [useMic, setUseMic] = useState(true);
  const [useCamera, setUseCamera] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [callStart, setCallStart] = useState<Date | null>(null);
  const callPayloadRef =
    useRef<{
      userId: number;
      discussionId: number;
      username: string;
      payload: { peerId: string };
    } | null>(null);
  const [message, setMessage] = useState("");
  const [convo, setConvo] =
    useState<{
      userId: number;
      discussionId: number;
      username: string;
      image: string;
    } | null>(null);
  const { cdnUrl } = useTranslation();

  const soundPlayer = useMemo(() => {
    return VideoCallSound.getInstance(
      process.env.NODE_ENV === "production" ? cdnUrl : ""
    );
  }, [cdnUrl]);

  const toggleMic = () => {
    setUseMic((prev) => {
      if (callRef.current)
        callRef.current.localStream
          ?.getAudioTracks()
          ?.forEach((track) => (track.enabled = !prev));
      if (localStream.current)
        localStream.current
          .getAudioTracks()
          .forEach((track) => (track.enabled = !prev));
      if (window.videoCallStream)
        window.videoCallStream
          .getAudioTracks()
          .forEach((track) => (track.enabled = !prev));

      return !prev;
    });
  };
  const toggleCamera = () => {
    setUseCamera((prev) => {
      if (callRef.current)
        callRef.current.localStream
          ?.getVideoTracks()
          ?.forEach((track) => (track.enabled = !prev));
      if (localStream.current)
        localStream.current
          .getVideoTracks()
          .forEach((track) => (track.enabled = !prev));
      if (window.videoCallStream)
        window.videoCallStream
          .getVideoTracks()
          .forEach((track) => (track.enabled = !prev));
      return !prev;
    });
  };

  const { userid } = useUser().userData;
  const toggleMinimize = () => setMinimized((prev) => !prev);
  const toggleChat = () => setShowChat((prev) => !prev);
  const togglePreview = () => setShowPreview((prev) => !prev);

  const localStream = useRef<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const statusRef = useRef<Status>(Status.PENDING);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Peer.js
  const peerRef = useRef<Peer | null>(null);
  const callRef = useRef<MediaConnection | null>(null);

  const { activeSocket } = useContext(SocketIoContext);

  const { openTimedToast } = useToast();

  useEffect(() => {
    if (!activeSocket) return;
    const callHandler = (event: LiveAlert) => {
      try {
        if (event.category !== "call" || !event.content_json) return;
        console.log("received call event", event);
        const payload = JSON.parse(event.content_json);
        if (!("peerId" in payload))
          throw new Error("No peerId in call notification payload");
        if (!("userId" in payload) || isNaN(payload.userId))
          throw new Error("No userId in call notification payload");
        handleIncomingCall({
          image:
            event.imageProfile ||
            "https://cdn.celibatairesduweb.com/img/Vignettes/admin2_v=3.png",
          name: event?.prenom || event.username,
          username: event.username,
          discussionId: Number(event.discussionId),
          userId: payload.userId,
          peerId: payload.peerId,
        });
      } catch (e) {
        console.error(e);
      }
    };
    activeSocket.on("notification", callHandler);
    return () => {
      activeSocket.removeListener("notification", callHandler);
    };
  }, [activeSocket]);

  /**
   * When Calling:
   * Setup local stream, if it fails, notify user and return
   * Create a new peerRef if it doesnt exist.
   * How can we share these peers? We need to remake them for every call
   * What could happen:
   * I send a call, I create a peer and send a message through the Websocket with my peerID
   * Other user sees this message as a notif or dialog or other
   * If accepted, other user creates a peer and sends actual wrtc request
   * If declined, show message to initial user
   */

  const call = async (
    userId: number,
    discussionId: number,
    username: string,
    image: string,
    onErrorMessage?: (message: string) => void
  ) => {
    initPeer(async (peer) => {
      try {
        const payload = {
          userId,
          discussionId,
          payload: {
            peerId: peer.id,
            userId: userid,
          },
          username,
        };
        callPayloadRef.current = payload;
        const startCallRes = await api.messages.postStartCall(payload);
        if (startCallRes.status !== "SUCCESS") {
          console.error("Error starting call", startCallRes);
          onErrorMessage?.(startCallRes.message);
        } else {
          setConvo({ userId, discussionId, username, image });
          if (![Status.EXIT, Status.PENDING].includes(statusRef.current))
            return;
          setMessage("Calling...");
          setActive(true);
          setIsDialing(true);
          const stream =
            localStream.current ||
            (await getMediaStream({
              audio: useMic,
              video: useCamera,
              setMessage,
            }));
          if (!stream) {
            hangUpCall();
            return;
          }
          statusRef.current = Status.CALLING;
          localStream.current = stream;
          console.log("calling stream", stream);
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            if (!callRef.current && statusRef.current === Status.CALLING) {
              setMessage("Call timed out. Please try again later.");
              hangUpCall();
            }
          }, 25_000);
        }
      } catch (e) {
        openTimedToast({
          title: "Error",
          type: "error",
          description: "Unable to make call request",
        });
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        return hangUpCall();
      }
    });
  };

  const handleConnection = async (
    peerId: string,
    discussionId: number,
    userId: number,
    username: string,
    image: string
  ) => {
    setConvo({ userId, discussionId, username, image });
    setMessage("Connecting...");
    initPeer(async (peer) => {
      const stream =
        localStream.current ||
        (await getMediaStream({
          audio: useMic,
          video: useCamera,
          setMessage,
        }));
      if (!stream) {
        hangUpCall();
        return;
      }
      localStream.current = stream;
      setActive(true);
      callRef.current = peer.call(peerId, stream);
      listenToMediaConnectionEvent(callRef.current);
    });
  };

  const handleIncomingCall = async ({
    image,
    name,
    discussionId,
    peerId,
    userId,
    username,
  }: {
    image: string;
    name: string;
    discussionId: number;
    peerId: string;
    userId: number;
    username: string;
  }) => {
    if (statusRef.current === Status.BUSY) return declineCall(peerId);
    setIsDialing([Status.PENDING, Status.EXIT].includes(statusRef.current));
    statusRef.current =
      statusRef.current === Status.PENDING ? Status.BUSY : statusRef.current;
    toast.success(
      <IncomingCallToast
        name={name}
        image={image}
        onAccept={() =>
          handleConnection(peerId, discussionId, userId, username, image)
        }
        onDecline={() => {
          declineCall(peerId);
          statusRef.current = Status.PENDING;
        }}
      />,
      {
        style: {
          marginTop: "3rem",
        },
        position: "top-right",
        duration: 25_000,
        onAutoClose: () => {
          statusRef.current = Status.PENDING;
          setIsDialing(false);
          setIsBusy(false);
        },
      }
    );
  };

  const declineCall = async (peerId: string) => {
    initPeer((peer) => {
      setIsDialing(false);
      setIsBusy(false);
      statusRef.current = Status.PENDING;
      const connection = peer.connect(peerId);
      connection.on("open", async () => {
        await connection.send("declineCall");
        connection.close();
      });
    });
  };

  function listenToMediaConnectionEvent(call: MediaConnection) {
    call.on("iceStateChanged", (state) => {
      console.log("ice change", state);

      if (
        state === "failed" ||
        state === "closed" ||
        state === "disconnected"
      ) {
        if (state === "failed") {
          setMessage("Call failed. Please try again later.");
        } else {
          setMessage("This call has ended.");
        }
        hangUpCall();
      }
    });
    call.on("stream", (incomingStream: MediaStream) => {
      if (statusRef.current !== Status.IN_CALL) {
        setMessage("");
        statusRef.current = Status.IN_CALL;
        setActive(true);
        setIsDialing(false);
        setIsBusy(true);
        setCallStart(new Date());
        soundPlayer.connect();
      }
      setRemoteStream(incomingStream);
    });
    call.on("close", () => {
      setMessage("This call has ended.");
      hangUpCall();
    });
    call.on("error", (x) => {
      setMessage("Call failed. Please try again later.");
      hangUpCall();
      console.error("error :(", x);
    });
  }

  const answerCall = async (call: MediaConnection) => {
    // Get Local stream
    setMessage("Connecting...");
    callRef.current = call;
    console.log("answering call", call);
    const stream =
      localStream.current ||
      (await getMediaStream({ audio: useMic, video: useCamera, setMessage }));
    if (!stream) {
      hangUpCall();
      return;
    }
    // Update Stream handle if was never set
    localStream.current = stream;
    setActive(true);
    listenToMediaConnectionEvent(callRef.current);
    callRef.current.answer(stream);
    console.log("callPayload", callPayloadRef.current);
    if (callPayloadRef.current) {
      await api.messages.postCallStarted({
        discussionId: callPayloadRef.current.discussionId,
        userId: callPayloadRef.current.userId,
        username: callPayloadRef.current.username,
      });
    }
  };
  const hangUpCall = () => {
    if (callPayloadRef.current) {
      const payload = callPayloadRef.current;
      (async () => {
        try {
          await api.messages.postEndCall(payload);
        } catch (error) {
          console.error("Error ending call:", error);
        }
      })();
    }
    callPayloadRef.current = null;
    if (statusRef.current !== Status.EXIT) soundPlayer.disconnect();
    statusRef.current = Status.EXIT;
    setIsDialing(false);
    setIsBusy(false);
    callRef.current?.close();
    stopStreams(localStream.current, remoteStream);
    callRef.current = null;
    peerRef.current?.destroy();
    setRemoteStream(null);
    localStream.current = null;
    setCallStart(null);
    close();
  };

  useEffect(() => {
    if (!isDialing || isBusy) return;
    soundPlayer.ring();
    return () => {
      soundPlayer.pauseSound("ringtone");
    };
  }, [isDialing, isBusy]);

  const initPeer = async (onOpen: (peerRef: Peer) => void) => {
    /// get ice from backend
    const iceServers = (await api.messages.getIceServers())?.data?.content || [
      {
        url: "stun:global.stun.twilio.com:3478",
        urls: "stun:global.stun.twilio.com:3478",
      },
    ];
    // We want to keep the same peer for the same user as long as the page is open
    const localPeer = new Peerjs({
      config: {
        iceServers,
      },
    }) as Peer;

    console.log("peer created", localPeer);
    localPeer.on("error", (error) => {
      console.error("Peer error", error);
    });
    localPeer.on("open", (id: string) => {
      console.log("peer open", id);
      onOpen(localPeer);
      peerRef.current = localPeer;
    });
    localPeer.on("call", answerCall);
    localPeer.on("connection", (connection: DataConnection) => {
      connection.on("open", () => console.log("connection opened"));
      connection.on("data", (data: unknown) => {
        if (data === "declineCall") hangUpCall();
      });
      connection.on("close", () => hangUpCall());
      connection.on("error", (error) =>
        console.error("Connection error", error)
      );
      connection.on("iceStateChanged", (state) => {
        console.log("ice state changed", state);
      });
    });
    localPeer.on("close", () => {
      peerRef.current = null;
      console.log("peer closed");
    });
    localPeer.on("disconnected", () => {
      peerRef.current = null;
      console.log("peer disconnected");
    });
    return localPeer;
  };

  const close = () => {
    setActive(false);
    statusRef.current = Status.PENDING;
    setMessage("");
    setIsDialing(false);
    setIsBusy(false);
    setConvo(null);
    setShowChat(false);
    callPayloadRef.current = null;
    if (peerRef.current) {
      peerRef.current.destroy();
      peerRef.current = null;
    }
  };

  const value = {
    active,
    isDialing,
    minimized,
    toggleMinimize,
    useMic,
    toggleMic,
    showChat,
    toggleChat,
    useCamera,
    toggleCamera,
    showPreview,
    togglePreview,
    localStream: localStream.current,
    remoteStream,
    call,
    answerCall,
    hangUpCall,
    callStart,
    convo,
    close,
    message,
    setMessage,
  };

  // Plan so far:
  // 1. Subscribe to Node.js Socket to listen for incoming calls
  // 2. Upon while receiving, use alert/notification to display incoming call
  // 3. On decline, remove notification and send response through socket
  // 4. On accept, retrieve stream and use it to connect

  return (
    <CONTEXT.Provider value={value}>
      {active && (remoteStream || isDialing) && <Main />}
      {children}
      {/* zIndex to display over google ads */}
      <Toaster style={{ zIndex: 3147483647 }} richColors />
    </CONTEXT.Provider>
  );
}

export function useVideoChat() {
  const value = useContext(CONTEXT);
  if (value === null)
    throw Error("useVideoChat must be called from within a VideoChatProvider");
  return value;
}

async function getMediaStream({
  audio,
  video,
  setMessage,
}: {
  audio: boolean;
  video: boolean;
  setMessage: (message: string) => void;
}) {
  try {
    stopStreams();
    polyfillMediaDevices();
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    if (!audio) {
      stream?.getAudioTracks()?.forEach((track) => (track.enabled = false));
    }
    if (!video) {
      stream?.getVideoTracks()?.forEach((track) => (track.enabled = false));
    }
    window.videoCallStream = stream;
    return stream;
  } catch (error: any) {
    console.error("Error accessing media devices:", error);

    if (setMessage) {
      // Display an appropriate error message to the user
      if (error?.name === "NotAllowedError") {
        setMessage(
          "Permission to access your camera and/or microphone was denied."
        );
      } else if (error?.name === "NotFoundError") {
        setMessage("No suitable camera or microphone was found.");
      } else {
        setMessage(
          "Unable to access your camera or microphone. Please check your settings."
        );
      }
    }

    return null;
  }
}

type IncomingCallToastProps = {
  name: string;
  image: string;
  onAccept(): void;
  onDecline(): void;
};

function IncomingCallToast({
  name,
  image,
  onAccept,
  onDecline,
}: IncomingCallToastProps) {
  return (
    <div className="flex gap-0 xs:gap-4 justify-center xs:justify-normal items-center flex-wrap xs:flex-nowrap w-full">
      <div className="flex gap-4 items-center grow">
        <img
          height={48}
          width={48}
          className="w-12 h-12 rounded-full"
          src={image}
          alt="Caller Profile"
        />
        {/* <img className="w-12 h-12 rounded-full" src={image} /> */}
        <p className="whitespace-pre grow">
          Incoming call from:{"\n"}
          <span className="text-lg font-medium">{name}</span>
        </p>
      </div>
      <div className="flex gap-4 items-center justify-center">
        <IconButton
          color="success"
          onClick={() => {
            toast.dismiss();
            onAccept();
          }}
        >
          <CallIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => {
            toast.dismiss();
            onDecline();
          }}
        >
          <CallEndIcon />
        </IconButton>
      </div>
    </div>
  );
}

function stopStreams(...streams: Array<MediaStream | null | undefined>) {
  console.log("stopping all streams");
  streams.forEach((stream) => {
    if (!stream) return;
    stream.getTracks().forEach((track) => track.stop());
  });
  if (window.videoCallStream) {
    window.videoCallStream.getTracks().forEach((track) => track.stop());
    delete window.videoCallStream;
  }
}

class VideoCallSound {
  public url: string;
  public connected: HTMLAudioElement;
  public disconnected: HTMLAudioElement;
  public ringtone: HTMLAudioElement;

  private constructor(cdnUrl: string) {
    this.url = cdnUrl;
    this.connected = new Audio(
      cdnUrl + "/app/assets/sounds/call-connected.mp3"
    );
    this.disconnected = new Audio(
      cdnUrl + "/app/assets/sounds/call-disconnected.mp3"
    );
    this.ringtone = new Audio(
      cdnUrl + "/app/assets/sounds/video-call-tone.mp3"
    );
    this.ringtone.loop = true;
    this.ringtone;
  }

  public ring() {
    this.ringtone.currentTime = 0;
    this.ringtone.play();
  }

  public connect() {
    this.pauseSound("ringtone");
    this.playSound("connected");
  }

  public disconnect() {
    this.pauseSound("ringtone");
    this.playSound("disconnected");
  }

  public playSound(sound: "connected" | "disconnected" | "ringtone") {
    if (this[sound].played && !this[sound].paused) return;
    this[sound].currentTime = 0;
    this[sound].play();
  }

  public pauseSound(sound: "connected" | "disconnected" | "ringtone") {
    this[sound].pause();
    this[sound].currentTime = 0;
  }

  public static getInstance(cndUrl: string) {
    if (typeof window === "undefined") {
      return new VideoCallSound(cndUrl);
    }

    if (!window._VideoCallSound || window._VideoCallSound.url !== cndUrl) {
      window._VideoCallSound = new VideoCallSound(cndUrl);
    }

    return window._VideoCallSound;
  }
}

declare global {
  interface Window {
    _VideoCallSound: VideoCallSound;
  }
}
