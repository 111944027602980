import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useRouter } from "next/router";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import useSearch from "../hooks/useSearch";
import useOnlineNow from "../hooks/useOnlineNow";

import { Subject, timer, interval, EMPTY } from "rxjs";
import {
  tap,
  throttleTime,
  delay,
  exhaustMap,
  switchMap,
} from "rxjs/operators";
import isVidamora from "../utils/isVidamora";
import useTranslation from "./TranslationContext";

import Card from "@mui/material/Card";
import LoadingSpinner from "../components/loadingSpinner";

import api from "../backendAPI";
import useMessenger from "../hooks/useMessenger";

const PING_INTERVAL_MS = 1000 * 60 * 4.5;

interface Context {
  currentPage: string;
}

export const NavigationContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function NavigationProvider({ children }: Props) {
  const { logout, loginInfoLoaded } = useAuth();
  const { i } = useTranslation();
  const { fetchConversations, fetchGreetings } = useMessenger();

  const { userData } = useUser();

  const { resetIndex: resetSearchIndex } = useSearch();
  const { resetIndex: resetOnlineNowIndex } = useOnlineNow();

  const router = useRouter();

  const currentPage = router.pathname;

  const [isUpdatingApp, setIsUpdatingApp] = useState(false);

  // useEffect(() => {
  //   function handleReloadMessenger(event: { target: any }) {
  //     if (
  //       event.target.closest('[aria-label*="Message"]') ||
  //       event.target.closest('[href*="messages"]')
  //     ) {
  //       fetchConversations({ resetPageSize: true });
  //       fetchGreetings({});
  //     }
  //   }

  //   document.addEventListener("click", handleReloadMessenger);

  //   return () => {
  //     document.removeEventListener("click", handleReloadMessenger);
  //   };
  // }, []);

  useEffect(() => {
    const apiCallMade$ = new Subject<void>();

    const pingSub = apiCallMade$
      .pipe(
        switchMap(() => interval(PING_INTERVAL_MS)),
        exhaustMap(() => api.keepAlive.ping())
      )
      .subscribe();

    function handleApiCall() {
      apiCallMade$.next();
    }

    document.addEventListener("api-call", handleApiCall);

    return () => {
      window.removeEventListener("api-call", handleApiCall);
      apiCallMade$.complete();
      pingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!loginInfoLoaded) return;

    handleAds();

    router.events.on("routeChangeComplete", handleAds);
    window.addEventListener("load", handleAds);
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      router.events.off("routeChangeComplete", handleAds);
      window.removeEventListener("load", handleAds);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loginInfoLoaded]);

  async function handleAds() {
    if (!userData) return;

    if (hasNoAdsMembership()) return;

    if (isUserInRegistration()) return;

    if (window.adsbygoogle && window.adsbygoogle.length > 90) {
      window.location.reload();
      return;
    }

    if (await shouldInsertGoogleAdsInitScript()) {
      insertGoogleAdsInitScript();

      return;
    }

    // timer(350).subscribe(() => {
    //   if (document.getElementById("clickio-ads-init")) {
    //     insertClickioAds();
    //   }
    // });
  }

  function isUserInRegistration() {
    return userData.isProfileCreated === false;
  }

  function hasNoAdsMembership() {
    return userData?.isPremiumNoAds;
  }

  useEffect(() => {
    if (userData && userData.warnings && userData.warnings.length > 0) {
      if (
        userData.warnings.includes("NO_ACTIVITIES_MUSICS_QUALITIES_SELECTED")
      ) {
        router.push("/profile-without-activities");
        return;
      }

      if (userData.warnings.includes("PROFILE_WITHOUT_IMAGE")) {
        router.push("/profile-without-image");
        return;
      }

      if (userData.warnings.includes("PROFILE_EMAIL_NOT_CONFIRMED")) {
        router.push("/confirm-email");
        return;
      }
    }
  }, [userData]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url.startsWith("/app/forum") && isVidamora()) {
        router.push("/");
        return;
      }

      if (
        /logout|login|create-account|registration|confirm-email|profile-deleted|forgot/.test(
          url
        )
      )
        return;

      timer(350).subscribe(() => {
        if (window.localStorage.getItem("token") == null) {
          if (/localhost/.test(window.location.href)) return;

          router.push("/logout");
        }
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, []);

  async function shouldInsertGoogleAdsInitScript() {
    if (/localhost/.test(window.location.href)) return false;

    return new Promise((res) => {
      setTimeout(() => {
        if (document.getElementById("google-ads-init")) res(false);

        if (document.querySelector('[data-no-google-ads="true"]')) res(false);

        return res(true);
      }, 150);
    });
  }

  function insertGoogleAdsInitScript(cb?: () => void) {
    if (document.getElementById("google-ads-init")) return false;

    const googleAdsInitScript = document.createElement("script");

    googleAdsInitScript.id = "google-ads-init";

    googleAdsInitScript.async = true;

    googleAdsInitScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6005108637849729";

    googleAdsInitScript.crossOrigin = "anonymous";

    if (cb) googleAdsInitScript.onload = () => setTimeout(cb, 500);

    document.head.append(googleAdsInitScript);
  }

  useEffect(() => {
    if (window.invalidConversation$) return;

    window.invalidConversation$ = new Subject();

    window.invalidConversation$
      .pipe(
        throttleTime(3000),

        tap(() => {
          window.location.href = "/app/messages";
        })
      )
      .subscribe();
  });

  useEffect(() => {
    if (window.httpError$) return;

    window.httpError$ = new Subject<number>();

    window.httpError$
      .pipe(
        throttleTime(3000),

        delay(150),
        tap(() => {
          logout();
        })
      )
      .subscribe();
  }, []);

  useEffect(() => {
    if (window.profileDeleted$) return;

    window.profileDeleted$ = new Subject<void>();

    window.profileDeleted$
      .pipe(
        throttleTime(3000),

        delay(150),
        tap(() => {
          logout();
        })
      )
      .subscribe();
  }, []);

  useEffect(() => {
    if (window.__CYPRESS) return;
    if (window.appOutOfDate$) return;

    window.appOutOfDate$ = new Subject();

    window.appOutOfDate$
      .pipe(
        tap(() => {
          setIsUpdatingApp(true);
        }),
        throttleTime(3000),
        tap(() => {
          window.localStorage.removeItem("x-app-version");
        }),
        delay(3000),
        tap(() => {
          window.location.reload();
        })
      )
      .subscribe();
  }, []);

  useEffect(() => {
    // handle refetch on nav links clicks for corresponding slices if not on the same page

    const handleClick = (event: Event) => {
      const target = event.target as HTMLAnchorElement;

      if (
        target.closest('[href*="online-now"]') &&
        !window.location.href.includes("profile")
      ) {
        resetOnlineNowIndex();

        return;
      }

      if (target.closest('[href$="search"]')) {
        resetSearchIndex();

        return;
      }

      // if (target.closest('[href*="date-me"]')) {
      //   if (!fetchDateMeResults) return;

      //   fetchDateMeResults("read");
      //   return;
      // }
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        currentPage,
      }}
    >
      {children}
      {isUpdatingApp && (
        <div className="fixed bottom-4 right-4 z-50">
          <Card>
            <div className="py-3 px-4">
              <div className="flex justify-center pb-2">
                <img src="/app/assets/vidamora-logo.svg" alt="Loading" />
              </div>
              <p className="pb-4 text-lg font-bold">
                {i("Update in progress...")}
              </p>
              <div className="flex justify-center">
                <LoadingSpinner />
              </div>
            </div>
          </Card>
        </div>
      )}
    </NavigationContext.Provider>
  );
}

const scrollSubject$ = new Subject<void>();

const resizeSubject$ = new Subject<void>();

export const searchResultsChangedSubject$ = new Subject<void>();

function handleScroll() {
  scrollSubject$.next();
}

function handleResize() {
  resizeSubject$.next();
}

declare global {
  interface Window {
    adCount?: number;
  }
}

export function insertClickioAds() {
  if (!/messages/.test(window.location.href)) return;

  let clickioElements = document.querySelectorAll("ins.ad-clickio");

  Array.from(clickioElements).forEach((element) => {
    if (element.offsetParent !== null) {
      // init or read global queue variable
      (window.__lxGc__ = window.__lxGc__ || { s: {}, b: 0 }).cmd =
        window.__lxGc__.cmd || [];

      // push command to the global queue
      __lxGc__.cmd.push(function () {
        // render ad unit into the DOM element
        __lxGc__.display(element.id, "_223834", "_687036");
      });
    }
  });
}
