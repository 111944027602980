import { FC } from "react";
import useTranslation from "../../context/TranslationContext";
import useConfirmDialog from "../../hooks/useConfirmDialog";

type Props = {
  onClick: () => void;
};

const CardMemberProfileActionRemove: FC<Props> = ({ onClick }) => {
  const { openPopup } = useConfirmDialog();
  const { i } = useTranslation();
  const handleClick = () => {
    openPopup({
      description: i(
        "Are you sure you want to remove this member from this list?"
      ),
      confirmText: i("Yes I do"),
      confirmAction: () => {
        onClick();
      },
      iconAlt: "delete icon",
      iconSrc: "/app/assets/blueDeleteIcon.svg",
    });
  };

  return (
    <div
      className="CardMemberProfileActionRemove select-none"
      data-testid="member-profile-action-button-for-remove"
    >
      <button
        className="bg-custom-black bg-opacity-40 pointer-events-auto md:h-[45px] md:w-[45px] w-[35px] h-[35px] rounded-xl flex justify-center items-center"
        onClick={handleClick}
      >
        <img alt="Delete icon" src="/app/assets/cardMember/deleteIcon.svg" />
      </button>
    </div>
  );
};

export default CardMemberProfileActionRemove;
