import { FC, useContext, useMemo } from "react";
import { TranslationContext } from "../../context/TranslationContext";

type Badge = "Certified" | "VIP";

function selectBadge(badge: Badge) {
  switch (badge) {
    case "Certified":
      return (
        <img
          alt="Badge for certified profile"
          src="/app/assets/cardMember/certifiedBadge.svg"
          className="w-[21px] h-[32px]"
        />
      );
    case "VIP":
      return (
        <img
          className="w-[30px] h-[30px] m-auto"
          alt="Badge for VIP profile"
          src="/app/assets/cardMember/vipBadge.svg"
        />
      );
  }
}

const CardMemberProfileBadge: FC<{ badge: Badge }> = ({ badge }) => {
  const { i } = useContext(TranslationContext);
  const badgeIcon = useMemo(() => selectBadge(badge), [badge]);

  return (
    <div className="group overflow-visible relative">
      {badgeIcon}
      <span className=" absolute top-[-2px] px-4 py-1 right-[120%] tooltip scale-0 group-hover:scale-100 transition-all bg-custom-semi-black text-custom-light-orange">
        {i(badge)}
      </span>
    </div>
  );
};

export const certifiedProfileIconAlt = "Badge for certified profile";
export const vipProfileIconAlt = "Badge for VIP profile";

export default CardMemberProfileBadge;
