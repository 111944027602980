import React, { FC } from "react";

type Props = {
  icon: string;
  text: string;
  iconAltText: string;
};

const DateMeQuickViewStats: FC<Props> = ({ icon, text, iconAltText }) => {
  return (
    <div className="DateMeQuickViewStats flex flex-row items-center">
      <div className="mr-2.5 h-5 w-5 flex flex-row justify-center">
        <img src={`/app/assets/${icon}.svg`} alt={iconAltText} />
      </div>
      <p
        className="font-normal text-base"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  );
};

export default DateMeQuickViewStats;
