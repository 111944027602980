import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  removeMargin?: boolean;
  description?: any;
  suggestion?: string;
  imageSrc?: string;
};

const NoMatchFound: FC<Props> = ({
  removeMargin = false,
  description,
  suggestion,
  imageSrc = "/app/assets/vidamora-logo.svg",
}) => {
  const { i, cdnUrl } = useContext(TranslationContext);
  if (!description)
    description = i("We couldn't find what you are looking for.");
  if (!suggestion) suggestion = i("Please, try another search.");
  return (
    <div
      className={`NoMatchFound flex flex-col col-span-full text-center ${
        removeMargin ? "pt-0" : "pt-32"
      }`}
    >
      <div className="flex justify-center mb-5">
        <img
          className="w-[73px] h-full object-covers"
          alt="vidamora logo"
          src={cdnUrl + imageSrc}
        />
      </div>

      <h2 className="font-semibold text-[22px] leading-[30px] mb-5">
        {description}
        <br />
        {suggestion}
      </h2>
    </div>
  );
};

export default NoMatchFound;
