import React, { FC, useContext, useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import useCertification from "../../hooks/useCertification";
import useUser from "../../hooks/useUser";
import Popup from "../popup";
import VerifConfirmationSection from "../verifConfirmationSection";
import VerifPopupImageConfirmationContainer from "../verifPopupImageConfirmationContainer";
import VerifPopupNoWebcam from "../verifPopupNoWebcam";
import WebCamConfirmImage from "./webCamConfirmImage";
import WebCamFinalPage from "./webCamFinalPage";
import WebCamHowTo from "./webCamHowTo";
import WebCamIntro from "./webCamIntro";
import useTranslation from "../../context/TranslationContext";
import { ROOT } from "../../backendAPI/Api";
import ButtonBGBlue from "../buttonBGBlue";
import { Subscription, timer } from "rxjs";
import { tap, take, finalize } from "rxjs/operators";

import MyProfilePictureUploader from "../myProfilePictureUploader";
import StyledPopup from "../styledPopup";

declare global {
  interface Window {
    mediaStream: MediaStream | undefined;
  }
}

const Certification: FC = () => {
  const { locale } = useTranslation();

  const {
    currentStep,
    isOpen,
    close,
    setCurrentStep,
    setIsPendingCertification,
    setFirstImageSrc,
    setSecondImageSrc,
    setChosenMethod,
  } = useCertification();
  const [file, setFile] = useState<File>();
  const [imgAsString, setImgAsString] = useState("");
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleImageSubmit = async () => {
    if (!file || isLoading) return;
    setIsLoading(true);
    const url = ROOT + "/image";
    const formData = new FormData();
    formData.append("uploadfile", file);
    formData.append("locale", locale);
    formData.append("type", "certify");
    formData.append("certificationChosenMethod", "webcam");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: accessToken,
      },
    };
    const response = await axios.post(url, formData, config);

    if (response.status == 200) {
      setCurrentStep("done");

      setFirstImageSrc(response.data.data.imageURL_1);
      setChosenMethod("webcam");

      setIsPendingCertification(true);
    }
    setIsLoading(false);
  };

  return (
    <StyledPopup
      customScrollbar={currentStep !== "webcam"}
      open={isOpen}
      onClick={close}
      hideCloseButton={
        !(currentStep === "upload-done" || currentStep === "done")
      }
      maxWidth={
        currentStep === "upload-intro" ||
        currentStep === "upload" ||
        currentStep === "upload-done"
          ? 903
          : 648
      }
    >
      {currentStep === "add-photo" && <AddPhotoCertification />}
      {currentStep === "pending-certification" && <PendingCertification />}
      {currentStep === "intro" && <WebCamIntro />}
      {currentStep === "how-to" && <WebCamHowTo />}
      {currentStep === "webcam" && (
        <WebCam
          close={close}
          setImgAsString={setImgAsString}
          setFile={setFile}
        />
      )}

      {currentStep === "upload-intro" && (
        <VerifPopupNoWebcam
          handleGoToNextPage={() => setCurrentStep("upload")}
        />
      )}
      {currentStep === "upload" && (
        <VerifPopupImageConfirmationContainer
          handleGoToNextPage={() => setCurrentStep("upload-done")}
        />
      )}
      {currentStep === "upload-done" && <UploadDone closeModal={close} />}
      {currentStep === "confirm-image" && (
        <WebCamConfirmImage
          onConfirmClick={handleImageSubmit}
          isLoading={isLoading}
          imgAsString={imgAsString}
        />
      )}
      {currentStep === "done" && <WebCamFinalPage imgAsString={imgAsString} />}
      {currentStep === "certified" && <ProfileCertified />}
    </StyledPopup>
  );
};

export default Certification;

function PendingCertification() {
  const { i } = useTranslation();
  const {
    imageSrcWebcam,
    webcamImageModele,
    chosenMethod,
    firstImageSrcNoWebcam,
    secondImageSrcNoWebcam,
    submittedImagePhoto1,
    submittedImagePhoto2,
    submittedImageWebcam,
  } = useCertification();

  const firstImage =
    chosenMethod === "photos"
      ? submittedImagePhoto1 || firstImageSrcNoWebcam
      : submittedImageWebcam;

  const secondImage =
    chosenMethod === "photos"
      ? submittedImagePhoto2 || secondImageSrcNoWebcam
      : webcamImageModele;

  return (
    <div data-testid="certification-pending" className="h-full text-center">
      <img
        src="/app/assets/vidamora-logo.svg"
        alt=""
        className="w-24 m-auto pb-6"
      />
      <p className="text-xl font-semibold">{i("Thank you!")}</p>
      <p className="text-xl font-semibold">
        {i("Your photos will be verified shortly.")}
      </p>
      <div className="flex pt-2 pb-6 flex-row items-center justify-around sm:space-x-3">
        <img
          src={firstImage}
          alt=""
          className="w-[45%] h-[150px] md:w-[218px] md:h-[218px] object-cover"
        />{" "}
        <img
          src={secondImage}
          alt=""
          className="w-[45%] h-[150px] md:w-[218px] md:h-[218px] object-cover"
        />
      </div>
    </div>
  );
}

function ProfileCertified() {
  const { i } = useTranslation();
  const { close } = useCertification();
  const {
    userData: { isCertified },
  } = useUser();

  return (
    <div data-testid="certification-done" className="h-full text-center">
      <img
        src="/app/assets/vidamora-logo.svg"
        alt=""
        className="w-24 m-auto pb-6"
      />
      {isCertified ? (
        <p className="text-xl font-semibold">
          {i("Your profile is already certified")}
        </p>
      ) : (
        <>
          <p className="text-xl font-semibold">{i("Thank you!")}</p>
          <p className="text-xl font-semibold">
            {i("Your profile is now certified!")}
          </p>
        </>
      )}
      <div className="flex pt-10 justify-center">
        <div className="w-52">
          <ButtonBGBlue
            innerText={i(isCertified ? "Close" : "Refresh the page")}
            onClick={() => {
              close();
              !isCertified && window.location.reload();
            }}
          />
        </div>
      </div>
    </div>
  );
}

// clarisse01;
function WebCam({ close, setFile, setImgAsString }: any) {
  const { i } = useTranslation();
  const { setCurrentStep } = useCertification();

  const webcamRef = useRef<HTMLVideoElement>(null);
  const countdownRef = useRef<Subscription | null>(null);

  const [mediaStream, setMediaStream] = useState<MediaStream>();

  const [mediaStreamError, setMediaStreamError] = useState(false);

  const countdown$ = timer(0, 1000).pipe(
    take(6),
    tap(() => {
      setIsCounting(true);
      setCounter((prevCount) => prevCount - 1);
    }),
    finalize(() => {
      setIsCounting(false);
      setCounter(6);
    })
  );

  const closeStream = () => {
    if (!mediaStream) return;
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((el) => {
      if (!el) return;
      el.src = "";
      el.pause();
      el.srcObject = null;
    });
    mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  useEffect(() => {
    polyfillMediaDevices();
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => setMediaStream(stream))
      .catch((e) => {
        setMediaStreamError(true);
      });
    return () => {
      countdownRef.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!mediaStreamError) return;

    setCurrentStep("upload-intro");
  }, [mediaStreamError]);

  useEffect(() => {
    if (!mediaStream || !webcamRef.current) return;

    webcamRef.current.srcObject = mediaStream;
    webcamRef.current.addEventListener("loadedmetadata", () => {
      setTimeout(() => {
        webcamRef.current!.removeAttribute("controls");
      });
    });
    return closeStream;
  }, [mediaStream]);

  const { webcamImageModele } = useCertification();

  const [counter, setCounter] = useState(6);
  const [isCounting, setIsCounting] = useState(false);

  const handleImageCapture = async () => {
    let finalImage: string = "";

    const canvas = document.createElement("canvas");

    if (!webcamRef.current) return;

    canvas.width = webcamRef.current.videoWidth;
    canvas.height = webcamRef.current.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx?.drawImage(
      webcamRef.current,
      0,
      0,
      webcamRef.current?.videoWidth,
      webcamRef.current?.videoHeight
    );

    const img = canvas.toDataURL("image/png");

    finalImage = img;

    const blob = await (await fetch(finalImage)).blob();

    setImgAsString(finalImage);

    setFile(new File([blob], "yolo.png", { type: "image/png" }));

    setCurrentStep("confirm-image");
  };

  return (
    <div className="w-full z-0 max-w-[603px] overflow-x-hidden">
      <button
        className="absolute z-90 right-6 top-6 lg:right-8 lg:top-8"
        onClick={() => {
          close();
        }}
      >
        <img src="/app/assets/whiteDeleteIcon.svg" alt="delete icon" />
      </button>
      <div className="flex -mx-[49px] -left-[25px] -right-[25px] rounded-t-2xl overflow-hidden -mt-6 relative">
        <style
          dangerouslySetInnerHTML={{
            __html: `#webcam-video{max-height:47.5vh;transform:translateX(25px)}@media(max-width:600px){#webcam-video{max-width:100%;}#webcam-video + img{transform:scale(0.75) translateY(30%);}}`,
          }}
        ></style>
        <video
          id="webcam-video"
          autoPlay
          muted
          playsInline
          ref={webcamRef}
          className="flex w-full z-0"
        ></video>
        <img
          src={webcamImageModele}
          alt="photo to replicate"
          className="h-32 w-36 object-cover absolute left-[68px] md:left-[15%]  bottom-4 border-[3px] border-custom-orange rounded-3xl"
        />
      </div>
      <div className="border-2 border-white relative bg-white z-50">
        <h2 className="text-base sm:text-[22px] md:text-xl font-semibold text-custom-black text-center mt-4 md:mt-8">
          {i("Place your hand as shown")}
        </h2>
        <p className="md:text-xl text-center mt-4  md:mx-16">
          {i(
            "When you're ready, press the button and the countdown will begin to take your picture"
          )}
        </p>
      </div>
      <div className="flex justify-center">
        {!isCounting ? (
          <button
            onClick={() => {
              countdownRef.current = countdown$.subscribe({
                complete: () => handleImageCapture(),
              });
            }}
            className="rounded z-30 bg-custom-blue text-white w-64 h-[50px] mt-6 font-arial-round-bold mb-2 md:mb-10"
          >
            {i("Take picture")}
          </button>
        ) : (
          <p className="w-12 h-12 my-5 bg-custom-orange text-white text-2xl rounded-full z-40 flex justify-center items-center">
            {counter}
          </p>
        )}
      </div>
    </div>
  );
}

function UploadDone({ closeModal }: { closeModal: () => void }) {
  return (
    <div className="pt-[106px] pb-20">
      <VerifConfirmationSection closeModal={closeModal} />
    </div>
  );
}

function AddPhotoCertification() {
  const { i } = useTranslation();
  const { setCurrentStep } = useCertification();
  return (
    <div>
      <h2 className="sm:text-[22px] text-xl font-semibold text-custom-black text-center mt-6">
        {i("Add a photo to your profile first")}
      </h2>
      <p className="text-xl text-center px-3 mt-4">
        {i(
          "To certify your profile, you must have at least one photo in your profile."
        )}
      </p>
      <MyProfilePictureUploader close={() => setCurrentStep("intro")} />
    </div>
  );
}

export function polyfillMediaDevices() {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#using_the_new_api_in_older_browsers

    if (navigator.mediaDevices === undefined) {
      // @ts-ignore
      navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          // @ts-ignore
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  } catch (error) {
    return Promise.reject(new Error("Old browser"));
  }
}
