import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

const VerifPopupFooter: FC = () => {
  const { i } = useContext(TranslationContext);

  return (
    <div className="VerifPopupFooter flex flex-col justify-center items-center">
      {" "}
      <div className="flex items-baseline w-fit">
        <p className="text-lg font-semibold mr-2">{i("Important:")}</p>
        <p
          className="mt-8 max-w-[386px]"
          dangerouslySetInnerHTML={{
            __html: i(
              "These photos are intended to certify your profile and <b>they will never be displayed on your profile.</b>"
            ),
          }}
        />
      </div>
      <div className="border border-custom-black-200/20 my-9 w-full"></div>
      <p className="text-center max-w-[695px]">
        {i(
          "If you are having trouble submitting your 2 photos, send them to us on our email address at [email] with the subject: Photos to certify my profile.",
          {
            email: (
              <span className="text-custom-blue cursor-pointer">
                contact@celibatairesduweb.com
              </span>
            ),
          }
        )}
      </p>
    </div>
  );
};

export default VerifPopupFooter;
