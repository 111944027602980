import React, { FC } from "react";

import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { PickerValue } from "../../types";
import FilterCategoryTitle from "../filterCategoryTitle";
import { ChevronDownIcon } from "@heroicons/react/outline";

type Props = {
  label?: string;
  placeholder?: string;
  values: Array<PickerValue>;
  selected: PickerValue | undefined;
  setSelected: React.Dispatch<React.SetStateAction<PickerValue | undefined>>;
  hasError?: boolean;
};

const StyledCombobox: FC<Props> = ({
  label,
  placeholder,
  values,
  selected,
  setSelected,
  hasError,
}) => {
  const [query, setQuery] = useState("");

  const filteredValues =
    query === ""
      ? values
      : values.filter((value: PickerValue) =>
          value.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Combobox value={selected} onChange={setSelected}>
      {label && (
        <Combobox.Label>
          <FilterCategoryTitle title={label} />
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Input
          className={`w-full h-[42px] border border-custom-gray rounded-[4px] p-2 sm:placeholder:text-base placeholder:text-sm placeholder:text-custom-black-200 text-sm sm:text-base ${
            hasError ? "border-[#CC0123] border-2" : ""
          }`}
          displayValue={(value: PickerValue) => value?.name || ""}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-60 rounded py-1 focus:outline-none overflow-auto text-sm sm:text-base">
            {filteredValues.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredValues.map((value) => (
                <Combobox.Option
                  key={value.id}
                  className={({ active }) =>
                    `cursor-default select-none focus:outline-none relative py-2 pl-3 pr-6 text-custom-blue ${
                      active && "bg-custom-light-blue bg-opacity-20"
                    }`
                  }
                  value={value}
                >
                  {({ selected, active }) => (
                    <>
                      <span className="block truncate font-normal">
                        {value.name}
                      </span>
                      {selected && (
                        <span className="text-custom-blue absolute inset-y-0 right-0 flex items-center pr-2">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default StyledCombobox;
