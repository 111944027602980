import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

type Filter = "perfect_match" | "who_i_want_to_meet";

export interface State {
  filter: Filter;
}

const initialState: State = {
  filter: "perfect_match",
};

export const perfectMatchSlice = createSlice({
  name: "perfectMatch",
  initialState,
  reducers: {
    reset: () => initialState,
    setFilter: (state, action: PayloadAction<Filter>) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = perfectMatchSlice.actions;

export default perfectMatchSlice.reducer;
