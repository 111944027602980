import { FC } from "react";

type Props = {
  label: string;
  description?: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableOption?: React.Dispatch<React.SetStateAction<string>>;
  children?: React.ReactNode;
  disableOption?: string;
  alwaysShowDescription?: boolean;
};

const SettingsActionCard: FC<Props> = ({
  label,
  description,
  checked,
  setChecked,
  children,
  setDisableOption,
  disableOption,
  alwaysShowDescription = false,
}) => {
  return (
    <div
      className="SettingsActionCard rounded w-full bg-custom-gray bg-opacity-20 py-6 lg:py-7 px-4 lg:px-5 mb-4 flex"
      onClick={() => {
        setChecked(!checked);
        if (setDisableOption) {
          setDisableOption(label);
        }
      }}
    >
      <div>
        <input
          className="hidden"
          type="checkbox"
          checked={checked || disableOption === label}
        />

        <span className="w-[26px] h-[26px] inline-block text-custom-light-blue border border-custom-gray rounded-full mr-2 lg:mr-3"></span>
      </div>

      <div>
        <label className="flex items-center cursor-pointer text-custom-black font-bold text-lg">
          {label}
        </label>
        {(alwaysShowDescription || (description && checked)) && (
          <p className="mt-4 bg-custom-orange-100 p-2 rounded">
            {description}
            {children}
          </p>
        )}
      </div>
    </div>
  );
};

export default SettingsActionCard;
