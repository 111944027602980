import React, { FC } from "react";

type Props = { checkListText: any };

const VerifPopupCheckList: FC<Props> = ({ checkListText }) => {
  return (
    <div className="VerifPopupCheckList w-11/12 flex items-baseline space-x-3 py-3">
      <img src="/app/assets/profile/checkIcon.svg" alt="check mark icon" />
      <p>{checkListText}</p>
    </div>
  );
};

export default VerifPopupCheckList;
