import { FC } from "react";

import classNames from "../../utils/twClassNames";
import SayHelloScrollbar from "../sayHelloScrollbar";
import useSayHi from "../../context/SayHiContext";

type Props = {
  setSelectedOption: React.Dispatch<React.SetStateAction<any>>;
  selectedOption: any;
};

const SayHelloCustomPrompts: FC<Props> = ({
  setSelectedOption,
  selectedOption,
}) => {
  const { customResponses } = useSayHi();
  return (
    <SayHelloScrollbar className="min-h-[115px] md:min-h-[230px]">
      <div className={classNames("SayHelloCustomPrompts pr-4 grow")}>
        <div className="gap-[15px] grid-cols-2 xs:grid-cols-3 sm:grid-cols-5 inline-grid w-full">
          {customResponses.map((card) => {
            return (
              <button
                key={card.greetingId}
                onClick={() => {
                  setSelectedOption(card);
                }}
                className={classNames(
                  selectedOption == card
                    ? "border-2 border-red-500 overflow-hidden rounded-md"
                    : "border-2 border-custom-blue-300 rounded-md",
                  "overflow-hidden"
                )}
              >
                <div className="SayHelloCustomCard w-full border-2 h-[100px] rounded-[4px] flex justify-center items-center bg-custom-blue-300">
                  <img
                    src={card.personnaliser_message}
                    alt={
                      card.personnaliser_message.match(
                        /\/([^/]+)\.\w+$/
                      )?.[1] ?? "Custom Emoji"
                    }
                    className="w-12 h-12"
                  />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </SayHelloScrollbar>
  );
};

export default SayHelloCustomPrompts;
