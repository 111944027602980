import React, { FC } from "react";
import StyledTextArea from "../styledTextArea";

import useTranslation from "../../context/TranslationContext";

export const MAX_LENGTH = 5000;

type Props = {
  recipientUsername: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const SayHelloCustomMessage: FC<Props> = ({ recipientUsername, setValue }) => {
  const { i } = useTranslation();

  return (
    <div className="h-[78px]">
      <StyledTextArea
        placeholder={i("Your message to [recipient]", {
          recipient: recipientUsername,
        })}
        onValueChange={(event) => setValue(event)}
        maxLength={MAX_LENGTH}
      />
    </div>
  );
};

export default SayHelloCustomMessage;
