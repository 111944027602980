export default function OpenProfileLink({
  children,
  username,
}: {
  children: React.ReactNode;
  username: string;
}) {
  const href =
    window.location.href +
    `${window.location.href.includes("?") ? "&" : "?"}profile=${username}`;

  return (
    <a
      className="cursor-pointer"
      onClick={(event) => {
        event.preventDefault();
        window.openProfileModal(username);
      }}
      href={href}
    >
      {children}
    </a>
  );
}
