import { FC, useContext, useEffect, useRef } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import useCertification from "../../../hooks/useCertification";

const WebCamFinalPage: FC<{ imgAsString: string }> = ({ imgAsString }) => {
  const { i } = useContext(TranslationContext);
  const { close, webcamImageModele } = useCertification();

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center  h-full sm:h-80 w-full relative md:flex-row space-x-6 md:space-x-0">
        <img
          ref={ref}
          src={imgAsString}
          className="w-[45%] h-[150px] md:w-[218px] md:h-[218px] border-[4px] border-custom-orange rounded-3xl sm:mr-[46px] object-cover my-10 sm:my-0"
        />
        <img
          src={webcamImageModele}
          alt="photo to replicate"
          className="w-[45%] h-[150px] md:h-[218px] md:w-[218px] object-cover border-[4px] border-custom-orange rounded-3xl"
        />
      </div>
      <div>
        <h2 className="sm:text-[22px] text-xl font-semibold text-custom-black text-center mt-6">
          {i("Our team will validate your photo shortly.")}
        </h2>
        <p className="text-center mt-6">
          {"* " + i("The photo remains private... it will never be displayed.")}
        </p>
      </div>
      <div className="flex justify-center space-x-10">
        <button
          className="rounded bg-custom-blue text-white w-[200px] h-[50px] mt-6 font-arial-round-bold mb-10"
          onClick={() => {
            close();
          }}
        >
          {i("Done")}
        </button>
      </div>
    </div>
  );
};

export default WebCamFinalPage;
