import { FC, useContext } from "react";
import { useRouter } from "next/router";
import { TranslationContext } from "../../context/TranslationContext";
import classNames from "../../utils/twClassNames";

import useCertification from "../../hooks/useCertification";

type Props = {
  completed: boolean;
  settingName: "email address" | "profile";
};

const SettingsCertifiedButton: FC<Props> = ({ completed, settingName }) => {
  const { i } = useContext(TranslationContext);

  const router = useRouter();
  const { start } = useCertification();

  const handleClick = () => {
    if (completed) return;

    switch (settingName) {
      case "email address":
        router.push("/confirm-email");
        break;
      case "profile":
        start();
        break;
    }
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        "SettingsCertifiedButton flex rounded  h-[32px] bg-gradient-to-r",
        completed
          ? "from-custom-gradient-lightgreen to-custom-gradient-darkgreen"
          : "from-custom-gradient-orange to-custom-red cursor-pointer"
      )}
    >
      <div className="flex items-center pl-[12px] pr-[8px] py-[8px]">
        {completed ? (
          <img src="/app/assets/white-checkmark.svg" alt="Certified icon" />
        ) : (
          <img
            src="/app/assets/shield-certified.svg"
            alt="Not certified icon"
          />
        )}
      </div>
      <div className="flex text-white items-center font-normal text-sm pr-[10px]">
        <p>
          {completed ? (
            <span>{i(`Certified ${settingName}`)} </span>
          ) : (
            <span>{i(`Certify my ${settingName}`)} </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default SettingsCertifiedButton;
