import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Messages extends Api {
  public async getDiscussions(queryParams: {
    pageIndex: number;
    pageSize: number;
    type: "inbox" | "favorites" | "unread" | "security" | "welcome";
    search?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        totalReturnedItem: number;
        hasMore: boolean;
        items: IDiscussion[];
      };
    }
  > {
    return this.get("/messages", queryParams);
  }

  public async getIceServers(): Promise<
    HTTPResponse & {
      data: {
        content: {
          url: string[];
          username?: string;
          urls: string[];
          credential?: string;
        }[];
      };
    }
  > {
    return this.get("/iceServers");
  }

  public async getDiscussion(queryParams: {
    pageIndex: number;
    pageSize: number;
    discussionId: number;
    userId?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        total: number;
        headerInfos: {
          isFavorited: boolean;
          isReported: boolean;
          Fraudeur: boolean;
          disableControls: boolean;
          isBlocked: boolean;
          discussionDeletedByDestinataire: boolean;
          myLastMessageHasBeenRead: boolean;
        };
        items: IMessage[];
      };
    }
  > {
    return this.get("/message", queryParams);
  }

  public async edit(body: {
    discussionId: number;
    messageId: string;
    message: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.patch("/message", body);
  }

  public async deleteMessage(body: {
    discussionId: number;
    messageId: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/message", body);
  }

  public async deleteConversation(body: {
    discussionId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/messages", body);
  }

  public async postMessageAction(body: {
    discussionId: number;
    action: "unread" | "addFavorite" | "addReport";
    raison?: string;
    userId?: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/messageAction", body);
  }

  public async deleteMessageAction(body: {
    discussionId: number;
    action: "deleteFavorite" | "deleteReport";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/messageAction", body);
  }

  public async postMessage(body: {
    discussionId: number;
    userId: number;
    username: string;
    message: string;
  }): Promise<
    HTTPResponse & {
      data: {
        messageId: string;
        time: `${number}${number}:${number}${number}`;
        message: string;
        messageTextArea: string;
        isEditable: boolean;
      };
    }
  > {
    return this.post("/message", body);
  }

  public async postGreetingMessage(body: {
    discussionId: number;
    userId: number;
    username: string;
    message: string;
    greetingId: number;
  }): Promise<HTTPResponse & { data: { discussionId: string } }> {
    return this.post("/message/greeting", body);
  }
  public async postStartCall(body: {
    discussionId: number;
    userId: number;
    username: string;
    payload: { peerId: string };
  }): Promise<HTTPResponse> {
    return this.post("/message/startCall", body);
  }

  public async postCallStarted(body: {
    discussionId: number;
    userId: number;
    username: string;
  }): Promise<HTTPResponse> {
    return this.post("/message/callStarted", body);
  }

  public async postEndCall(body: {
    discussionId: number;
    userId: number;
    username: string;
    payload: { peerId: string } | {};
  }): Promise<HTTPResponse> {
    return this.post("/message/endCall", body);
  }

  public async postPhoto(formFields: {
    uploadfile: File;
    discussionId: number;
    userId: string;
    username: string;
  }): Promise<
    HTTPResponse & {
      data: {
        messageId: string;
        message: string;
        pathImage: string;
        pathPhoto_thumbs: string;
        time: string | `${number}${number}:${number}${number}`;
        isEditable: boolean;
      };
    }
  > {
    const formData = new FormData();

    const root = this.getRoot();

    formData.append("uploadfile", formFields.uploadfile);
    formData.append("discussionId", formFields.discussionId.toString());
    formData.append("userId", formFields.userId);
    formData.append("username", formFields.username);

    formData.append("locale", this.getLocale());

    const response = await fetch(root + "/message/photo", {
      method: "POST",
      headers: {
        Authorization: this.getToken(),
      },
      body: formData,
    });

    const jsonResponse = await response.json();

    return jsonResponse;
  }
}

export interface IDiscussion {
  userId: number;
  discussionType: "security" | "welcome" | "user";
  discussionId: number;
  userName: string;
  profileMainPhoto: string;
  age: string;
  prenom: string;
  location: string;
  isVIP: boolean;
  isCertified: boolean;
  isUserDeleted: boolean;
  isUserOnline: boolean;
  dateLastMessage: string;
  haveNewMessage: boolean;
  myLastMessageHasBeenRead: boolean;
  lastMessage: string;
  isFavorited: boolean;
  DateDerniereVisite: string;
}

export interface IMessage {
  messageId: string;
  userId: string;
  isPhoto: boolean;
  pathPhoto: string;
  pathPhoto_thumbs: string;
  date?: string;
  timestamp: number;
  type: "incoming" | "outgoing";
  time: string; // TIME était ici   11:11
  message: string; // html
  isDeleted?: boolean;
  isInfoVideoCall?: boolean;
  isEditable: boolean;
  messageHasBeenEdited: boolean;
  messageTextArea: string;
}

export type MessageType = "incoming" | "outgoing";
