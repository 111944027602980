import React, { FC, useContext, useRef } from "react";

import api from "../../backendAPI";
import useToast from "../../hooks/useToast";
import useMessenger from "../../hooks/useMessenger";
import classNames from "../../utils/twClassNames";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  preventImageUpload?: boolean;
};

const MessageChatBoxImage: FC<Props> = ({ preventImageUpload }) => {
  const { i } = useContext(TranslationContext);
  const { currentConversation, fetchMessages, sendPhoto } = useMessenger();

  const { openToast } = useToast();

  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleImageSubmit = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    if (
      !imageInputRef.current ||
      !imageInputRef.current.files ||
      !imageInputRef.current.files[0]
    ) {
      return;
    }

    if (!currentConversation.conversationInfo) return;

    const file = imageInputRef.current.files[0];

    sendPhoto(file);
  };

  return (
    <button
      data-testid="messenger-img-upload-button"
      className="MessageChatBoxImageUpload w-[25px] h-[25px] min-w-[25px] min-h-[25px] cursor-pointer relative tooltip"
    >
      <div className="absolute left-14 -top-12">
        <p className="tooltiptext w-32">{i("Upload image")}</p>
      </div>
      <label
        htmlFor="image-input"
        className={classNames(preventImageUpload ? "opacity-50" : "")}
      >
        <img
          alt={uploadImageIconAltAttribute}
          src="/app/assets/uploadImageIcon.svg"
          className="cursor-pointer"
        />
      </label>
      <form>
        {!preventImageUpload && (
          <input
            ref={imageInputRef}
            onChange={handleImageSubmit}
            type="file"
            id="image-input"
            className="hidden"
            name="uploadfile"
            accept="image/*"
          />
        )}
      </form>
    </button>
  );
};

export default MessageChatBoxImage;

export const uploadImageIconAltAttribute = "Upload image icon";
