import React, { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  currentStep: number;
  currentStepText: any;
};

const VerifPopupSteps: FC<Props> = ({ currentStep, currentStepText }) => {
  const { i } = useContext(TranslationContext);
  return (
    <div className="VerifPopupSteps w-full pt-10 flex items-baseline">
      <div className="text-lg font-bold flex space-x-1 pr-1">
        <p>{i("Step")}</p>
        <p> {currentStep}: </p>
      </div>
      <div className="w-fit">{currentStepText}</div>
    </div>
  );
};

export default VerifPopupSteps;
