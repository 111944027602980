import { FC } from "react";
import { descriptionOrDefault } from "../profileStatsContainer/ProfileStatsContainer";
import ProfileStatsItemContainer from "../profileStatsItemContainer";
import ProfileStatsItemTitle from "../profileStatsItemTitle";
import UnderReviewLabel from "../underReviewLabel";

type Props = {
  label: string;
  description: string;
  waitingReview?: boolean;
};

const ProfileStatsItem: FC<Props> = ({ label, description, waitingReview }) => (
  <ProfileStatsItemContainer>
    <div className="flex flex-row space-x-2 items-center h-7">
      <ProfileStatsItemTitle>{label}</ProfileStatsItemTitle>
      {waitingReview && <UnderReviewLabel />}
    </div>
    <p
      dangerouslySetInnerHTML={{ __html: descriptionOrDefault(description) }}
    ></p>
  </ProfileStatsItemContainer>
);

export default ProfileStatsItem;
