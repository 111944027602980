import Api, { HTTPResponse } from "./Api";

export default class Image extends Api {
  public async deleteImage(body: { imageId: number; action: string }): Promise<
    HTTPResponse & {
      data: {
        message: string;
        newMainImage: string;
        popupMessage: string;
        popupTitle: string;
        showPopup: boolean;
      };
    }
  > {
    return this.delete("/image", body);
  }

  public async patchImage(body: {
    imageId: number;
    action: string;
  }): Promise<HTTPResponse & { data: any }> {
    return this.patch("/image", body);
  }

  public async patchImagesOrder(
    order: number[]
  ): Promise<HTTPResponse & { data: any }> {
    return this.patch("/image", {
      imageId: -1,
      action: "profileImagesOrder",
      imagesOrder: order,
    });
  }
}
