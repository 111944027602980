import React from "react";
import useUser from "../../hooks/useUser";
import useTranslation from "../../context/TranslationContext";
import classNames from "../../utils/twClassNames";

const IS_SUGAR_TESTING = false;

const LINKS = {
  fr: {
    img: "/app/assets/Werbeanzeige.png",
    link: "https://www.mysugardaddy.fr/?msd=celib1",
  },
  en: {
    img: "/app/assets/Werbeanzeige_en.png",
    link: "https://www.mysugardaddy.fr/?msd=vidam1",
  },
  // TODO: Find ES version of images, currently uses english values
  es: {
    img: "/app/assets/Werbeanzeige_en.png",
    link: "https://www.mysugardaddy.fr/?msd=vidam1",
  },
} as const;

export default function SugarDaddyAd({ className }: { className?: string }) {
  const {
    userData: { isPremiumNoAds },
  } = useUser();
  const { lang } = useTranslation();

  if (isPremiumNoAds) return null;

  if (!IS_SUGAR_TESTING) return null;

  return (
    <div
      className={classNames(
        "max-w-[300px] mx-auto my-3 Sugar",
        className ?? false
      )}
    >
      <a href={LINKS[lang || "en"].link}>
        <img
          src={LINKS[lang || "en"].img}
          alt="SugarDaddy Ad"
          className="h-auto w-full"
        />
      </a>
    </div>
  );
}
