import React, { FC } from "react";

type Props = { imageAlt: string; imageSrc: string };

const VerifPopupImage: FC<Props> = ({ imageSrc, imageAlt }) => {
  return (
    <div className="w-[45%] h-[150px] md:w-[218px] md:h-[218px] VerifPopupImage border-[5px]  overflow-hidden  m-auto mb-5 rounded-[25px] border-custom-orange">
      <img
        src={imageSrc}
        alt={imageAlt}
        className="object-cover relative w-full h-full"
      />
    </div>
  );
};

export default VerifPopupImage;
