import { FC, useContext, useEffect, useRef } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import useCertification from "../../../hooks/useCertification";
import LoadingSpinner from "../../loadingSpinner";

type Props = {
  onConfirmClick: () => void;
  isLoading: boolean;
  imgAsString: string;
};

const WebCamConfirmImage: FC<Props> = ({
  onConfirmClick,
  isLoading,
  imgAsString,
}) => {
  const { i } = useContext(TranslationContext);
  const { setCurrentStep, webcamImageModele } = useCertification();

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center h-full sm:h-80 w-full relative md:flex-row space-x-6 md:space-x-0">
        <img
          ref={ref}
          className="w-[45%] h-[150px] md:w-[218px] md:h-[218px] border-[4px] border-custom-orange rounded-3xl sm:mr-[46px] object-cover mt-4  md:my-10 sm:my-0"
          src={imgAsString}
        />
        <img
          src={webcamImageModele}
          alt="photo to replicate"
          className="w-[45%] h-[150px] md:h-[218px] md:w-[218px] object-cover border-[4px] border-custom-orange rounded-3xl mt-4 md:mt-0"
        />
      </div>
      <div>
        <h2 className="sm:text-[22px] text-xl font-semibold text-custom-black text-center mt-5">
          {i(
            "If the image matches you can send it to us for verification. Otherwise, you can retake it."
          )}
        </h2>
      </div>
      <div className="flex justify-center sm:space-x-10 flex-col sm:flex-row items-center mb-5">
        <button
          className="rounded bg-custom-blue text-white w-[200px] h-[50px] mt-6 font-arial-round-bold"
          onClick={() => {
            setCurrentStep("webcam");
          }}
        >
          {i("Retake")}
        </button>
        <div className=" flex justify-center items-center w-[200px] h-[50px] mt-6">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <button
              className="rounded bg-custom-blue text-white font-arial-round-bold w-full h-full"
              onClick={onConfirmClick}
            >
              {i("Send")}
            </button>
          )}
        </div>
      </div>
      <p className=" text-center mb-10">
        {"* " + i("The photo remains private... it will never be displayed.")}
      </p>
    </div>
  );
};

export default WebCamConfirmImage;
