import React, { FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ClosePopUp from "../closePopUp";
import CustomScrollBar from "../CustomScrollbar/CustomScrollBar";
import classNames from "../../utils/twClassNames";

type Props = {
  children?: React.ReactNode;
  hideCloseButton?: boolean;
  open: boolean;
  onClick: (open: boolean) => void;
  maxWidth?: number;
  noPadding?: boolean;
  customScrollbar?: boolean;
};

const StyledPopup: FC<Props> = ({
  children,
  hideCloseButton,
  open,
  onClick,
  maxWidth,
  noPadding = false,
  customScrollbar = true,
}) => {
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          data-testid="delete-confirm-popup-body"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => onClick(false)}
        >
          <div className="items-end justify-center min-h-[100dvh] lg:min-h-screen text-center block md:px-0 px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 -z-10 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block align-middle h-[100dvh] lg:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div
                className={classNames(
                  `z-[60] inline-block bg-white rounded-2xl shadow-xl transform transition-all align-middle mx-auto max-h-[95dvh] lg:max-h-[95vh]`,
                  maxWidth ? "w-full" : "w-auto"
                )}
                style={{ maxWidth: maxWidth ?? 750 }}
              >
                <div
                  className="absolute right-4 top-4 sm:right-6 sm:top-6 z-20"
                  style={{
                    opacity: hideCloseButton ? 0 : 1,
                    pointerEvents: hideCloseButton ? "none" : "auto",
                  }}
                >
                  <ClosePopUp onClick={() => onClick(false)} />
                </div>
                {customScrollbar ? (
                  <CustomScrollBar>
                    <div
                      className={classNames(
                        "w-full",
                        noPadding ? "p-0" : "p-4 sm:p-6"
                      )}
                    >
                      {children}
                    </div>
                  </CustomScrollBar>
                ) : (
                  <>
                    <div
                      className={classNames(
                        "w-full",
                        noPadding ? "p-0" : "p-4 sm:p-6"
                      )}
                    >
                      {children}
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default StyledPopup;
