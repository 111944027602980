import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { ButtonBase, Rating } from "@mui/material";
import ButtonBGBlue from "../buttonBGBlue";
import useFeedbackPopup from "../../context/FeedbackContext";
import useTranslation from "../../context/TranslationContext";

export type FeedBackFormData = {
  note_question1: string;
  note_question2: string;
  comment: string;
};

type FeedbackPopupProps = {
  open: boolean;
  onClose(): void;
  onSubmit(form: FeedBackFormData): void;
  onRefuse(): void;
};

export default function FeedbackPopup({
  open,
  onClose,
  onSubmit,
  onRefuse,
}: FeedbackPopupProps) {
  const [activeStep, setActiveStep] = useState(0);
  // const { experienceRating, recommendationRating } = useFeedbackPopup();
  const [experienceRating, setExperienceRating] = useState(0);
  const [recommendationRating, setRecommendationRating] = useState(0);
  const [comment, setComment] = useState("");
  const { i } = useTranslation();

  const handleBack = () => {
    setActiveStep((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    if (activeStep == 2) {
      onSubmit({
        note_question1: String(experienceRating),
        note_question2: String(recommendationRating),
        comment,
      });
    }
    setActiveStep((prev) => Math.min(3, prev + 1));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[100] inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="overflow-hidden inline-block align-bottom md:min-w-[600px] bg-[#F0F0F0] top-10 rounded-2xl text-left shadow-xl transform transition-all sm:align-middle items-center">
              <div className="py-8 text-center bg-gray-200 border-b-[#D2D2D2] border-b-2">
                <p className="text-xl font-medium">
                  {i("Your opinion is important to us")}
                </p>
              </div>
              <div className="bg-white min-h-[225px] px-4 sm:px-8 py-4 flex justify-center">
                {
                  [
                    <ExperienceStep
                      key="experience-step"
                      value={experienceRating}
                      onChange={(newValue) => {
                        setExperienceRating(newValue);
                        handleNext();
                      }}
                    />,
                    <RecommendStep
                      key="recommend-step"
                      value={recommendationRating}
                      onChange={(newValue) => {
                        setRecommendationRating(newValue);
                        handleNext();
                      }}
                    />,
                    <CommentStep
                      key="comment-step"
                      value={comment}
                      onChange={(newValue) => setComment(newValue)}
                    />,
                    <ThankYouStep key="thankyou-step" />,
                  ][activeStep]
                }
              </div>
              <div className="p-4 px-3 xs:px-8 sm:px-12 border-t-[#D2D2D2] border-t-2 bg-white">
                {activeStep !== 3 ? (
                  <div className="flex justify-between gap-2">
                    <ButtonBase
                      onClick={onRefuse}
                      sx={{ color: "rgb(107, 114, 128)" }}
                    >
                      {i("Not Now")}
                    </ButtonBase>
                    <div className="flex h-min items-center">
                      {activeStep !== 0 && (
                        <ArrowButton onClick={handleBack} direction="left" />
                      )}
                      <div className="text-lg font-medium">
                        {activeStep + 1}/3
                      </div>
                      {activeStep < 2 ? (
                        <ArrowButton
                          onClick={handleNext}
                          direction="right"
                          disabled={
                            (activeStep === 0 && !experienceRating) ||
                            (activeStep === 1 && !recommendationRating)
                          }
                        />
                      ) : (
                        <button
                          className="w-30 ml-2 inline-flex justify-center items-center rounded h-[35px] border-2 shadow-sm px-2 py-1  text-lg font-medium text-white border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
                          onClick={handleNext}
                        >
                          {i("Submit")}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <ButtonBGBlue
                      className="w-40"
                      innerText={i("Close")}
                      onClick={onClose}
                    />
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const ExperienceStep = ({
  value,
  onChange,
}: {
  value: number;
  onChange(value: number): void;
}) => {
  const { i } = useTranslation();
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    setTimeout(() => setReadOnly(false), 500);
  }, []);

  return (
    <div className="max-w-[350px] w-full h-full flex flex-col justify-between items-center gap-4">
      <p className="text-xl text-center">
        {i("How would you qualify your experience on the site?")}
      </p>
      <div>
        <Rating
          readOnly={readOnly}
          value={value}
          onChange={(_, newValue) => onChange(newValue ?? 0)}
          precision={0.5}
          // size={"large"}
          onFocus={(e) => e.stopPropagation()}
          onChangeActive={() => {}}
          sx={{
            [".MuiRating-label"]: { display: "none" },
            width: 1,
            fontSize: {
              xs: 45,
              sm: 55,
              md: 65,
            },
            margin: "auto",
            // justifyContent: "space-between",
            color: "rgb(56, 106, 236)",
          }}
        />
        <div className="flex justify-between">
          <p className="text">Terrible</p>
          <p className="text">Excellent</p>
        </div>
      </div>
    </div>
  );
};

const RecommendStep = ({
  value,
  onChange,
}: {
  value: number;
  onChange(value: number): void;
}) => {
  const { i } = useTranslation();
  return (
    <div className="max-w-[350px] w-full h-full flex flex-col justify-between items-center gap-4">
      <p className="text-xl text-center">
        {i("Would you recommend this site to a friend?")}
      </p>
      <div>
        <Rating
          value={value}
          onChange={(_, newValue) => onChange(newValue ?? 0)}
          precision={0.5}
          sx={{
            [".MuiRating-label"]: { display: "none" },
            width: 1,
            fontSize: {
              xs: 45,
              sm: 55,
              md: 65,
            },
            margin: "auto",
            // justifyContent: "space-between",
            color: "rgb(56, 106, 236)",
          }}
        />
        <div className="flex justify-between">
          <p className="text-center">{i("Not at all")}</p>
          <p className="text-center w-min">{i("Yes, without hesitation")}</p>
        </div>
      </div>
    </div>
  );
};

const CommentStep = ({
  value,
  onChange,
}: {
  value: string;
  onChange(value: string): void;
}) => {
  const { i } = useTranslation();
  return (
    <div className="flex flex-col h-full w-full justify-between items-center px-5 gap-4">
      <p className="max-w-[350px] text-xl text-center">
        {i("Do you have any suggestions and/or comments to share with us?")}
      </p>
      <textarea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className="messageBox relative w-full h-full outline-none border min-h-[115px] border-custom-light-gray-600 rounded-[4px] p-3.5 pt-2.5 pl-[22px] pr-10 bg-white text-base text-custom-black placeholder-custom-black-500/50 focus:outline-none focus:shadow-outline"
        placeholder={i("Type your message...")}
      />
    </div>
  );
};

const ThankYouStep = () => {
  const { i } = useTranslation();
  return (
    <div className="max-w-[450px] flex flex-col justify-center items-center gap-8">
      <p className="text-xl text-center">
        {i("Thank you for sharing your feedback. We will read it attentively.")}
      </p>
      <div>
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </div>
  );
};

type ArrowButtonProps = {
  direction: "left" | "right";
  onClick(): void;
  disabled?: boolean;
};
const ArrowButton = ({ direction, onClick, disabled }: ArrowButtonProps) => {
  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div
      onClick={handleClick}
      style={{
        height: 50,
        width: 50,
        pointerEvents: disabled ? "none" : "auto",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {direction === "left" ? <LeftArrow /> : <RightArrow />}
    </div>
  );
};

const LeftArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M19 12a1 1 0 0 1-1 1H8.414l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L8.414 11H18a1 1 0 0 1 1 1z"
        fill="rgb(56, 106, 236)"
        data-name="Left"
      />
    </svg>
  );
};

const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={"100%"}
      width={"100%"}
    >
      <path
        d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z"
        fill="rgb(56, 106, 236)"
        data-name="Right"
      />
    </svg>
  );
};
