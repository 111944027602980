import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  logout,
  login,
  createAccount,
  clearAuthError,
  setNeedsToConfirmEmail,
  State,
  resetIsLoading,
  getLoginInfo,
  loadToken,
} from "../redux/slices/authSlice";
import { assertLanguage } from "../context/TranslationContext";

type UseAuth = () => State & {
  resetIsLoading: () => void;
  clearAuthError: () => void;
  setNeedsToConfirmEmail: (needsToConfirmEmail: boolean) => void;
  autoLogin: () => void;

  login: typeof login;

  getLoginInfo: typeof getLoginInfo;

  logout: () => void;

  loadToken: typeof loadToken;

  createAccount: ({
    email,
    password,
    username,
    password2,
  }: {
    email: string;
    password: string;
    username: string;
    password2: string;
  }) => void;
};

const useAuth: UseAuth = () => {
  const selectIsAuth = (state: RootState) => state.auth.isAuth;

  const isAuth = useSelector(selectIsAuth);

  const selectAccessToken = (state: RootState) => state.auth.accessToken;

  const selectAuthError = (state: RootState) => state.auth.authError;

  const authError = useSelector(selectAuthError);

  const accessToken = useSelector(selectAccessToken);

  const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

  const refreshToken = useSelector(selectRefreshToken);

  const isLoading = useSelector((state: RootState) => state.auth.isLoading);

  const selectAuthErrorMessage = (state: RootState) =>
    state.auth.authErrorMessage;

  const authErrorMessage = useSelector(selectAuthErrorMessage);

  const selectNeedsToConfirmEmail = (state: RootState) =>
    state.auth.needsToConfirmEmail;

  const selectNeedsToAnswerQuestions = (state: RootState) =>
    !state.registration.isDone && state.auth.needsToAnswerQuestions;

  const needsToConfirmEmail = useSelector(selectNeedsToConfirmEmail);

  const selectNeedsCertify = (state: RootState) => state.auth.needsToCertify;

  const needsToCertify = useSelector(selectNeedsCertify);

  const needsToAnswerQuestions = useSelector(selectNeedsToAnswerQuestions);

  const lastVisitedURL = useSelector(
    (state: RootState) => state.auth.lastVisitedURL
  );

  const profileDeleted = useSelector(
    (state: RootState) => state.auth.profileDeleted
  );

  const profileNotCreated = useSelector(
    (state: RootState) => state.auth.profileNotCreated
  );

  const loginInfoLoaded = useSelector(
    (state: RootState) => state.auth.loginInfoLoaded
  );

  const role = useSelector((state: RootState) => state.auth.role);

  const actions = useActions({
    login,
    logout,
    clearAuthError,
    createAccount,
    resetIsLoading,
    setNeedsToConfirmEmail,
    getLoginInfo,
    loadToken,
  });

  const autoLogin = () => {
    const token = window.localStorage.getItem("token");

    actions.login({
      token: accessToken || token,
      locale: assertLanguage(),
    });
  };

  return {
    isAuth,
    authErrorMessage,
    authError,
    accessToken,
    refreshToken,
    isLoading,
    needsToConfirmEmail,
    needsToCertify,
    lastVisitedURL,
    profileDeleted,
    profileNotCreated,
    loginInfoLoaded,
    needsToAnswerQuestions,
    role,
    ...actions,
    autoLogin,
  };
};

export default useAuth;
