import { FC } from "react";

type Props = {
  children: React.ReactNode;
};

const ProfileStatsRow: FC<Props> = ({ children }) => (
  <div className="ProfileStatsRow sm:flex sm:flex-row sm:space-y-0 flex flex-col space-y-6 pt-[21px]">
    {children}
  </div>
);

export default ProfileStatsRow;
