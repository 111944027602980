import React, { useEffect, useRef, useState } from "react";
import { useVideoChat } from "../contexts/VideoChatContext";
import useUser from "../../hooks/useUser";
import classNames from "../../utils/twClassNames";
import { ControlButton } from "./VideoChatControls";

import { interval, map } from "rxjs";

const PreviewIcon = "/app/assets/white-eye.svg";

/**
 * Component that displays a preview of the instantiated outgoing local MediaStream. In the case
 * where a MediaStream is not available or that video channels are blocked, displays a message
 * to show that no video is currently being recorded
 * @component
 */
export default function VideoPreview() {
  const { useCamera, localStream, callStart, remoteStream } = useVideoChat();
  const previewVideoRef = useRef<HTMLVideoElement | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const videoTimerRef = useRef<HTMLDivElement | null>(null);

  // Connects to webcam stream to preview video
  useEffect(() => {
    if (!previewVideoRef.current || !localStream) return;
    previewVideoRef.current.srcObject = localStream;
    previewVideoRef.current.play();
    previewVideoRef.current.muted = true;
    return () => {
      if (!previewVideoRef.current) return;
      previewVideoRef.current.srcObject = null;
      previewVideoRef.current.pause();
      previewVideoRef.current.src = "";
    };
  }, [localStream, useCamera, showPreview]);

  useEffect(() => {
    if (!callStart) return;
    const timer$ = interval(1000).pipe(
      map(() => {
        const now = new Date();
        const diff = Math.floor((now.getTime() - callStart.getTime()) / 1000);
        const hours = Math.floor(diff / 3600);
        const minutes = Math.floor((diff % 3600) / 60);
        const seconds = Math.floor(diff % 60);
        return `${hours ? hours + ":" : ""}${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      })
    );
    const subscription = timer$.subscribe((value) => {
      if (videoTimerRef.current)
        videoTimerRef.current.textContent = String(value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [callStart]);

  const showVideo = localStream && useCamera;

  return (
    <div className="relative flex items-center justify-center overflow-visible px-4 w-full">
      <div
        ref={videoTimerRef}
        className={classNames(
          "text-lg p-4 absolute left-0 top-0",
          !remoteStream && "invisible"
        )}
      />
      <div
        className={classNames("w-[200px] z-10 transition-all overflow-hidden")}
        style={{ height: showPreview ? 150 : 0 }}
      >
        {showVideo && localStream ? (
          <video playsInline ref={previewVideoRef} height={"100%"} />
        ) : (
          <PreviewPlaceHolder />
        )}
      </div>
      <div className="absolute right-0 top-0 z-10">
        <ControlButton
          iconSrc={PreviewIcon}
          onClick={() => setShowPreview((prev) => !prev)}
        />
      </div>
    </div>
  );
}

/**
 * Basic Component when no video is available
 */
const PreviewPlaceHolder = () => {
  const { userData } = useUser();

  const pic =
    userData.mainImage ||
    "https://cdn.celibatairesduweb.com/img/Vignettes/admin2_v=3.png";

  return (
    <div className="bg-custom-dark-gray p-8 rounded flex justify-center items-center h-full">
      <div
        className="rounded-full bg-center bg-cover h-[110px] w-[110px]"
        style={{
          backgroundImage: `url(${pic})`,
        }}
      ></div>
    </div>
  );
};
