import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  openToast,
  openTimedToast,
  closeToast,
  State,
  ToastType,
} from "../redux/slices/toastSlice";

type UseToast = () => State & {
  openTimedToast: (payload: {
    title: string;
    description: string;
    type: ToastType;
    timeout?: number;
  }) => void;
  openToast: (payload: {
    title: string;
    description: string;
    type: ToastType;
  }) => void;
  closeToast: () => void;
};

const useToast: UseToast = () => {
  const isOpen = useSelector((state: RootState) => state.toast.isOpen);

  const title = useSelector((state: RootState) => state.toast.title);

  const description = useSelector(
    (state: RootState) => state.toast.description
  );

  const type = useSelector((state: RootState) => state.toast.type);

  const actions = useActions({ openToast, closeToast, openTimedToast });

  return { isOpen, title, description, type, ...actions };
};

export default useToast;
