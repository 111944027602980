import { useContext, useEffect, useRef } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import useCertification from "../../../hooks/useCertification";
import ButtonBGBlue from "../../buttonBGBlue";
import VerifPopupImage from "../../verifPopupImage";

export default function WebCamHowTo() {
  const { i } = useContext(TranslationContext);
  const { setCurrentStep, webcamImageModele } = useCertification();

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: "end",
        });
      }
    }, 100);

    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div className="p-6">
      <p
        ref={ref}
        className="pt-10 pb-6 placeholder-blue-400 text-center text-custom-black sm:text-[22px] text-xl font-semibold"
      >
        {i("You will have to place your hand as shown in the image below")}
      </p>
      <VerifPopupImage
        imageAlt="vidamora certification image"
        imageSrc={webcamImageModele}
      />

      <p className="pb-4 text-xl text-center text-custom-black">
        {i(
          "It is very important that your hand and your face appear clearly and unobstructed"
        )}
      </p>
      <div className="w-[200px] m-auto pb-3">
        <ButtonBGBlue
          innerText={i("Certify my profile")}
          onClick={() => {
            setCurrentStep("webcam");
          }}
        />
      </div>
    </div>
  );
}
