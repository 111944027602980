import React, { createContext, Dispatch, useEffect, useState } from "react";

import ConfirmDialog from "../components/confirmDialog";

interface Context {
  isOpen: boolean;
  title: string;
  action: string;
  description: string;
  confirmText: string;
  confirmAction: any;
  setConfirmAction: any;
  openPopup: any;
  closePopup: () => void;
  iconDescription: IconDescription;
  setIconDescription: Dispatch<React.SetStateAction<IconDescription>>;
}

export type IconDescription = {
  iconSrc: string;
  iconAlt: string;
};

export const ConfirmDialogContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function ConfirmDialogProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [action, setAction] = useState("");
  const [description, setDescription] = useState<string>("");
  const [confirmText, setConfirmText] = useState("");
  const [iconDescription, setIconDescription] = useState<IconDescription>({
    iconSrc: "",
    iconAlt: "",
  });

  const [confirmAction, setConfirmAction] = useState<any>(undefined);

  const openPopup = (payload: {
    title?: string;
    action?: string;
    description?: string;
    cancelText?: string;
    confirmText?: string;
    iconSrc?: string;
    iconAlt?: string;
    confirmAction: () => void;
    cancelAction?: () => void;
    skip?: boolean;
  }) => {
    if (!payload.skip) setIsOpen(true);
    console.log({ payload });

    if (payload.description) setDescription(payload.description);
    if (payload.confirmText) setConfirmText(payload.confirmText);
    if (payload.iconSrc) {
      setIconDescription({
        iconSrc: payload.iconSrc,
        iconAlt: payload.iconAlt || "",
      });
    }

    setTitle(payload.title || "");
    setConfirmAction(() => payload.confirmAction);
    setAction(payload.action || "");
  };

  const closePopup = () => {
    setIsOpen(false);
    // cleanup
    setTimeout(() => {
      if (isOpen) return;

      setIconDescription({ iconSrc: "", iconAlt: "" });
    }, 350);
  };

  return (
    <ConfirmDialogContext.Provider
      value={{
        isOpen,
        description,
        confirmText,
        confirmAction,
        setConfirmAction,
        openPopup,
        closePopup,
        iconDescription,
        setIconDescription,
        title,
        action,
      }}
    >
      {children}
      <ConfirmDialog />
    </ConfirmDialogContext.Provider>
  );
}
