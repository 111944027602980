import React, { createContext, useState } from "react";

interface Context {
  currentQuestionIndex: number;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  age: Birthday;
  setAge: React.Dispatch<React.SetStateAction<Birthday>>;
}

type Birthday = { currentDay: {}; currentMonth: {}; currentYear: {} };

export const RegistrationQuestionsContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function RegistrationQuestionsProvider({ children }: Props) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [age, setAge] = useState<Birthday>({
    currentDay: {},
    currentMonth: {},
    currentYear: {},
  });
  const [countryPlaceholder, setCountryPlaceholder] = useState("");
  const [cityPlaceholder, setCityPlaceholder] = useState("");

  return (
    <RegistrationQuestionsContext.Provider
      value={{
        currentQuestionIndex,
        setCurrentQuestionIndex,
        age,
        setAge,
        cityPlaceholder,
        setCityPlaceholder,
        countryPlaceholder,
        setCountryPlaceholder,
      }}
    >
      {children}
    </RegistrationQuestionsContext.Provider>
  );
}
