import { EMPTY, fromEvent, Subject, Subscription } from "rxjs";
import { switchMap, first, map, debounceTime } from "rxjs/operators";

const AVAILABLE_SOUNDS = {
  message: "/app/assets/sounds/Message_Notification_resized.mp3",
  notification: "/app/assets/sounds/shortNotification.mp3",
  error: "/app/assets/sounds/error.wav",
  "videochat.ringtone": "/app/assets/sounds/video-call-tone.mp3",
  "videochat.call-connected": "/app/assets/sounds/call-connected.mp3",
  "videochat.call-disconnected": "/app/assets/sounds/call-disconnected.mp3",
};

type PlaySound = (sound: Sound) => void;

type Sound = keyof typeof AVAILABLE_SOUNDS | "silence";

class AudioPlayer {
  private availableSounds = AVAILABLE_SOUNDS;
  private audioElement: HTMLAudioElement | null = null;
  private sounds$: Subject<Sound>;
  private soundsSub: Subscription;

  public static getInstance() {
    if (typeof window === "undefined") {
      return { playSound: () => {} };
    }

    if (!window._AudioPlayer) {
      window._AudioPlayer = new AudioPlayer();
    }

    return window._AudioPlayer;
  }

  private constructor() {
    this.sounds$ = new Subject();

    this.soundsSub = this.sounds$
      .asObservable()
      .pipe(
        debounceTime(350),
        switchMap((sound) => {
          if (sound === "silence") return EMPTY;

          const cdnUrl = /vidamora/.test(window.location.href)
            ? "https://cdn.vidamora.com"
            : "https://cdn.celibatairesduweb.com";

          this.audioElement = new Audio(cdnUrl + this.availableSounds[sound]);

          return fromEvent(this.audioElement, "canplaythrough").pipe(
            map(() => this.audioElement as HTMLAudioElement),
            first()
          );
        })
      )
      .subscribe({
        next: (audio) => {
          audio.play().catch(() => {});
        },
      });
  }

  playSound = (sound: Sound) => {
    this.sounds$.next(sound);
  };
}

const playSound: PlaySound = AudioPlayer.getInstance().playSound;

export default playSound;

declare global {
  interface Window {
    _AudioPlayer: {
      playSound: (sound: Sound) => void;
    };
  }
}
