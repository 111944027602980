import { FC, useMemo } from "react";
import useTranslation, { Languages } from "../../context/TranslationContext";

const NavBarLogo: FC<{ justifyCenter?: boolean }> = ({
  justifyCenter = false,
}) => {
  const { cdnUrl, lang, isVidamora } = useTranslation();
  const imgUrl = useMemo(
    () => getLogoUrl({ cdnUrl, lang, isVidamora }),
    [cdnUrl, lang, isVidamora]
  );

  return (
    <div
      style={justifyCenter ? { justifyContent: "center" } : {}}
      className="NavBarLogo flex w-48 items-center h-full"
    >
      <img
        data-testid="navbar-logo"
        src={imgUrl}
        className="md:my-2 absolute md:relative object-cover w-24 xs:w-36 md:w-[170px]"
        alt="Logo"
      />
    </div>
  );
};

const getLogoUrl = ({
  cdnUrl,
  lang,
  isVidamora,
}: {
  cdnUrl: string;
  lang: Languages | null;
  isVidamora: boolean;
}) => {
  if (!isVidamora) return `${cdnUrl}/app/assets/cdw-logo-170x50.jpg`;
  switch (lang) {
    // Not Needed for now
    // case 'en':
    //   return `${cdnUrl}/app/assets/vidamora-logo-170x50.png`
    // case 'fr':
    //   return `${cdnUrl}/app/assets/vidamora-logo-170x50.png`
    case "es":
      return `${cdnUrl}/app/assets/vidamora-logo-color-170x50-es.png`;
    default:
      return `${cdnUrl}/app/assets/vidamora-logo-170x50.png`;
  }
};

export default NavBarLogo;
