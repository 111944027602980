import React, { FC } from "react";

type Props = {
  name: string;
  age: number;
};

const CardMemberProfileName: FC<Props> = ({ name, age }) => (
  <div className="CardMemberProfileName grow overflow-hidden text-white text-base md:text-lg font-semibold flex h-fit">
    <p className="truncate">{name}, {age}</p>
  </div>
);

export default CardMemberProfileName;
