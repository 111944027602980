import React, { useContext, useEffect, useRef, useState } from "react";
import FeedbackPopup from "../components/feedbackPopup";
import useUser from "../hooks/useUser";
import api from "../backendAPI";
import { FeedBackFormData } from "../components/feedbackPopup/FeedbackPopup";
import FeedbackNotification from "../components/feedbackPopup/FeedbackNotification";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface IFeedBackContext {
  openFeedback(): void;
  experienceRating: number;
  setExperienceRating: SetState<number>;
  recommendationRating: number;
  setRecommendationRating: SetState<number>;
  comment: string;
  setComment: SetState<string>;
  alreadyCompleted: boolean;
}

const FeedbackContext = React.createContext<IFeedBackContext | null>(null);

export function FeedbackContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false);
  // Form Values
  const [experienceRating, setExperienceRating] = useState(0);
  const [recommendationRating, setRecommendationRating] = useState(0);
  const [comment, setComment] = useState("");
  const alreadyCompleted = useRef(false);
  const { feedback } = useUser();

  useEffect(() => {
    if (
      !feedback ||
      feedback.disableFeedback ||
      !feedback.showFeedback ||
      alreadyCompleted.current
    )
      return;
    setTimeout(openFeedback, 2000);
  }, [feedback]);

  const openFeedback = () => setOpen(true);

  const handleSubmit = async (form: FeedBackFormData) => {
    alreadyCompleted.current = true;
    const response = await api.feedback.postFeedback(form);
  };

  const handleRefuse = async () => {
    setOpen(false);
    const response = await api.feedback.postNotNow();
  };
  const value = {
    openFeedback,
    experienceRating,
    setExperienceRating,
    recommendationRating,
    setRecommendationRating,
    comment,
    setComment,
    alreadyCompleted: alreadyCompleted.current,
  };

  return (
    <FeedbackContext.Provider value={value}>
      {children}
      <FeedbackPopup
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        onRefuse={handleRefuse}
      />
      <FeedbackNotification onClick={openFeedback} />
    </FeedbackContext.Provider>
  );
}

export default function useFeedbackPopup() {
  const instance = useContext(FeedbackContext);
  if (!instance) throw Error("Feedback Popup needs a provider");
  return instance;
}
