import React, { createContext, useContext, useEffect, useState } from "react";
import SayHelloPopup from "../components/sayHelloPopup";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface Context {
  isOpen: boolean;
  currentPageIndex: number;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
  openPopup: (userImage: string) => void;
  closePopup: () => void;
  setImageAlt: React.Dispatch<React.SetStateAction<string>>;
  imageAlt: string;
  promptedUserImage: string;
  setPromptedUserImage: React.Dispatch<React.SetStateAction<string>>;
  greetings: Greeting[];
  shortResponses: Greeting[];
  longResponses: Greeting[];
  customResponses: Greeting[];

  recipientUserID: number;
  recipientUsername: string;

  setRecipientUserID: React.Dispatch<React.SetStateAction<number>>;
  setRecipientUsername: React.Dispatch<React.SetStateAction<string>>;
}

export type Greeting = {
  type: "long" | "short" | "custom";
  greetingId: number;
  message: string;
  image: string;
  Personnaliser_imageId: number;
  personnaliser_message: string;
};

export const SayHiContext = createContext<Context | null>(null);

type Props = {
  children: React.ReactNode;
};

export function SayHiProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(-1);
  const [promptedUserImage, setPromptedUserImage] = useState("");
  const [imageAlt, setImageAlt] = useState("");

  const [recipientUserID, setRecipientUserID] = useState(0);
  const [recipientUsername, setRecipientUsername] = useState("");
  const [shortResponses, setShortResponses] = useState<Greeting[]>([]);
  const [longResponses, setLongResponses] = useState<Greeting[]>([]);
  const [customResponses, setCustomResponses] = useState<Greeting[]>([]);

  const greetings = useSelector(
    (state: RootState) => state._bootstrap.greetings
  );

  useEffect(() => {
    const short: typeof shortResponses = [];
    const long: typeof longResponses = [];
    const custom: typeof customResponses = [];
    greetings.forEach((greeting) => {
      if (greeting.type === "short") short.push(greeting);
      else if (greeting.type === "long") long.push(greeting);
      else custom.push(greeting);
    });
    setShortResponses(short);
    setLongResponses(long);
    setCustomResponses(custom);
  }, [greetings]);

  const openPopup = (userImage: string, skip?: boolean) => {
    setPromptedUserImage(userImage);
    setCurrentPageIndex(-1);
    if (!skip) {
      setIsOpen(true);
    }
  };

  const closePopup = () => {
    setPromptedUserImage("");
    setIsOpen(false);
    setCurrentPageIndex(-1);
  };

  return (
    <SayHiContext.Provider
      value={{
        isOpen,
        openPopup,
        closePopup,
        imageAlt,
        setImageAlt,
        promptedUserImage,
        setPromptedUserImage,
        greetings,
        shortResponses,
        longResponses,
        customResponses,
        setRecipientUsername,
        recipientUsername,
        currentPageIndex,
        setCurrentPageIndex,

        recipientUserID,
        setRecipientUserID,
      }}
    >
      {children}
      <SayHelloPopup
        imageSrc={promptedUserImage}
        imageAlt={imageAlt}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </SayHiContext.Provider>
  );
}

export default function useSayHi() {
  const instance = useContext(SayHiContext);
  if (!instance) throw Error("SayHi requires a valid context provider");
  return instance;
}
