import { NextRouter } from "next/router";
import { throwError, timer, of, Subject, EMPTY } from "rxjs";
import {
  retry,
  concatWith,
  exhaustMap,
  tap,
  catchError,
  delay,
} from "rxjs/operators";

import * as Sentry from "@sentry/browser";

export default function gotoAddPhotoPopup(router: NextRouter) {
  gotoAddPhotoPopup$.next(router);
}

const gotoAddPhotoPopup$ = new Subject<NextRouter>();

gotoAddPhotoPopup$
  .asObservable()
  .pipe(
    tap((router) => {
      router.push("/profile/my-profile");
    }),
    exhaustMap(() =>
      of(null).pipe(
        delay(350),
        exhaustMap(() => tryToScrollToPhotos()),

        retry({ count: 20, delay: 250 }),

        concatWith(
          timer(750).pipe(
            exhaustMap(() => tryToClickAddPhoto()),
            retry({ count: 20, delay: 250 })
          )
        )
      )
    ),
    catchError((err) => {
      Sentry.captureException(err);
      return EMPTY;
    })
  )
  .subscribe();

const tryToScrollToPhotos = () => {
  const MyProfilePhotosContainer = document.querySelector(
    ".MyProfilePhotosContainer"
  );

  if (MyProfilePhotosContainer === null) {
    return throwError(
      () => new Error("Unable to find first element for add photo")
    );
  }

  MyProfilePhotosContainer.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });

  return of(null);
};

const tryToClickAddPhoto = () => {
  const MyProfilePhotoUploaderPlaceholder = document.querySelector(
    ".MyProfilePhotoUploaderPlaceholder"
  ) as HTMLButtonElement | null;

  if (MyProfilePhotoUploaderPlaceholder === null) {
    return throwError(
      () => new Error("Unable to find second element for add photo")
    );
  }

  MyProfilePhotoUploaderPlaceholder.click();

  return of(null);
};
