import { HomeCta } from "../components/welcomeBackSection/WelcomeBackSection";
import { Languages } from "../context/TranslationContext";
import Api, { HTTPResponseWithDataMessage, HTTPResponse, ROOT } from "./Api";

declare global {
  interface Window {
    appVersion: string | null;
  }
}

type LoginWithEmailAndPasswordBody = {
  locale: Languages;
  email: string;
  password: string;
};

type LoginWithTokenBody = {
  locale: Languages;
  token: string;
};

export default class Authentication extends Api {
  public async getLogin(): Promise<
    HTTPResponse & {
      data: {
        infosProfile: UserInfo;
        notifications: LoginNotification;
        orangeBarMessage: string;
        certificationProcess: CertificationProcess;
        searchDefaultParams: {
          ageMax: number;
          ageMin: number;
          cityId: number;
          countryId: number;
          departementId: number;
          displayCity: string;
          distance: number;
          hasPictureOnly: boolean;
          isCertifiedOnly: boolean;
          lastVisite: number;
          lat: string;
          lng: string;
          provinceId: number;
          sexeId: number;
          sexeRechercheId: number;
          heightRange: number[];
        };
        blueContainerContent?: HomeCta;
      };
    }
  > {
    return this.get("/login");
  }

  public async postLogin(
    body: LoginWithEmailAndPasswordBody | LoginWithTokenBody
  ): Promise<
    HTTPResponse & {
      data: {
        tokens: {
          access_token: string;
          refresh_token: string;
        };
        preferredDisplayLanguage: Languages;
      };
    }
  > {
    const response = await fetch(ROOT + "/login", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!window.localStorage.getItem("x-app-version")) {
      window.localStorage.setItem(
        "x-app-version",
        response.headers.get("x-app-version")!
      );
    }

    const jsonResponse = await response.json();

    return jsonResponse;
  }

  public async postLogout(): Promise<HTTPResponse & { data: { url: string } }> {
    return this.post("/logout", {});
  }
}

export type LoginNotification = {
  haveNewMessage: boolean;
  haveNewVisite: boolean;
  haveNewWhoWantsToMeetMe: boolean;
  haveNewPerfectMatch: boolean;
  haveNewNotifications: boolean;
  haveNewGreetings: boolean;
  haveNewProfileAction: boolean;
};

export type CertificationProcess = {
  chosenMethod: "photos" | "webcam";
  alreadySubmittedPhotos: boolean;
  webcamImageModele: string;
  submittedImageWebcam: string;
  submittedImagePhoto1: string;
  submittedImagePhoto2: string;
};

export type UserInfo = {
  warnings: Warning[];
  userid: number;
  NomUsager: string;
  firstname: string;
  mainImage: string;
  preferredDisplayLanguage: Languages;
  isCertified: boolean;
  isProfileCreated: boolean;
  isVideoChatEnabled: boolean;
  isVIP: boolean;
  isPremiumNoAds: boolean;
  EmailConfirmed: boolean;
  role: "moderator" | "admin" | "";
  distanceUnit: "km" | "miles";
  initSearchPageParamDefault: [];
  showPopupRuleWhenAddPhoto: boolean;
  searchDefaultParams: {
    sexeId: number;
    sexeRechercheId: number;
    ageMin: number;
    ageMax: number;
    countryId: number;
    provinceId: number;
    departementId: number;
    cityId: number;
    selectedPlaceName: string;
    distance: number;
    lastVisite: string;
    hasPictureOnly: boolean;
    isCertifiedOnly: boolean;
    lat: number;
    lng: number;
    alcoolId: string[];
    cheveuxId: string[];
    enfantActuelId: string[];
    enfantDesiresId: string[];
    etatCivileId: string[];
    ethnieId: string[];
    fumeurId: string[];
    occupationId: string[];
    heightRange: number[];

    relationshipType: string[];
    religionId: string[];
    signeZodiaqueId: string[];

    voitureId: string[];
    yeuxId: string[];

    drogueId: string[];

    AppPhysiqueId: string[];
  };
  DateMeDefaultParams: {
    ageMin: number;
    ageMax: number;
    genderId: number;
    distance: number;
  };
  OnlineDefaultParams: {
    ageMax: number;
    ageMin: number;
    distance: number;
    genderId: number;
  };
};

type Warning =
  | ""
  | "PROFILE_WITHOUT_IMAGE"
  | "NO_ACTIVITIES_MUSICS_QUALITIES_SELECTED"
  | "INFOS_PROFILE_REACTIVATED"
  | "PROFILE_EMAIL_NOT_CONFIRMED"
  | "PROFILE_NOT_CREATED";
