import { useEffect } from "react";

export default function InertRemover() {
  useEffect(() => {
    const targetNode = document.getElementById("__next");
    if (!targetNode) return;

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.attributeName === "inert" &&
          targetNode.hasAttribute("inert")
        ) {
          targetNode.removeAttribute("inert");
        }
      }
    });

    observer.observe(targetNode, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
}
