import { FC, useContext } from "react";
import { Member } from "../../backendProxies";

import usePopup from "../../hooks/useConfirmDialog";

import { ReportPopupContext } from "../../context/ReportPopupContext";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  member: Member;
  onBlock?: () => void;
  onReport?: () => void;
};

const ProfileBlock: FC<Props> = ({ member, onBlock, onReport }) => {
  const { i } = useContext(TranslationContext);
  const { openPopup } = usePopup();

  const { openPopup: openReportPopup } = useContext(ReportPopupContext);

  const block = () => {
    if (onBlock) return onBlock();
    openPopup({
      description: i("Do you really want to block this user?[username]", {
        username: "\n" + member.info.userName,
      }),
      confirmText: i("Yes"),
      confirmAction: () => {
        member.addToBlockList();
      },
    });
  };

  const report = () => {
    if (onReport) return onReport();
    openReportPopup(+member.info.profilId, member.info.userName, "profile");
  };

  return (
    <div
      aria-label="button"
      className="ProfileBlock flex bg-white shadow-custom-shadow rounded py-5 sm:pl-[19px] pl-[16px]"
    >
      <div className="fill-custom-light-blue min-w-[23px] w-[23px] min-h-[23px] h-[23px]">
        <img
          src="/app/assets/cancelIconLightBlue.svg"
          alt={profileBlockIconAlt}
          className="fill-custom-light-blue"
        />
      </div>
      <div className="text-custom-blue pl-[11px] flex items-center font-normal">
        <p>
          <span
            data-testid="block-profile-button"
            onClick={block}
            className="underline cursor-pointer"
          >
            {i("Block")}
          </span>{" "}
          {i("or")}{" "}
          <span onClick={report} className="underline cursor-pointer">
            {i("Report")}
          </span>{" "}
          {i("this member")}
        </p>
      </div>
    </div>
  );
};

export const profileBlockIconAlt = "Block or report this member";

export default ProfileBlock;
