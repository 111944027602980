import React, { FC } from "react";

type Props = {};

const LinkIcon: FC<Props> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#7F7F7F" />
      <path
        d="M15.0719 20.4133C14.1455 21.3741 12.637 21.4628 11.6046 20.6169C10.9898 20.0844 10.6757 19.2838 10.7645 18.4751C10.8414 17.8789 11.1199 17.327 11.5539 16.9111L13.2318 15.2315C13.5734 14.8896 13.5734 14.3355 13.2318 13.9937C12.89 13.6519 12.3361 13.6519 11.9943 13.9937L10.352 15.6371C9.60341 16.3592 9.13131 17.3208 9.01773 18.3549C8.8056 20.684 10.5211 22.7441 12.8494 22.9563C14.0998 23.0702 15.3362 22.6231 16.2247 21.7357L17.9796 19.9808C18.3212 19.639 18.3212 19.0848 17.9796 18.743C17.6379 18.4013 17.0839 18.4013 16.7422 18.743L15.0719 20.4133Z"
        fill="white"
      />
      <path
        d="M21.3442 9.84114C19.602 8.56071 17.1827 8.76145 15.6752 10.3115L14.0235 11.9643C13.6819 12.3061 13.6819 12.8603 14.0235 13.2021C14.3652 13.5438 14.9192 13.5438 15.2609 13.2021L16.9388 11.5254C17.8449 10.588 19.3109 10.4824 20.3419 11.2803C21.4031 12.1507 21.5579 13.7168 20.6878 14.7783C20.6369 14.8405 20.583 14.9001 20.5263 14.957L18.7713 16.7125C18.4297 17.0543 18.4297 17.6084 18.7713 17.9503C19.1131 18.292 19.667 18.292 20.0088 17.9503L21.7637 16.1948C23.4158 14.5368 23.4116 11.8531 21.7541 10.2004C21.6254 10.072 21.4884 9.95199 21.3442 9.84114Z"
        fill="white"
      />
      <path
        d="M14.0236 16.7137L16.7426 13.9938C17.0844 13.6519 17.6385 13.6519 17.9803 13.9938C18.322 14.3357 18.322 14.8899 17.9803 15.2318L15.2612 17.9517C14.9195 18.2936 14.3654 18.2936 14.0236 17.9517C13.6818 17.6098 13.6818 17.0555 14.0236 16.7137Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkIcon;
