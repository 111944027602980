import { FC, useContext, Dispatch } from "react";
import { Dialog } from "@headlessui/react";

import useConfirmDialog from "../../hooks/useConfirmDialog";

import ButtonBGBlue from "../buttonBGBlue";
import ButtonBlueBGWhite from "../buttonBlueBGWhite";

import Popup from "../popup";
import { TranslationContext } from "../../context/TranslationContext";

import { IconDescription } from "../../context/ConfirmDialogContext";
import StyledPopup from "../styledPopup";

const ConfirmDialog: FC = () => {
  const { i } = useContext(TranslationContext);
  const {
    closePopup,
    isOpen,
    confirmAction,
    description,
    confirmText,
    title,
    action,
    iconDescription,
  } = useConfirmDialog();

  const handleConfirm = () => {
    confirmAction();
    closePopup();
  };

  return (
    <div className="ConfirmDialog">
      <StyledPopup open={isOpen} onClick={closePopup}>
        <ConfirmDialogContent
          title={title}
          action={action}
          description={description}
          confirmText={confirmText}
          iconDescription={iconDescription}
          handleConfirm={handleConfirm}
          closePopup={closePopup}
        />
      </StyledPopup>
    </div>
  );
};

export function ConfirmDialogContent({
  title,
  action,
  description,
  confirmText,
  iconDescription,
  handleConfirm,
  closePopup,
}: {
  title?: string;
  action?: string;
  description: string;
  confirmText: string;
  iconDescription: IconDescription;
  handleConfirm: () => void;
  closePopup: () => void;
}) {
  const { i } = useContext(TranslationContext);
  if (!description) {
    throw new Error("Description is required for ConfirmDialogContent");
  }

  return (
    <div className="flex w-full justify-center items-center p-4">
      <div className="sm:max-w-[378px] min-w-[350px] px-6">
        <div className="mx-auto flex items-center justify-center h-20 w-20">
          <img
            src={
              iconDescription.iconSrc
                ? iconDescription.iconSrc
                : "/app/assets/cancelIcon.svg"
            }
            className="h-full w-full"
            alt={
              iconDescription.iconAlt ? iconDescription.iconAlt : "cancel icon"
            }
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div>
            <Dialog.Description className="text-xl text-black font-semibold">
              {title || i("Are you sure?")}
            </Dialog.Description>
          </div>
          <div className="mt-2">
            <div>
              {action && <p className="text-lg text-black mb-4">{action}</p>}
              <Dialog.Description
                className="text-lg text-black max-h-20 text-ellipsis overflow-hidden"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {description}
              </Dialog.Description>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse sm:flex-row space-y-3 space-y-reverse sm:space-y-0 sm:space-x-3">
          <ButtonBlueBGWhite
            innerText={i("Cancel")}
            onClick={closePopup}
            testid="delete-confirm-cancel-button"
          />
          <ButtonBGBlue
            innerText={confirmText}
            onClick={handleConfirm}
            testid="delete-confirm-confirm-button"
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmDialog;
