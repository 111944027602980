import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";

import useUser from "../../hooks/useUser";
import useCertification from "../../hooks/useCertification";

import * as Sentry from "@sentry/browser";

import gotoAddPhotoPopup from "../../utils/gotoAddPhotoPopup";
import goToMailSettings from "../../utils/goToMailSettings";
import useCompleteProfileProgression from "../../hooks/useCompleteProfileProgression";
import api from "../../backendAPI";

export type ServerAction =
  | "certify-profile"
  | "my-profile"
  | "confirm-registration"
  | "vip-subscription"
  | "noads-subscription"
  | "contact-us"
  | "contact"
  | "blocked-members"
  | "delete-profile"
  | "conditions"
  | "mail-settings";

const NavBarBanner: FC = () => {
  const { topBannerVisibility, setTopBannerVisibility } = useUser();

  const { topBarMessage } = useCompleteProfileProgression();

  const [showNothing, setShowNothing] = useState(false);

  const { start } = useCertification();

  const router = useRouter();

  useEffect(() => {
    const serverActionHandler = async (event: any) => {
      if (!event.target.dataset.serverAction) return;

      const serverAction = event.target.dataset.serverAction as ServerAction;

      const promoCategory = event.target.dataset.serverPromoCategory;

      if (promoCategory) {
        const type = event.target.dataset.serverPromoType ?? "custom";
        await api.general.post("/promote/searchResult", {
          category: promoCategory,
          type,
        });
      }

      switch (serverAction) {
        case "certify-profile":
          start();
          break;
        case "vip-subscription":
          router.push("/settings?tab=vip");

          break;
        case "noads-subscription":
          return router.push("/settings?tab=ad-free");
        case "my-profile":
          gotoAddPhotoPopup(router);

          break;

        case "confirm-registration":
          router.push("/confirm-email");
          break;

        case "contact-us":
        case "contact":
          router.push("/contact-us");
          break;

        case "blocked-members":
          router.push("/settings?tab=blocked-members");
          break;

        case "delete-profile":
          router.push("/settings?tab=account");
          break;

        case "conditions":
          router.push("/terms-and-conditions");
          break;
        case "mail-settings":
          return goToMailSettings(router);
        default:
          Sentry.captureException(
            JSON.stringify({
              message: `Invalid server action: ${serverAction}`,
            })
          );
      }
    };

    document.addEventListener("click", serverActionHandler);

    return () => document.removeEventListener("click", serverActionHandler);
  }, []);

  useEffect(() => {
    function showNothing() {
      return /messages/.test(window.location.href);
    }

    if (showNothing()) {
      setShowNothing(true);
    }
  }, []);

  if (showNothing) {
    return (
      <div className="NavBarBanner bg-gradient-to-r from-custom-gradient-orange to-custom-red p-1 md:p-2"></div>
    );
  }
  if (!topBannerVisibility) {
    return (
      <div className="NavBarBanner bg-gradient-to-r from-custom-gradient-orange to-custom-red p-1 md:p-2"></div>
    );
  }
  return (
    <>
      <div className="NavBarBanner bg-gradient-to-r from-custom-gradient-orange to-custom-red text-white p-1 text-center">
        {
          <p
            className="text-sm mx-5"
            dangerouslySetInnerHTML={{ __html: topBarMessage }}
          ></p>
        }

        <div
          onClick={() => setTopBannerVisibility(false)}
          className="absolute top-2 right-2 h-3 w-3 cursor-pointer"
        >
          <img alt="Delete icon" src="/app/assets/cardMember/deleteIcon.svg" />
        </div>
      </div>
    </>
  );
};

export default NavBarBanner;
