import React, { FC, useContext } from "react";
import { useRouter } from "next/router";
import { Menu } from "@headlessui/react";
import classNames from "../../utils/twClassNames";

import useUser from "../../hooks/useUser";
import useTranslation, {
  TranslationContext,
} from "../../context/TranslationContext";
import Link from "next/link";
import useAuth from "../../hooks/useAuth";

type Props = {
  hasNotifications: boolean;
  open: boolean;
  showHamburgerMenu: boolean;
  toggleDrawer: (shouldOpen: boolean) => void;
};

const NavBarIcons: FC<Props> = ({ open, showHamburgerMenu, toggleDrawer }) => {
  const router = useRouter();
  const { i } = useContext(TranslationContext);
  const { userData, notifications } = useUser();
  const { isAuth, role } = useAuth();

  return (
    <>
      <div className="NavBarIcons flex items-center justify-end h-full w-full">
        {role !== "" ? (
          <div className="hidden sm:block w-full">
            <label htmlFor="search" className="sr-only">
              {i("Search")}
            </label>
            <Link href="/search-by-username">
              <a
                className="relative flex w-full justify-end cursor-pointer"
                data-testid="navbar-search-icon-container"
                id="navbar-search-icon-container"
              >
                <img
                  className="mr-2"
                  src="/app/assets/searchUser.png"
                  alt="Search icon"
                />
              </a>
            </Link>
          </div>
        ) : null}
        <div className="block sm:hidden w-full">
          <label htmlFor="search" className="sr-only">
            {i("Search")}
          </label>
          <Link href="/search">
            <a
              className="relative flex w-full justify-end cursor-pointer"
              data-testid="navbar-search-icon-container"
              id="navbar-search-icon-container"
            >
              <img
                className="mr-2"
                src="/app/assets/searchIcon.svg"
                alt="Search icon"
              />
            </a>
          </Link>
        </div>
        {showHamburgerMenu && (
          <Link href="/notifications">
            <a
              data-testid="bell-notification"
              type="button"
              className={
                "justify-center flex-shrink-0 w-6 flex items-center bg-white mx-1 ml-[10px] mr-[10px] md:ml-[30px] md:mr-[30px] xl:ml-[28px] lg:mr-[27px] focus:outline-none relative tooltip"
              }
            >
              <span className="sr-only">{i("View notifications")}</span>
              <img
                style={{ maxWidth: "22px" }}
                src="/app/assets/bellicon-2.svg"
                alt="Notifications icon"
              />
              <p className="tooltiptext w-28 top-8 h-8">{i("Notifications")}</p>
              <div
                className={
                  notifications && notifications.haveNewNotifications
                    ? "border-2 border-white rounded-full mb-3 relative"
                    : "border-2 border-transparent rounded-full mb-3 relative"
                }
              >
                <div
                  className={classNames(
                    "border-2 h-3 w-3 rounded-full absolute -top-[9px] -left-[13px]",
                    notifications && notifications.haveNewNotifications
                      ? "border-white bg-custom-orange"
                      : "border-transparent"
                  )}
                ></div>
              </div>
            </a>
          </Link>
        )}
        <button
          data-testid="navbar-profile-icon-container"
          onClick={() => router.push("/profile/my-profile")}
          type="button"
          className={classNames(
            isAuth && "xl:hidden",
            "flex-shrink-0 flex items-center bg-white text-gray-400 rounded-full hover:text-gray-500 focus:outline-none mr-[10px] md:mr-[30px] xl:mr-[27px]"
          )}
        >
          <span className="sr-only">{i("View notifications")}</span>
          <div className="w-7 text-custom-black relative">
            <img src="/app/assets/userIcon.svg" alt="Your profile picture" />
            {notifications && notifications.haveNewProfileAction && (
              <div className="border-2 border-white rounded-full mb-3 absolute top-[6px] -right-[2px]">
                <div className="border-2 h-3 w-3 rounded-full absolute -top-[9px] -left-[13px] border-white bg-custom-orange"></div>
              </div>
            )}
          </div>
        </button>

        {showHamburgerMenu && (
          <div className="flex items-center xl:hidden ">
            {/* Mobile menu button */}
            <button className="inline-flex w-5 items-center justify-center rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
              <span className="sr-only">{i("Open main menu")}</span>
              {open ? (
                <div
                  onClick={() => {
                    toggleDrawer(false);
                  }}
                  data-testid="navbar-close-mobile-menu"
                  className="w-10 h-10 text-custom-black font-bold text-2xl pt-2 z-[60]"
                >
                  <img src="/app/assets/deleteIcon.svg" alt="Delete icon" />
                </div>
              ) : (
                <img
                  onClick={() => toggleDrawer(true)}
                  src="/app/assets/menuIcon.svg"
                  data-testid="navbar-open-mobile-menu"
                  className="block h-6 w-6 text-custom-black font-bold z-10"
                  aria-hidden="true"
                  alt="Open mobile menu"
                />
              )}
            </button>
          </div>
        )}
        {showHamburgerMenu && (
          <div className="hidden xl:flex xl:items-center">
            {/* Profile dropdown */}
            <ProfileDropdown />
          </div>
        )}
      </div>
    </>
  );
};

export default NavBarIcons;

export const ProfileDropdown = () => {
  const { i } = useTranslation();
  const { userData, notifications } = useUser();

  return (
    <Menu as="div" className="relative flex-grow">
      <div className="flex items-center">
        <Menu.Button className="bg-white rounded-full w-8 flex text-sm focus:outline-none items-center">
          <span className="sr-only">{i("Open user menu")}</span>
          <Link href="/profile/my-profile">
            <a type="button" className="relative">
              <img
                className="relative h-8 w-8 rounded-full"
                src={
                  userData && userData.mainImage
                    ? userData.mainImage
                    : "https://cdn.celibatairesduweb.com/img/Vignettes/AucuneImageHomme.gif"
                }
                alt=""
              />

              {userData.isVIP == true && userData.isPremiumNoAds == true && (
                <>
                  <img
                    src="/app/assets/vipcard.png"
                    className="absolute -top-3 -right-4 w-[22px]"
                  />
                  <img
                    src="/app/assets/noads.svg"
                    className="absolute -top-3 -right-10 w-[22px]"
                  />
                </>
              )}
              {userData.isVIP == true && userData.isPremiumNoAds == false && (
                <img
                  src="/app/assets/vipcard.png"
                  className="absolute -top-3 -right-4 w-[22px]"
                />
              )}
              {userData.isPremiumNoAds == true && userData.isVIP == false && (
                <img
                  src="/app/assets/noads.svg"
                  className="absolute -top-3 -right-4 w-[22px]"
                />
              )}
            </a>
          </Link>
        </Menu.Button>
        <Link href="/profile/my-profile">
          <a type="button" className="flex">
            <p className="whitespace-nowrap pl-[2px] text-base font-bold text-custom-blue cursor-pointer max-w-[160px] truncate">
              {userData && userData.NomUsager ? userData.NomUsager : ""}
            </p>
          </a>
        </Link>
      </div>
    </Menu>
  );
};
