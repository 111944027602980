import { FC, useState, useContext } from "react";
import api from "../../backendAPI";
import useMessenger from "../../hooks/useMessenger";
import useToast from "../../hooks/useToast";
import useTranslation from "../../context/TranslationContext";
import MessageChatBoxImageUpload from "../messageChatBoxImageUpload";
import MessageChatBoxSend from "../messageChatBoxSend";
import MessageChatBoxText from "../messageChatBoxText";
import { useEffect } from "react";
import { LiveAlert, SocketIoContext } from "../../context/SocketIoContext";

const MessageChatBox: FC<{
  disableLiveAlerts?: boolean;
  isGreetings?: boolean;
  onSend?: (message: string) => void;
}> = ({ isGreetings, onSend, disableLiveAlerts }) => {
  const {
    greetings,
    currentConversation,
    sendMessage,
    currentGreeting,
    setMyLastMessageHasBeenViewed,
    fetchGreetings,
    fetchConversations,
    chatMessage,
    setChatMessage,
  } = useMessenger();
  const { i } = useTranslation();

  const { activeSocket } = useContext(SocketIoContext);

  const [
    triggeredLastMessageHasBeenViewed,
    setTriggeredLastMessageHasBeenViewed,
  ] = useState(false);

  useEffect(() => {
    if (!activeSocket) return;

    const handleReceiveNewMessageFromSocket = (event: LiveAlert) => {
      if (event.category !== "message") return;

      if (!event.message) return;

      setTriggeredLastMessageHasBeenViewed(false);
    };

    activeSocket.on("notification", handleReceiveNewMessageFromSocket);

    return () => {
      activeSocket.off("notification", handleReceiveNewMessageFromSocket);
    };
  }, [activeSocket]);

  const { openToast } = useToast();

  const preventInput = greetings.isActiveConversation
    ? false
    : currentConversation.headerInfos?.Fraudeur ||
      currentConversation.headerInfos?.disableControls ||
      currentConversation.conversationInfo?.isUserDeleted;

  const handleSendMessage = () => {
    if (chatMessage.length === 0) return;

    if (greetings.isActiveConversation && !isGreetings) {
      return sendGreeting();
    }

    if (onSend) {
      onSend(chatMessage);
    } else if (currentConversation.conversationInfo) {
      sendRegularMessage();
    }
  };

  const sendGreeting = () => {
    const { infos } = currentGreeting;
    if (!infos) return;
    api.messages
      .postGreetingMessage({
        discussionId: -1,
        userId: infos.userId,
        username: infos.userName,
        message: chatMessage,
        greetingId: infos.greetingId,
      })
      .then((response) => {
        if (response.http_code == 200) {
          const isDesktop = window.innerWidth > 1024;

          if (isDesktop) {
            setChatMessage("");
            fetchGreetings({});
            fetchConversations({});

            setTimeout(() => {
              const convoToClick = document.querySelector(
                `[data-discussion-id="${response.data.discussionId}"] > button`
              ) as HTMLElement | null;

              if (convoToClick) {
                convoToClick.click();
              }
            }, 1250);
          } else {
            window.location.reload();
          }
        } else {
          openToast({
            type: "error",
            title: i("Error"),
            description: response.message,
          });
        }
      });
  };

  const sendRegularMessage = () => {
    sendMessage(chatMessage);
    setMyLastMessageHasBeenViewed(false);
    setChatMessage("");
  };

  return (
    <div className="MessageChatBox flex flex-row w-full max-w-full space-x-5 items-center">
      {!isGreetings && (
        <MessageChatBoxImageUpload preventImageUpload={preventInput} />
      )}
      <MessageChatBoxText
        disableLiveAlerts={disableLiveAlerts}
        isGreetings={isGreetings}
        onSendClick={handleSendMessage}
        setChatMessage={setChatMessage}
        chatMessage={chatMessage}
        preventSubmitting={preventInput}
        triggeredLastMessageHasBeenViewed={triggeredLastMessageHasBeenViewed}
        setTriggeredLastMessageHasBeenViewed={
          setTriggeredLastMessageHasBeenViewed
        }
      />
      <MessageChatBoxSend
        onSendClick={handleSendMessage}
        chatMessage={chatMessage}
      />
    </div>
  );
};

export default MessageChatBox;
