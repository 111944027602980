import {
  FC,
  useEffect,
  useRef,
  useContext,
  useState,
  Fragment,
  UIEvent,
} from "react";
import MessageConvoTimestamp from "../messageConvoTimestamp";
import MessageBubble from "../messageBubble";

import InfiniteScroller from "../infiniteScroller";

import useMessenger from "../../hooks/useMessenger";
import { SocketIoContext, LiveAlert } from "../../context/SocketIoContext";
import { TranslationContext } from "../../context/TranslationContext";
import { IMessage, MessageType } from "../../backendAPI/Messages";
import useWindowSize from "../../hooks/useWindowSize";
import { timer } from "rxjs";
import { tap, delay } from "rxjs/operators";

// todo test with keys is problematic but ok for now

/*
  Component to display a list of messages sorted from newest to oldest.

  A spacing is added between each messages depending on the message type and the date of the message.
  Ex. Spacing between an incoming and an outgoing message will be bigger than between two incoming messages.
  Also, we add a MessageConvoTimestamp component between messages that have a different date.

  If used in the popup, the messages prop is going to be true and passed instead of getting 
  the current conversation from redux
*/

type Props = {
  messages?: IMessage[];
  allowModifications?: boolean;
};

const MessageActiveConvo: FC<Props> = ({
  messages,
  allowModifications = true,
}) => {
  return (
    <div className="MessageActiveConvo h-full">
      <Conversation
        messages={messages}
        allowModifications={allowModifications}
      />
    </div>
  );
};

const Conversation: FC<{ messages?: any[]; allowModifications?: boolean }> = ({
  messages,
  allowModifications = true,
}) => {
  const { i } = useContext(TranslationContext);
  const {
    currentConversation,
    fetchMessages,
    setMyLastMessageHasBeenViewed,
    shouldScrollBot,
    setShouldScrollBot,
  } = useMessenger();
  const { activeSocket } = useContext(SocketIoContext);
  const [otherMemberIsTyping, setOtherMemberIsTyping] = useState(false);

  const { windowWidth } = useWindowSize();

  const messageActiveConvoRef = useRef<HTMLDivElement | null>(null);

  const messengerBotRef = useRef<HTMLDivElement | null>(null);

  const scrollPosition = useRef<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 150);
  }, [currentConversation.items.length]);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (!isLoading) {
      event.currentTarget.scroll(0, event.currentTarget.scrollTop);
    } else {
      event.currentTarget.scroll(
        0,
        Math.max(scrollPosition.current, event.currentTarget.scrollTop)
      );
    }
  };

  useEffect(() => {
    if (!shouldScrollBot) return;

    timer(150)
      .pipe(
        tap(() => {
          const messageActiveConvo = messageActiveConvoRef.current;

          if (messageActiveConvo) {
            // https://stackoverflow.com/questions/37914983/overflow-scrolling-not-working-on-position-fixed-element-ios
            // need to alter size of the element to tell safari ios to get right size of the element (dynamic js added element is not working as expected)
            messageActiveConvo.style.borderTop = "1px solid white";
          }
        }),
        delay(150),
        tap(() => {
          const messageActiveConvo = messageActiveConvoRef.current;

          const textArea = document.getElementById(
            "messenge-textarea"
          ) as HTMLTextAreaElement | null;

          if (textArea === null || messageActiveConvo === null) return;

          const h = messageActiveConvo.getBoundingClientRect().height;

          messageActiveConvo.style.borderTop = "0.5px solid white";

          messageActiveConvo.scroll(0, 0);

          if (windowWidth ?? 0 > 600) {
            textArea.focus();
          }

          setShouldScrollBot(false);
        })
      )
      .subscribe();
  }, [shouldScrollBot]);

  useEffect(() => {
    if (!activeSocket) return;

    setOtherMemberIsTyping(false);

    const messengerAlertHandler = (event: LiveAlert) => {
      if (event.category !== "message") return; // not our business to handle other alerts

      if (!currentConversation.conversationInfo) return; // prevent poking current convo that is not defined

      if (
        event.discussionId != currentConversation.conversationInfo.discussionId
      )
        return;

      if (event.message || event.message_image) {
        setOtherMemberIsTyping(false);
      }

      if (event.isTyping) {
        setOtherMemberIsTyping(true);
      }

      if (event.stopTyping) {
        setOtherMemberIsTyping(false);
      }

      if (event.isReading) {
        setMyLastMessageHasBeenViewed(true);
      }
    };

    activeSocket.on("notification", messengerAlertHandler);

    return () => {
      activeSocket.removeListener("notification", messengerAlertHandler);
    };
  }, [activeSocket]);

  if ((!currentConversation || !currentConversation.items) && !messages) {
    return <></>;
  }

  const timeStampedMessages = (messages ||
    currentConversation.items) as IMessage[];

  const isLastMessageOutgoing =
    currentConversation.items[0]?.type === "outgoing" || false;

  return (
    <div
      id="active-convo-container"
      data-convo-userid={currentConversation?.conversationInfo?.userId || ""}
      className="flex flex-col-reverse h-full px-3 sm:px-5 relative overflow-x-hidden custom-scrollbar"
      ref={messageActiveConvoRef}
      onScroll={handleScroll}
    >
      <p
        ref={messengerBotRef}
        id="messenger-bottom"
        className="h-4 border-white border-1 w-full invisible"
      >
        .
      </p>
      <div className="flex">
        {otherMemberIsTyping && (
          <div className="relative flex items-center w-full">
            <p className="text-custom-light-gray-600 w-fit text-sm sm:text-base">
              {`${
                currentConversation?.conversationInfo?.prenom ||
                currentConversation?.conversationInfo?.userName
              } ${i("is typing")}`}
            </p>
            <div className="mx-1 ml-2 mt-1 h-1 p-1 rounded-full bg-custom-light-gray-600/50 animate-pulse" />
            <div className="mr-1 mt-1 h-1 p-1 rounded-full bg-custom-light-gray-600/50 animate-pulse" />
            <div className="mr-1 mt-1 h-1 p-1 rounded-full bg-custom-light-gray-600/50 animate-pulse" />
          </div>
        )}{" "}
      </div>
      {currentConversation?.headerInfos?.Fraudeur && (
        <div className="p-2 mt-2 text-center text-custom-black font-semibold text-2xl">
          <img
            src="/app/assets/reportIcon.svg"
            alt="warning sign"
            className="w-12 m-auto mt-5 mb-5"
          />

          <p className="text-lg">
            {" "}
            {i("In order to protect you,")}
            <span className="underline px-2">
              <span className="font-bold">
                {currentConversation?.conversationInfo?.userName}
              </span>{" "}
              {i("was deleted")}
            </span>
            {i("by our team of moderators.")} <br />
            {i(
              "It has been reported as fraudulent. It is therefore impossible to reply to his message. We are sorry for this inconvenience."
            )}
          </p>
        </div>
      )}

      {currentConversation?.headerInfos?.discussionDeletedByDestinataire &&
        !currentConversation?.headerInfos?.disableControls && (
          <div className="p-2 mt-2 text-center text-custom-black font-semibold text-lg">
            <img
              src="/app/assets/Icons/trash.svg"
              alt="warning sign"
              className="w-10 m-auto mt-5 mb-3"
            />

            <p>{i("The discussion was deleted by the recipient.")}</p>
          </div>
        )}
      {currentConversation?.headerInfos?.disableControls &&
        currentConversation?.headerInfos?.Fraudeur === false &&
        currentConversation?.conversationInfo?.discussionId != -1 &&
        currentConversation?.conversationInfo?.discussionId != -2 && (
          <div className="p-2 mt-2 text-center text-custom-black font-semibold text-2xl">
            <img
              src="/app/assets/reportIcon.svg"
              alt="warning sign"
              className="w-12 m-auto mt-5 mb-5"
            />
            <p className="text-lg">
              <span className="underline px-2">
                {currentConversation?.conversationInfo?.userName}{" "}
                {i("deactivated their profile")}
              </span>
              <br />
              {i(
                "It is impossible to visit their profile and reply to this message."
              )}
            </p>
          </div>
        )}
      {isLastMessageOutgoing &&
        (currentConversation?.headerInfos?.myLastMessageHasBeenRead ||
          currentConversation?.myLastMessageHasBeenViewed) && (
          <div className="relative w-full text-right right-0 justify-end flex items-center">
            <img
              src="/app/assets/blueCheckmark.svg"
              alt="checkmark"
              className="h-[12px] m-1"
            />
            <p className="pl-1 text-custom-light-gray-600 text-sm sm:text-base">
              {i("The message has been read")}
            </p>
          </div>
        )}

      {timeStampedMessages.length > 0 &&
        timeStampedMessages.map((message, index) => {
          // If date not the same as next one or last element
          const date = i(message.date ?? "TODAY");
          const previousDate =
            timeStampedMessages?.[index + 1] &&
            i(timeStampedMessages?.[index + 1]?.date ?? "TODAY");
          const showDate =
            date !== previousDate || timeStampedMessages.length === index + 1;
          return (
            <Fragment key={`${message.messageId}`}>
              <MessageBubble
                index={index}
                {...message}
                allowModifications={allowModifications}
              />
              {showDate ? (
                <MessageConvoTimestamp date_tz={date} />
              ) : (
                <SpaceToAddUnderMessage
                  type={message.type}
                  previousMessageType={timeStampedMessages[index + 1]?.type}
                />
              )}
            </Fragment>
          );
        })}
      {currentConversation.hasMore &&
        !currentConversation.isLoading &&
        !currentConversation.isLoadingMore &&
        currentConversation.conversationInfo?.discussionType === "user" &&
        !isLoading && (
          <div className="translate-y-28 h-10">
            <InfiniteScroller
              rootMargin={0}
              setShouldLoad={() => {
                if (!currentConversation.conversationInfo) return;
                setIsLoading(true);
                scrollPosition.current =
                  messageActiveConvoRef.current?.scrollTop || 0;
                fetchMessages({
                  increasePageSize: true,
                  conversationId:
                    currentConversation.conversationInfo.discussionId,
                  fromLiveAlert: false,
                });
              }}
            />
          </div>
        )}
    </div>
  );
};

type SpaceToAddUnderMessageProps = {
  type: MessageType;
  previousMessageType: MessageType | null;
};

const SpaceToAddUnderMessage: FC<SpaceToAddUnderMessageProps> = ({
  type,
  previousMessageType,
}) => {
  if (type === previousMessageType) {
    return <div className="h-[5px] min-h-[5px]" />;
  } else {
    return <div className="h-[20px] min-h-[20px]" />;
  }
};

export default MessageActiveConvo;
