import Api, { HTTPResponse, ROOT } from "./Api";
import { CertificationProcess, UserInfo } from "./Authentication";

export default class Registration extends Api {
  public async postRegistration(body: {
    username: string;
    email: string;
    password: string;
    password2: string;
  }): Promise<
    HTTPResponse & {
      data: {
        message: string;
        tokens: {
          access_token: string;
          refresh_token: string;
        };
        infosProfile: UserInfo;
        notifications: {
          haveNewMessage: boolean;
          haveNewVisite: boolean;
          haveNewWhoWantsToMeetMe: boolean;
          haveNewPerfectMatch: boolean;
          haveNewNotifications: boolean;
          haveNewGreetings: boolean;
          haveNewProfileAction: boolean;
        };
        orangeBarMessage: string;
        certificationProcess: CertificationProcess;
      };
    }
  > {
    const response = await fetch(ROOT + "/registration", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ ...body, locale: this.getLocale() }),
    });

    const jsonResponse = await response.json();

    return jsonResponse;
  }

  public async postRegistrationProfile(
    body: {
      cmbpays_autocomplet: string;
      txtville: string;
      txtcityid: number;
      autocomplete: string;
      lat: string;
      lng: string;
      locality: string;
      country: string;
      cmbJour: number;
      cmbMois: number;
      cmbAnnee: number;
      optsexe: number;
      cmbSexeRechercher: number;
      cmbRecherche: string;
      txtprenom: string;
      cmbAnimaux: string;
      cmbTaille: number;
      cmbAppPhys: number;
      cmbCheveux: number;
      cmbYeux: number;
      cmbEtatCivile: number;
      cmbNbEnfantActuel: number;
      cmbEnfantDesirer: number;
      cmbFumeurs: number;
      cmbAlcool: number;
      cmbDrogue: number;
      cmbOccupation: number;
      txtmsg: string;
      chkDDN: number;
      chkalert: number;
      chknl: number;
      optVisibilite: number;
    },
    method?: "POST" | "PUT"
  ): Promise<HTTPResponse & { data: any }> {
    return method === "PUT"
      ? this.put("/registration-profile", body)
      : this.post("/registration-profile", body);
  }
}
