import Api, { HTTPResponse } from "./Api";

export type VIPPlan = {
  "data-title": string;
  "data-subtitle_line_1": string;
  "data-subtitle_line_2": string;
  "data-button-text": string;
  "data-image": string;
  "data-popup_line_1": string;
  paymentsMethod: {
    paypal: {
      "data-paypal-plan-Id": string;
    };
    creditCard: {
      "data-credit-card-plan-Id": string;
    };
  };
};

export type PaymentData = {
  plans: {
    vip: VIPPlan[];
    noAds: VIPPlan[];
  };

  addTaxeMessage: string;
  brand_name: "CelibatairesDuWeb";
  locale: "en_US" | "fr_CA";
  client_id: string;
  paymentMethodPage: {
    messageToShow: string;
    tabs: {
      id: string;
      label: string;
    }[];
    defaultTabSelected: string;
  };
  additionalInformation: {
    creditcard: {
      currencyCode: string;
      scriptUrl: string;
      appId: string;
      locationId: string;
      textButton: string;
      defaultEmail: string;
      defaultCountryId: string;
      defaultRegionId: string;
    };
    "foreign-currency": {
      "show-lines": string;
      card_line_1: string;
      card_line_2: string;
      popup_title: string;
      popup_line_2: string;
    };
  };
};

export type SubscriptionData = {
  id: string;
  description: string;
  renewalDate: string;
  endDate: string;
  status: "Annulé" | "Actif" | "Terminé";
  showCancelButton: boolean;
};

export default class Subscription extends Api {
  public async getSubscription(): Promise<
    HTTPResponse & {
      data: SubscriptionData[];
    }
  > {
    return this.get("/subscription", {});
  }

  public async deleteSubscription(body: {
    id: string;
    email: string;
    username: string;
    message: string;
  }): Promise<HTTPResponse> {
    return this.delete("/subscription", body);
  }

  public async postSquareUp(body: any): Promise<any> {
    return this.post("/subscription", body);
  }

  public async getSuscriptionPaypalData(): Promise<
    HTTPResponse & {
      data: PaymentData;
    }
  > {
    return this.get("/subscription/plans");
  }

  public async getSuccessUrl(params: {
    subscriptionId?: string | null;
    subscriptionGroup: "vip" | "noAds";
    provider: "paypal" | "paymentwall";
  }): Promise<
    HTTPResponse & {
      data: {
        url: string;
        message: string;
      };
    }
  > {
    return this.get("/subscription/onSuccessValidation", params);
  }

  public async getSubscriptionStripeData(): Promise<
    HTTPResponse & {
      data: {
        currency: string;
        symbole: string;
        addTaxeMessage: string;
      };
    }
  > {
    return this.get("/subscription/forms", {});
  }
}

// export type SubscriptionData = {
//   accountType: string;
//   subscriptionType: string;
//   subscriptionStartDate: string;
//   subscriptionEndDate: string;
//   nextBillingDate: string;
//   CancelSubscriptionLinkOrText: string;
// };
