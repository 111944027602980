import { FC } from "react";
import useUser from "../../hooks/useUser";
import SideNavLink from "../sideNavLink";

import { LoginNotification } from "../../backendAPI/Authentication";
import { useState } from "react";
import { useEffect } from "react";

type Props = {
  currentPage: string;
  mainLinks?: { name: string; icon: string; href: string }[];
};

const SideNavFeatures: FC<Props> = ({ currentPage, mainLinks }) => {
  const { notifications } = useUser();
  const [sideNavLinks, setNavLinks] = useState(mainPages);
  useEffect(() => {
    if (mainLinks) {
      setNavLinks(mainLinks);
    }
  }, []);
  return (
    <div className="SideNavFeatures">
      <div>
        {sideNavLinks.map((item, index) => {
          const hasNotifications =
            item?.notificationIndicator &&
            notifications[item.notificationIndicator];
          return (
            <SideNavLink
              isCurrentPage={currentPage === item.name}
              hasNotification={hasNotifications}
              {...item}
              key={index}
            />
          );
        })}
      </div>

      <div className="flex items-center w-full h-10">
        <div className="w-full h-[1px] bg-custom-blue opacity-20"></div>
      </div>
      <div>
        {maintenanceOptions.map((item, index) => (
          <SideNavLink
            isCurrentPage={currentPage === item.name}
            {...item}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

type Feature = {
  name: string;
  icon: string;
  href: string;
  notificationIndicator?: keyof LoginNotification;
  onClick?: () => void;
};

export const mainPages: Feature[] = [
  {
    name: "Messages",
    icon: "/app/assets/messageIcon.svg",
    href: "/messages",
    notificationIndicator: "haveNewMessage",
  },
  {
    name: "Visited your profile",
    icon: "/app/assets/smileFaceIcon.svg",
    href: "/visited-your-profile",
    notificationIndicator: "haveNewVisite",
  },
  {
    name: "Wants to meet you",
    icon: "/app/assets/userHeartIcon.svg",
    href: "/who-wants-to-meet-me",
    notificationIndicator: "haveNewWhoWantsToMeetMe",
  },
  {
    name: "Perfect match",
    icon: "/app/assets/heartIcon.svg",
    href: "/perfect-match",
    notificationIndicator: "haveNewPerfectMatch",
  },
  {
    name: "Favorites",
    icon: "/app/assets/starIcon.svg",
    href: "/favorites",
  },
];

export const maintenanceOptions: Feature[] = [
  {
    name: "Settings",
    icon: "/app/assets/cogIcon.svg",
    href: "/settings",
  },
  {
    name: "Logout",
    icon: "/app/assets/signoutIcon.svg",
    href: "/logout",
  },
];

export default SideNavFeatures;
