export const DEFAULT_LANGUAGE = "en";
export const AVAILABLE_LANGUAGES = {
  en: "English",
  fr: "Français",
  es: "Español",
} as const;
export type Languages = keyof typeof AVAILABLE_LANGUAGES;
export type Translation = Record<string, string>;

/**
 * Checks if a given value is a valid language. If not, checks the url to determine current language. Defaults to english if nothing matches.
 * @param value value to be checked as a language
 * @returns Language as a string
 */
export function assertLanguage(value?: any): Languages {
  if (!value) value = window.localStorage.getItem("lang");
  if (value in AVAILABLE_LANGUAGES) return value as Languages;
  if (/vidamora/.test(window.location.href)) return "en";
  if (/celibataire/.test(window.location.href)) return "fr";
  return DEFAULT_LANGUAGE;
}
