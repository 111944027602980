import { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const StyledContainer: FC<Props> = ({ children, className = "", onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "StyledContainer shadow-custom-shadow rounded bg-white",
        className
      )}
    >
      {children}
    </div>
  );
};

export default StyledContainer;
