import React, { createContext, useState } from "react";
import NotificationDialog from "../components/notificationDialog";

interface Context {
  isOpen: boolean;
  title: string;
  body: React.ReactNode;
  bottomActionButtonText: string;
  bottomActionButtonUrl: string;
  openPopup: any;
  closePopup: () => void;
}

export const NotificationDialogContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function NotificationDialogProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState<React.ReactNode>(undefined);
  const [bottomActionButtonText, setBottomActionButtonText] = useState("");
  const [bottomActionButtonUrl, setBottomActionButtonUrl] = useState("");

  const openPopup = (payload: {
    title: string;
    body: React.ReactNode;
    bottomActionButtonText: string;
    bottomActionButtonUrl: string;
  }) => {
    // need to set back to empty when we open another popup
    setIsOpen(true);
    setTitle(payload.title);
    setBody(payload.body);

    setBottomActionButtonText(payload.bottomActionButtonText);

    setBottomActionButtonUrl(payload.bottomActionButtonUrl);
  };
  const closePopup = () => {
    setIsOpen(false);
    // cleanup
  };

  return (
    <NotificationDialogContext.Provider
      value={{
        isOpen,
        title,
        body,
        bottomActionButtonText,
        bottomActionButtonUrl,
        openPopup,
        closePopup,
      }}
    >
      {children}
      <NotificationDialog />
    </NotificationDialogContext.Provider>
  );
}
