import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  title: string;
  description: string;
  isOpen: boolean;
  type: ToastType;
}

import { logout } from "./authSlice";

export type ToastType = "success" | "warning" | "error" | "";

const initialState: State = {
  title: "",
  description: "",
  isOpen: false,
  type: "",
};

export const openTimedToast = createAsyncThunk(
  "toast/openTimedToast",
  async (
    payload: {
      title: string;
      description: string;
      type: ToastType;
      timeout: number;
    },
    { dispatch }
  ) => {
    dispatch(
      openToast({
        title: payload.title,
        description: payload.description,
        type: payload.type,
      })
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, payload.timeout || 4000);
    });
  }
);

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast: (
      state,
      action: PayloadAction<{
        title: string;
        description: string;
        type: ToastType;
        href?: string;
        hrefText?: string;
      }>
    ) => {
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.type = action.payload.type;

      state.isOpen = true;
    },
    closeToast: (state) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState);

    builder.addCase(openTimedToast.fulfilled, (state, action) => {
      state.isOpen = false;
    });
  },
});

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
