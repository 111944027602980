import React, { useEffect, useMemo, useRef, useState } from "react";
import { useVideoChat } from "../contexts/VideoChatContext";
import { Placeholder } from "../../components/messageBannerPicture/MessageBannerPicture.stories";
import useMessenger from "../../hooks/useMessenger";
import classNames from "../../utils/twClassNames";
import useTranslation from "../../context/TranslationContext";

export default function VideoContainer() {
  const [error, setError] = useState(false);
  const { remoteStream, isDialing } = useVideoChat();
  const { i } = useTranslation();

  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);

  const handleRetry = () => {
    if (!remoteVideoRef.current || !remoteStream) return;
    remoteVideoRef.current.srcObject = remoteStream;
    remoteVideoRef.current.play();
  };

  useEffect(() => {
    console.log("new remote thingy");
    if (!remoteVideoRef.current || !remoteStream) return;
    remoteStream
      .getVideoTracks()?.[0]
      ?.addEventListener("ended", (e) => console.log("track ended", e));
    remoteVideoRef.current.srcObject = remoteStream;
    remoteVideoRef.current.play();
    console.log("remote stream change");
    return () => {
      if (!remoteVideoRef.current) return;
      remoteVideoRef.current.srcObject = null;
      remoteVideoRef.current.pause();
      remoteVideoRef.current.src = "";
    };
  }, [remoteStream?.id]);

  const hasVideo = useMemo(
    () =>
      !!remoteStream
        ?.getVideoTracks()
        ?.find((track) => track.enabled && track.readyState === "live"),
    [remoteStream]
  );

  return (
    <figure
      className="flex flex-grow justify-center items-center mt-4 mb-8 sm:object-contain object-cover w-full"
      style={{ width: "100%", position: "relative" }}
    >
      {hasVideo ? (
        <video
          playsInline
          ref={remoteVideoRef}
          className="absolute h-full w-auto"
          onEnded={(e) => console.log("ended", e)}
          onChange={(e) => console.log("changed", e)}
          onChangeCapture={(e) => console.log(e, "change")}
          onError={(e) => {
            setError(true);
            console.log(e);
          }}
          style={{ objectFit: "inherit" }}
        />
      ) : (
        <PlaceHolder />
      )}
      {error && (
        <button onClick={handleRetry}>
          {i("Error")} {i("Try again")}
        </button>
      )}
    </figure>
  );
}

const PlaceHolder = () => {
  const { i } = useTranslation();
  const { isDialing, convo } = useVideoChat();

  if (!convo) {
    return null;
  }

  return (
    <div
      className={classNames(
        "p-8 rounded overflow-visible",
        isDialing && "pulse-ring"
      )}
    >
      <div>
        <img
          src={convo.image}
          width={300}
          height={300}
          style={{
            maxHeight: "300px",
            maxWidth: "300px",
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
          onError={(e) => {
            e.currentTarget.src =
              "https://cdn.celibatairesduweb.com/img/Vignettes/admin2_v=3.png";
          }}
          className={classNames("rounded-full")}
          alt={i("[username]'s profile picture", {
            username: convo.username ?? "User",
          })}
        />
      </div>
    </div>
  );
};
