import React, { FC, useContext } from "react";
import { DetailedMemberInfo } from "../../backendProxies/members/DetailedMember";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileAboutMeQuote from "../profileAboutMeQuote";
import ProfileStatsItem from "../profileStatsItem";
import ProfileStatsRow from "../profileStatsRow";
import StyledContainer from "../styledContainer";
import StyledContainerHeader from "../styledContainerHeader";

type Props = {
  info: DetailedMemberInfo;
};

const ProfileAboutMeContainer: FC<Props> = ({ info }) => {
  const { i } = useContext(TranslationContext);
  return (
    <StyledContainer className="ProfileAboutMeContainer">
      <StyledContainerHeader className="sm:px-8 px-[15px] sm:pt-[27px] pt-[22px]">
        {i("About")}
      </StyledContainerHeader>
      <div className="divide-y flex flex-col space-y-[21px] sm:px-[21px] px-[15px] pb-[21px] pt-[7px]">
        <div>
          <div
            className="sm:px-[10px] break-words"
            dangerouslySetInnerHTML={{ __html: info.maDescription }}
          />
          {info.msgHeader && <ProfileAboutMeQuote quote={info.msgHeader} />}
        </div>

        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("First Name")}
              description={info.prenom}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Physical app.")}
              description={info.appPhysique}
            />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Hair color")}
              description={info.cheveux}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Eye color")} description={info.yeux} />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Height")} description={info.taille} />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Looking for")}
              description={info.sexeRecherche}
            />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex w-full">
            <ProfileStatsItem
              label={i("I am here for")}
              description={info.typeRecherche}
            />
          </div>
        </ProfileStatsRow>
      </div>
    </StyledContainer>
  );
};

export default ProfileAboutMeContainer;
