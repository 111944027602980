import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  letterCount: number;
  maxLength: number;
};

const StyledTextAreaCounter: FC<Props> = ({ letterCount, maxLength }) => (
  <div
    className={classNames(
      "StyledTextAreaCounter text-custom-light-gray-600 text-xs",
      letterCount >= maxLength ? "text-red-500" : ""
    )}
  >
    {maxLength > 0 && `${letterCount} / ${maxLength}`}
  </div>
);

export default StyledTextAreaCounter;
