import { ROOT } from "../../backendAPI/Api";
import { PinnedItem } from "./BackendProxy";
import CanFail, { ICanFail } from "./CanFail";

interface IFetchBehavior<Data, Endpoint, QueryParams> extends ICanFail {
  fetchFromServer(
    endpoint: Endpoint,
    pageIndex: number,
    config: QueryParams
  ): Promise<[Data[], boolean, PinnedItem]>;
}

class FetchBehavior<Data, Endpoint, QueryParams>
  extends CanFail
  implements IFetchBehavior<Data, Endpoint, QueryParams>
{
  constructor() {
    super();
  }

  public async fetchFromServer(
    endpoint: Endpoint,
    pageIndex: number,
    config: QueryParams
  ): Promise<[Data[], boolean, PinnedItem]> {
    let url = `${ROOT}/`;

    if (window.localStorage.getItem("token") === null) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (window.localStorage.getItem("lang") === null) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    url += `${endpoint}?pageIndex=${pageIndex}&locale=${window.localStorage.getItem(
      "lang"
    )}`;

    for (let key in config) {
      url += `&${key}=${config[key]}`;
    }

    let response;

    let jsonResponse;

    try {
      response = await fetch(url, {
        headers: new Headers({
          Authorization: window.localStorage.getItem("token")!,
          "Content-Type": "application/json",
        }),
      });
    } catch (error) {
      this.setError(error as Error);

      return [[], false, null];
    }

    try {
      jsonResponse = await response.json();
    } catch (error) {
      this.setError(error as Error);

      return [[], false, null];
    }

    let canLoadMore = true;

    const hasNoItems = jsonResponse.data.items === 0;

    const notEnoughResultsToFillExpectedNumber =
      //@ts-ignore
      +config!.pageSize > jsonResponse.data.items;

    if (
      jsonResponse.data.items * +jsonResponse.data.pageIndex >=
        jsonResponse.data.total ||
      hasNoItems ||
      notEnoughResultsToFillExpectedNumber
    ) {
      canLoadMore = false;
    }

    return [
      jsonResponse.data.list || [],
      canLoadMore,
      jsonResponse.data?.specialItem ?? null,
    ];
  }
}

export default FetchBehavior;
