import Api, { HTTPResponse } from "./Api";

export default class SearchByUsername extends Api {
  public async getSearchByUsername(queryParams: {
    pageIndex: number;
    pageSize: number;
    username: string;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any[];
      };
    }
  > {
    return this.get("/search/username", queryParams);
  }
}
