import React, { FC } from "react";

const LoadingSpinner: FC = () => (
  <div className="LoadingSpinner">
    <div className="flex items-center justify-center w-12">
      <div className="animate-spin flex">
        <img src="/app/assets/spinner.png" alt="spinner" />
      </div>
    </div>
  </div>
);

export default LoadingSpinner;
