import Head from "next/head";
import React, { useEffect, useState } from "react";
import PageContainer from "../components/pageContainer";
import PageHeader from "../components/pageHeader";
import SideBarAdContainer from "../components/sideBarAdContainer";
import SettingsAccount from "../components/settingsAccount";
import SettingsEmail from "../components/settingsEmail";
import SettingsPassword from "../components/settingsPassword";
import SettingsDeleteAccount from "../components/settingsDeleteAccount";
import SettingsBlockedMembers from "../components/settingsBlockedMembers";
import useUser from "../hooks/useUser";
import { useRouter } from "next/router";
import useTranslation, { Languages } from "../context/TranslationContext";
import useWindowSize from "../hooks/useWindowSize";
import api from "../backendAPI";
import { PaymentData, VIPPlan } from "../backendAPI/Subscription";
import AuthGuard from "../components/authGuard";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AdWrapper from "../components/AdWrapper";
import BodyAdContainerMobile from "../components/bodyAdContainerMobile";
import Link from "next/link";
import SettingsVIP, {
  VipSuccess,
  VipWarning,
} from "../components/settingsVIP/SettingsVIP";
import SettingsSubscriptions from "../components/SettingsSubscriptions";

export default function Settings() {
  const { i, locale } = useTranslation();
  const { windowWidth } = useWindowSize();
  const [currentTab, setCurrentTab] = useState<string>(
    SettingsLinkRoutes.Account
  );
  const [paypalData, setPaypalData] = useState<PaymentData | null>(null);
  const currentTitle = i(getTitle(currentTab));
  const router = useRouter();

  useEffect(() => {
    if (!window.location.search) return;
    setCurrentTab(
      new URLSearchParams(window.location.search).get("tab") ||
        SettingsLinkRoutes.Account
    );
  }, [router.asPath]);

  useEffect(() => {
    api.subscription.getSuscriptionPaypalData().then((res) => {
      setPaypalData(res.data);
    });
  }, []);

  return (
    <AuthGuard>
      {paypalData?.client_id ? (
        <PayPalScriptProvider
          options={{
            clientId: paypalData.client_id,
            vault: true,
            intent: "subscription",
            "disable-funding": "credit",
            locale: getPaypalLocal(locale),
          }}
        >
          <div>
            <Head>
              <title>{i("Settings")}</title>
              <meta name="robots" content="noindex" />
            </Head>
            <main>
              {windowWidth && windowWidth >= 1280 ? (
                <DesktopVersion
                  currentTab={currentTab}
                  currentTitle={currentTitle}
                  onTabChange={(hash) => setCurrentTab(hash)}
                  paypalData={paypalData}
                />
              ) : (
                <MobileVersion
                  currentTab={currentTab}
                  currentTitle={currentTitle}
                  onTabChange={(hash) => setCurrentTab(hash)}
                  paypalData={paypalData}
                />
              )}
            </main>
          </div>
        </PayPalScriptProvider>
      ) : null}
    </AuthGuard>
  );
}

const getPaypalLocal = (locale: Languages | null) => {
  switch (locale) {
    case "fr":
      return "fr_FR";
    case "es":
      return "es_ES";
    default:
      return "en_US";
  }
};

type MainContentProps = {
  currentTab: string;
  paypalData: PaymentData;
  onTabChange(hash: SettingsLinkRoutes): void;
  planToBuy: VIPPlan | null;
  setPlanToBuy(plan: VIPPlan | null): void;
};
function MainContent({
  currentTab,
  paypalData,
  onTabChange,
  planToBuy,
  setPlanToBuy,
}: MainContentProps) {
  const router = useRouter();
  return (
    <div className="flex">
      <div className="TabContainer w-full shadow-custom-shadow p-4 bg-white rounded">
        {currentTab === SettingsLinkRoutes.Account && <SettingsAccount />}
        {currentTab === SettingsLinkRoutes.EmailNotifications && (
          <SettingsEmail />
        )}
        {currentTab === SettingsLinkRoutes.ChangePassword && (
          <SettingsPassword />
        )}
        {currentTab === SettingsLinkRoutes.BlockedMembers && (
          <SettingsBlockedMembers />
        )}
        {(currentTab === SettingsLinkRoutes.VIPMember ||
          currentTab === SettingsLinkRoutes.AdFree) && (
          <SettingsVIP
            paypalData={paypalData}
            defaultTab={currentTab}
            onTabChange={onTabChange}
            currentTab={currentTab}
            planToBuy={planToBuy}
            setPlanToBuy={setPlanToBuy}
          />
        )}
        {(currentTab === SettingsLinkRoutes.VIPBuy ||
          currentTab === SettingsLinkRoutes.AdFreeBuy) && (
          <SettingsVIP
            paypalData={paypalData}
            defaultTab={SettingsLinkRoutes.VIPMember}
            onTabChange={onTabChange}
            currentTab={currentTab}
            planToBuy={planToBuy}
            setPlanToBuy={setPlanToBuy}
          />
        )}
        {currentTab === SettingsLinkRoutes.VIPSuccess && (
          <VipSuccess type="vip" />
        )}
        {currentTab === SettingsLinkRoutes.NoAdsSuccess && (
          <VipSuccess type="ad-free" />
        )}
        {currentTab === SettingsLinkRoutes.VIPWarning && (
          <VipWarning
            onTryAgain={() => {
              router.replace(`?tab=${SettingsLinkRoutes.VIPMember}`);
              onTabChange(SettingsLinkRoutes.VIPMember);
            }}
          />
        )}
        {currentTab === SettingsLinkRoutes.DeleteProfile && (
          <SettingsDeleteAccount />
        )}
        {currentTab === SettingsLinkRoutes.ManageSubscriptions && (
          <SettingsSubscriptions />
        )}
      </div>
      {currentTab !== SettingsLinkRoutes.VIPMember &&
        currentTab !== SettingsLinkRoutes.AdFree &&
        currentTab !== SettingsLinkRoutes.VIPBuy &&
        currentTab !== SettingsLinkRoutes.AdFreeBuy &&
        currentTab !== SettingsLinkRoutes.ManageSubscriptions && (
          <AdWrapper>
            <div className="mx-6 hidden lg:block">
              <SideBarAdContainer googleid="3967401955" />
            </div>
          </AdWrapper>
        )}
    </div>
  );
}

type ContainerProps = {
  currentTitle: string;
  currentTab: string;
  onTabChange(hash: SettingsLinkRoutes): void;
  paypalData: PaymentData;
};

function DesktopVersion({
  currentTitle,
  currentTab,
  onTabChange,
  ...ContentProps
}: ContainerProps) {
  const [planToBuy, setPlanToBuy] = React.useState<VIPPlan | null>(null);

  function onTabClick(hash: SettingsLinkRoutes) {
    setPlanToBuy(null);
    onTabChange(hash);
  }

  return (
    <PageContainer
      pagesWithNotification={[]}
      currentPage="Settings"
      altSideNav={<SettingsNav onClick={onTabClick} currentHash={currentTab} />}
    >
      <div className="flex justify-between flex-col lg:flex-row mb-[15px]">
        <PageHeader title={currentTitle} />
      </div>
      <MainContent
        currentTab={currentTab}
        onTabChange={onTabClick}
        planToBuy={planToBuy}
        setPlanToBuy={setPlanToBuy}
        {...ContentProps}
      />
    </PageContainer>
  );
}

function MobileVersion({
  currentTitle,
  currentTab,
  onTabChange,
  ...ContentProps
}: ContainerProps) {
  const [tab, setTab] = useState<string | null>(null);
  const [planToBuy, setPlanToBuy] = React.useState<VIPPlan | null>(null);

  const router = useRouter();

  useEffect(() => {
    setTab(new URLSearchParams(window.location.search).get("tab"));
  }, []);

  function onTabClick(hash: SettingsLinkRoutes) {
    setPlanToBuy(null);
    onTabChange(hash);
  }

  const handleTab = (hash: SettingsLinkRoutes) => {
    setTab(hash);
    onTabClick(hash);
  };

  return (
    <PageContainer pagesWithNotification={[]} currentPage="Settings">
      <div className="h-full">
        {tab ? (
          <>
            <div className="flex mb-[15px] items-center">
              <img
                onClick={() => (planToBuy ? setPlanToBuy(null) : setTab(null))}
                className="w-12 h-12"
                src="/app/assets/Icons/arrowLeft.svg"
                alt="Back Arrow"
              />

              <PageHeader title={currentTitle} />
            </div>
            <MainContent
              currentTab={currentTab}
              onTabChange={onTabClick}
              planToBuy={planToBuy}
              setPlanToBuy={setPlanToBuy}
              {...ContentProps}
            />
          </>
        ) : (
          <SettingsNav onClick={handleTab} currentHash={currentTab} />
        )}
      </div>
    </PageContainer>
  );
}

export enum SettingsLinkRoutes {
  Account = "account",
  EmailNotifications = "email",
  ChangePassword = "password",
  BlockedMembers = "blocked-members",
  VIPMember = "vip",
  VIPBuy = "vip-buy",
  AdFree = "ad-free",
  AdFreeBuy = "ad-free-buy",
  ManageSubscriptions = "manage-subscriptions",
  DeleteProfile = "delete-profile",
  VIPWarning = "vip-warning",
  VIPSuccess = "vip-success",
  NoAdsSuccess = "noads-success",
}

type SettingSubLink = {
  label: string;
  title: string;
  hash: SettingsLinkRoutes;
  iconSrc: string;
};

const SETTINGS_LINKS = [
  {
    id: "settings-section",
    sectionName: "Settings",
    sublinks: [
      {
        label: "Account",
        title: "Account",
        hash: SettingsLinkRoutes.Account,
        iconSrc: "/app/assets/Icons/accountIcon.svg",
      },
      {
        label: "Email Notifications",
        title: "Email Notifications",
        hash: SettingsLinkRoutes.EmailNotifications,
        iconSrc: "/app/assets/Icons/emailIcon.svg", // "/app/assets/messageIcon.svg",
      },
      {
        label: "Change Password",
        title: "Change Password",
        hash: SettingsLinkRoutes.ChangePassword,
        iconSrc: "/app/assets/Icons/passwordIcon.svg",
      },
      {
        label: "Blocked Members",
        title: "Blocked Members",
        hash: SettingsLinkRoutes.BlockedMembers,
        iconSrc: "/app/assets/messages/blockIcon2.svg",
      },
    ],
  },
  {
    id: "memberships-section",
    sectionName: "Memberships",
    sublinks: [
      {
        label: "V.I.P Member",
        title: "Subscriptions",
        hash: SettingsLinkRoutes.VIPMember,
        iconSrc: "/app/assets/vipcard.png",
      },
      {
        label: "Ad-Free",
        title: "Subscriptions",
        hash: SettingsLinkRoutes.AdFree,
        iconSrc: "/app/assets/noads.svg",
      },
      {
        label: "Manage my subscriptions",
        title: "Manage my subscriptions",
        hash: SettingsLinkRoutes.ManageSubscriptions,
        iconSrc: "/app/assets/Icons/payIcon.svg",
      },
    ],
  },
  {
    id: "profile-section",
    sectionName: "",
    sublinks: [
      {
        label: "Delete/Deactivate my profile",
        title: "Delete/Deactivate my profile",
        hash: SettingsLinkRoutes.DeleteProfile,
        iconSrc: "/app/assets/Icons/trash.svg",
      },
    ],
  },
] as const;

const getTitle = (hash: string) => {
  const current = (SETTINGS_SUBLINKS.find((x) => x.hash === hash) ??
    SETTINGS_SUBLINKS[0]) as typeof SETTINGS_LINKS[number]["sublinks"][number];
  return current.title;
};

const SETTINGS_SUBLINKS = SETTINGS_LINKS.reduce((values, section) => {
  return values.concat(section.sublinks);
}, [] as SettingSubLink[]);

type SettingsNavProps = {
  onClick: (link: SettingsLinkRoutes) => void;
  currentHash: string;
};

function SettingsNav({ currentHash, onClick }: SettingsNavProps) {
  const { windowWidth } = useWindowSize();

  const isMobile = !(windowWidth && windowWidth >= 1280);

  const { i } = useTranslation();

  // paypal please
  // 4032034795424186
  // 05/2026
  // 238

  return (
    <>
      <div className="SettingsSideNav xl:flex flex-col">
        <div className="shadow-custom-shadow w-full sm:w-300 mb-5">
          <div className="flex-col flex-grow rounded-md pb-5 px-1 w-full h-1/2 bg-white overflow-y-auto">
            <div className="mt-5 flex-grow flex flex-col">
              <nav
                className="grid flex-1 px-2 bg-white space-y-2 gap-4"
                aria-label="Sidebar"
              >
                {SETTINGS_LINKS.map((section, index) => (
                  <div key={section.id}>
                    <h2 className="text-xl text-custom-black mb-1">
                      {section.sectionName && i(section.sectionName)}
                    </h2>
                    <div className="w-full h-[1px] bg-custom-blue opacity-20" />
                    <div className="flex flex-col min-h-[65px] justify-end pt-1">
                      {section.sublinks.map((sublink) => (
                        <NavLink
                          key={sublink.hash}
                          onClick={() => onClick(sublink.hash)}
                          label={i(sublink.label)} // TODO: Translate title
                          href={sublink.hash}
                          icon={sublink.iconSrc}
                          isActive={
                            !isMobile && currentHash.startsWith(sublink.hash)
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <AdWrapper>
        <div className="hidden xl:flex sticky top-[120px]">
          {!isMobile ? (
            <BodyAdContainerMobile googleid="6724593086" />
          ) : (
            <SideBarAdContainer googleid="6724593086" />
          )}
        </div>
      </AdWrapper>
    </>
  );
}

type NavLinkProps = {
  label: string;
  onClick?: () => void;
  href?: string;
  isActive?: boolean;
  icon?: string;
};
function NavLink({
  label,
  href = "",
  icon,
  isActive = false,
  onClick,
}: NavLinkProps) {
  return (
    <div
      className="SideNavLink"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Link href={`?tab=${href}`}>
        <a
          data-testid={`sidenav-link-for-${label}`}
          className={`${
            isActive ? "bg-custom-light-blue bg-opacity-40 " : ""
          }text-custom-blue hover:bg-custom-light-blue hover:bg-opacity-20 group flex px-2 py-1 items-center text-base font-medium rounded-[10px]`}
        >
          <div className="flex relative shadow-custom-shadow-md mr-2 rounded-lg bg-white items-center justify-center p-2 d-flex align-items-center justify-content-center">
            <img
              src={icon}
              className="relative"
              alt={label}
              height={18}
              width={18}
            />
          </div>

          <span className="flex-1 text-sm">{label}</span>
        </a>
      </Link>
    </div>
  );
}
