import React, { FC } from "react";
import StyledContainer from "../styledContainer";
import StyledContainerHeader from "../styledContainerHeader";
import ProfilePhotoCarousel from "../profilePhotoCarousel";
import { DetailedMember } from "../../backendProxies";
import useTranslation from "../../context/TranslationContext";

type Props = {
  member: DetailedMember;
  onItemClick?: (index: number) => void;
};

const ProfilePhotosContainer: FC<Props> = ({ member, onItemClick }) => {
  const { i } = useTranslation();
  const { info } = member;
  if (!info.photos?.length) return null;
  return (
    <div className="ProfilePhotoCarousel w-full max-w-full">
      <StyledContainer className="flex flex-col space-y-[14px] pt-[22px] w-full pb-6">
        <StyledContainerHeader className="px-[15px]">
          {i("Photos")}
        </StyledContainerHeader>
        <div className="px-[15px] w-full">
          <ProfilePhotoCarousel
            member={member}
            photos={info.photos}
            highResPhotos={info.photos_grandFormat}
            onItemClick={onItemClick}
            firstName={info.prenom}
            age={info.age}
            location={member.getLocation()}
          />
        </div>
      </StyledContainer>
    </div>
  );
};

export default ProfilePhotosContainer;
