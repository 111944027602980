import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaymentData, VIPPlan } from "../../backendAPI/Subscription";
import useTranslation from "../../context/TranslationContext";
import useUser from "../../hooks/useUser";
import ButtonBGBlue from "../buttonBGBlue";

import { PayPalButtons } from "@paypal/react-paypal-js";
import api from "../../backendAPI";
import StyledCombobox from "../styledCombobox";
import StyledAsyncCombobox from "../styledAsyncCombobox";
import { PickerValue } from "../../types";
import StyledTextField from "../styledTextField";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// https://developer.paypal.com/tools/sandbox/card-testing/

// card number : 4032033113059898
// expiration date : 09/2028
// security code : 082

type Props = {
  planInfo: VIPPlan;
  paymentMethodPageInfo: PaymentData["paymentMethodPage"];
  additionalInformation: PaymentData["additionalInformation"];
  planType: "vip" | "noAds";
  onClick: () => void;
};

const PaymentSection: FC<Props> = ({
  planInfo,
  paymentMethodPageInfo,
  additionalInformation,
  planType,
  onClick,
}) => {
  const { i } = useTranslation();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>(
    paymentMethodPageInfo.defaultTabSelected
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const { userData } = useUser();

  useEffect(() => {
    let script: HTMLScriptElement;

    if (additionalInformation.creditcard.scriptUrl) {
      script = document.createElement("script");

      script.src = additionalInformation.creditcard.scriptUrl;
      script.async = true;

      document.body.appendChild(script);
    }

    return () => {
      if (script) document.body.removeChild(script);
    };
  }, [additionalInformation?.creditcard?.scriptUrl]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.origin !== "https://api.paymentwall.com") return;
      try {
        const eventData = JSON.parse(event.data); // Check if the event is a payment success

        if (eventData.event === "paymentSuccess") {
          setIsSuccess(true);
          return api.subscription
            .getSuccessUrl({
              subscriptionId: eventData.id,
              subscriptionGroup: planType,
              provider: "paymentwall",
            })
            .then((res) => {
              if (res.status !== "SUCCESS")
                throw new Error("Error while fetching success url");

              window.location.href = res.data.url;
            })
            .catch((err) => {
              window.location.href =
                planType === "vip"
                  ? "settings?tab=vip-success"
                  : "settings?tab=noads-success";
            });
        }
      } catch (error) {
        console.error("Error parsing event data", error);
      }
    };

    window.addEventListener("message", handleMessage, false); // Cleanup event listener on component unmount

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  if (!userData?.userid) return null;

  return (
    <div className="MembershipCard w-full overflow-x-hidden">
      {/* // go back header */}
      <div className="flex flex-row items-center">
        <button onClick={onClick}>
          <svg
            height={24}
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 12a1 1 0 0 1-1 1H8.414l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L8.414 11H18a1 1 0 0 1 1 1z"
              fill="rgb(56, 106, 236)"
              data-name="Left"
            />
          </svg>
        </button>
        {planInfo?.["data-image"] && (
          <img
            src={planInfo["data-image"]}
            alt="vidamora vip logo"
            className="w-[73px] h-[46px]"
          />
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            {planInfo["isPromoNewPriceMonthly"] && (
              <p className="text-xs sm:text-lg leading-4 font-semibold text-custom-orange line-through">
                {planInfo["data-title"]}
              </p>
            )}
            <p className="text-lg leading-4 font-semibold text-custom-orange">
              {planInfo["isPromoNewPriceMonthly"] ?? planInfo["data-title"]}
            </p>
          </div>
          <p className="text-sm leading-4">
            {planInfo["data-subtitle_line_1"]}{" "}
            {planInfo["isPromoNewPrice"] && (
              <>
                <span className="line-through">
                  {planInfo["data-subtitle_line_2"]}
                </span>{" "}
              </>
            )}
            {planInfo["isPromoNewPrice"] || planInfo["data-subtitle_line_2"]}
          </p>
        </div>
      </div>
      {paymentMethodPageInfo.tabs?.length > 0 && (
        <div
          className="flex flex-wrap w-full justify-center items-center relative gap-4"
          style={{ rowGap: 8 }}
        >
          <p className="text-lg text-custom-black font-medium w-full text-center md:w-fit md:text-left md:absolute left-10">
            {i("Choose a payment method:")}
          </p>
          {paymentMethodPageInfo.tabs.map((tab) => (
            <div
              key={tab.id}
              className={`flex h-8 justify-center items-center rounded-xl gap-2 p-4 cursor-pointer hover:bg-gray-300 ease-in-out duration-150 ${
                currentPaymentMethod === tab.id ? "bg-gray-200" : ""
              }`}
              onClick={() => setCurrentPaymentMethod(tab.id)}
            >
              <p>{tab.label}</p>
            </div>
          ))}
        </div>
      )}
      {!paymentMethodPageInfo.tabs?.length ? (
        <div className="flex flex-col w-full gap-4">
          <div className="relative flex flex-col w-full text-center gap-4 border border-gray-300 p-10 mt-10 mb-4 rounded-xl">
            <div className="wrapper">
              <svg
                className="warning-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
                width="52"
                height="52"
              >
                <path
                  className="warning__triangle"
                  d="M26 8 L6 44 H46 L26 8 Z"
                  stroke="#ec623b"
                  strokeWidth="2"
                  fill="none"
                />

                <line
                  x1="26"
                  y1="20"
                  x2="26"
                  y2="32"
                  stroke="#ec623b"
                  strokeWidth="3"
                />

                <circle cx="26" cy="38" r="2" fill="#ec623b" />
              </svg>
            </div>

            <p>{paymentMethodPageInfo.messageToShow}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-4">
          <div className="relative flex flex-col w-full gap-4 border border-gray-300 p-4 my-4 rounded-xl">
            {isSuccess ? (
              <div className="flex flex-col justify-center items-center w-full h-40">
                <div className="absolute top-0 left-0 w-full h-full z-0 flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              // <div className="h-fit flex flex-col items-center justify-center text-custom-black">
              //   <div className="wrapper">
              //     <svg
              //       className="checkmark"
              //       xmlns="http://www.w3.org/2000/svg"
              //       viewBox="0 0 52 52"
              //     >
              //       <circle
              //         className="checkmark__circle"
              //         cx="26"
              //         cy="26"
              //         r="25"
              //         fill="none"
              //       />
              //       <path
              //         className="checkmark__check"
              //         fill="none"
              //         d="M14.1 27.2l7.1 7.2 16.7-16.8"
              //       />
              //     </svg>
              //   </div>
              //   <p className="text-4xl font-semibold text-custom-blue mt-6 text-center">
              //     {planType === "vip"
              //       ? i("You are now a V.I.P.")
              //       : i("You are now subscribed to the “No Ads” plan!")}
              //   </p>
              //   <div className="relative flex justify-center items-center flex-col mt-6">
              //     <img src={planInfo["data-image"]} alt="Plan Image" />
              //     <p className="text-xl font-semibold mt-1 text-center mb-8">
              //       {planInfo["data-subtitle_line_1"]}
              //     </p>
              //   </div>

              //   {/* <p className="text-center mb-8">{plan["data-subtitle_line_2"]}</p> */}
              //   <div>
              //     <ButtonBGBlue
              //       innerText={i("Refresh the page")}
              //       onClick={() => {
              //         window.location.reload();
              //       }}
              //     />
              //   </div>
              // </div>
              <>
                {currentPaymentMethod === "paypal" && (
                  <PayPalContent
                    brandName={
                      planInfo["paymentsMethod"]["paypal"]["brand_name"]
                    }
                    userId={userData.userid.toString()}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    plan={planInfo}
                    planType={planType}
                  />
                )}
                {currentPaymentMethod === "paymentwall" && (
                  <>
                    <iframe
                      className="z-10"
                      src={
                        planInfo["paymentsMethod"]["paymentwall"]["iframe_url"]
                      }
                      width="100%"
                      height="500px"
                    />
                    <div className="absolute top-0 left-0 w-full h-full z-0 flex justify-center items-center">
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </>
                )}
                {currentPaymentMethod === "creditcard" && (
                  <SquareUpContent
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    additionalInformation={additionalInformation}
                    planInfo={planInfo}
                    planType={planType}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const SquareUpContent = ({
  isSuccess,
  setIsSuccess,
  additionalInformation,
  planInfo,
  planType,
}: {
  isSuccess: boolean;
  setIsSuccess: (value: boolean) => void;
  additionalInformation: PaymentData["additionalInformation"];
  planInfo: VIPPlan;
  planType: "vip" | "noAds";
}) => {
  const { i, lang } = useTranslation();
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [countryChoices, setCountryChoices] = useState<any[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(
    additionalInformation?.creditcard?.defaultEmail || ""
  );
  const [phone, setPhone] = useState("");
  const [payments, setPayments] = useState<any>();
  const [card, setCard] = useState<any>();
  const [hasPaymentError, setHasPaymentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    init();
    api.general.getLocationHelperCountries().then((response) => {
      if (response.status === "ERROR") return;

      const formattedCountries = response.data.list.map((country: any) => {
        if (
          additionalInformation?.creditcard?.defaultCountryId === country.id &&
          !selectedCountry
        ) {
          setSelectedCountry({ ...country, name: country.description });
        }
        return {
          ...country,
          name: country.description,
        };
      });

      setCountryChoices(formattedCountries);
    });
  }, []);

  async function initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach("#card-container");
    return card;
  }

  const validateFields = () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !selectedCountry ||
      !city ||
      !region ||
      !address
    ) {
      setErrorMessage(i("Please fill out all required fields."));
      return false;
    }

    if (!EMAIL_REGEX.test(email)) {
      setErrorMessage(i("Please enter a valid email address."));
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (errorMessage && validateFields()) setErrorMessage("");
  }, [firstName, lastName, email, phone, selectedCountry, city, region]);

  async function createPayment(token: string, verificationToken: string) {
    const body = {
      locationId: additionalInformation.creditcard.locationId,
      sourceId: token,
      verificationToken: verificationToken,
      idempotencyKey: (window as any)?.crypto?.randomUUID(),
      provider: "squareup",
      planId: planInfo["paymentsMethod"]["creditcard"]["planId"],
      customerEmail: email,
      customerFirstName: firstName,
      customerFamilyName: lastName,
      customerPhone: phone,
      customerAddress: address,
      customerCity: city,
      customerRegion: region?.name,
      customerCountry: selectedCountry?.countryCode?.toUpperCase(),
    };

    const paymentResponse = await api.subscription.postSquareUp(body);
    console.log(paymentResponse);
    if (paymentResponse?.status !== "SUCCESS") {
      throw new Error("Payment failed");
    }
  }

  async function tokenize(paymentMethod: any) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    }
  }

  async function verifyBuyer(payments: any, token: string) {
    const verificationDetails = {
      amount: planInfo["paymentsMethod"]["creditcard"]["amount"].toString(),
      billingContact: {
        addressLines: [address],
        familyName: lastName,
        givenName: firstName,
        email: email,
        country: selectedCountry?.countryCode?.toUpperCase(),
        phone: phone,
        region: region?.name, // TODO: Code region
        city: city,
      },
      currencyCode: additionalInformation.creditcard.currencyCode,
      intent: "CHARGE_AND_STORE",
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }

  async function handlePaymentMethodSubmission(event: any) {
    setIsLoading(true);
    setErrorMessage("");
    event.preventDefault();
    if (!card || !payments) {
      setIsLoading(false);
      setHasPaymentError(true);
    }
    if (!validateFields()) return setIsLoading(false); // Stop submission if validation fails
    try {
      const token = await tokenize(card);
      let verificationToken = await verifyBuyer(payments, token);
      const paymentResults = await createPayment(token, verificationToken);
      setIsSuccess(true);
      window.location.href =
        planType === "vip"
          ? "settings?tab=vip-success"
          : "settings?tab=noads-success";
      console.debug("Payment Success", paymentResults);
    } catch (e: any) {
      setErrorMessage("Payment failed, please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  async function init() {
    setHasPaymentError(false);
    let i = 0;
    if (!(window as any).Square) {
      if (i > 10) {
        console.error("Square.js failed to load properly");
        return setHasPaymentError(true);
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));
      i++;
    }

    try {
      let tempPayment = (window as any).Square.payments(
        additionalInformation.creditcard.appId,
        additionalInformation.creditcard.locationId
      );
      tempPayment.setLocale(lang);
      setPayments(tempPayment);
      let tempCard = await initializeCard(tempPayment);
      setCard(tempCard);
    } catch (e) {
      console.error("Initializing Card failed", e);
      setHasPaymentError(true);
    }
  }

  const fetchRegionsFromDB = useCallback(
    async (input) => {
      if (!selectedCountry) return [];
      const response = await api.general.getProvinces({
        id: selectedCountry.id,
        name: selectedCountry.name,
        search: input,
      });

      const values: PickerValue[] = response.data.list.map((i) => {
        if (
          additionalInformation?.creditcard?.defaultRegionId === i.id &&
          !region
        ) {
          setRegion({ id: i.id, name: i.description });
        }
        return Object.assign({}, { id: i.id, name: i.description });
      });

      return values;
    },
    [selectedCountry]
  );

  return (
    <>
      <div
        className="flex-col justify-center items-center w-full h-fit"
        style={{
          display: hasPaymentError ? "flex" : "none",
        }}
      >
        <svg
          className="warning-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          width="52"
          height="52"
        >
          <path
            className="warning__triangle"
            d="M26 8 L6 44 H46 L26 8 Z"
            stroke="#ec623b"
            strokeWidth="2"
            fill="none"
          />

          <line
            x1="26"
            y1="20"
            x2="26"
            y2="32"
            stroke="#ec623b"
            strokeWidth="3"
          />

          <circle cx="26" cy="38" r="2" fill="#ec623b" />
        </svg>

        <p className="text-center">
          {i(
            "There was an error with the payment method. Please try again or choose another payment method."
          )}
        </p>
      </div>

      <div
        style={{
          display: !card && !hasPaymentError ? "flex" : "none",
        }}
        className="flex flex-col justify-center items-center w-full h-40"
      >
        <div className="absolute top-0 left-0 w-full h-full z-0 flex justify-center items-center">
          <svg
            aria-hidden="true"
            className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
      <form
        // prevent submission
        onSubmit={(e) => e.preventDefault()}
        style={{
          visibility: card && !hasPaymentError ? "visible" : "hidden",
          maxHeight: card && !hasPaymentError ? "none" : "0",
        }}
        className="flex max-w-full flex-col overflow-hidden"
        id="payment-form"
      >
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex-1">
            <StyledTextField
              className={`h-10 ${
                errorMessage
                  ? "placeholder-shown:border-[#CC0123] placeholder-shown:border-2"
                  : ""
              }`}
              required
              type="text"
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={i("First Name")}
            />
          </div>
          <div className="flex-1">
            <StyledTextField
              className={`h-10 ${
                errorMessage
                  ? "placeholder-shown:border-[#CC0123] placeholder-shown:border-2"
                  : ""
              }`}
              required
              type="text"
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={i("Last Name")}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-between mb-4">
          <div className="flex-1">
            <StyledTextField
              className={`h-10 ${
                errorMessage && !EMAIL_REGEX.test(email)
                  ? "border-[#CC0123] border-2"
                  : ""
              }`}
              required
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={i("Email")}
            />
          </div>
          <div className="flex-1">
            <PhoneInputWithCountrySelect
              defaultCountry={selectedCountry?.countryCode?.toUpperCase()}
              containerComponentProps={{
                className: `${
                  phone || !errorMessage ? "" : "border-[#CC0123] border-2"
                } flex-1 flex w-full flex-row h-10 border overflow-visible border-custom-gray rounded-[4px] px-2 placeholder:text-base placeholder:text-custom-black-200`,
              }}
              numberInputProps={{
                className: "border-none",
              }}
              placeholder={i("Phone Number")}
              value={phone}
              onChange={(value) => setPhone(value?.toString() ?? "")}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 justify-between mb-4">
          <div className="flex-1">
            <StyledTextField
              className={`h-10 ${
                errorMessage
                  ? "placeholder-shown:border-[#CC0123] placeholder-shown:border-2"
                  : ""
              }`}
              required
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={i("Address")}
            />
          </div>
          <div className="flex-1">
            <StyledTextField
              className={`h-10 ${
                errorMessage
                  ? "placeholder-shown:border-[#CC0123] placeholder-shown:border-2"
                  : ""
              }`}
              required
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder={i("City")}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-between mb-4">
          <div className="flex-1">
            <StyledCombobox
              hasError={selectedCountry === undefined && errorMessage !== ""}
              placeholder={i("Country")}
              selected={selectedCountry}
              setSelected={(value) => {
                setSelectedCountry(value);
                setRegion(undefined);
              }}
              values={countryChoices}
            />
          </div>
          <div className="flex-1">
            {selectedCountry ? (
              <StyledAsyncCombobox
                hasError={region === undefined && errorMessage !== ""}
                placeholder={i("Region")}
                selected={region}
                setSelected={setRegion}
                request={fetchRegionsFromDB}
                hasFallback={false}
              />
            ) : (
              <StyledCombobox
                hasError={region === undefined && errorMessage !== ""}
                placeholder={i("Region")}
                setSelected={() => {}}
                selected={region}
                values={[{ id: 0, name: i("Select a country first") }]}
              />
            )}
          </div>
        </div>
        <div id="card-container"></div>
        {errorMessage && (
          <p className="text-[#CC0123] border-[#CC0123] mb-2 font-semibold w-fit border-2 px-4 py-2">
            {errorMessage}
          </p>
        )}
        <div className="flex flex-row justify-center w-full">
          <button
            className="ButtonBGBlue disabled:opacity-50 w-fit inline-flex justify-center items-center rounded h-[50px] border-2 shadow-sm px-4 py-2 text-lg font-medium text-white border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
            id="card-button"
            disabled={isLoading}
            onClick={handlePaymentMethodSubmission}
          >
            {isLoading ? (
              <div className="LoadingSpinner w-20 justify-center flex">
                <div className="flex items-center justify-center w-4">
                  <div className="animate-spin flex">
                    <img src="/app/assets/spinner.png" alt="spinner" />
                  </div>
                </div>
              </div>
            ) : (
              additionalInformation["creditcard"]["textButton"]
            )}
          </button>
        </div>
      </form>
    </>
  );
};

const PayPalContent = ({
  brandName,
  userId,
  isSuccess,
  setIsSuccess,
  plan,
  planType,
}: {
  brandName: string;
  userId: string;
  isSuccess: boolean;
  setIsSuccess: (isSuccess: boolean) => void;
  plan: VIPPlan;
  planType: "vip" | "noAds";
}) => {
  const { i, cdnUrl } = useTranslation();

  const [isError, setIsError] = useState(false);

  if (isError) {
    return (
      <div className="h-fit flex flex-col items-center justify-center text-custom-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="50"
          viewBox="0 -960 960 960"
          width="50"
          className="text-red-500"
        >
          <path
            fill="rgb(239,68,68)"
            d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>

        <p className="text-xl font-semibold mt-6 text-center mb-8">
          {i(
            "Oops... there seems to have been a problem with your credit card"
          )}
        </p>
        <div>
          <ButtonBGBlue
            innerText={i("Try again")}
            onClick={() => setIsError(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="membership-paypal-popup"
      className="h-fit flex flex-col items-center justify-center text-custom-black"
    >
      <img src={plan["data-image"]} alt="vidamora logo" className="w-24 mb-3" />

      {plan["isPromoNewPriceMonthly"] && (
        <p class="line-through !text-[24px] text-custom-orange font-semibold">
          {plan["data-title"]}
        </p>
      )}
      <p className="text-4xl font-semibold text-custom-orange">
        {plan["isPromoNewPriceMonthly"] || plan["data-title"]}
      </p>
      <p className="text-xl mt-6 text-center font-semibold">
        {plan["data-subtitle_line_1"]}
      </p>

      <p className="text-lg text-center font-semibold mb-8">
        {plan["isPromoNewPrice"] && (
          <>
            <span className="line-through">{plan["data-subtitle_line_2"]}</span>{" "}
          </>
        )}
        {plan["isPromoNewPrice"] || plan["data-subtitle_line_2"]}
      </p>
      {plan["isPromoNote"] && (
        <p
          className="mb-8 text-center"
          dangerouslySetInnerHTML={{ __html: plan["isPromoNote"] }}
        ></p>
      )}

      <PayPalButtons
        onError={(err) => {
          setIsError(true);
        }}
        onApprove={(data, actions) => {
          setIsSuccess(true);
          return api.subscription
            .getSuccessUrl({
              subscriptionId: data.subscriptionID,
              subscriptionGroup: planType,
              provider: "paypal",
            })
            .then((res) => {
              if (res.status !== "SUCCESS")
                throw new Error("Error while fetching success url");

              window.location.href = res.data.url;
            })
            .catch((err) => {
              window.location.href =
                planType === "vip"
                  ? "settings?tab=vip-success"
                  : "settings?tab=noads-success";
            });
        }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: plan.paymentsMethod.paypal["data-paypal-plan-Id"],
            custom_id: userId,
            application_context: {
              brand_name: brandName,
              shipping_preference: "NO_SHIPPING",
            },
          });
        }}
      />
    </div>
  );
};

export default PaymentSection;
