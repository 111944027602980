import React, { FC } from "react";

type Props = {
  title: string;
  textAlign?: "center" | "right" | "left";
};

const SayHelloHeader: FC<Props> = ({ title, textAlign = "left" }) => {
  return (
    <div
      className="SayHelloHeader text-lg font-semibold text-custom-black"
      style={{ textAlign: textAlign }}
    >
      {title}
    </div>
  );
};

export default SayHelloHeader;
