import React, { FC, useContext, useEffect, useRef } from "react";
import ButtonBGBlue from "../buttonBGBlue";
import SayHelloHeader from "../sayHelloHeader";
import SayHelloUserImage from "../sayHelloUserImage";
import { SendMessagePopupContext } from "../../context/SendMessagePopupContext";

import useToast from "../../hooks/useToast";
import { TranslationContext } from "../../context/TranslationContext";
import useMessenger from "../../hooks/useMessenger";
import { SendMessageProps } from "../../redux/slices/messengerSlice";
import StyledPopup from "../styledPopup";
import MessageChatBox from "../messageChatBox";
import GreetingActiveConvo from "../greetingActiveConvo";
import MessageActiveConvo from "../messageActiveConvo";

const CustomMessagePopup: FC = () => {
  const {
    isOpen,
    setIsOpen,
    imageSrc,
    recipientUserId,
    recipientUserName,
    message,
    setMessage,
  } = useContext(SendMessagePopupContext);

  const handleClose = () => {
    setIsOpen(false);
    setMessage("");
  };

  return (
    <StyledPopup open={isOpen} onClick={handleClose} maxWidth={750}>
      <SendMessagePopupContent
        imageSrc={imageSrc}
        recipientUserId={recipientUserId}
        recipientUserName={recipientUserName}
        message={message}
        setMessage={setMessage}
        setIsOpen={setIsOpen}
      />
    </StyledPopup>
  );
};

export function SendMessagePopupContent({
  imageSrc,
  recipientUserId,
  recipientUserName,
  message,
  setMessage,
  setIsOpen,
  onSend,
  disableLiveAlerts,
}: {
  imageSrc: string;
  recipientUserId: number;
  recipientUserName: string;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSend?: () => void;
  disableLiveAlerts?: boolean;
}) {
  const {
    fetchMessages,
    currentConversation,
    sendMessage,
    setChatMessage,
    error,
  } = useMessenger();

  const { openToast, openTimedToast } = useToast();
  const { i } = useContext(TranslationContext);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    fetchMessages({
      increasePageSize: false,
      conversationId: -5,
      resetPageSize: false,
      fromLiveAlert: false,
      userId: recipientUserId,
    });
  }, [recipientUserId]);

  const handleSend = (_message: string) => {
    const response = sendMessage({
      chatMessage: _message,
      discussionId: -1,
      userId: recipientUserId,
      username: recipientUserName,
    } as SendMessageProps);
    response.then(({ payload: { http_code, status, message } }) => {
      if (http_code === 400) {
        openToast({
          title: i("Error"),
          description: message,
          type: "error",
        });
      } else {
        openTimedToast({
          title: i("Success"),
          description: i("Message sent!"),
          type: "success",
          timeout: 4000,
        });
        setMessage("");
        setChatMessage("");
        if (onSend) onSend();
        if (setIsOpen) setIsOpen(false);
      }
    });
  };

  return (
    <div className="SayHelloCustom h-full min-w-72 xs:min-w-80 w-full z-[60]">
      <div
        className={`h-full flex flex-col w-full ${
          onSend ? "" : "max-w-[855px]"
        } items-center`}
      >
        {/* <div className="flex items-center w-full xs:w-3/4 space-x-2">
          <div className="w-full flex justify-end"></div>
          <div className="min-w-[100px]">
            <SayHelloUserImage imageSrc={imageSrc} />
          </div>
          <div className="w-full"></div>
        </div> */}
        <div className="w-full h-full flex flex-col">
          <div className="mt-[2px] mb-[15px]">
            <SayHelloHeader title="" />
          </div>
          {/* <>
            <div>
              <div className="StyledTextArea flex w-full mb-5 relative xs:h-[78px] md:h-[156px]">
                <textarea
                  autoFocus={true}
                  tabIndex={999}
                  ref={textAreaRef}
                  maxLength={5000}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={4}
                  data-testid="message-popup-textarea"
                  className="ext-area w-full h-full border border-custom-gray rounded-[4px] xs:p-2 md:p-4 placeholder:text-base placeholder:text-custom-black-200 resize-none"
                  placeholder={i("Your message to [recipient]", {
                    recipient: recipientUserName,
                  })}
                ></textarea>
                <div className="right-[15px] bottom-[15px] absolute z-20">
                  <div className="StyledTextAreaCounter text-custom-light-gray-600 text-xs">
                    {message.length} / 5000
                  </div>
                </div>
              </div>
            </div>
          </> */}

          <div className="h-full overflow-hidden">
            {currentConversation.items.length > 0 && (
              <MessageActiveConvo messages={currentConversation.items} />
            )}
          </div>

          {currentConversation.conversationInfo?.userId != -1 && (
            <div
              className={`pb-[18px] h-fit pt-[12px] ${
                onSend ? "pr-5" : "px-5"
              }`}
            >
              <MessageChatBox
                isGreetings
                onSend={handleSend}
                disableLiveAlerts={disableLiveAlerts}
              />
            </div>
          )}

          {/* <div className="mb-6 sm:mb-0">
            <ButtonBGBlue
              className="w-40"
              innerText={i("Send")}
              testid="message-popup-send-button"
              onClick={handleSend}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CustomMessagePopup;
