import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Greetings extends Api {
  public async getGreetings(body: {
    pageIndex: number;
    pageSize: number;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        total: number;
        items: IGreeting[];
      };
    }
  > {
    return this.get("/greetings", body);
  }

  public async getGreeting(body: { greetingId: number }): Promise<
    HTTPResponse & {
      data: {
        alert_icon: string;
        alert_message: string;
        date: string;
        isPhoto: boolean;
        message: string;
        pathPhoto: string;
        time: string;
        userId: string;
      };
    }
  > {
    return this.get("/greeting", body);
  }

  public async postGreeting(body: {
    userId: number;
    greetingId: number | string;
    Personnaliser_imageId: number;
    personnaliser_message: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/greeting", body);
  }

  public async deleteGreeting(body: {
    greetingId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/greeting", body);
  }
}

export interface IGreeting {
  userId: number;
  greetingId: number;
  userName: string;
  profileMainPhoto: string;
  age: string;
  prenom: string;
  location: string;
  isVIP: boolean;
  isCertified: boolean;
  isUserDeleted: boolean;
  isUserOnline: boolean;
  isNew: boolean;
  DateDerniereVisite: string;
}
