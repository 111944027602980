import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../backendAPI";

import { logout } from "./authSlice";

export interface State {
  answers: RegistrationAnswers;
  currentIndex: number;
  isDone: boolean;
  selectedCountryName: string;
  selectedCityName: string;
  justFinishedRegistration: boolean;
  isFading: boolean;
  lastIndex?: number;
}

export const initialAnswers = {
  cmbpays_autocomplet: "",
  txtville: "",
  txtcityid: 0,
  autocomplete: "",
  lat: "",
  lng: "",
  locality: "",
  country: "",
  cmbJour: 0,
  cmbMois: 0,
  cmbAnnee: 0,
  optsexe: 0,
  cmbSexeRechercher: 0,

  txtprenom: "",
  cmbAnimaux: "",
  cmbTaille: 0,
  cmbAppPhys: 0,
  cmbCheveux: 0,
  cmbYeux: 0,
  cmbEtatCivile: 0,
  cmbNbEnfantActuel: 0,
  cmbEnfantDesirer: 0,
  cmbFumeurs: 0,
  cmbAlcool: 0,
  cmbDrogue: 0,
  cmbOccupation: 0,
  txtmsg: "",
  chkDDN: 0,
  chkalert: 0,
  chknl: 0,
  chkAmour: "",
  optVisibilite: 0,
};

const initialState: State = {
  answers: initialAnswers,
  currentIndex: 0,
  isDone: false,
  selectedCountryName: "",
  selectedCityName: "",
  justFinishedRegistration: false,
  isFading: false,
  lastIndex: undefined,
};

export interface RegistrationAnswers {
  cmbpays_autocomplet: string;
  txtville: string;
  txtcityid: number;
  autocomplete: string;
  lat: string;
  lng: string;
  locality: string;
  country: string;
  cmbJour: number;
  cmbMois: number;
  cmbAnnee: number;
  optsexe: number;
  cmbSexeRechercher: number;
  txtprenom: string;
  cmbAnimaux: string;
  cmbTaille: number;
  cmbAppPhys: number;
  cmbCheveux: number;
  cmbYeux: number;
  cmbEtatCivile: number;
  cmbNbEnfantActuel: number;
  cmbEnfantDesirer: number;
  cmbFumeurs: number;
  cmbAlcool: number;
  cmbDrogue: number;
  cmbOccupation: number;
  txtmsg: string;
  chkDDN: number;
  chkalert: number;
  chknl: number;
  chkAmour: string;
  optVisibilite: number;
}

export const createAccount = createAsyncThunk(
  "user/createAccount",
  async ({
    email,
    password,
    username,
    password2,
  }: {
    email: string;
    password: string;
    username: string;
    password2: string;
  }) => {
    const response = await api.registration.postRegistration({
      email,
      password,
      username,
      password2,
    });

    return response;
  }
);

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    reset: () => initialState,
    setLastIndex: (state, action: PayloadAction<number>) => {
      state.lastIndex = action.payload;
    },
    moveForward: (state) => {
      if (state.lastIndex === undefined) return;
      if (state.currentIndex >= state.lastIndex) {
        state.isDone = true;
        return;
      }

      state.isFading = true;

      state.currentIndex += 1;

      if (window.innerWidth < 768) {
        setTimeout(() => {
          const topTarget = document.querySelector(
            '[data-nav="scroll-top-target"]'
          );

          if (!topTarget) return;
          topTarget.scrollIntoView({ behavior: "smooth" });
        }, 150);
      }
    },

    setIsFading: (state, action: PayloadAction<boolean>) => {
      state.isFading = action.payload;
    },

    moveBackward: (state) => {
      if (state.currentIndex === 0) return;
      state.currentIndex -= 1;
    },

    setIsDone: (state, action: PayloadAction<boolean>) => {
      state.isDone = action.payload;
    },

    setSelectedCityName: (state, action: PayloadAction<string>) => {
      state.selectedCityName = action.payload;
    },
    setSelectedCountryName: (state, action: PayloadAction<string>) => {
      state.selectedCountryName = action.payload;
    },

    setJustFinishedRegistration: (state, action: PayloadAction<boolean>) => {
      state.justFinishedRegistration = action.payload;
    },

    setQuestionAnswer(
      state,
      action: PayloadAction<{
        key: keyof typeof initialAnswers;
        value: string | number;
      }>
    ) {
      const { key, value } = action.payload;

      Object.assign(state.answers, { [key]: value });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

export const {
  reset,
  moveBackward,
  moveForward,
  setQuestionAnswer,
  setIsDone,
  setSelectedCityName,
  setSelectedCountryName,
  setJustFinishedRegistration,
  setIsFading,
  setLastIndex,
} = registrationSlice.actions;

export default registrationSlice.reducer;
