import React from "react";

import { useVideoChat } from "../contexts/VideoChatContext";

const microphone = "/app/assets/microphone.svg";
const microphoneOff = "/app/assets/microphone-off.svg";
const phonedown = "/app/assets/phonedown.svg";
const camera = "/app/assets/camera.svg";
const cameraOff = "/app/assets/camera-off.svg";
const messageIcon = "/app/assets/messages/messageIcon.svg";

/**
 * Displays controls to interact with the VideoChatContext, such as toggling media tracks and disconnecting the call
 * @component
 */
export default function VideoChatControls() {
  const {
    isDialing,
    useMic,
    toggleMic,
    useCamera,
    toggleCamera,
    toggleChat,
    hangUpCall,
    remoteStream,
    call,
    convo,
    message,
    setMessage,
  } = useVideoChat();

  const ActiveCallControls = () => {
    return (
      <>
        <ControlButton
          disabled={isDialing}
          iconSrc={useMic ? microphone : microphoneOff}
          onClick={toggleMic}
        />
        <ControlButton
          iconSrc={phonedown}
          variant="important"
          onClick={() => {
            setMessage("This call has ended.");
            hangUpCall();
          }}
        />
        <ControlButton
          disabled={isDialing}
          iconSrc={useCamera ? camera : cameraOff}
          onClick={toggleCamera}
        />
      </>
    );
  };

  const AwaitingCallControls = () => {
    return (
      <>
        <ControlButton
          iconSrc={useMic ? microphone : microphoneOff}
          onClick={toggleMic}
        />
        <ControlButton
          iconSrc={phonedown}
          variant="success"
          rotation={225}
          onClick={() => {
            convo && console.log(convo);
            convo &&
              call(
                convo.userId,
                convo.discussionId,
                String(convo.userId),
                convo.image
              );
          }}
        />
        <ControlButton
          iconSrc={useCamera ? camera : cameraOff}
          onClick={toggleCamera}
        />
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      {message && (
        <div className="flex w-full justify-center items-center">{message}</div>
      )}
      <div className="flex justify-center relative" style={{ gap: "3vw" }}>
        {remoteStream || isDialing ? (
          <ActiveCallControls />
        ) : (
          <AwaitingCallControls />
        )}
        <div className="absolute right-0 xs:right-6">
          <ControlButton iconSrc={messageIcon} onClick={toggleChat} />
        </div>
      </div>
    </div>
  );
}

type ControlButtonProps = {
  rotation?: number;
  size?: number;
  iconSrc: string;
  onClick(): void;
  variant?: "important" | "success";
  disabled?: boolean;
};
/**
 *
 * @param param0
 * @todo hover doesnt work -.-
 */
export const ControlButton = ({
  size = 65,
  iconSrc,
  onClick,
  variant,
  disabled,
  rotation = 0,
}: ControlButtonProps) => {
  let color;
  switch (variant) {
    case "success":
      color = "rgb(0, 255, 0)";
      break;
    case "important":
      // color = "bg-custom-orange hover:opacity-40";
      color = "rgb(255, 121, 63)";
      break;
    default:
      color = "hover:bg-custom-dark-gray";
  }

  return (
    <button
      disabled={disabled}
      className={`w-[${size}px] h-[${size}px] rounded-full p-4 hover:opacity-50 opacity-100 cursor-pointer`}
      style={{
        background: color,
        opacity: disabled ? 0.5 : 1,
        transform: `rotate(${rotation}deg)`,
      }}
      onClick={onClick}
    >
      <img src={iconSrc} height={size} width={size} />
    </button>
  );
};
