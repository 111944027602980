import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export type ForumPost = {
  categoryId: string;
  categoryTitle: string;
  categoryDesc: string;
  categoryImage: string;
  categoryURL: string;
  nbDiscussion: string;
  nbReponse: string;
  lastAnswer_by: string;
  lastAnswer_bySeeParticipationURL: string;
  lastAnswer_Date: string;
  lastAnswer_image: string;
  lastDiscussion_Title: string;
  lastDiscussion_URL: string;
  lastDiscussion_discussionId: string;
  created_by: string;
  created_date: string;
  created_bySeeParticipationURL: string;
  nbVu: string;
  discussionURL: string;
  discussionTitle: string;
  discussionImage: string;
  discussionId: string;
  iconAlt?: string;
};

export type POSTForumAction =
  | "setAllAsread"
  | "addDiscuFavorite"
  | "followDiscu"
  | "addVote"
  | "reportReponse"
  | "addDiscussion";

export type DELETEForumAction =
  | "removeDiscuFavorite"
  | "unfollowDiscu"
  | "removeVote";

export default class Forum extends Api {
  public async postForumAction(body: {
    action: POSTForumAction;
    discussionId?: number;
    reponseId?: string;
    reponseURL?: string;
    userId?: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/forumAction", body);
  }

  public async deleteForumAction(body: {
    action: DELETEForumAction;
    discussionId?: number;
    reponseId?: number;
    userId?: number;
    reponseURL?: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/forumAction", body);
  }

  public async getForumDiscussions(queryParamsMap: {
    pageIndex: number;
    pageSize: number;
    forumId: string;
    search?: string;
  }): Promise<HTTPResponse & { data: any }> {
    return this.get("/forumDiscussions", queryParamsMap);
  }

  public async getForumDiscussion(queryParamsMap: {
    pageIndex: number;
    pageSize: number;
    discussionId: number;
  }): Promise<
    HTTPResponse & {
      data: {
        discussionFavorited: boolean;
        discussionFollowed: boolean;
      } & any;
    }
  > {
    return this.get("/forumDiscussion", queryParamsMap);
  }

  public async getForums(): Promise<
    HTTPResponse & {
      data: {
        items: number;
        total: number;
        list: ForumPost[];
      };
    }
  > {
    return this.get("/forums");
  }

  public async getForumPages(queryParamsMap: {
    pageIndex: number;
    pageSize: number;
    page:
      | "dernieresParticipations"
      | "nouvellesDiscussions"
      | "nouveauxmessages"
      | "mesParticipations"
      | "participationsMembre"
      | "discussionFavorites";
    username?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        items: number;
        total: number;
        categoryId: number;
        categoryURL: string;
        discussionImage: string;
        discussionTitle: string;

        list: {
          nbDiscussion: number;
          nbReponse: number;
          lastAnswer_by: string;
          lastAnswer_bySeeParticipationURL: string;
          lastAnswer_Date: string;
          lastAnswer_image: string;
          lastDiscussion_Title: string;
          lastDiscussion_URL: string;
          created_by: string;
          created_date: string;
          created_bySeeParticipationURL: string;
          nbVu: any;
          discussionURL: string;
          discussionTitle: string;
          discussionImage: string;
          discussionId: number;
        }[];
      };
    }
  > {
    return this.get("/forumPages", queryParamsMap);
  }

  public async addDiscussion(body: {
    forumId: number;
    message: string;
    title: string;
    followDiscussion: 0 | 1;
  }): Promise<
    HTTPResponse & {
      data: {
        discussionURL: string;
        discussionId: string;
        message: string;
      } & any;
    }
  > {
    return this.post("/forumDiscussion", body);
  }
}
