import { FC, useContext, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileInterviewItem from "../profileInterviewItem";
import StyledContainer from "../styledContainer";
import StyledContainerHeader from "../styledContainerHeader";

type InterviewItem = {
  question: string;
  reponse: string;
};

type Props = {
  interviewItems: InterviewItem[];
};

const NB_QUESTIONS_WHEN_NOT_EXPANDED = 4;

const ProfileInterviewContainer: FC<Props> = ({ interviewItems }) => {
  const { i } = useContext(TranslationContext);
  const isInterviewEmpty = interviewItems.length == 0;

  return (
    <div id="ProfileInterviewContainer" className="ProfileInterviewContainer">
      <StyledContainer className="sm:px-[30px] px-[15px] py-[30px]">
        <StyledContainerHeader className="pb-6">
          {i("Face to face Interview")}
        </StyledContainerHeader>
        {isInterviewEmpty ? (
          <EmptyInterview />
        ) : (
          <Interviews interviewItems={interviewItems} />
        )}
      </StyledContainer>
    </div>
  );
};

const EmptyInterview: FC = () => {
  const { i } = useContext(TranslationContext);
  return (
    <p
      data-testid="profile-interview-no-interview"
      className="text-base leading-[22px] font-normal text-custom-black-500"
    >
      {i("The user has not yet completed this section.")}
    </p>
  );
};

const Interviews: FC<{ interviewItems: InterviewItem[] }> = ({
  interviewItems,
}) => {
  const { i } = useContext(TranslationContext);
  const [expanded, setExpanded] = useState(false);
  const interviewCanExpand =
    interviewItems.length > NB_QUESTIONS_WHEN_NOT_EXPANDED;

  const interviewItemsToShow = expanded
    ? interviewItems
    : interviewItems.slice(0, 4);
  return (
    <>
      <div className="flex flex-col space-y-[35px]">
        {interviewItemsToShow.map((item, index) => (
          <ProfileInterviewItem
            key={index}
            index={index}
            question={item.question}
            answer={item.reponse}
          />
        ))}
      </div>
      {interviewCanExpand && (
        <p
          onClick={() => setExpanded(!expanded)}
          className="hover:cursor-pointer text-custom-blue text-base leading-[26px] pt-8"
        >
          {expanded ? i("Show less") : i("Show more")}
        </p>
      )}
    </>
  );
};

export default ProfileInterviewContainer;
