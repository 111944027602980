import { Greeting } from "../context/SayHiContext";
import { Languages, Translation } from "../context/TranslationContext";
import Api, { HTTPResponse } from "./Api";

export type LocationHelperResult = LocationHelperCountry | LocationHelperCity;

export type LocationHelperCountry = {
  id: number;
  description: string;
  valuesForGoogleAPI: string;
  method: "googleapi" | "db";
  isSelected: boolean;
  comboboxValueRegistration: string;
};

type LocationHelperCity = {
  id: number;
  name: string;
};

export type LocationHelperPlace = {
  name: string;
  name_suggestion: string;
  countryId: number;
  cityId: number;
  provinceId: number;

  type?: "country" | "region" | "city";
};

type RegistrationQuestions = {
  list: unknown[];
  heightSliderValues: { id: string; description: string }[];
};

export default class General extends Api {
  public async getLocationHelperCountries(): Promise<
    HTTPResponse & {
      data: {
        title: string;
        subtitle: string;
        type: string;
        nextType: string;
        btnBack: string;
        list: {
          id: number;
          description: string;
          countryCode: string;
          method: "googleapi" | "db";
          isSelected: boolean;
        }[];
      };
    }
  > {
    return this.get("/general/locationHelper/countries");
  }

  public async getLocationHelper(body: {
    type: "countries" | "provinces" | "departements" | "cities";
    id?: number;
    name?: string;
    from?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        title: string;
        subtitle: string;
        type: string;
        nextType: string;
        btnBack: string;
        list: {
          id: number;
          name: string;
          countryCode: string;
          method: "googleapi" | "db";
          isSelected: boolean;
        }[];
      };
    }
  > {
    return this.get("/general/locationHelper", body);
  }

  public async getProvinces(body: {
    id?: number;
    name?: string;
    search?: string;
  }): Promise<
    HTTPResponse & {
      data: {
        title: string;
        subtitle: string;
        type: string;
        nextType: string;
        btnBack: string;
        list: {
          id: string;
          description: string;
        }[];
      };
    }
  > {
    return this.get("/general/locationHelper/provinces", body);
  }

  public async getCitiesSuggestion(
    countryId: number,

    search: string,
    provinceId?: number
  ): Promise<
    HTTPResponse & {
      data: {
        returnedItems: number;
        list: {
          fullLocation_suggestion: string;
          fullLocation: string;
          cityId: number;
        }[];
      };
    }
  > {
    return this.get("/general/citiesSuggestion", {
      countryId: countryId,
      search: search,
      departementId: -1,
      provinceId: provinceId ?? -1,
    });
  }

  public async getPlacesSuggestion(search: string): Promise<
    HTTPResponse & {
      data: {
        returnedItems: number;
        list: LocationHelperPlace[];
      };
    }
  > {
    return this.get("/general/placesSuggestion", {
      search: search,
    });
  }

  private cachedRegistrationQuestions: any;

  public async getRegistrationQuestions(): Promise<
    HTTPResponse & { data: RegistrationQuestions }
  > {
    if (this.cachedRegistrationQuestions)
      return this.cachedRegistrationQuestions;

    this.cachedRegistrationQuestions = await this.get(
      "/general/getRegistrationQuestions"
    );

    return this.cachedRegistrationQuestions;
  }

  public async getTranslation(lang: Languages): Promise<
    HTTPResponse & {
      data: { translations: Translation; greetings: Greeting[] };
    }
  > {
    return this.get("/translations", {}, undefined, true);
  }
}
