import { FC, useContext, useEffect, useState } from "react";
import { Subject, BehaviorSubject, tap } from "rxjs";
import { TranslationContext } from "../../context/TranslationContext";

import useSearch from "../../hooks/useSearch";
import { LocationHelperPlace } from "../../backendAPI/General";

declare global {
  interface Window {
    cityId$: Subject<string>;
    selectedPlaceName$: Subject<string>;
    isFromGoogle$: BehaviorSubject<boolean>;
    countryId: number | undefined;
    countryCode: string | undefined;

    location$: Subject<LocationHelperPlace>;
  }
}

const LocationPickerList: FC = () => {
  const {
    setLocation: setReduxLocation,
    setSelectedPlaceName: setReduxSelectedPlaceName,
    setFilteredSearch,
    setLocationFromGoogleApi,
  } = useSearch();
  const { i } = useContext(TranslationContext);

  const [isLoading, setIsLoading] = useState(true);

  const [selectedCityId, setSelectedCityId] = useState<string | null>(null);

  const [showGoogleInput, setShowGoogleInput] = useState(false);
  const [lat, setLat] = useState<number | null>(null);
  const [long, setLong] = useState<number | null>(null);

  const [selectedPlaceName, setSelectedPlaceName] =
    useState<string | null>(null);
  const [selectedPlaceNameFromGoogle, setSelectedPlaceNameFromGoogle] =
    useState<string | null>(null);

  useEffect(() => {
    if (selectedCityId) {
      setReduxSelectedPlaceName(selectedPlaceName!);

      setReduxLocation({
        cityId: +selectedCityId,
        name_suggestion: selectedPlaceName as string,
        countryId: window.countryId || -1,
        provinceId: -1,

        type: "city",
        name: selectedPlaceName as string,
      });

      return;
    }

    // if (lat) {
    //   setFilteredSearch({ lat: lat });
    // }

    // if (long) {
    //   setFilteredSearch({ lng: long });
    // }

    // setReduxSelectedPlaceName(selectedPlaceNameFromGoogle!);

    const elementOutsideDropdown = document.querySelector(
      ".PageHeader"
    ) as HTMLElement;

    if (elementOutsideDropdown === null) return;

    elementOutsideDropdown.click();
  }, [selectedCityId, selectedPlaceName, selectedPlaceNameFromGoogle]);

  useEffect(() => {
    setTimeout(() => {
      import("./script").then(({ LocationFetcher }) => {
        LocationFetcher.init();

        window.cityId$ = new Subject<string>();
        window.selectedPlaceName$ = new Subject<string>();
        window.isFromGoogle$ = new BehaviorSubject(false);

        window.location$ = new Subject<LocationHelperPlace>();

        window.location$.subscribe((location: LocationHelperPlace) => {
          setReduxLocation(location);
          setReduxSelectedPlaceName(location.name);
        });

        window.cityId$.subscribe((cityId: string | null) => {
          console.log("city id changed");
          setSelectedCityId(cityId);
        });

        window.selectedPlaceName$.subscribe((placeId: string | null) => {
          console.log("selected name place changed");
          setSelectedPlaceName(placeId);
        });

        window.isFromGoogle$.subscribe((isFromGoogle: boolean) => {
          setShowGoogleInput(isFromGoogle);
        });

        setIsLoading(false);
      });
    }, 1750);

    return () => {
      if (window.cityId$) window.cityId$.complete();
      if (window.selectedPlaceName$) window.selectedPlaceName$.complete();
      if (window.isFromGoogle$) window.isFromGoogle$.complete();

      if (window.location$) window.location$.complete();

      //@ts-ignore
      window.cityId$ = null;
      //@ts-ignore
      window.selectedPlaceName$ = null;
      //@ts-ignore
      window.isFromGoogle$ = null;

      //@ts-ignore
      window.location$ = null;
    };
  }, []);

  useEffect(() => {
    if (!showGoogleInput) {
      const existingPacContainer = document.querySelector(".pac-container");

      if (existingPacContainer) {
        existingPacContainer.remove();
      }

      return;
    }

    const googleInput = document.getElementById(
      "google-input-from-picker"
    ) as HTMLInputElement;

    let autocomplete: any = new google.maps.places.Autocomplete(googleInput, {
      componentRestrictions: { country: window.countryCode || null },
    });

    const handlePlaceChanged = () => {
      const place = autocomplete.getPlace();

      if (
        !place.geometry ||
        !place.geometry.location ||
        !place.name ||
        !place.address_components ||
        !place.formatted_address
      )
        return;

      // setLat(place.geometry.location.lat());
      // setLong(place.geometry.location.lng());

      // setSelectedPlaceNameFromGoogle(place.formatted_address);

      // setReduxSelectedPlaceName(place.formatted_address);
      setLocationFromGoogleApi({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.formatted_address,
        countryId: window.countryId || -1,
      });

      const showButton = document.getElementById(
        "location-helper-select-button"
      )!;

      showButton.classList.add("show-button");
    };

    let autocompleteListener = autocomplete.addListener(
      "place_changed",
      handlePlaceChanged
    );

    return () => {
      google.maps.event.removeListener(autocompleteListener);
      google.maps.event.clearInstanceListeners(autocomplete);
      autocomplete = null;
    };
  }, [showGoogleInput]);

  return (
    <>
      <span className="LocationPickerList">
        <style id="location-helper-inline-styles"></style>
        <div id="resultCities"></div>
        <span
          id="location-helper-list-trigger"
          className="min-w-24 px-2 pb-[2px] h-fit font-medium hidden"
        >
          {i("click here")}
        </span>

        <div id="location-helper-list-container">
          <div id="location-helper-list">
            <div className="section">
              <div className="section_header">
                <p
                  id="location-helper-info"
                  className="text-base font-semibold text-custom-black"
                ></p>
                <p id="location-helper-info-type"> </p>
                <p
                  id="location-helper-close"
                  className="max-w-[40px] max-h-[40px]"
                >
                  <svg
                    height={20}
                    width={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-full h-full hover:text-gray-600"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </p>
              </div>
              <div className="section_header">
                {!showGoogleInput && (
                  <input
                    type="text"
                    id="location-helper-filter"
                    placeholder=""
                  />
                )}
              </div>

              <div className="section_middle">
                <>
                  <div
                    style={
                      showGoogleInput
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    id="location-helper-google-container"
                  >
                    <input
                      className="w-full"
                      id="google-input-from-picker"
                      type="text"
                    />
                  </div>
                </>

                <ul
                  style={{ display: showGoogleInput ? "none" : "block" }}
                  id="location-helper-ul"
                ></ul>
              </div>

              <div className="section_footer" id="location-helper-actions">
                <div id="location-back"></div>

                <button
                  id="location-helper-select-button"
                  className="w-fit inline-flex justify-center items-center rounded h-[50px] border-2 shadow-sm px-4 py-2  text-lg font-medium text-white border-custom-blue bg-custom-blue transition hover:duration-150 hover:bg-white hover:text-custom-blue"
                >
                  {i("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="location-helper-background-overlay"></div>
      </span>
    </>
  );
};

export default LocationPickerList;
