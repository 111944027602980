import React, {
  FC,
  useState,
  useRef,
  useContext,
  useEffect,
  lazy,
  Suspense,
} from "react";
import useAuth from "../../hooks/useAuth";
import axios, { AxiosError } from "axios";
import LoadingSpinner from "../loadingSpinner";
import ButtonBlueBGWhite from "../buttonBlueBGWhite";
import ButtonBGBlue from "../buttonBGBlue";
import { EditProfileContext } from "../../context/EditProfileContext";
import PopUpHeader from "../popUpHeader";
import PopUpSubheader from "../popUpSubheader";
import api from "../../backendAPI";
import classNames from "../../utils/twClassNames";
import useTranslation from "../../context/TranslationContext";

import { HTTPResponse, ROOT } from "../../backendAPI/Api";
import useToast from "../../hooks/useToast";
import { Photo } from "../../types";
import useCompleteProfileProgression from "../../hooks/useCompleteProfileProgression";

type Props = { setAsMainImage?: boolean; close: () => void };

const PictureCropper = lazy(() => import("../pictureCropper"));

const MyProfilePictureUploader: FC<Props> = ({ setAsMainImage, close }) => {
  const { i, locale } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [path, setPath] = useState<string>("");
  const [uploadedPhoto, setUploadedPhoto] = useState<Photo>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { accessToken } = useAuth();
  const { myProfileInfo, setMyProfileInfo } = useContext(EditProfileContext);
  const { addedPhoto } = useCompleteProfileProgression();
  const { openTimedToast } = useToast();

  const [isFileTooBig, setIsFileTooBig] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;
    const file: File = event.target.files[0];

    if (file.size >= 10000000) {
      setIsFileTooBig(true);
      return;
    }

    setFile(event.target.files[0]);
    setPath(URL.createObjectURL(event.target.files[0]));
  };

  const resetFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFile(undefined);
    setPath("");
    if (inputRef && inputRef.current) inputRef.current.value = "";
  };

  useEffect(() => {
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    document
      .getElementById("UploadImageContainer")
      ?.addEventListener("drop", (e) => {
        e.preventDefault();
        if (!e.dataTransfer?.files[0]) return;
        setFile(e.dataTransfer?.files[0]);
        setPath(URL.createObjectURL(e.dataTransfer?.files[0]));
      });
  }, []);

  const handleSubmit = async () => {
    if (isLoading || !file) return;
    setIsLoading(true);

    const url = ROOT + "/image";
    const formData = new FormData();
    formData.append("uploadfile", file);
    formData.append("locale", locale);
    formData.append("type", "profile");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios.post(url, formData, config);
      if (response.status !== 200 || !myProfileInfo)
        throw new Error("Unable to save changes");

      const { data } = response.data;
      if (setAsMainImage) {
        const res = await api.image.patchImage({
          imageId: data?.imageId,
          action: "setMainImage",
        });
        if (res.status === "SUCCESS") {
          myProfileInfo.mainImage = {
            id: data?.imageId,
            path: data?.imageURL_grandFormat,
            isApprouved: false,
          };
        }
      }

      if (myProfileInfo.photos.length === 0) addedPhoto();
      myProfileInfo.photos.push({
        id: data?.imageId,
        path: data?.imageURL_vignette,
        isApprouved: false,
      });
      myProfileInfo.highResPhotos.push({
        id: data?.imageId,
        path: data?.imageURL_grandFormat,
        isApprouved: false,
      });
      setMyProfileInfo({ ...myProfileInfo });
      setUploadedPhoto({
        id: data.imageId,
        path: data?.imageURL_grandFormat,
        isApprouved: false,
      });
    } catch (error) {
      const message =
        axios.isAxiosError(error) &&
        (error as AxiosError<HTTPResponse>).response?.data?.message;
      openTimedToast({
        title: i("Error"),
        type: "error",
        description: message || i("Unable to save changes."),
      });
      setIsLoading(false);
    }
  };

  if (myProfileInfo && myProfileInfo.photos.length > 11 && !uploadedPhoto) {
    return (
      <div className="md:w-600 w-auto h-auto flex space-y-6 flex-col mt-4 items-center p-6">
        <PopUpHeader>{i("Oups!")}</PopUpHeader>
        <PopUpSubheader>
          {i(
            "Your profile has already reached the maximum number of pictures. Please remove one if you want to add a new one."
          )}
        </PopUpSubheader>
      </div>
    );
  }

  return uploadedPhoto && file ? (
    <div className="w-full max-w-[600px] h-[600px]">
      <Suspense
        fallback={
          <div className="grid-col-1 place-items-center h-full">Loading...</div>
        }
      >
        <PictureCropper
          imgUrl={uploadedPhoto.path}
          photoId={uploadedPhoto.id}
          close={close}
        />
      </Suspense>
    </div>
  ) : (
    <div className="md:w-600 w-auto h-auto flex-col space-y-8 flex items-center p-6">
      {isFileTooBig && (
        <div className="flex flex-col items-center space-y-4">
          <img
            src="/app/assets/messages/reportIcon.svg"
            alt="report icon"
            className="w-20"
          />
          <p className="text-custom-black text-2xl font-semibold w-full">
            {i(
              "The file you are tying to upload is too big. Please, select a smaller one."
            )}
          </p>
        </div>
      )}
      <div>
        {!file && (
          <label htmlFor="files" className="cursor-pointer">
            <div
              onDropCapture={(e) => {
                e.preventDefault();
              }}
              id="UploadImageContainer"
              className={classNames(
                file
                  ? "border-custom-blue/90"
                  : "border-gray-300 border-dashed",
                "border-2 max-w-[500px] w-full py-6 px-5 mt-5 rounded-md hover:border-custom-blue/70 duration-500"
              )}
            >
              <p className="text-custom-black/80 text-lg text-left">
                {i("Drop files here, or click below!")}
              </p>
              <div className="flex text-custom-black space-x-2 text-sm bg-gray-200 w-fit p-4 py-2 rounded-md font-semibold my-4">
                <img
                  src="/app/assets/upload.png"
                  alt="upload icon"
                  className="w-5 object-cover"
                />
                <p>{i("Upload")}</p>
              </div>
              <input
                ref={inputRef}
                type="file"
                onChange={onChange}
                className="hidden"
                id="files"
                accept="image/*"
              />
              <p className="text-custom-black text-lg text-left">
                {i("You can upload files up to a maximum of 2 GB.")}
              </p>
            </div>
          </label>
        )}
        {file && (
          <div className="text-center">
            <button onClick={resetFile} className="py-3 text-custom-light-blue">
              {i("Remove File")}
            </button>
            <img
              className="md:max-h-[500px] sm:max-h-[350px]"
              alt="image to upload"
              style={{ width: "100%" }}
              src={path}
            />
          </div>
        )}
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-row lg:w-[325px] justify-center space-x-[15px]">
          <ButtonBlueBGWhite innerText={i("Cancel")} onClick={close} />
          <ButtonBGBlue
            innerText={i("Confirm")}
            onClick={() => handleSubmit()}
          />
        </div>
      )}
    </div>
  );
};

export default MyProfilePictureUploader;
