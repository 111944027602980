import { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import useTranslation from "../../context/TranslationContext/context";

type Props = {
  name:
    | "Messages"
    | "Visited your profile"
    | "Wants to meet you"
    | "Perfect match"
    | "Favorites"
    | "Settings"
    | "Logout";
  href: string;
  icon: string;
  isCurrentPage: boolean;
  hasNotification?: boolean;
  onClick?: () => void;
};

const SideNavLink: FC<Props> = ({
  name,
  href,
  icon,
  isCurrentPage,
  hasNotification,
  onClick,
}) => {
  const { i } = useTranslation();
  const router = useRouter();
  return (
    <div
      className="SideNavLink"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        if (href) {
          if (href.includes("messages")) {
            window.location.href = "/app/messages";
          } else {
            router.push(href);
          }
        }
      }}
    >
      <a
        aria-label={`Sidenav link for ${name}`}
        data-testid={`sidenav-link-for-${name}`}
        className={`${
          isCurrentPage ? "bg-indigo-50 " : ""
        }text-custom-blue hover:bg-custom-light-blue hover:bg-opacity-20 group flex px-2 py-2 items-center text-base font-medium rounded-[10px] cursor-pointer`}
      >
        <div className="flex relative shadow-custom-shadow-md mr-2 rounded-lg bg-white items-center justify-center p-2 d-flex align-items-center justify-content-center">
          <img src={icon} className="relative" alt={i(name)} />
          {hasNotification && <NotificationCircle />}
        </div>

        <span className="flex-1">{i(name)}</span>
      </a>
    </div>
  );
};

function NotificationCircle() {
  return (
    <div
      data-testid="notification-marker"
      className="border-2 absolute border-white right-1 top-1 rounded-full"
    >
      <div className="border-4 rounded-full border-custom-orange"></div>
    </div>
  );
}

export default SideNavLink;
