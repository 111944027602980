import { RootState } from "../store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../backendAPI";

import { checkIfHasMore } from "../utils";

import { login, logout } from "./authSlice";

export interface State {
  results: any[];
  pageIndex: number;
  hasMore: boolean;
  isLoading: boolean;
  error: Error | null;
  searchFilter: "all" | "criteria";
}
const initialState: State = {
  results: [],
  pageIndex: 1,
  hasMore: true,
  isLoading: false,
  error: null,
  searchFilter: "criteria",
};

export const fetchResults = createAsyncThunk(
  "visitedMyProfile/fetchResults",

  async (shouldReset: boolean = false, { getState }) => {
    const state = getState() as RootState;

    const pageIndex = shouldReset ? 1 : state.visitedMyProfile.pageIndex;
    const criteria = state.visitedMyProfile.searchFilter;

    const response = await api.visitedMyProfile.getVisitedMyProfile({
      pageIndex: pageIndex,
      pageSize: 12,
      type: criteria,
    });

    return response;
  }
);

export const visitedMyProfileSlice = createSlice({
  name: "visitedMyProfile",
  initialState,
  reducers: {
    reset: () => initialState,

    setSearchFilter: (state, action: PayloadAction<"all" | "criteria">) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;
      state.results = state.results.concat(action.payload.data.list);
      state.pageIndex += 1;
    });

    builder.addCase(fetchResults.pending, (state, action) => {
      const reset = action.meta.arg;

      if (reset) {
        state.results = [];
        state.pageIndex = 1;
      }
      state.isLoading = true;
    });

    builder.addCase(fetchResults.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as Error; // fix
    });

    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setSearchFilter, reset } = visitedMyProfileSlice.actions;

export default visitedMyProfileSlice.reducer;
