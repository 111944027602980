import React, { FC, useContext, useState } from "react";
import api from "../../backendAPI";
import { AlertMessageContext } from "../../context/AlertMessageContext";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileActionButton from "../profileActionButton";

type Props = {
  userId: number;
  profilId: number;
  initiallyDateMeVoted: boolean;
};

const VoteDateMeActionButton: FC<Props> = ({
  userId,
  profilId,
  initiallyDateMeVoted,
}) => {
  const { i } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateMeVoted, setIsDateMeVoted] = useState(initiallyDateMeVoted);
  const { pushAlert } = useContext(AlertMessageContext);

  const onClick = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const current = isDateMeVoted;
    setIsDateMeVoted((prev) => !prev);
    const response = await api.dateMe.postDateMeAction({
      userId: userId,
      userProfileId: profilId,
      choix: current ? "no" : "yes",
    });

    if (response.http_code !== 200) {
      setIsDateMeVoted((prev) => !prev);
      pushAlert({
        title: i("Error"),
        message: i("Failed. Please try again later!"),
        type: "error",
        time: 2000,
      });
    }
    setIsLoading(false);
  };

  return (
    <ProfileActionButton
      onClick={onClick}
      icon={{
        alt: i("I want to meet"),
        src: isDateMeVoted
          ? "/app/assets/filledActionHeartIcon.svg"
          : "/app/assets/actionHeartIcon.svg",
      }}
    />
  );
};

export default VoteDateMeActionButton;
