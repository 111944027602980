import React, { createContext, useState, useEffect, useRef } from "react";
import AlertMessageContainer from "../components/alertMessageContainer";
import { AlertMessage } from "../types";

interface Context {
  pushAlert: (alertMessage: AlertMessage) => void;
}

export const AlertMessageContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function AlertMessageProvider({ children }: Props) {
  const [alertMessages, setAlertMessages] = useState<AlertMessage[]>([]);

  const pushAlert = (alertMessage: AlertMessage) =>
    setAlertMessages([...alertMessages, alertMessage]);
  const skip = () => setAlertMessages(alertMessages.slice(1));

  return (
    <AlertMessageContext.Provider
      value={{
        pushAlert,
      }}
    >
      {children}
      {alertMessages[0] && (
        <AlertMessageContainer skip={skip} alertMessage={alertMessages[0]} />
      )}
    </AlertMessageContext.Provider>
  );
}
