import React, { FC, useContext } from "react";
import { useRouter } from "next/router";
import FooterLangDropdown from "../footerLangDropdown";
import FooterLinks from "../footerLinks";
import FooterLogo from "../footerLogo/FooterLogo";
import FooterSocial from "../footerSocial/FooterSocial";
import { TranslationContext } from "../../context/TranslationContext";

const Footer: FC = () => {
  const { i } = useContext(TranslationContext);
  const router = useRouter();
  const xmas = new Date();
  const year = xmas.getFullYear();
  return (
    <div className="Footer w-full bg-gradient-to-r from-custom-gradient-orange to-custom-red">
      <div className=" mx-auto max-w-[1400px] w-full  text-white lg:items-center flex pt-[43px] px-5 pb-[25px] py-10 lg:py-9 flex-col lg:flex-row relative">
        <div className="flex lg:flex-row lg-0 lg:h-auto order-1 lg:-order-1 items-center xs:items-end lg:items-center">
          <FooterLogo />
          <p className="flex items-center w-2/6 xs:w-fit ">© {year} |</p>
          <p
            onClick={() => router.push("/terms-and-conditions")}
            className="hover:underline font-semibold ml-1 cursor-pointer"
          >
            {" "}
            {i("Terms & Conditions")}
          </p>
          <p
            onClick={() => router.push("/complaints-policy")}
            className="hover:underline font-semibold ml-3 cursor-pointer"
          >
            {" "}
            {i("Complaints")}
          </p>
        </div>
        <div className="flex flex-1 justify-between">
          <div className="flex flex-1 flex-col lg:items-center lg:flex-row">
            <FooterSocial />
            <FooterLinks />
          </div>
          <div className="w-32 lg:border-l ml-2 h-8">
            <FooterLangDropdown availableLangs={["English", "French"]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
