import React, { FC } from "react";

type Props = { children?: React.ReactNode };

const ProfileStatsItemContainer: FC<Props> = ({ children }) => {
  return (
    <div className="ProfileStatsItemContainer flex flex-col w-full sm:px-[10px] text-custom-black-500">
      {children}
    </div>
  );
};

export default ProfileStatsItemContainer;
