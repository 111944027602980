import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = { onClick: () => void; icon: { alt: string; src: string } };

const ProfileActionButton: FC<Props> = ({ onClick, icon }) => {
  return (
    <button
      data-testid={`profile-action-button-for-${icon.alt}`}
      onClick={onClick}
      className="ProfileActionButton rounded-xl w-[70px] h-[50px] bg-custom-light-blue/10 grid place-items-center relative tooltip"
    >
      <img src={icon.src} alt={icon.alt} className="max-h-[33px]" />
      <div className="absolute pt-[54px]">
        <p
          className={classNames(
            "tooltiptext",
            icon.alt.length >= 10 ? "w-40 absolute top-16 h-9 -left-20" : "w-20"
          )}
        >
          {icon.alt}
        </p>
      </div>
    </button>
  );
};

export default ProfileActionButton;
