import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

type Gender = "Man" | "Woman" | "Both";

import {
  fetchResults,
  setSelectedMinAge,
  setSelectedMaxAge,
  setSelectedGenderName,
  setDistance,
  setSortBy,
  State,
  resetIndex,
} from "../redux/slices/onlineNowSlice";

import { Member } from "../backendProxies";

type UseOnlineNow = () => State & {
  resetIndex: () => void;
  fetchResults: (shouldReset: boolean) => void;
  setSelectedMinAge: (age: number) => void;
  setSelectedMaxAge: (age: number) => void;
  setSelectedGenderName: (name: string) => void;
  setDistance: (distance: number) => void;

  setSortBy: (sortBy: string) => void;

  reset: () => void;
  isLoading: boolean;
  hasMore: boolean;
  members: Member[];
};

const useOnlineNow: UseOnlineNow = () => {
  const selectResults = (state: RootState) => state.onlineNow.results;

  const selectMembers = createSelector([selectResults], (results) => {
    if (!results || results.length === 0) return [];

    return results.map((result) => new Member(result));
  });

  const fetchingResults = useSelector(
    (state: RootState) => state.onlineNow.fetchingResults
  );

  const members = useSelector(selectMembers);

  const hasMore = useSelector((state: RootState) => state.onlineNow.hasMore);

  const isLoading = useSelector(
    (state: RootState) => state.onlineNow.isLoading
  );

  const selectedMinAge = useSelector(
    (state: RootState) => state.onlineNow.selectedMinAge
  );

  const selectedGenderName = useSelector(
    (state: RootState) => state.onlineNow.selectedGenderName
  );

  const selectedMaxAge = useSelector(
    (state: RootState) => state.onlineNow.selectedMaxAge
  );

  const distance = useSelector((state: RootState) => state.onlineNow.distance);

  const sortBy = useSelector((state: RootState) => state.onlineNow.sortBy);

  const shouldReload = useSelector(
    (state: RootState) => state.onlineNow.shouldReload
  );

  const preferencesLoaded = useSelector(
    (state: RootState) => state.onlineNow.preferencesLoaded
  );

  const actions = useActions({
    fetchResults,
    setSelectedMinAge,
    setSelectedMaxAge,
    setSelectedGenderName,
    setDistance,
    resetIndex,

    setSortBy,
  });

  return {
    members,
    hasMore,
    isLoading,
    selectedMinAge,
    selectedMaxAge,
    selectedGenderName,
    distance,
    fetchingResults,
    sortBy,
    shouldReload,
    ...actions,
    preferencesLoaded,
  };
};

export default useOnlineNow;
