import React, { FC, useEffect, useRef } from "react";

type Props = {
  setShouldLoad: React.Dispatch<React.SetStateAction<boolean>>;
  rootMargin?: number;
};

const InfiniteScroller: FC<Props> = ({ setShouldLoad, rootMargin = 800 }) => {
  const scrollTriggerElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadingMarker = scrollTriggerElementRef.current!;

    // change depending on size of the screen

    const observerOptions = {
      root: null,
      rootMargin: rootMargin + "px",
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setShouldLoad(true);
        observer.unobserve(loadingMarker);
        observer.disconnect();
      }
    }, observerOptions);

    observer.observe(loadingMarker);

    return () => {
      observer.unobserve(loadingMarker);
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={scrollTriggerElementRef}
      className="InfiniteScroller w-full flex justify-center"
    >
      <span className="h-1 w-1"></span>
    </div>
  );
};

export default InfiniteScroller;
