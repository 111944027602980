import { FC } from "react";
import StyledContainer from "../styledContainer";

type Props = {
  label: string;
  formattedDate: string;
};

const ProfileBirthDate: FC<Props> = ({ label, formattedDate }) => (
  <StyledContainer className="ProfileBirthDate min-h-[62px] w-full lg:w-[300px] flex flex-wrap justify-between lg:p-[18px] p-[15px] items-center">
    <p className="font-bold text-custom-black text-base">{label}</p>
    <p className="text-custom-black-500 text-base">{formattedDate}</p>
  </StyledContainer>
);

export default ProfileBirthDate;
