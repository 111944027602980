import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

const ProfileCertifiedBadge: FC = () => {
  const { i } = useContext(TranslationContext);
  return (
    <div className="ProfileCertifiedBadge h-8 w-8 sm:w-fit bg-gradient-to-r from-custom-gradient-orange items-start to-custom-red rounded-l flex flex-row sm:px-3 justify-center">
      <img
        className="pt-2"
        alt={certifiedIconAlt}
        src="/app/assets/profile/certifiedIcon.svg"
      />
      <p className="text-white font-medium text-sm leading-6 hidden sm:flex pl-1.5 pt-[5px]">
        {i("Certified profile")}
      </p>
    </div>
  );
};

export const certifiedIconAlt = "Certified Profile icon";

export default ProfileCertifiedBadge;
