import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class DateMe extends Api {
  public async getDateMePages(queryParams: {
    pageIndex: number;
    pageSize: number;
    page: "who_i_want_to_meet" | "who_wants_to_meet_me" | "perfect_match";
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any[];
      };
    }
  > {
    return this.get("/dateMePages", queryParams);
  }

  public async deleteFromDateMe(body: {
    id: number;
    page: "who_i_want_to_meet" | "perfect_match";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/dateMePages", body);
  }

  public async postDateMeAction(body: {
    userId: number; // other user
    userProfileId: number; // my profile
    choix: "yes" | "no";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/dateMeAction", body);
  }

  public async getDateMe(queryParams: {
    pageIndex: number;
    pageSize: number;
    type: "read" | "update";
    ageMin: number;
    ageMax: number;
    distance: number;
    genderId: number;
  }): Promise<
    HTTPResponse & {
      data: {
        list: DateMeMember[];
        total: number;
        items: number;
      };
    }
  > {
    return this.get("/dateMe", queryParams);
  }
}

export interface DateMeMember {
  id: number;
  profilId: number;
  userId: number;
  userName: string;
  prenom: string;
  genre: string;
  mainImage: string;
  age: number;
  city: string;
  isOnline: boolean;
  LastUpdate: number;
  isVIP: boolean;
  isCertified: boolean;
  totalVoteProfil: number;
  photos: string[];
  sexeRecherche: string;
  sexeRecherche_title: string;
  typeRecherche: string;
  typeRecherche_title: string;
  NbVisitedMyProfile: string;
  lastVisitedDate: string;
  etatCivile: string;
  Appearance_title: string;
  Appearance: string;
  ftfInterviewDone_title: string;
  ftfInterviewDone: string;
  status: string;
  isFavorited: boolean;
}
