import { FC, useState } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  placeholder?: string;
  defaultContent?: string;
  maxLength?: number;
  onEnterPressed?: (content: string) => void;
  onValueChanged?: (content: string) => void;
  resetContentOnEnterPressed?: boolean;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

const StyledTextField: FC<Props> = ({
  placeholder = "",
  defaultContent = "",
  maxLength,
  onEnterPressed,
  onValueChanged,
  resetContentOnEnterPressed = false,
  className,
  ...props
}) => {
  defaultContent = defaultContent.substring(0, maxLength);

  const [content, setContent] = useState(defaultContent);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (maxLength && value.length >= maxLength) {
      return;
    }

    if (onValueChanged) onValueChanged(value);
    setContent(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (onEnterPressed) {
        onEnterPressed(content);
        if (resetContentOnEnterPressed) setContent("");
      }
    }
  };

  return (
    <div className="StyledTextField flex w-full h-full relative">
      <input
        value={content}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        maxLength={maxLength}
        className={classNames(
          "w-full h-full border border-custom-gray rounded-[4px] p-2 placeholder:text-base placeholder:text-custom-black-200",
          className || false
        )}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default StyledTextField;
