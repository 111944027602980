import React, { FC } from "react";
import useSayHi from "../../context/SayHiContext";
import useToast from "../../hooks/useToast";
import useUser from "../../hooks/useUser";
import classNames from "../../utils/twClassNames";
import SayHelloCaption from "../sayHelloCaption";
import SayHelloEmojiSmall from "../sayHelloEmojiSmall";
import useTranslation from "../../context/TranslationContext";

import { Greeting } from "../../context/SayHiContext";

import api from "../../backendAPI";

type Props = {
  content: Greeting;
  index: number;
  size: "small" | "large";
  onSettled?: () => void;
};

const SayHelloShortCard: FC<Props> = ({ content, index, size, onSettled }) => {
  const { userData } = useUser();
  const { openTimedToast } = useToast();
  const { closePopup, recipientUserID } = useSayHi();
  const { i } = useTranslation();

  const handleSubmitLongMessage = async () => {
    const response = await api.greetings.postGreeting({
      userId: recipientUserID,
      greetingId: content.greetingId,
      Personnaliser_imageId: 0,
      personnaliser_message: content.message,
    });

    if (response.status === "ERROR") {
      if (onSettled) onSettled();
      openTimedToast({
        type: "error",
        title: i("Error"),
        description: response.message,
        timeout: 4000,
      });

      return;
    }
    if (onSettled) onSettled();
    openTimedToast({
      type: "success",
      title: i("Success"),
      description: response.data.message,
      timeout: 4000,
    });
  };

  return (
    <button
      onClick={() => {
        handleSubmitLongMessage();
        closePopup();
      }}
      key={index}
      className={classNames(
        "SayHelloShortCard bg-custom-light-blue bg-opacity-20 px-4 h-[150px] w-full flex flex-col items-center rounded-[4px] sm:col-span-2 mr-2 lg:mr-0",
        size === "large"
          ? "max-w-full lg:max-w-[353px]"
          : "max-w-full lg:max-w-[173px]"
      )}
    >
      <SayHelloEmojiSmall iconSrc={content.image} iconAlt={"emoji"} />
      <div className="text-center">
        <SayHelloCaption
          text={content.message.replace("{username}", userData.NomUsager)}
        />
      </div>
    </button>
  );
};

export default SayHelloShortCard;
