import { XIcon } from "@heroicons/react/solid";
import React, { FC } from "react";

type Props = {
  onClick: () => void;
};

const ClosePopUp: FC<Props> = ({ onClick }) => {
  return (
    <div className="ClosePopUp">
      <button data-testid="close-pop-up" className="w-6 h-6" onClick={onClick}>
        <XIcon className="w-full h-full hover:text-gray-600" />
      </button>
    </div>
  );
};

export default ClosePopUp;
