import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { InputAdornment } from "@mui/material";
import useId from "@mui/utils/useId";
import React, { FC, useState } from "react";

type Props = {
  label: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SettingsChangePasswordField: FC<Props> = ({
  label,
  placeholder,
  value,
  onChange,
}) => {
  const inputId = useId();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="SettingsChangePasswordField">
      <label htmlFor={label} className="font-semibold mb-3 block">
        {label}
      </label>
      <div className="relative w-full sm:w-[358px]">
        <input
          className="block rounded truncate text-base placeholder:text-custom-black-200 text-custom-black border-custom-gray w-full h-12"
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={label}
        />
        <label
          htmlFor={inputId}
          className="h-8 w-8 absolute top-6 right-3"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          <InputAdornment position="end">
            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
          </InputAdornment>
        </label>
      </div>
    </div>
  );
};

export default SettingsChangePasswordField;
