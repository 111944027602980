import Member, { MemberInfo } from "./Member";

// todo (pascal)
// sexeId (plusieurs) vs genre (un seul)
// mainImage (plusieurs) vs profileMainPhoto (un seul)

export type DetailedMemberInfo = MemberInfo & {
  locationToDisplay: string; // html
  dotColor: string;
  genre: string;
  genderId: string;
  about: string;
  cityId: string;
  typeRecherche: string;
  taille: string;
  appPhysique: string;
  cheveux: string;
  yeux: string;
  ethnie: string;
  etatCivil: string;
  enfantActuel: string;
  enfantDesire: string;
  fumeur: string;
  alcool: string;
  drogue: string;
  occupation: string;
  profession: string;
  voiture: string;
  religion: string;
  signeZodiaque: string;
  maDescription: string;
  nbVues: string;
  dateInscrit: string;
  derniereVisite: string;
  isProfileActive: boolean;
  msgHeader: string;
  showDOB: boolean;
  readyToRelocate: string;
  totalVoteProfil: number;
  firstDate: string;
  dob: string;
  sexeRecherche: string;
  countryId: string;
  paysDescription: string;
  provinceDescription: string;
  departementDescription: string;
  NbVisitedMyProfile: string;
  LastUpdate: number;
  isVoted: boolean;
  isDateMeVoted: boolean;
  isFavorited: boolean;
  OptionsMessagerie: {
    sexeId: "1" | "2";
    maxDistance: number;
    countriesAccepted: string;
    ageRange: string;
    DoNotSmoke: boolean;
    DoNotAlcool: boolean;
    DoNotTakeDrugs: boolean;
  };
  photos: {
    path: string;
    id: string;
  }[];
  photos_grandFormat: {
    path: string;
    id: string;
  }[];

  activities: { id: string; description: string }[];
  musics: { id: string; description: string }[];
  qualities: { id: string; description: string }[];
  entrevueFaceAFace: { question: string; reponse: string }[];
  animaux: { id: string; description: string }[];
};

type Informations = {
  // isCorresponding: boolean;
  // sendGreetingsEnabled: boolean;

  allowedDateMe: boolean;
  allowedGreetings: boolean;
  allowedMessaging: boolean;
};

class DetailedMember extends Member {
  public info: DetailedMemberInfo;
  public informations: Informations = {
    // isCorresponding: false,
    // sendGreetingsEnabled: false,
    allowedDateMe: false,
    allowedGreetings: false,
    allowedMessaging: false,
  };

  public getLocation() {
    return `${this.info.city} ${this.info.provinceDescription}, ${this.info.paysDescription}`;
  }

  constructor(config: DetailedMemberInfo, informations: Informations) {
    super(config);

    this.info = config;

    this.informations = informations;
  }
}

export default DetailedMember;
