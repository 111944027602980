import React, { FC } from "react";
import GreetingBubble from "../greetingBubble";
import useTranslation from "../../context/TranslationContext";

type Props = { currentGreeting: any };

const GreetingActiveConvo: FC<Props> = ({ currentGreeting }) => {
  const { i } = useTranslation();
  return (
    <div className="GreetingActiveConvo h-full px-5 overflow-hidden">
      <div className="flex flex-col-reverse h-full overflow-y-auto no-scrollbar">
        <GreetingBubble
          message={currentGreeting.message}
          time={currentGreeting.time}
        />
        <div className="MessageConvoTimestamp">
          <p className="text-sm text-custom-black w-full text-center py-6">
            {i(currentGreeting.date ?? "TODAY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GreetingActiveConvo;
