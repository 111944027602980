import { useContext } from "react";

import { NotificationDialogContext } from "../context/NotificationDialogContext";

type UseNotificationDialog = () => {
  openPopup: (payload: {
    title: string;
    body: React.ReactNode;
    bottomActionButtonText: string;
    bottomActionButtonUrl: string;
  }) => void;
  closePopup: () => void;
  isOpen: boolean;
  title: string;
  bottomActionButtonUrl: string;
  body: React.ReactNode;
  bottomActionButtonText: string;
};

const useNotificationDialog: UseNotificationDialog = () => {
  const {
    openPopup,
    closePopup,
    isOpen,
    title,
    bottomActionButtonUrl,
    body,
    bottomActionButtonText,
  } = useContext(NotificationDialogContext);

  return {
    openPopup,
    closePopup,
    isOpen,
    title,
    bottomActionButtonUrl,
    body,
    bottomActionButtonText,
  };
};

export default useNotificationDialog;
