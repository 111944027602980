import { FC } from "react";

type Props = { children: React.ReactNode };

const ProfileStatsItemTitle: FC<Props> = ({ children }) => (
  <p className="ProfileStatsItemTitle min-w-fit leading-[22px] font-bold pb-[6px]">
    {children}
  </p>
);

export default ProfileStatsItemTitle;
