import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../backendAPI";

declare global {
  interface Window {
    mediaStream: MediaStream | undefined;
  }
}

export type Step =
  | "intro"
  | "how-to"
  | "add-photo"
  | "webcam"
  | "done"
  | "upload-intro"
  | "upload"
  | "upload-done"
  | "confirm-image"
  | "pending-certification"
  | "certified";

export interface State {
  isOpen: boolean;
  hasWebcam: boolean;
  currentStep: Step;
  isCertified: boolean;
  isPendingCertification: boolean;
  chosenMethod: "webcam" | "photos" | "";

  firstImageSrcNoWebcam: string;
  secondImageSrcNoWebcam: string;
  imageSrcWebcam: string;

  webcamImageModele: string;

  submittedImagePhoto1: string;
  submittedImagePhoto2: string;
  submittedImageWebcam: string;

  addPhotoFirst: boolean;
}

const initialState: State = {
  isOpen: false,
  hasWebcam: false,
  currentStep: "intro",
  isCertified: false,
  isPendingCertification: false,
  chosenMethod: "",
  firstImageSrcNoWebcam: "",
  secondImageSrcNoWebcam: "",
  imageSrcWebcam: "",
  webcamImageModele: "",
  submittedImagePhoto1: "",
  submittedImagePhoto2: "",
  submittedImageWebcam: "",
  addPhotoFirst: true,
};

import { getLoginInfo, login, logout } from "../slices/authSlice";

export const certificationSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<Step>) => {
      state.currentStep = action.payload;
    },

    setFirstImageSrc: (state, action: PayloadAction<string>) => {
      state.firstImageSrcNoWebcam = action.payload;
    },

    setSecondImageSrc: (state, action: PayloadAction<string>) => {
      state.secondImageSrcNoWebcam = action.payload;
    },

    setIsPendingCertification: (state, action: PayloadAction<boolean>) => {
      state.isPendingCertification = action.payload;
    },

    setChosenMethod: (state, action: PayloadAction<State["chosenMethod"]>) => {
      state.chosenMethod = action.payload;
    },

    close: (state) => {
      state.isOpen = false;
      state.currentStep = "intro";

      const videoElements = document.querySelectorAll("video");

      videoElements.forEach((el) => {
        if (!el) return;
        el.pause();
        el.src = "";

        el.srcObject = null;
      });
      if (!window.mediaStream) return;

      window.mediaStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(start.fulfilled, (state, action) => {
      state.webcamImageModele = action.payload.webcamImageModele;

      const chosenMethod = action.payload.chosenMethod;

      if (chosenMethod !== "") {
        state.chosenMethod = chosenMethod;
      }
      state.submittedImagePhoto1 = action.payload.submittedImagePhoto1;
      state.submittedImagePhoto2 = action.payload.submittedImagePhoto2;
      state.submittedImageWebcam = action.payload.submittedImageWebcam;
      state.addPhotoFirst = action.payload.addPhotoFirst;

      const alreadySubmittedPhotos = action.payload.status === "pending";
      const needsToAddPhotoFirst = action.payload.addPhotoFirst;

      if (needsToAddPhotoFirst) {
        state.currentStep = "add-photo";
      }

      if (alreadySubmittedPhotos) {
        state.currentStep = "pending-certification";
      }

      const isCertified = action.payload.status === "certified";

      if (isCertified) {
        state.currentStep = "certified";
      }

      state.isOpen = true;
    });

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") {
        return;
      }

      if (!action.payload.data.certificationProcess.alreadySubmittedPhotos) {
        return;
      }

      const { certificationProcess } = action.payload.data;

      state.isPendingCertification =
        certificationProcess.alreadySubmittedPhotos;

      state.imageSrcWebcam = certificationProcess.submittedImageWebcam;

      state.firstImageSrcNoWebcam = certificationProcess.submittedImagePhoto1;
      state.secondImageSrcNoWebcam = certificationProcess.submittedImagePhoto2;

      state.webcamImageModele = certificationProcess.webcamImageModele;

      state.chosenMethod = certificationProcess.chosenMethod;
    });

    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export const start = createAsyncThunk("certificationSlice/start", async () => {
  const response = await api.editProfile.getCertificationInformations();

  const certificationInformations = response.data.certificationProcess;

  return certificationInformations;
});

export const {
  close,
  setCurrentStep,
  setFirstImageSrc,
  setSecondImageSrc,
  setIsPendingCertification,
  setChosenMethod,
} = certificationSlice.actions;

export default certificationSlice.reducer;
