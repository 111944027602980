import React, { FC } from "react";
import SayHelloEmojiLarge from "../sayHelloEmojiLarge";

type Props = {
  greetingText: string;
  iconSrc: string;
  iconAlt: string;
  handleGoToNextPage: () => void;
};

const SayHelloCard: FC<Props> = ({
  greetingText,
  iconAlt,
  iconSrc,
  handleGoToNextPage,
}) => {
  return (
    <button
      onClick={() => handleGoToNextPage()}
      className="SayHelloCard flex flex-col items-center justify-end w-full sm:max-w-[180px] sm:h-[180px] py-4 sm:py-0 rounded-[10px] bg-custom-light-blue bg-opacity-20"
    >
      <SayHelloEmojiLarge iconSrc={iconSrc} iconAlt={iconAlt} />
      <p className="text-[16px] font-medium text-custom-black-500 sm:mt-8 sm:mb-8">
        {greetingText}
      </p>
    </button>
  );
};

export default SayHelloCard;
