import { FC } from "react";
import BodyAdContainerMobile from "../bodyAdContainerMobile";
import SideBarAdContainer from "../sideBarAdContainer";
import SideNavFeatures from "../sideNavFeatures";

import useAuth from "../../hooks/useAuth";
import useWindowSize from "../../hooks/useWindowSize";
import AdWrapper from "../AdWrapper";

type Props = {
  currentPage: string;
  hasSmallAd?: boolean;
  googleid?: string;
};

const SideNav: FC<Props> = ({ currentPage, hasSmallAd, googleid }) => {
  const { isAuth } = useAuth();

  const { windowWidth } = useWindowSize();

  return (
    <>
      <div className="SideNav xl:flex flex-col hidden">
        <div className="shadow-custom-shadow w-300 mb-5">
          {isAuth && (
            <div className="flex-col flex-grow rounded-md pb-5 px-1 w-full h-1/2 bg-white overflow-y-auto">
              <div className="mt-5 flex-grow flex flex-col">
                <nav
                  className="flex-1 px-2 bg-white space-y-2"
                  aria-label="Sidebar"
                >
                  <SideNavFeatures currentPage={currentPage} />
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdWrapper>
        <div className="hidden xl:flex sticky top-[120px]">
          {windowWidth && windowWidth >= 1280 ? (
            hasSmallAd ? (
              <BodyAdContainerMobile googleid={googleid} />
            ) : (
              <SideBarAdContainer googleid={googleid} />
            )
          ) : null}
        </div>
      </AdWrapper>
    </>
  );
};

export default SideNav;
