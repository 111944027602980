import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
  isAnyOf,
} from "@reduxjs/toolkit";

import playSound from "../utils/playSound";
import bootstrapReducer from "./slices/bootstrapSlice";
import userReducer from "./slices/userSlice";
import authReducer, {
  authSlice,
  getLoginInfo,
  login,
} from "./slices/authSlice";

import visitedMyProfileReducer from "./slices/visitedMyProfileSlice";
import newMembersReducer from "./slices/newMembersSlice";
import favoritesReducer from "./slices/favoritesSlice";
import searchByUsernameReducer from "./slices/searchByUsernameSlice";
import searchReducer, {
  searchSlice,
  fetchResults as searchFetchResults,
  setFetchingMoreResults,
} from "./slices/searchSlice";
import certificationReducer, {
  certificationSlice,
} from "./slices/certificationSlice";

import { userSlice } from "./slices/userSlice";

import messengerReducer, {
  fetchConversations,
  messengerSlice,
} from "./slices/messengerSlice";
import onlineNowReducer, {
  onlineNowSlice,
  fetchResults as onlineNowFetchResults,
  setFetchingMoreResults as setOnlineNowFetchingMoreResults,
} from "./slices/onlineNowSlice";
import perfectMatchReducer from "./slices/perfectMatchSlice";
import registrationReducer, { createAccount } from "./slices/registrationSlice";
import toastReducer, { toastSlice } from "./slices/toastSlice";
import completeProfileProgressionReducer, {
  deletedPhoto,
  completeProfileProgressionSlice,
} from "./slices/completeProfileProgressionSlice";

import api from "../backendAPI";

const rootReducer = combineReducers({
  _bootstrap: bootstrapReducer,
  user: userReducer,
  auth: authReducer,
  toast: toastReducer,
  visitedMyProfile: visitedMyProfileReducer,
  searchByUsername: searchByUsernameReducer,
  favorites: favoritesReducer,
  search: searchReducer,
  newMembers: newMembersReducer,
  certification: certificationReducer,
  messenger: messengerReducer,
  onlineNow: onlineNowReducer,
  perfectMatch: perfectMatchReducer,
  registration: registrationReducer,
  completeProfileProgression: completeProfileProgressionReducer,
});

const searchFiltersChangedMiddleware = createListenerMiddleware();

searchFiltersChangedMiddleware.startListening({
  matcher: isAnyOf(
    searchSlice.actions.setSelectedMinAge,
    searchSlice.actions.setSelectedMaxAge,
    searchSlice.actions.setSelectedGenderName,
    searchSlice.actions.setDistance,
    searchSlice.actions.setLocation,
    searchSlice.actions.setSortBy,
    searchSlice.actions.setFilteredSearch,
    searchSlice.actions.resetFilters,
    searchSlice.actions.setLocationFromGoogleApi
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    listenerApi.dispatch(setFetchingMoreResults(true));

    await listenerApi.delay(500);

    listenerApi.dispatch(searchFetchResults(true));
  },
});

const onlineNowFiltersChangesMiddleware = createListenerMiddleware();

onlineNowFiltersChangesMiddleware.startListening({
  matcher: isAnyOf(
    onlineNowSlice.actions.setSelectedMinAge,
    onlineNowSlice.actions.setSelectedMaxAge,
    onlineNowSlice.actions.setSelectedGenderName,
    onlineNowSlice.actions.setDistance,

    onlineNowSlice.actions.setSortBy
  ),

  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    listenerApi.dispatch(setOnlineNowFetchingMoreResults(true));

    await listenerApi.delay(500);

    listenerApi.dispatch(onlineNowFetchResults(true));
  },
});

const playSoundMiddleware = createListenerMiddleware();

playSoundMiddleware.startListening({
  matcher: isAnyOf(toastSlice.actions.openToast),
  effect: async (action) => {
    if (action.payload.type !== "warning" && action.payload.type !== "error")
      return;

    playSound("error");
  },
});

const refetchMessagesMiddleware = createListenerMiddleware();

refetchMessagesMiddleware.startListening({
  matcher: isAnyOf(messengerSlice.actions.setUsernameFilter),

  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    await listenerApi.delay(350);

    listenerApi.dispatch(fetchConversations({ resetPageSize: true }));
  },
});

const refetchTopBarMessageMiddleware = createListenerMiddleware();

refetchTopBarMessageMiddleware.startListening({
  matcher: isAnyOf(
    completeProfileProgressionSlice.actions.addedPhoto,
    deletedPhoto.fulfilled,
    certificationSlice.actions.setIsPendingCertification,
    createAccount.fulfilled
  ),
  effect: async (action, listenerApi) => {
    const getLoginResponse = await api.authentication.getLogin();

    listenerApi.dispatch(
      completeProfileProgressionSlice.actions.setTopBarMessage(
        getLoginResponse.data.orangeBarMessage
      )
    );
  },
});

const preferredDisplayLanguageMiddleware = createListenerMiddleware();

preferredDisplayLanguageMiddleware.startListening({
  matcher: isAnyOf(getLoginInfo.fulfilled),
  effect: async (action) => {
    const preferredDisplayLanguage =
      action.payload.data?.infosProfile?.preferredDisplayLanguage;
    if (preferredDisplayLanguage) {
      window.localStorage.setItem("lang", preferredDisplayLanguage);
    }
  },
});

export const testStore = configureStore({
  reducer: rootReducer,
});

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(refetchTopBarMessageMiddleware.middleware)
      .prepend(refetchMessagesMiddleware.middleware)
      .prepend(searchFiltersChangedMiddleware.middleware)
      .prepend(onlineNowFiltersChangesMiddleware.middleware)
      .prepend(playSoundMiddleware.middleware)
      .prepend(preferredDisplayLanguageMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
