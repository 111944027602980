import React, { FC, useContext, useState } from "react";
import { SendMessagePopupContext } from "../../context/SendMessagePopupContext";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileActionButton from "../profileActionButton";
import ProfileMailSettingsPopupContent from "../profileMailSettingsPopupContent";
import StyledPopup from "../styledPopup";
import { skip } from "rxjs";

type Props = {
  userId: number;
  username: string;
  mainImageSrc: string;
  isAllowedToSendMessage: boolean;
  mailSettings: any;
  onSendMessage?: () => void;
  onOpenMailSettings?: () => void;
};

const MessageActionButton: FC<Props> = ({
  userId,
  username,
  mainImageSrc,
  isAllowedToSendMessage = false,
  mailSettings,
  onSendMessage,
  onOpenMailSettings,
}) => {
  const { openPopup: openSendMessagePopup } = useContext(
    SendMessagePopupContext
  );
  const { i } = useContext(TranslationContext);
  const [openMailSettingsPopup, setOpenMailSettingsPopup] = useState(false);

  const onClick = () => {
    if (isAllowedToSendMessage) {
      openSendMessagePopup(mainImageSrc, userId, username, !!onSendMessage);
      if (onSendMessage) onSendMessage();
    } else {
      if (onOpenMailSettings) return onOpenMailSettings();
      setOpenMailSettingsPopup(true);
    }
  };

  return (
    <div className="MessageActionButton">
      <StyledPopup
        open={openMailSettingsPopup}
        onClick={setOpenMailSettingsPopup}
      >
        <ProfileMailSettingsPopupContent
          mailSettings={mailSettings}
          username={username}
          onClose={() => setOpenMailSettingsPopup(false)}
        />
      </StyledPopup>
      <ProfileActionButton
        onClick={onClick}
        icon={{ alt: i("Send message"), src: "/app/assets/commentIcon.svg" }}
      />
    </div>
  );
};

export default MessageActionButton;
