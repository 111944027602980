import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const StyledContainerHeader: FC<Props> = ({ children, className = "" }) => {
  return (
    <div className={classNames("StyledContainerHeader", className)}>
      <h3 className="font-semibold text-custom-black-500 text-lg leading-[26px]">
        {children}
      </h3>
    </div>
  );
};

export default StyledContainerHeader;
