import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import useTranslation, { Languages } from "../../context/TranslationContext";
import LinkIcon from "../linkIcon";
import LinkShareButton from "../linkShareButton";

type ShareButtonsProps = {
  shareUrl?: string;
  EmailButtonProps?: {
    subject?: string;
    body?: string;
  };
};

export default function ShareButtons({
  shareUrl,
  EmailButtonProps,
}: ShareButtonsProps) {
  const { i } = useTranslation();
  const url = shareUrl ?? window.location.href;

  return (
    <div
      data-testid={`share-button-url-${url}`}
      className="ShareButtons flex bg-white shadow-custom-shadow rounded"
    >
      <div className="flex flex-row items-center sm:pl-[18px] pl-[15px] py-4 font-semibold space-x-2">
        <p className="font-bold text-custom-black text-base">{i("Share")}</p>
        <TwitterShareButton url={url} className="tooltip">
          <TwitterIcon size={32} round />
          <div className="relative top-0 -left-8">
            <p className="tooltiptext w-16">Twitter</p>
          </div>
        </TwitterShareButton>
        <FacebookShareButton url={url} className="tooltip">
          <FacebookIcon size={32} round />{" "}
          <div className="relative top-0 -left-8">
            <p className="tooltiptext w-20">Facebook</p>
          </div>
        </FacebookShareButton>
        <EmailShareButton
          subject=""
          body=""
          separator={"\n\n"}
          url={url}
          className="tooltip"
          {...EmailButtonProps}
        >
          <EmailIcon size={32} bgStyle={{ fill: "#F68F63" }} round />
          <div className="relative top-0 -left-8">
            <p className="tooltiptext w-16">{i("Email")}</p>
          </div>
        </EmailShareButton>
        <LinkShareButton url={url}>
          <div className="relative tooltip pt-1.5">
            <LinkIcon />
            <div className="absolute top-[38px] left-4">
              <p className="tooltiptext w-20">{i("Copy link")}</p>
            </div>
          </div>
        </LinkShareButton>
      </div>
    </div>
  );
}
