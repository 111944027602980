import { FC } from "react";

type Props = {
  date_tz: string;
};

const MessageConvoTimestamp: FC<Props> = ({ date_tz }) => {
  return (
    <div className="MessageConvoTimestamp">
      <p className="text-sm text-custom-black w-full text-center py-6">
        {date_tz}
      </p>
    </div>
  );
};

export default MessageConvoTimestamp;
