import React, { FC, useContext, useEffect, useState } from "react";
import StyledContainerHeader from "../styledContainerHeader";

import SettingsActionCard from "../settingsActionCard";
import ButtonBGBlue from "../buttonBGBlue";
import DeactivatingAccountOptions from "../deactivateAccountOptions";
import { TranslationContext } from "../../context/TranslationContext";

const SettingsDeleteAccount: FC = () => {
  const { i } = useContext(TranslationContext);
  const [disableProfile, setDisableProfile] = useState(false);
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [disableOption, setDisableOption] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);

  const isDisabled = () => !disableProfile && !deleteProfile;

  const handleShowConfirm = () => {
    if (isDisabled()) return;

    setShowConfirm(true);
  };

  useEffect(() => {
    if (disableProfile) {
      setDeleteProfile(false);
    }
  }, [disableProfile]);

  useEffect(() => {
    if (deleteProfile) {
      setDisableProfile(false);
    }
  }, [deleteProfile]);

  return (
    <div className="SettingsDeleteAccount">
      <div>
        <StyledContainerHeader className="pb-2">
          {i("Delete/Deactivate my profile")}
        </StyledContainerHeader>

        {!showConfirm && (
          <div>
            <div className="mb-8">
              <p className="text-lg">
                {i(
                  "To cancel your registration, you have 2 possible choices. Make your choice and click on the button. However, make sure that you are aware of the differences, as the 2 choices offered are very different."
                )}
              </p>
            </div>

            <div>
              <div>
                <SettingsActionCard
                  label={i("Disable my profile")}
                  checked={disableProfile}
                  setChecked={setDisableProfile}
                  alwaysShowDescription={true}
                  description={i(
                    "This option disables your profile. This means you will no longer appear in the search results. Regardless of the type of search, your profile will not be displayed. This action is reversible at any time. So you can reactivate it when you want. Use this option if you are not sure if you will come back or if you are undecided."
                  )}
                />
              </div>

              <div>
                <SettingsActionCard
                  label={i("Delete my profile")}
                  checked={deleteProfile}
                  setChecked={setDeleteProfile}
                  alwaysShowDescription={true}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: i(
                        `This option completely deletes your profile and everything related to it. So all the favorites, the messages, the photos, your profile visits history, your accumulated points, in short: everything will be deleted and this <span class='font-bold'>is not reversible. Once deleted, your profile cannot be restored.</span>`
                      ),
                    }}
                  />
                </SettingsActionCard>
              </div>
            </div>

            <div>
              <div className="w-52 mt-5">
                <ButtonBGBlue
                  innerText={i("Next")}
                  disabled={isDisabled()}
                  onClick={handleShowConfirm}
                />
              </div>
            </div>
          </div>
        )}

        {showConfirm && deleteProfile && (
          <DeactivatingAccountOptions
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            action="delete"
          />
        )}

        {showConfirm && disableProfile && (
          <DeactivatingAccountOptions
            disableOption={disableOption}
            setDisableOption={setDisableOption}
            action="disable"
          />
        )}
      </div>
    </div>
  );
};

export default SettingsDeleteAccount;
