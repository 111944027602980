import Api, { HTTPResponseWithDataMessage } from "./Api";

export default class History extends Api {
  public getHistory(queryParamsMap: { pageIndex: number; pageSize: number }) {
    return this.get("/history", queryParamsMap);
  }

  public deleteFromHistory(body: {
    userId: string | "all";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/history", body);
  }
}
