import BackendProxy from "../utils/BackendProxy";
import Member from "./Member";

export type Endpoint =
  | "newMembers"
  | "history"
  | "online"
  | "search"
  | "visitedMyProfile"
  | "dateMe"
  | "dateMePages"
  | "favorites";

export type QueryParams = {
  pageSize: number;
  type?: "update" | "read" | "all" | "criteria";
  ageMin?: number;
  ageMax?: number;
  distance?: number;
  genderId?: number;
  lookingFor?: number;
  page?: "who_wants_to_meet_me";
  sortBy?: "all" | "mostpopular" | "newest";
};

class MemberList extends BackendProxy<Member, Endpoint, QueryParams> {
  constructor(endpoint: Endpoint, config: QueryParams) {
    super(endpoint, config, Member);
  }
}

export default MemberList;
