import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../backendAPI";

export interface State {
  isAuth: boolean;
  authError: boolean;
  authErrorMessage: string;
  accessToken: string;
  refreshToken: string;
  isLoading: boolean;
  isRegistered: boolean;
  needsToConfirmEmail: boolean;
  needsToCertify: boolean;
  needsToAnswerQuestions: boolean;
  lastVisitedURL: string;
  profileDeleted: boolean;
  profileNotCreated: boolean;
  loginInfoLoaded: boolean;
  role: string;
}

const initialState: State = {
  isAuth: false,
  authError: false,
  authErrorMessage: "",
  accessToken: "",
  refreshToken: "",
  isLoading: false,
  isRegistered: false,
  needsToConfirmEmail: false,
  needsToCertify: false,
  needsToAnswerQuestions: false,
  lastVisitedURL: "/",
  profileDeleted: false,
  profileNotCreated: false,
  loginInfoLoaded: false,
  role: "",
};

export const createAccount = createAsyncThunk(
  "user/createAccount",
  async ({
    email,
    password,
    username,
    password2,
  }: {
    email: string;
    password: string;
    username: string;
    password2: string;
  }) => {
    const response = await api.registration.postRegistration({
      email,
      password,
      username,
      password2,
    });

    return response;
  }
);

const postLogin = api.authentication.postLogin;

type PostLoginBody = Parameters<typeof postLogin>[0];

export const getLoginInfo = createAsyncThunk(
  "user/fetchLoginInfo",
  async (_, { rejectWithValue, dispatch }) => {
    const response = await api.authentication.getLogin();

    if (response.status === "ERROR") {
      if (response.message === "nouser") {
        await dispatch(logout()).unwrap();
      }
      return rejectWithValue(response);
    }
    return response;
  }
);

export const login = createAsyncThunk(
  "user/fetchLogin",
  async (body: PostLoginBody, { rejectWithValue }) => {
    const response = await api.authentication.postLogin({
      ...body,
    });

    if (response.status === "ERROR") {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const logout = createAsyncThunk("user/fetchLogout", async () => {
  const response = await api.authentication.postLogout();

  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadToken: (
      state,
      action: PayloadAction<{ access_token: string; refresh_token?: string }>
    ) => {
      const { access_token: accessToken, refresh_token: refreshToken } =
        action.payload;
      state.isLoading = false;
      state.isAuth = true;
      state.accessToken = accessToken;
      window.localStorage.setItem("token", accessToken);
      if (refreshToken) {
        state.refreshToken = refreshToken;
      }
    },

    resetIsLoading: (state) => {
      state.isLoading = false;
    },

    clearAuthError: (state) => {
      state.isLoading = false;
      state.authError = false;
      state.authErrorMessage = "";
    },

    setNeedsToConfirmEmail: (state, action: PayloadAction<boolean>) => {
      state.needsToConfirmEmail = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createAccount.fulfilled, (state, action) => {
      if (window.gtag) {
        window.gtag("event", "create-account", {
          event_category: "registration",
          event_label: "inscription-app",
          screen_name: window.location.pathname,
        });
      }

      state.isLoading = false;
      if (action.payload.status === "ERROR") {
        state.authError = true;
        state.authErrorMessage = action.payload.message;
        return;
      }

      state.isAuth = true;
      state.accessToken = action.payload.data.tokens.access_token;

      window.localStorage.setItem(
        "token",
        action.payload.data.tokens.access_token
      );

      window.localStorage.setItem(
        "userid",
        action?.payload?.data?.infosProfile?.userid?.toString() ?? ""
      );

      window.localStorage.setItem(
        "username",
        action?.payload?.data?.infosProfile?.NomUsager ?? ""
      );

      state.refreshToken = action.payload.data.tokens.refresh_token;
    });

    builder.addCase(createAccount.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createAccount.rejected, (state) => {
      state.isLoading = false;
      state.authError = true;
    });

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      window.localStorage.setItem(
        "userid",
        action?.payload?.data?.infosProfile?.userid?.toString() ?? ""
      );

      window.localStorage.setItem(
        "username",
        action?.payload?.data?.infosProfile?.NomUsager ?? ""
      );

      if (needToCertify(action.payload)) {
        state.needsToCertify = true;
      } else {
        state.needsToCertify = false;
      }

      if (needToAnswerQuestions(action.payload)) {
        state.needsToAnswerQuestions = true;
      } else {
        state.needsToAnswerQuestions = false;
      }

      if (needToConfirmEmail(action.payload)) {
        state.needsToConfirmEmail = true;
      } else {
        state.needsToConfirmEmail = false;
      }

      if (needToFinishRegistration(action.payload)) {
        state.profileNotCreated = true;
      } else {
        state.profileNotCreated = false;
      }

      state.loginInfoLoaded = true;
      state.isAuth = true;
      state.isLoading = false;
      state.accessToken = window.localStorage.getItem("token") ?? "";
      state.role = action.payload.data.infosProfile.role;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;

      window.localStorage.setItem(
        "token",
        action.payload.data.tokens.access_token
      );

      state.isAuth = true;

      state.accessToken = action.payload.data.tokens.access_token;

      state.refreshToken = action.payload.data.tokens.refresh_token;
    });

    builder.addCase(login.pending, (state) => {
      state.profileDeleted = false;
      state.isLoading = true;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.authError = true;

      const message = (action?.payload as { message: string })?.message;

      if (!message) return;

      if (message === "PROFILE_DELETED_BY_MODERATOR") {
        state.profileDeleted = true;
      }
      if (message === "PROFILE_DELETED") {
        state.profileDeleted = true;
      }
      if (
        message === "invalidUsernamePassword" ||
        ((action.payload as { http_code: number })?.http_code as number) === 400
      ) {
        // Remove token if bad request
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userid");
      }
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      if (window.socket) {
        window.socket?.offAny();
        window.socket?.disconnect();
        window.socket = undefined;
      }

      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userid");

      window.location.href =
        action?.payload?.data?.url || getFallbackSignInUrl();

      return initialState;
    });
    builder.addCase(logout.pending, (state) => {
      state.profileDeleted = false;
    });
    builder.addCase(logout.rejected, (state) => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userid");

      window.location.href = getFallbackSignInUrl();

      return initialState;
    });
  },
});

function getFallbackSignInUrl() {
  return /vidamora/.test(window.location.href)
    ? "https://www.vidamora.com/sign-in"
    : "https://www.celibatairesduweb.com/login.php";
}

function needToConfirmEmail(payload: any): boolean {
  return payload.data.infosProfile.warnings.includes(
    "PROFILE_EMAIL_NOT_CONFIRMED"
  );
}

function needToFinishRegistration(payload: any): boolean {
  return payload.data.infosProfile.isProfileCreated === false;
}

function needToCertify(payload: any): boolean {
  return (
    payload.data.infosProfile.warnings.includes("MANDATORY_CERTIFICATION") &&
    !payload.data.infosProfile.isCertified
  );
}

function needToAnswerQuestions(payload: any): boolean {
  return payload.data.infosProfile.warnings.includes(
    "PROFILE_QUESTIONS_REQUIRED"
  );
}

export const {
  clearAuthError,
  setNeedsToConfirmEmail,
  resetIsLoading,
  loadToken,
} = authSlice.actions;

export default authSlice.reducer;
