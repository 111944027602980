import { Props } from "@headlessui/react/dist/types";
import React, { Fragment, PropsWithChildren } from "react";
import useUser from "../../hooks/useUser";

type AdWrapperProps = PropsWithChildren<{}>;

/**
 * Wrapper Component to handle display of ad containers, checking if the user is VIP
 * @param children Pass the ad container as a child
 * @HOC
 */
export default function AdWrapper({ children }: AdWrapperProps) {
  const {
    userData: { isPremiumNoAds },
  } = useUser();
  if (isPremiumNoAds) return null;
  return <Fragment>{children}</Fragment>;
}
