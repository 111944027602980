import { FC } from "react";
import useTranslation from "../../context/TranslationContext";

import { SubscriptionType } from "../settingsVIP/SettingsVIP";

const MembershipBenefits: FC<{ type?: SubscriptionType }> = ({
  type = "vip",
}) => {
  const { i } = useTranslation();

  const introduction = () => {
    if (type === "vip") {
      return (
        <p className="text-[22px] font-bold mb-6">
          {i("All the privileges of VIP membership:")}
        </p>
      );
    } else {
      return (
        <p className="text-[22px] font-bold mb-6">
          {i("All the privileges of an “Ad-Free” subscription:")}
        </p>
      );
    }
  };

  const membershipList1 = () => {
    if (type === "vip") {
      return [
        i("See if your messages have been deleted"),
        i("See if your sent messages have been read"),
        i(
          `Always appear in <span class='font-bold'>highlighted and first</span> in your recipients' inboxes`
        ),
        i(
          "Always be first in the 'Date me' section, which increases your chances of being seen"
        ),
      ];
    } else {
      return [
        i("Browse the site without any advertising"),
        i("No longer be interrupted by advertisements when changing pages"),
        i("Navigates search results more smoothly and without interruption"),
      ];
    }
  };

  const membershipList2 = () => {
    if (type === "vip") {
      return [
        i("Get better visibility for your profile (3 to 4 times higher)"),
        i("Send unlimited greetings to all the members you want"),
        i(
          `By becoming a V.I.P. member, you will receive many more messages and meet more people, which will <span class='font-bold'>GREATLY</span> increase your chances of finding love and dating <span class='font-bold'>quickly</span>`
        ),
      ];
    } else {
      return [
        i("Less distraction = Straight to the point"),
        i(
          "Increased focus on profiles, which will allow you to meet people even faster."
        ),
        i(
          `All this to tell you that if you subscribe to the “<span class='font-bold'>Ad-Free</span>” subscription, you will no longer be bothered by advertisements when you are connected to the site.`
        ),
      ];
    }
  };

  return (
    <div className="MembershipBenefits bg-custom-gray/20 py-10 px-3 md:px-9 rounded-lg text-custom-black">
      {introduction()}
      <div className="max-w-[949px] items-baseline md:space-x-[50px] flex flex-col md:flex-row">
        <div className="w-full space-y-6">
          {membershipList1().map((info, index) => {
            return (
              <div key={index} className="w-full flex items-start">
                <img
                  src="/app/assets/blueCheckmark.svg"
                  alt="check mark"
                  className="mt-2"
                />
                <p
                  className="w-full pl-3"
                  dangerouslySetInnerHTML={{ __html: info }}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full flex flex-col space-y-6 mt-6 md:mt-0 md:justify-between">
          {membershipList2().map((info, index) => {
            return (
              <div key={index} className="w-full flex items-start">
                <img
                  src="/app/assets/blueCheckmark.svg"
                  alt="check mark"
                  className="mt-2"
                />
                <p
                  className="w-full pl-3"
                  dangerouslySetInnerHTML={{ __html: info }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembershipBenefits;
