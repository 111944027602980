import Api, { HTTPResponseWithDataMessage } from "./Api";

export default class Report extends Api {
  public async reportProfile(body: {
    profileId: number;
    username: string;
    raison: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/report/profile", body);
  }

  public async reportPicture(body: {
    profileId: number;
    username: string;
    raison: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/report/photos", body);
  }
}
