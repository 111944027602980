import React, { FC } from "react";

type Props = {
  iconSrc: string;
  iconAlt: string;
};

const SayHelloEmojiSmall: FC<Props> = ({ iconSrc, iconAlt }) => (
  <img
    className="SayHelloEmojiSmall w-8 h-8 mt-8 mb-[10px] m-auto"
    src={iconSrc}
    alt={iconAlt}
  />
);

export default SayHelloEmojiSmall;
