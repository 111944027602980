import React, { FC } from "react";

import SayHelloUserImage from "../sayHelloUserImage";

import BackButton from "../backButton";

type Props = {
  children: React.ReactNode;
  recipientImg?: string;
  recipientUsername?: string;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
};

// todo (simon) cleanup this component and the subcomponents

const SayHelloCustom: FC<Props> = ({
  children,
  recipientImg,
  setCurrentPageIndex,
}) => {
  return (
    <div
      className="SayHelloCustom flex flex-col items-center grow"
      style={{ height: "calc(100% - 24px)" }}
    >
      {recipientImg ? (
        <div className="flex items-center w-full space-x-2 h-20">
          <div className="w-full flex justify-start">
            <BackButton onClick={() => setCurrentPageIndex(-1)} />
          </div>
          {/* <div className="min-w-[100px]">
            <SayHelloUserImage imageSrc={recipientImg} />
          </div> */}
          <div className="w-full"></div>
        </div>
      ) : (
        <div className="flex items-center w-full space-x-2">
          <BackButton onClick={() => setCurrentPageIndex(-1)} />
        </div>
      )}
      {children}
    </div>
  );
};

export default SayHelloCustom;
