import React, { FC, useContext, useEffect, useState } from "react";
import DateMeQuickViewStatus from "../dateMeQuickViewStatus";
import DateMeQuickViewStats from "../dateMeQuickViewStats";
import ProfileCertifiedBadge from "../profileCertifiedBadge";
import StyledContainer from "../styledContainer";
import { DetailedMember } from "../../backendProxies";
import { useRouter } from "next/router";

import MessageActionButton from "../messageActionButton";
import GreetingActionButton from "../greetingActionButton";
import FavoriteActionButton from "../favoriteActionButton";
import VoteActionButton from "../voteActionButton";
import VoteDateMeActionButton from "../voteDateMeActionButton";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  member: DetailedMember;
  isPlaceholder?: boolean;
  onSendMessage?: () => void;
  onOpenMailSettings?: () => void;
  onOpenGreetings?: () => void;
};

// todo (jeremie): extract recurrent components
// todo (simon) -> deprecate the isPlaceholder prop

const ProfileFullCard: FC<Props> = ({
  member,
  onSendMessage,
  onOpenMailSettings,
  onOpenGreetings,
  isPlaceholder = false,
}) => {
  const { i } = useContext(TranslationContext);

  if (!member) {
    return <Placeholder />;
  }

  return (
    <StyledContainer className="ProfileFullCard flex flex-col sm:rounded-l-2xl rounded-tl-2xl">
      <div className="block md:flex relative p-4 md:p-0 pb-0">
        <div className="w-full md:w-[273] md:max-w-[273px]  md:min-w-[273px] pr-2.5 sm:pr-6">
          <div className="relative sm:static h-full w-full rounded-2xl">
            <div className="relative h-full w-full flex justify-center">
              <div className="relative">
                {member.info.isCertified && (
                  <div className="absolute right-0 sm:top-[22px] top-[12px] sm:hidden">
                    <ProfileCertifiedBadge />
                  </div>
                )}
                <img
                  className="object-cover sm:h-full rounded-xl  w-[250px] h-[330px] md:w-full"
                  src={member.info.mainImage}
                  alt={`${member.info.userName}'s main profile picture`}
                />
              </div>
            </div>
            {member.info.isCertified && (
              <div className="hidden sm:block absolute right-0 sm:top-[22px] top-[12px]">
                <ProfileCertifiedBadge />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-4 sm:pt-6 sm:justify-start pb-0 py-4 w-full pr-3.5">
          <DateMeQuickViewStatus
            isVip={member.info.isVIP}
            username={member.info.userName}
            dot={member.info.dotColor}
            lastActive_text={i("Last visit")}
            lastActive={member.info.derniereVisite}
          />
          <DateMeQuickViewStats
            icon="locationMarker"
            iconAltText="location marker"
            text={member.info.locationToDisplay}
          />
          <DateMeQuickViewStats
            icon={member.info.genderId === "1" ? "maleSign" : "femaleSign"}
            iconAltText="gender sign"
            text={`${member.info.about}`}
          />
          <DateMeQuickViewStats
            icon="ringsWedding"
            iconAltText="Relation"
            text={member.info.etatCivil}
          />
          <div className="flex-wrap gap-3 sm:pt-2.5 sm:pb-6 w-full justify-left hidden sm:flex">
            {member.informations?.allowedGreetings ? (
              <GreetingActionButton
                member={member}
                onOpenGreetings={onOpenGreetings}
              />
            ) : null}
            <MessageActionButton
              isAllowedToSendMessage={member.informations?.allowedMessaging}
              userId={+member.info.userId}
              username={member.info.userName}
              mainImageSrc={member.info.mainImage}
              mailSettings={member.info.OptionsMessagerie}
              onSendMessage={onSendMessage}
              onOpenMailSettings={onOpenMailSettings}
            />
            <FavoriteActionButton
              initiallyFavorited={member?.info?.isFavorited || false}
              userId={+member.info.userId}
            />
            {member.informations?.allowedDateMe ? (
              <VoteDateMeActionButton
                initiallyDateMeVoted={member?.info?.isDateMeVoted || false}
                userId={+member.info.userId}
                profilId={+member.info.profilId}
              />
            ) : null}

            <VoteActionButton
              initialNbVotes={member.info.totalVoteProfil}
              initiallyVoted={member?.info?.isVoted || false}
              userId={+member.info.userId}
            />
          </div>
        </div>
      </div>
      <div className="flex-wrap gap-2 md:gap-3 pt-4 pb-5 w-full justify-center flex px-2 md:px-4 sm:hidden md:pr-14">
        {member.informations?.allowedGreetings ? (
          <GreetingActionButton
            member={member}
            onOpenGreetings={onOpenGreetings}
          />
        ) : null}
        <MessageActionButton
          isAllowedToSendMessage={member.informations?.allowedMessaging}
          userId={+member.info.userId}
          username={member.info.userName}
          mainImageSrc={member.info.mainImage}
          mailSettings={member.info.OptionsMessagerie}
          onSendMessage={onSendMessage}
          onOpenMailSettings={onOpenMailSettings}
        />
        <FavoriteActionButton
          initiallyFavorited={member?.info?.isFavorited || false}
          userId={+member.info.userId}
        />
        {member.informations?.allowedDateMe ? (
          <VoteDateMeActionButton
            initiallyDateMeVoted={member?.info?.isDateMeVoted || false}
            userId={+member.info.userId}
            profilId={+member.info.profilId}
          />
        ) : null}

        <VoteActionButton
          initialNbVotes={member.info.totalVoteProfil}
          initiallyVoted={member?.info?.isVoted || false}
          userId={+member.info.userId}
        />
      </div>
    </StyledContainer>
  );
};

export default ProfileFullCard;

function Placeholder() {
  return (
    <div className="StyledContainer shadow-custom-shadow rounded bg-white ProfileFullCard flex flex-col sm:rounded-l-2xl rounded-tl-2xl">
      <div className="flex sm:relative">
        <div className="sm:w-[273] w-1/2 sm:max-w-[273px] max-w-[170px] sm:min-w-[273px] overflow-hidden pr-2.5 sm:pr-6">
          <div className="relative sm:static h-full w-full rounded-2xl overflow-hidden">
            <div
              style={{
                background:
                  "linear-gradient(360deg, rgba(0, 0, 0, 0.26) 0%, rgba(47, 47, 47, 0) 100%)",
              }}
              className="object-cover h-[300px] w-[250px]"
            ></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 sm:pt-6 sm:pb-0 sm:justify-start justify-between py-4 sm:w-full w-1/2"></div>
      </div>
    </div>
  );
}
