import React, { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileInterestTag from "../profileInterestTag";
import StyledContainer from "../styledContainer";

const colors = {
  activities: "text-custom-interest-title-green",
  musics: "text-custom-interest-title-pink",
  qualities: "text-custom-interest-title-teal",
  animals: "text-custom-interest-title-violet",
};

type Props = {
  title: string;
  type: "activities" | "musics" | "qualities" | "animals";
  tags?: { id: string; description: string }[];
};

const ProfileInterestContainer: FC<Props> = ({ title, type, tags = [] }) => {
  const { i } = useContext(TranslationContext);
  const sortedAndFilteredTags = tags
    .filter((tag) => tag && tag.description)
    .sort((a, b) => a.description.localeCompare(b.description));
  return (
    <StyledContainer className="ProfileInterestContainer flex flex-col md:space-y-4 space-y-3 md:p-6 p-4">
      <div className="flex flex-row justify-between">
        <p className={"text-lg font-bold leading-[22px] " + colors[type]}>
          {title}
        </p>
      </div>
      <div className="flex flex-wrap gap-2.5">
        {sortedAndFilteredTags.length == 0 ? (
          <p className="text-custom-black">
            {i("The user has not yet completed this section.")}
          </p>
        ) : (
          sortedAndFilteredTags.map((tag, index) => (
            <ProfileInterestTag
              key={index}
              tag={{ id: tag.id, description: tag.description, selected: true }}
              type={type}
            />
          ))
        )}
      </div>
    </StyledContainer>
  );
};

export default ProfileInterestContainer;
