import React, { FC, useCallback, useEffect, useState } from "react";
import ProfilePhotoCarouselItem from "../profilePhotoCarouselItem";
import ProfilePhotoViewerPopup from "../profilePhotoViewerPopup";
import useEmblaCarousel from "embla-carousel-react";
import StyledCarouselPrevButton from "../styledCarouselPrevButton";
import StyledCarouselNextButton from "../styledCarouselNextButton";
import { Member } from "../../backendProxies";
import { Photo } from "../../types";

type Props = {
  member: Member;
  photos: Photo[];
  highResPhotos: Photo[];
  firstName: string;
  age: number;
  location: string;
  onItemClick?: (index: number) => void;
};

const ProfilePhotoCarousel: FC<Props> = ({
  member,
  photos,
  highResPhotos,
  firstName,
  age,
  location,
  onItemClick,
}) => {
  const [open, setOpen] = useState(false);
  const [defaultCarouselIndex, setDefaultCarouselIndex] = useState(0);
  const [viewportRef, embla] = useEmblaCarousel({
    draggable: true,
    containScroll: "trimSnaps",
    inViewThreshold: 0,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    embla.on("reInit", onSelect);
    onSelect();
  }, [embla, onSelect]);

  function onCarouselItemClick(index: number) {
    if (onItemClick) return onItemClick(index);
    if (!embla || !embla.clickAllowed()) return;
    setDefaultCarouselIndex(index);
    setOpen(true);
  }

  return (
    <div className="flex w-full max-w-full">
      {open && (
        <div className="hidden">
          <ProfilePhotoViewerPopup
            member={member}
            imgSrcs={highResPhotos.map((photo) => photo.path)}
            firstName={firstName}
            age={age}
            city={location}
            open={open}
            defaultIndex={defaultCarouselIndex}
            onClick={setOpen}
            setOpen={setOpen}
          />
        </div>
      )}

      <div className="relative bg-white w-full max-w-full">
        <div
          className="overflow-hidden w-full embla__viewport"
          ref={viewportRef}
        >
          <div className="embla__container">
            {photos.map((photo, index) => (
              <div className="relative min-w-fit pl-2.5" key={index}>
                <ProfilePhotoCarouselItem
                  testid={`profile-photo-carousel-item-${index}`}
                  key={index}
                  alt={"profile picture " + index.toString()}
                  imgSrc={photo.path}
                  onClick={() =>
                    embla?.clickAllowed() ? onCarouselItemClick(index) : {}
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <StyledCarouselPrevButton
          onClick={scrollPrev}
          enabled={prevBtnEnabled}
        />
        <StyledCarouselNextButton
          onClick={scrollNext}
          enabled={nextBtnEnabled}
        />
      </div>
    </div>
  );
};

export default ProfilePhotoCarousel;
