import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Favorites extends Api {
  public async getFavorites(queryParams: {
    pageIndex: number;
    pageSize: number;
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: any;
      };
    }
  > {
    return this.get("/favorites", queryParams);
  }

  public async postFavorite(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/favorite", body);
  }

  public async deleteFavorite(body: {
    userId: number | "all";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/favorite", body);
  }
}
