import { FC } from "react";

type Props = {
  title: string;
  subtitle?: string;
};

const PageHeader: FC<Props> = ({ title, subtitle }) => (
  <div className="PageHeader w-auto">
    <h2 className="sm:text-2xl text-[22px] font-semibold text-custom-black">
      {title}
    </h2>
    {subtitle && <p className="text-lg text-custom-black mt-2">{subtitle}</p>}
  </div>
);

export default PageHeader;
