import { RootState } from "../store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../backendAPI";

import { createAccount, getLoginInfo, login, logout } from "./authSlice";

import { IGreeting } from "../../backendAPI/Greetings";
import { IDiscussion, IMessage } from "../../backendAPI/Messages";
import { LiveAlert } from "../../context/SocketIoContext";

import * as Sentry from "@sentry/react";
import { assertLanguage } from "../../context/TranslationContext";
import Router from "next/router";

export type MessageTypeFilter =
  | "inbox"
  | "favorites"
  | "unread"
  | "security"
  | "welcome";

export interface State {
  greetings: {
    pageIndex: number;
    pageSize: number;
    isLoading: boolean;
    items: Greeting[];
    hasMore: boolean;
    isActiveConversation: boolean;
    currentGreetingId: number | undefined;
  };
  currentGreeting: {
    alert_icon: string;
    alert_message: string;
    date: string;
    isPhoto: boolean;
    message: string;
    pathPhoto: string;
    time: string;
    userId: string;
    infos: Greeting | undefined;
  };

  conversations: {
    pageIndex: number;
    pageSize: number;
    isLoading: boolean;
    items: Discussion[];
    typeFilter: MessageTypeFilter;
    usernameFilter: string;
    usernameFilterChanged: boolean;
    hasMore: boolean;
    isLoadingMore: boolean;

    currentConversationId: number | undefined;
  };
  currentConversation: {
    pageIndex: number;
    pageSize: number;
    isLoading: boolean;
    items: Array<Message | IMessage>;
    hasMore: boolean;
    isLoadingMore: boolean;
    isSystemMessage: boolean;
    myLastMessageHasBeenViewed: boolean;
    hasNewMessage: boolean;

    conversationInfo: Discussion | undefined;
    headerInfos:
      | {
          isFavorited: boolean;
          isReported: boolean;
          Fraudeur: boolean;
          disableControls: boolean;
          isBlocked: boolean;
          discussionDeletedByDestinataire: boolean;
          myLastMessageHasBeenRead: boolean;
        }
      | undefined;
  };

  error: string | undefined;
  isReloading: boolean;
  showActiveConvoOnMobile: boolean;
  shouldScrollBot: boolean;
  isEditingIndex: number | null;
  userid: string | null;
  chatMessage: string;
}

export type Discussion = IDiscussion;

export type Message = IMessage;

export type Greeting = IGreeting;

const initialState: State = {
  greetings: {
    pageIndex: 1,
    pageSize: 20,
    isLoading: true,
    items: [],
    hasMore: false,
    isActiveConversation: false,
    currentGreetingId: undefined,
  },
  currentGreeting: {
    alert_icon: "",
    alert_message: "",
    date: "",
    isPhoto: false,
    message: "",
    pathPhoto: "",
    time: "",
    userId: "",
    infos: undefined,
  },

  conversations: {
    pageIndex: 1,
    pageSize: 30,
    isLoading: true,
    items: [],
    isLoadingMore: false,
    typeFilter: "inbox",
    usernameFilter: "",
    usernameFilterChanged: false,
    hasMore: false,
    currentConversationId: undefined,
  },
  currentConversation: {
    pageIndex: 1,
    pageSize: 60,
    isLoading: true,
    items: [],
    isLoadingMore: false,
    hasMore: false,
    isSystemMessage: false,
    conversationInfo: undefined,
    headerInfos: undefined,
    myLastMessageHasBeenViewed: false,
    hasNewMessage: false,
  },
  error: undefined,
  isReloading: false,
  showActiveConvoOnMobile: false,
  shouldScrollBot: false,
  isEditingIndex: null,
  userid: null,
  chatMessage: "",
};

export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    reset: () => initialState,

    returnToMessengerHome: (state) => {
      state.conversations.currentConversationId = undefined;
      state.currentConversation.items = [];
      state.currentConversation.headerInfos = undefined;
      state.currentConversation.conversationInfo = undefined;
      state.showActiveConvoOnMobile = false;
    },

    setChatMessage: (state, action: PayloadAction<string>) => {
      state.chatMessage = action.payload;
    },

    setUserId: (state, action: PayloadAction<string>) => {
      state.userid = action.payload;
    },

    setShouldScrollBot: (state, action: PayloadAction<boolean>) => {
      state.shouldScrollBot = action.payload;
    },

    addGreetingsFromLiveAlert: (
      state,
      action: PayloadAction<Omit<LiveAlert, "age"> & { age: string }>
    ) => {
      if (
        state.greetings.items.length === 0 &&
        state.conversations.items.length === 0
      )
        state.greetings.items.unshift({
          userId: action.payload.fkusager_from!,
          greetingId: action.payload.discussionId!,
          profileMainPhoto: action.payload.imageProfile!,
          userName: action.payload.username!,
          age: action.payload.age,
          prenom: action.payload.prenom!,
          location: action.payload.location!,
          isVIP: !!action.payload.isVIP!,
          isCertified: !!action.payload.isCertified!,
          isUserDeleted: false,
          isUserOnline: true,
          DateDerniereVisite: action.payload.dateMessage,
          isNew: true,
        });
    },

    addMessageFromLiveAlert: (
      state,
      action: PayloadAction<Omit<LiveAlert, "age"> & { age: string }>
    ) => {
      if (state.conversations.items.length === 0) return;

      const oldConvoIndex = state.conversations.items.findIndex(
        (c) => c.discussionId == action.payload.discussionId
      );

      if (oldConvoIndex != -1) {
        state.conversations.items.splice(oldConvoIndex, 1);
      }

      // Regex to remove \ from before quotes
      const normalize_message = action.payload.message.replace(
        /\\(['"])/g,
        "$1"
      );

      // push the discussion to the top of the conversations list
      state.conversations.items.unshift({
        userId: action.payload.fkusager_from!.toString(),
        discussionType: "user",
        discussionId: action.payload.discussionId.toString(),
        userName: action.payload.username,
        profileMainPhoto: action.payload.imageProfile,
        age: action.payload.age,
        prenom: action.payload.prenom,
        location: action.payload.location,
        isVIP: !!action.payload.isVIP,
        isCertified: !!action.payload.isCertified,
        isUserDeleted: false,
        isUserOnline: true,
        dateLastMessage: action.payload.dateMessage,
        haveNewMessage: true,
        myLastMessageHasBeenRead: false,
        lastMessage: normalize_message,
        isFavorited: false,
        DateDerniereVisite: action.payload.dateMessage,
      });

      if (
        action.payload.discussionId != state.conversations.currentConversationId
      )
        return;

      const dateFormat = Intl.DateTimeFormat(assertLanguage(), {
        hour: "numeric",
        minute: "2-digit",
      });

      state.currentConversation.items.unshift({
        isPhoto: action.payload.message_image != "",
        message: normalize_message,
        pathPhoto: action.payload.message_image || "",
        pathPhoto_thumbs: action.payload.message_image_thumbnail || "",
        time: dateFormat.format(),
        userId: String(action.payload?.fkusager_from),
        type: "incoming",
        timestamp: Date.now() / 1000,
        // Only added for TS
        isEditable: false,
        messageTextArea: "",
        messageHasBeenEdited: false,
        messageId: `${Date.now()}${action.payload?.fkusager_from}`,
      });

      state.shouldScrollBot = true;
    },

    setIsReloading: (state, action: PayloadAction<boolean>) => {
      state.isReloading = action.payload;
    },

    setShowActiveConvoOnMobile: (state, action: PayloadAction<boolean>) => {
      state.showActiveConvoOnMobile = action.payload;
    },

    setUsernameFilter: (state, action: PayloadAction<string>) => {
      state.conversations.usernameFilter = action.payload;
      state.conversations.usernameFilterChanged = true;
    },

    setMyLastMessageHasBeenViewed: (state, action: PayloadAction<boolean>) => {
      state.currentConversation.myLastMessageHasBeenViewed = action.payload;
    },

    unreadConversation: (state, action: PayloadAction<number>) => {
      const conversationToUnreadIndex = state.conversations.items.findIndex(
        (convo) => {
          return convo.discussionId === action.payload;
        }
      );

      state.conversations.items[conversationToUnreadIndex].haveNewMessage =
        true;
    },
    setIsEditingIndex: (state, action: PayloadAction<number | null>) => {
      state.isEditingIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    // start Greetings

    builder.addCase(fetchGreetings.fulfilled, (state, action) => {
      state.greetings.isLoading = false;
      state.currentConversation.isLoading = false;

      state.error = checkForError(state.error, action.payload);
      if (state.error) return;

      state.greetings.items = action.payload.data.items;
    });

    builder.addCase(fetchGreetings.pending, (state, action) => {
      state.currentConversation.isLoading = true;
    });

    builder.addCase(fetchGreetings.rejected, (state, action) => {});

    // end greetings

    builder.addCase(
      loadGreetingAsActiveConversation.fulfilled,
      (state, action) => {
        state.showActiveConvoOnMobile = true;
        state.greetings.isActiveConversation = true;
        state.greetings.currentGreetingId = action.meta.arg;
        const infos = state.greetings.items.find(
          (greeting) => greeting.greetingId === action.meta.arg
        )!;

        const newGreeting = { ...action.payload.data, infos };

        state.currentGreeting = newGreeting;
      }
    );

    builder.addCase(loadGreetingAsActiveConversation.pending, (state) => {
      state.currentConversation.isLoading = true;
    });

    builder.addCase(deleteGreeting.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);
      if (state.error) return;

      state.greetings.isActiveConversation = false;

      state.currentGreeting = initialState.currentGreeting;

      const indexOfGreetingToDelete = state.greetings.items.findIndex(
        (greeting) => greeting.greetingId === action.meta.arg
      );

      state.greetings.items.splice(indexOfGreetingToDelete, 1);

      state.currentConversation.isLoading = false;
    });

    // start discussions

    builder.addCase(fetchConversations.fulfilled, (state, action) => {
      state.conversations.isLoading = false;
      state.conversations.isLoadingMore = false;
      state.conversations.usernameFilterChanged = false;

      if (!action?.payload?.data) {
        Sentry.captureMessage(
          "fetchConversations: no data\n" + JSON.stringify(action.payload)
        );
        return;
      }

      state.error = checkForError(state.error, action.payload);
      if (state.error) return;

      state.conversations.items = action.payload.data.items;

      state.conversations.hasMore = action.payload.data.hasMore;
    });

    builder.addCase(fetchConversations.pending, (state, action) => {
      const { increasePageSize, resetPageSize, typeFilter } = action.meta.arg;

      if (typeFilter) {
        state.conversations.typeFilter = typeFilter;
      }

      if (increasePageSize) {
        state.conversations.pageSize += 20;
        state.conversations.isLoadingMore = true;
      }

      if (resetPageSize) {
        state.conversations.pageSize = 30;
      }
    });

    // end discussions

    // add to favorite

    builder.addCase(addConversationToFavorite.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);
      if (state.error) return;

      const favoritedConversationIndex = state.conversations.items.findIndex(
        (conversation) => conversation.discussionId == +action.meta.arg
      );

      state.conversations.items[favoritedConversationIndex].isFavorited = true;

      if (
        state.currentConversation.conversationInfo?.discussionId ==
        +action.meta.arg
      ) {
        state.currentConversation.conversationInfo.isFavorited = true;
      }
    });

    // end add to favorite

    // remove from favorite

    builder.addCase(
      removeConversationFromFavorite.fulfilled,
      (state, action) => {
        state.error = checkForError(state.error, action.payload);
        if (state.error) return;

        const conversationToRemoveFromFavoriteIndex =
          state.conversations.items.findIndex(
            (conversation) => conversation.discussionId == +action.meta.arg
          );

        state.conversations.items[
          conversationToRemoveFromFavoriteIndex
        ].isFavorited = false;

        if (
          state.currentConversation.conversationInfo?.discussionId ==
          +action.meta.arg
        ) {
          state.currentConversation.conversationInfo.isFavorited = false;
        }
      }
    );

    // end remove to favorite

    // start messages

    builder.addCase(fetchMessages.pending, (state, action) => {
      const { increasePageSize, resetPageSize, conversationId } =
        action.meta.arg;

      state.greetings.isActiveConversation = false;
      state.isEditingIndex = null;

      if (!increasePageSize) {
        // we do not want to hide the current convo while fetching more
        state.currentConversation.isLoading = true;
      }
      if (state.conversations.currentConversationId !== conversationId)
        state.chatMessage = "";
      state.conversations.currentConversationId = conversationId;

      const indexClickedConversation = state.conversations.items.findIndex(
        (convo) => {
          return convo.discussionId === conversationId;
        }
      );

      if (state.conversations.items[indexClickedConversation]) {
        state.conversations.items[indexClickedConversation].haveNewMessage =
          false;
      }

      if (increasePageSize) {
        state.currentConversation.pageSize += 20;
        state.currentConversation.isLoadingMore = true;
      }

      if (resetPageSize) {
        state.currentConversation.pageSize = 20;
      }
    });

    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.currentConversation.isLoading = false;
      state.currentConversation.isLoadingMore = false;
      state.greetings.isActiveConversation = false;
      state.showActiveConvoOnMobile = true;

      // If user convo from Popup, set items to response data regardless
      if (action.meta.arg.conversationId === -5) {
        state.currentConversation.items = action.payload.data.items;
      }

      state.error = checkForError(state.error, action.payload);

      if (state.error) {
        state.currentConversation = {
          pageIndex: 1,
          pageSize: 60,
          isLoading: true,
          items: [],
          isLoadingMore: false,
          hasMore: false,
          isSystemMessage: false,
          conversationInfo: undefined,
          headerInfos: undefined,
          myLastMessageHasBeenViewed: false,
          hasNewMessage: false,
        };

        return;
      }

      // TODO: Get from data.conversationInfo
      const conversationInfo = state.conversations.items.find(
        (convo) =>
          convo.discussionId == state.conversations.currentConversationId
      );

      if (!conversationInfo) {
        // If conversation is not found, redirect to messages
        returnToMessengerHome();
        return;
      }

      state.currentConversation.items = action.payload.data.items;

      state.currentConversation.headerInfos = action.payload.data.headerInfos;

      state.currentConversation.conversationInfo = conversationInfo;

      state.currentConversation.hasMore =
        action.payload.data.items.length < action.payload.data.total;

      const { fromLiveAlert } = action.meta.arg;

      if (fromLiveAlert) {
        state.currentConversation.hasNewMessage = true;
      }

      if (action.payload.data.headerInfos.myLastMessageHasBeenRead) {
        state.currentConversation.myLastMessageHasBeenViewed = true;
      } else {
        state.currentConversation.myLastMessageHasBeenViewed = false;
      }

      if (!action.meta.arg.increasePageSize) {
        state.shouldScrollBot = true;
      }
    });

    // end messages

    // send message

    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);
      if (state.error || action.payload.status === "ERROR") return;
      const rawMessage =
        typeof action.meta.arg === "string"
          ? action.meta.arg
          : action.meta.arg.chatMessage;

      const regexUrl =
        /(http|https|www)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;

      const transformedMessage = rawMessage
        .replace(/\n/g, " <br />")
        .replace(regexUrl, `<a href="$&" target="_blank">$&</a>`);

      const myuserid = state.userid || window.localStorage.getItem("userid");

      if (!myuserid) {
        Sentry.captureException(new Error(JSON.stringify(state)));
        return;
      }

      const mockMessage: IMessage = {
        // Can't use this one, as it is the ID of other user
        // userId: `${
        //   action.meta.arg?.userId ||
        //   state.currentConversation.conversationInfo!.userId
        // }`,
        userId: myuserid,
        messageId: action.payload.data.messageId,
        isPhoto: false,
        pathPhoto: "",
        pathPhoto_thumbs: "",
        isEditable: true,
        timestamp: Date.now() / 1000,
        time: action.payload.data.time,
        message: transformedMessage,
        messageTextArea: action.payload.data.messageTextArea,
        messageHasBeenEdited: false,
        type: "outgoing",
      };

      if (state.currentConversation.headerInfos) {
        state.currentConversation.headerInfos.discussionDeletedByDestinataire =
          false;

        state.currentConversation.headerInfos.myLastMessageHasBeenRead = false;
      }

      if (
        state.currentConversation.conversationInfo?.discussionId ==
        action.payload?.data?.discussionId
      ) {
        state.currentConversation.items.unshift(mockMessage);
      } else {
        const messengerConvoIndex = state.conversations.items.findIndex(
          (item) => {
            return item.discussionId == action.payload?.data?.discussionId;
          }
        );

        if (messengerConvoIndex === -1) {
          const mockNewConvo = {
            userId: -9000,
            discussionType: "user" as const,
            //@ts-ignore
            discussionId: action.payload?.data?.discussionId ?? -9000,
            userName: action.payload?.data?.userName ?? "",
            profileMainPhoto: action.payload?.data?.mainImage,
            age: action.payload?.data?.age,
            prenom: "",
            location: "",
            isVIP: false,
            isCertified: false,
            isUserDeleted: false,
            isUserOnline: false,
            dateLastMessage: "Il y a 0 min.",
            haveNewMessage: false,
            myLastMessageHasBeenRead: false,
            lastMessage: action.payload?.data?.messageTextArea,
            isFavorited: false,
            DateDerniereVisite: "",
          };

          state.conversations.items.unshift(mockNewConvo);
        }

        return;
      }

      const conversationIndex = state.conversations.items.findIndex(
        (item) =>
          item.discussionId ==
          state.currentConversation.conversationInfo?.discussionId
      );

      if (conversationIndex !== -1) {
        state.conversations.items[conversationIndex].lastMessage =
          transformedMessage;
        state.shouldScrollBot = true;
      }
    });

    // end send message

    builder.addCase(sendPhoto.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);

      const myUserid = state.userid || window.localStorage.getItem("userid");

      if (!myUserid) {
        Sentry.captureException(new Error(JSON.stringify(state)));
        return;
      }

      if (state.error) return;
      const mockMessage: IMessage = {
        userId: myUserid,
        messageId: action.payload.data.messageId,
        isPhoto: true,
        pathPhoto: action.payload.data.pathImage,
        pathPhoto_thumbs: action.payload.data.pathPhoto_thumbs,
        isEditable: false,
        time: action.payload.data.time,
        timestamp: Date.now() / 1000,
        type: "outgoing",
        message: "",
        messageTextArea: "",
        messageHasBeenEdited: false,
      };

      if (state.currentConversation.headerInfos) {
        state.currentConversation.headerInfos.discussionDeletedByDestinataire =
          false;

        state.currentConversation.headerInfos.myLastMessageHasBeenRead = false;
      }

      state.currentConversation.items.unshift(mockMessage);

      state.shouldScrollBot = true;
    });

    builder.addCase(logout.fulfilled, () => initialState);

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      state.userid =
        action?.payload?.data?.infosProfile?.userid?.toString() ?? "";
    });

    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.userid =
        action?.payload?.data?.infosProfile?.userid?.toString() ?? "";
    });

    builder.addCase(editMessage.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);
      if (state.error) {
        if (action.payload.http_code === 400) {
          state.isEditingIndex = null;
        }
        return;
      }
      const index = state.currentConversation.items.findIndex(
        (item) => item.messageId == action.meta.arg.messageId
      );
      state.currentConversation.items[index] = {
        ...state.currentConversation.items[index],
        message: action.meta.arg.newMessage,
        messageHasBeenEdited: true,
      };
      state.isEditingIndex = null;
      if (index == 0) {
        // Last message shows ups in conversation preview
        const conversationIndex = state.conversations.items.findIndex(
          (item) =>
            item.discussionId ==
            state.currentConversation.conversationInfo?.discussionId
        );
        state.conversations.items[conversationIndex].lastMessage =
          action.meta.arg.newMessage;
      }
    });

    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      state.error = checkForError(state.error, action.payload);
      if (state.error) return;
      const message = state.currentConversation.items.find(
        (item) => item.messageId == action.meta.arg
      );
      if (!message) return;
      message.isDeleted = true;
      message.message = "Message deleted";
      message.messageTextArea = "<i>Message deleted</i>";
      message.pathPhoto = "";
      message.pathPhoto_thumbs = "";
      const conversation = state.conversations.items.find(
        (item) =>
          item.discussionId ==
          state.currentConversation.conversationInfo?.discussionId
      );
      if (!conversation) return;
      conversation.lastMessage = "";
      // Enable this to go back to previous valid message. Only client side though, refresh will make last_message empty string
      //   conversation.lastMessage =
      //     state.currentConversation.items[1]?.message ?? "";
    });

    builder.addCase(deleteConversation.fulfilled, (state, action) => {
      if (!state.currentConversation.conversationInfo) return;

      const deletedConversationIndex = state.conversations.items.findIndex(
        (conversation) => {
          return conversation.discussionId == +action.payload;
        }
      );

      state.conversations.items.splice(deletedConversationIndex, 1);

      state.currentConversation.items = [];
      state.currentConversation.conversationInfo = undefined;

      state.conversations.currentConversationId = undefined;

      state.isReloading = true;

      state.showActiveConvoOnMobile = false;
    });
  },
});

export const fetchGreetings = createAsyncThunk(
  "messenger/fetchGreetings",
  async () => {
    const response = await api.greetings.getGreetings({
      pageIndex: 1,
      pageSize: 20,
    });

    return response;
  }
);

export const deleteGreeting = createAsyncThunk(
  "messenger/deleteGreeting",

  async (greetingId: number) => {
    const response = await api.greetings.deleteGreeting({
      greetingId,
    });

    return response;
  }
);

export const loadGreetingAsActiveConversation = createAsyncThunk(
  "messenger/loadGreetingAsActiveConversation",

  async (greetingId: number) => {
    const url = new URL(window.location.href);
    url.searchParams.delete("conversation");
    await Router.push(url);

    const response = await api.greetings.getGreeting({
      greetingId: greetingId,
    });

    return response;
  }
);

export const fetchConversations = createAsyncThunk(
  "messenger/fetchConversations",
  async (
    config: {
      increasePageSize?: boolean;
      typeFilter?: "inbox" | "favorites" | "unread" | "security" | "welcome";
      resetPageSize?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;

    const response = await api.messages.getDiscussions({
      pageIndex: state.messenger.conversations.pageIndex,
      pageSize: state.messenger.conversations.pageSize,
      type: config.typeFilter || state.messenger.conversations.typeFilter,
      search: state.messenger.conversations.usernameFilter,
    });

    return response;
  }
);

export const addConversationToFavorite = createAsyncThunk(
  "messenger/addConversationToFavorite",
  async (conversationId: string) => {
    const response = await api.messages.postMessageAction({
      discussionId: parseInt(conversationId),
      action: "addFavorite",
    });

    return response;
  }
);

export const removeConversationFromFavorite = createAsyncThunk(
  "messenger/removeConversationFromFavorite",
  async (conversationId: string) => {
    const response = await api.messages.deleteMessageAction({
      discussionId: parseInt(conversationId),
      action: "deleteFavorite",
    });

    return response;
  }
);

export type FetchMessagesProps =
  | {
      increasePageSize?: boolean;
      resetPageSize?: boolean;
      conversationId: number | string;
      fromLiveAlert: boolean;
      userId?: number;
    }
  | {
      increasePageSize?: boolean;
      resetPageSize?: boolean;
      conversationId: -5 | string;
      fromLiveAlert: boolean;
      userId: number;
    };

export const fetchMessages = createAsyncThunk(
  "messenger/fetchMessages",
  async (config: FetchMessagesProps, { getState }) => {
    const state = getState() as RootState;
    if (config.conversationId === -5 && "userId" in config) {
      return await api.messages.getDiscussion({
        pageIndex: 1,
        pageSize: state.messenger.currentConversation.pageSize,
        discussionId: config.conversationId,
        userId: `${config.userId}`,
      });
    }

    const response = await api.messages.getDiscussion({
      pageIndex: 1,
      pageSize: state.messenger.currentConversation.pageSize,
      discussionId: config.conversationId,
    });

    const conversationId = config?.conversationId?.toString();
    if (conversationId && window && history) {
      // if a profile popup is shown, don't change the url
      if (window.location.href.includes("profile=")) return response;
      const url = new URL(window.location.href);
      url.searchParams.set("conversation", config?.conversationId?.toString());
      Router.push(url);

      document.dispatchEvent(new Event("conversationchanged"));
    }

    return response;
  }
);

export type SendMessageProps =
  | string
  | {
      discussionId: -1;
      chatMessage: string;
      userId: number;
      username: string;
    };

export const sendMessage = createAsyncThunk(
  "messenger/sendMessage",
  async (props: SendMessageProps, { getState }) => {
    const state = getState() as RootState;

    const myUserid =
      state.messenger.userid || window.localStorage.getItem("userid");

    if (!myUserid) {
      Sentry.captureException(new Error(JSON.stringify(state)));
      return;
    }

    const error = checkEmptyString(
      typeof props === "string" ? props : props.chatMessage
    );

    if (error) {
      return error!;
    }

    let config;
    if (typeof props === "string") {
      config = {
        discussionId:
          state.messenger.currentConversation.conversationInfo!.discussionId,
        userId: state.messenger.currentConversation.conversationInfo!.userId,
        username:
          state.messenger.currentConversation.conversationInfo!.userName,
        message: props,
      };
    } else {
      config = {
        discussionId: props.discussionId,
        message: props.chatMessage,
        username: props.username,
        userId: props.userId,
      };
    }
    const response = await api.messages.postMessage(config);
    return {
      ...response,
      myUserid,
    };
  }
);

export const sendPhoto = createAsyncThunk(
  "messenger/sendPhoto",
  async (file: File, { getState }) => {
    const state = getState() as RootState;

    const response = await api.messages.postPhoto({
      discussionId:
        state.messenger.currentConversation.conversationInfo!.discussionId,
      userId:
        state.messenger.currentConversation.conversationInfo!.userId.toString(),
      username: state.messenger.currentConversation.conversationInfo!.userName,
      uploadfile: file,
    });

    return response;
  }
);

export const editMessage = createAsyncThunk(
  "messanger/editMessage",
  async (
    { messageId, newMessage }: { messageId: string; newMessage: string },
    { getState }
  ) => {
    const state = getState() as RootState;
    return (
      checkEmptyString(newMessage) ??
      (await api.messages.edit({
        discussionId:
          state.messenger.currentConversation.conversationInfo!.discussionId,
        messageId: messageId,
        message: newMessage,
      }))
    );
  }
);

export const deleteMessage = createAsyncThunk(
  "messenger/deleteMessage",
  async (messageId: string, { getState }) => {
    const state = getState() as RootState;

    const response = await api.messages.deleteMessage({
      discussionId:
        state.messenger.currentConversation.conversationInfo!.discussionId,
      messageId: messageId,
    });

    return response;
  }
);

export const deleteConversation = createAsyncThunk(
  "messenger/deleteDiscussion",
  async (discussionId: number, { getState }) => {
    await api.messages.deleteConversation({
      discussionId: discussionId,
    });

    const url = new URL(window.location.href);
    url.searchParams.delete("conversation");
    await Router.push(url);

    return discussionId;
  }
);

export const {
  reset,
  setUsernameFilter,

  setMyLastMessageHasBeenViewed,
  unreadConversation,
  setIsReloading,
  setShowActiveConvoOnMobile,
  addMessageFromLiveAlert,
  addGreetingsFromLiveAlert,
  setShouldScrollBot,
  setIsEditingIndex,
  setUserId,
  returnToMessengerHome,
  setChatMessage,
} = messengerSlice.actions;

/**
 * Checks HTTP errors for a message, and adds a space if the same error comes again to bypass the useEffect
 * @todo Find a better way to handle this use case, like replacing useEffect with a dedicated setter to show the toast
 * @param prevError The previous error message (state.error)
 * @param payload The received payload
 * @returns The new error message (if there is one), or an empty string
 */
function checkForError(
  prevError: string | undefined,
  payload: { message: string; status: "ERROR" | "SUCCESS" }
): string {
  if (payload.status === "ERROR") {
    return payload.message + (prevError === payload.message ? " " : "");
  }
  return "";
}

/**
 * Checks the string for content by removing whitespaces and html tags
 * @param message the string to check
 */
function checkEmptyString(message: string) {
  if (!message || !message.replace(/(<[^>]*>|\s)/g, "")) {
    return {
      status: "ERROR",
      http_code: 500,
      message: "Message must not be empty.",
    } as const;
  }
  return null;
}

export default messengerSlice.reducer;
