import { Languages } from "../../context/TranslationContext";
import BackendProxy from "../utils/BackendProxy";
import Advice from "./Advice";

export type Endpoint = "blogs";

export type QueryParams = {
  pageSize: number;
  q?: string;
  locale: Languages;
};

class AdviceList extends BackendProxy<Advice, Endpoint, QueryParams> {
  constructor(endpoint: Endpoint, config: QueryParams) {
    super(endpoint, config, Advice);
  }
}

export default AdviceList;
