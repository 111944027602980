import React, { FC } from "react";

https: type Props = {
  currentPictureIndex: number;
  maxNumberOfPictures: number;
};

const DateMePictureCounter: FC<Props> = ({
  currentPictureIndex,
  maxNumberOfPictures,
}) => {
  return (
    <div className="DateMePictureCounter flex items-center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8333 3.33333H15.41L13.59 0.973333C13.3554 0.671505 13.0553 0.427014 12.7122 0.258378C12.3692 0.0897429 11.9923 0.00138587 11.61 0L8.39 0C8.00775 0.00138587 7.63082 0.0897429 7.28778 0.258378C6.94473 0.427014 6.64456 0.671505 6.41 0.973333L4.59 3.33333H4.16667C3.062 3.33466 2.00296 3.77407 1.22185 4.55518C0.440735 5.3363 0.00132321 6.39534 0 7.5L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V7.5C19.9987 6.39534 19.5593 5.3363 18.7782 4.55518C17.997 3.77407 16.938 3.33466 15.8333 3.33333ZM7.73 1.99167C7.80806 1.89088 7.90808 1.80922 8.02244 1.7529C8.13681 1.69658 8.26252 1.66709 8.39 1.66667H11.61C11.7375 1.66721 11.8631 1.69676 11.9775 1.75307C12.0918 1.80938 12.1919 1.89097 12.27 1.99167L13.305 3.33333H6.695L7.73 1.99167ZM18.3333 15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V7.5C1.66667 6.83696 1.93006 6.20107 2.3989 5.73223C2.86774 5.26339 3.50363 5 4.16667 5H15.8333C16.4964 5 17.1323 5.26339 17.6011 5.73223C18.0699 6.20107 18.3333 6.83696 18.3333 7.5V15.8333Z"
          fill="black"
        />
        <path
          d="M10 6.66699C9.0111 6.66699 8.0444 6.96024 7.22215 7.50964C6.3999 8.05905 5.75904 8.83994 5.3806 9.75357C5.00217 10.6672 4.90315 11.6725 5.09608 12.6424C5.289 13.6123 5.76521 14.5033 6.46447 15.2025C7.16373 15.9018 8.05465 16.378 9.02455 16.5709C9.99446 16.7638 10.9998 16.6648 11.9134 16.2864C12.827 15.908 13.6079 15.2671 14.1573 14.4448C14.7068 13.6226 15 12.6559 15 11.667C14.9987 10.3413 14.4715 9.07031 13.5341 8.13292C12.5967 7.19552 11.3257 6.66832 10 6.66699ZM10 15.0003C9.34073 15.0003 8.69627 14.8048 8.1481 14.4386C7.59994 14.0723 7.1727 13.5517 6.9204 12.9426C6.66811 12.3335 6.6021 11.6633 6.73072 11.0167C6.85933 10.3701 7.1768 9.77614 7.64298 9.30997C8.10915 8.84379 8.7031 8.52632 9.3497 8.39771C9.9963 8.26909 10.6665 8.3351 11.2756 8.58739C11.8847 8.83969 12.4053 9.26693 12.7716 9.81509C13.1378 10.3633 13.3333 11.0077 13.3333 11.667C13.3333 12.551 12.9821 13.3989 12.357 14.024C11.7319 14.6491 10.8841 15.0003 10 15.0003Z"
          fill="black"
        />
      </svg>
      <p className="ml-1.5">
        {currentPictureIndex}/{maxNumberOfPictures}
      </p>
    </div>
  );
};

export default DateMePictureCounter;
