import React, { FC } from "react";

type Props = {
  title: string;
};

const FilterCategoryTitle: FC<Props> = ({ title }) => {
  return (
    <div className="block mb-1.5 text-base font-semibold text-custom-black">
      {title}
    </div>
  );
};

export default FilterCategoryTitle;
