import { FC } from "react";
import { Tag } from "../../types";

const bgColors = {
  activities: "bg-custom-light-green",
  musics: "bg-custom-light-pink",
  qualities: "bg-custom-light-teal",
  animals: "bg-custom-light-violet",
};

const borderColors = {
  activities: "border-custom-light-green",
  musics: "border-custom-light-pink",
  qualities: "border-custom-light-teal",
  animals: "border-custom-light-violet",
};

type Props = {
  type: "activities" | "musics" | "qualities" | "animals";
  tag: Tag;
  onClick?: () => void;
};

const ProfileInterestTag: FC<Props> = ({ type, tag, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`ProfileInterestTag flex h-11 min-h-[44px] px-5 items-center w-fit rounded border-2 ${
        borderColors[type]
      } ${tag.selected && bgColors[type]}`}
    >
      <p className="text-base text-custom-black-500">{tag.description}</p>
    </button>
  );
};

export default ProfileInterestTag;
