import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class VisitedMyProfile extends Api {
  public async getVisitedMyProfile(queryParams: {
    pageIndex: number;
    pageSize: number;
    type: "all" | "criteria";
  }): Promise<
    HTTPResponse & {
      data: {
        pageIndex: number;
        items: number;
        total: number;
        list: {
          Appearance: string;
          Appearance_title: string;
          LastUpdate: string;
          LastUpdate_text: string;
          NbVisitedMyProfile: string;
          age: string;
          city: string;
          etatCivile: string;
          ftfInterviewDone: string;
          ftfInterviewDone_title: string;
          genre: string;
          id: number;
          isCertified: boolean;
          isFavorited: boolean;
          isOnline: boolean;
          isVIP: boolean;
          lastVisitedDate: string;
          mainImage: string;
          photos: unknown[];
          prenom: string;
          profilId: string;
          sexeRecherche: string;
          sexeRecherche_title: string;
          status: string;
          totalVoteProfil: string;
          typeRecherche: string;
          typeRecherche_title: string;
          userId: string;
          userName: string;
        }[];
      };
    }
  > {
    return this.get("/visitedMyProfile", queryParams);
  }

  public async deleteVisitedMyProfile(body: {
    userId: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/visitedMyProfile", body);
  }
}
