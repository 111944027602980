import React, { FC } from "react";

type Props = {
  errorMessage: string;
  show?: boolean;
  testId?: string;
};

const ErrorLabel: FC<Props> = ({
  errorMessage,
  show = true,
  testId = "error-label",
}) => {
  return show ? (
    <p
      data-testid={testId}
      className="ErrorLabel text-custom-red font-semibold"
      dangerouslySetInnerHTML={{ __html: errorMessage }}
    />
  ) : (
    <></>
  );
};

export default ErrorLabel;
