import React, { FC, useContext, useEffect, useState } from "react";
import { SocketIoContext } from "../../context/SocketIoContext";

import FloatingNotification from "../floatingNotification";

const NotificationContainer: FC = () => {
  const { notification } = useContext(SocketIoContext);
  const [incomingNotification, setIncomingNotification] =
    useState(notification);
  useEffect(() => {
    setIncomingNotification(notification);
  }, [notification]);

  return (
    <div className="NotificationContainer fixed right-3 top-14 sm:right-5 z-[60]">
      {incomingNotification &&
        notification &&
        incomingNotification.type !== "no-message" && (
          <FloatingNotification
            type={incomingNotification?.type}
            username={incomingNotification?.username}
            notification={notification}
          />
        )}
    </div>
  );
};

export default NotificationContainer;
