import React, { useContext, useState } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import useCertification from "../../../hooks/useCertification";
import useUser from "../../../hooks/useUser";
import LoadingSpinner from "../../loadingSpinner";

export default function WebCamIntro() {
  const { i } = useContext(TranslationContext);
  const { userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const { setCurrentStep } = useCertification();
  const onClick = () => {
    if (isLoading) return;
    setIsLoading(true);
    let mediaStream: MediaStream | null = null;
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        mediaStream = stream;
        // Check if valid webcam available
        if (stream.getVideoTracks().length > 0) {
          setCurrentStep("how-to");
        } else {
          setCurrentStep("upload-intro");
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setCurrentStep("upload-intro");
        setIsLoading(false);
      })
      .finally(() => {
        // Correctly Close mediaStream
        if (!mediaStream) return;
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      });
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="border-[3px] border-custom-orange relative rounded-3xl h-[85px] w-[85px] overflow-hidden mt-14">
          <img src={userData.mainImage} alt="Your profile picture" />

          <div className="w-fit absolute bottom-1 right-1">
            <img
              alt="Badge for certified profile"
              src="/app/assets/cardMember/certifiedBadge.svg"
              className="min-w-[25px] md:w-[25px] h-[30px] md:h-[34px]"
            />
          </div>
        </div>
      </div>

      <div>
        <h2 className="sm:text-[22px] text-xl font-semibold text-custom-black text-center mt-6">
          {i("Certify your profile for more success!")}
        </h2>
        <p className="text-xl text-center mt-4">
          {i("Send as many messages as you want... and more")}
        </p>
      </div>

      <div className="flex justify-center">
        <ul>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            <span
              dangerouslySetInnerHTML={{
                __html: i(
                  `To prove that your profile is <strong>real and genuine</strong>`
                ),
              }}
            />
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i("To remove limits on sending messages")}
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i("To send a lot more greetings")}
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i("To receive even more reponses to your message")}
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i("To get even more new messages")}
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i(
              "Your profile will be seen more often. Certified profiles are highlighted"
            )}
          </li>
          <li className="mt-4">
            <img
              className="inline-block mr-3"
              src="/app/assets/checkmark.svg"
              alt="checkmark"
            />
            {i("It's free!")}
          </li>
        </ul>
      </div>

      <div className="flex justify-center mb-10 mt-[25px]">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <button
            onClick={onClick}
            className="rounded bg-custom-blue text-white w-64 h-[50px]  font-arial-round-bold  "
          >
            {i("I want to certify my profile")}
          </button>
        )}
      </div>
    </div>
  );
}
