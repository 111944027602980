import React, { FC, useState, useEffect, useContext } from "react";
import { Transition } from "@headlessui/react";
import Link from "next/link";
import useTranslation, {
  TranslationContext,
} from "../../context/TranslationContext";
import { LiveAlert } from "../../context/SocketIoContext";
import classNames from "../../utils/twClassNames";
import { BanIcon, XIcon } from "@heroicons/react/solid";

type Props = {
  type:
    | "greeting"
    | "message"
    | "visite"
    | "perfectMatch"
    | "want_to_meet_me"
    | "vote"
    | "favorite"
    | "feedback"
    | "vip"
    | "noads"
    // Admin bubbles, should put to another filed maybe
    | "approved"
    | "refused";
  username: string;
  index?: number;
  notification: LiveAlert;
};

const FloatingNotification: FC<Props> = ({
  type,
  username,
  index,
  notification,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { i } = useContext(TranslationContext);
  useEffect(() => {
    if (notification.category === "call") return;
    setIsVisible(true);
    const handleDeleteFloatingNotification = setTimeout(() => {
      setIsVisible(false);
    }, 8000);
    return () => {
      clearTimeout(handleDeleteFloatingNotification);
    };
  }, [notification]);

  const handleTypeOfNotification = () => {
    switch (type) {
      case "greeting":
        return (
          <Link href={"/profile/" + username} passHref>
            <div className="cursor-pointer flex text-left">
              {" "}
              <img
                src="/app/assets/floatingNotificationIcons/greetingIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i("[username] has sent you a greeting", {
                  username: (
                    <span className="underline underline-offset-2 pr-1">
                      {username}
                    </span>
                  ),
                })}
              </p>
            </div>
          </Link>
        );
      case "message":
        return (
          <a
            aria-label="View new Message"
            href={`/app/messages?conversation=${
              notification.discussionId || ""
            }`}
          >
            <div className="cursor-pointer flex">
              <img
                src="/app/assets/floatingNotificationIcons/messageIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i("[username] sent you a new message. Read the message", {
                  username: (
                    <span className="underline underline-offset-2 pr-1">
                      <a
                        href={`/app/messages?conversation=${
                          notification.discussionId || ""
                        }`}
                      >
                        {username}
                      </a>
                    </span>
                  ),
                })}
                {/* <span className="underline underline-offset-2 pr-1">
                  <Link
                    href={`/messages?id=${notification.discussionId || ""}`}
                    passHref
                  >
                    {username}
                  </Link>
                </span>{" "}
                {i("sent you a new message. Read the message")} */}
              </p>
            </div>
          </a>
        );
      case "visite":
        return (
          <Link href={"/profile/" + username} passHref>
            <div className="cursor-pointer flex">
              {" "}
              <img
                src="/app/assets/floatingNotificationIcons/smileFaceIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i("[username] is visiting your profile right now", {
                  username: (
                    <span className="underline underline-offset-2 pr-1">
                      {username}
                    </span>
                  ),
                })}
              </p>
            </div>
          </Link>
        );
      case "perfectMatch":
        return (
          <Link href="/perfect-match" passHref>
            <div className="cursor-pointer flex">
              {" "}
              <img
                src="/app/assets/floatingNotificationIcons/heartIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i(
                  "You have a new perfect match with [username]. See the match",
                  {
                    username: (
                      <Link href={"/profile/" + username} passHref>
                        <span className="underline underline-offset-2 pr-1">
                          {username}.
                        </span>
                      </Link>
                    ),
                  }
                )}
              </p>
            </div>
          </Link>
        );
      case "want_to_meet_me":
        return (
          <Link href="/who-wants-to-meet-me" passHref>
            <div className="cursor-pointer flex">
              {" "}
              <img
                src="/app/assets/floatingNotificationIcons/userHeartIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i("[username] wants to meet you. See the request", {
                  username: (
                    <span className="underline underline-offset-2 pr-1">
                      <Link href={"/profile/" + username} passHref>
                        {username}
                      </Link>
                    </span>
                  ),
                })}
              </p>
            </div>
          </Link>
        );
      case "favorite":
        return (
          <Link href={"/profile/" + username} passHref>
            <div className="cursor-pointer flex">
              {" "}
              <img
                src="/app/assets/floatingNotificationIcons/outlinedStarIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i("[username] added you to his favories", {
                  username: (
                    <span className="underline underline-offset-2 pr-1">
                      {username}
                    </span>
                  ),
                })}
              </p>
            </div>
          </Link>
        );
      case "vote":
        return (
          <Link href={"/profile/" + username} passHref>
            <div className="cursor-pointer flex">
              <img
                src="/app/assets/floatingNotificationIcons/coloredThumbsUpIcon.svg"
                alt={closeIconAlt}
                className="pr-3 w-10"
                onClick={() => setIsVisible(false)}
              />
              <p>
                {i(
                  "[username] has given your profile a vote. See their profile.",
                  {
                    username: (
                      <span className="underline underline-offset-2 pr-1">
                        {username}
                      </span>
                    ),
                  }
                )}
              </p>
            </div>
          </Link>
        );
      case "approved":
      case "refused":
        // Moderator Bubbles
        if (notification.category === "my-profile") {
          return (
            <ModeratorNotification
              onClose={() => setIsVisible(false)}
              {...notification}
            />
          );
        }
      default:
        return null;
    }
  };

  return (
    <Transition
      show={isVisible}
      data-testid={`floating-notification-${type}-${index}`}
      className={classNames(
        "MessageFloatingNotification  w-[350px] h-fit bg-gradient-to-r rounded-[4px] flex justify-between py-4 px-5 mb-5",
        notification.category !== "my-profile"
          ? "from-custom-transparent-lightpurple to-custom-transparent-purple text-white"
          : notification.type === "approved"
          ? "bg-custom-gradient-orange text-white"
          : "bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      )}
      {...transitionConfig}
    >
      {handleTypeOfNotification()}
      {notification.type !== "refused" ? (
        <img
          src={"/app/assets/whiteDeleteIcon.svg"}
          alt={closeIconAlt}
          className="py-1 pl-2 h-6 cursor-pointer"
          onClick={() => setIsVisible(false)}
        />
      ) : (
        <button
          data-testid="toast-close-button"
          className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setIsVisible(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </Transition>
  );
};

const transitionConfig = {
  enter: "transition-opacity duration-150",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity duration-150",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

export const closeIconAlt = "Dismiss notification icon";

export default FloatingNotification;

type ModeratorNotificationProps = LiveAlert & {
  type: "approved" | "refused";
  onClose(): void;
};

function ModeratorNotification({
  onClose,
  type,
  subcategory,
  message,
}: ModeratorNotificationProps) {
  const { i } = useTranslation();

  const getIcon = () => {
    if (type === "refused") return <BanIcon aria-hidden="true" />;
    else if (subcategory === "certification")
      return (
        <img
          alt="Badge for certified profile"
          src="/app/assets/cardMember/certifiedBadge.svg"
        />
      );
    else
      return (
        <img
          src="/app/assets/floatingNotificationIcons/whiteUserIcon.svg"
          alt={closeIconAlt}
        />
      );
  };

  return (
    <div className="flex gap-3 items-center">
      {React.cloneElement(getIcon(), {
        onClick: onClose,
        className: "text-red-400 w-10 min-w-[36px]",
      })}
      <p>{message}</p>
    </div>
  );
}
