import { FC, useEffect } from "react";
import Link from "next/link";
import MessageBannerPicture from "../messageBannerPicture";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

const MessageActiveUserInfoContainer: FC<{ discussionInfo: any }> = ({
  discussionInfo,
}) => {
  return (
    <div className="MessageActiveUserInfoContainer truncate">
      <div className="flex flex-row space-x-[9px]">
        <div className="relative">
          <MessageBannerPicture
            userImgSrc={discussionInfo.profileMainPhoto}
            userName={discussionInfo.userName}
          />
          {discussionInfo.isUserOnline && (
            <div className="p-[5px] border-2 border-white rounded-full bg-custom-status-green absolute bottom-1 -right-1"></div>
          )}
        </div>

        <div className="flex flex-col truncate">
          <div className="flex flex-row items-center mt-0 mb-[1px] truncate">
            <OpenProfileLink username={discussionInfo.userName}>
              <span
                className={
                  discussionInfo.userId === -1
                    ? "text-[16px] sm:text-[20px] font-semibold leading-7 text-custom-black-500 cursor-pointer mt-3"
                    : "text-[16px] sm:text-[20px] font-semibold leading-7 text-custom-black-500 cursor-pointer"
                }
              >
                {discussionInfo.userName}
                {discussionInfo.prenom && discussionInfo.userId != -2 && (
                  <span className="hidden sm:inline px-1">
                    {"("}
                    {discussionInfo.prenom}
                    {")"}
                  </span>
                )}
                {discussionInfo.age && discussionInfo.userId != -2
                  ? `, ${discussionInfo.age}`
                  : ""}
              </span>
            </OpenProfileLink>

            {discussionInfo.isVIP && discussionInfo.userId != -2 && (
              <img
                className=" w-7 ml-1 mr-[1px]"
                alt="VIP profil icon"
                src="/app/assets/vipcard.png"
              />
            )}
            {!discussionInfo?.isVIP && discussionInfo?.isCertified && (
              <img
                className="h-7 ml-1 mr-[1px]"
                alt="VIP profil icon"
                src="/app/assets/notificationsCertifiedBadgeIcon.svg"
              />
            )}
          </div>{" "}
          <p className="text-base text-custom-black leading-5 truncate">
            {discussionInfo.location && discussionInfo.userId != -2
              ? `${discussionInfo.location}`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageActiveUserInfoContainer;
