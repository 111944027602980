import Link from "next/link";
import React, { FC } from "react";

type Props = {
  username: string;
  isVip?: boolean;
  dot?: string;
  lastActive_text: string;
  lastActive: string;
};

const DateMeQuickViewStatus: FC<Props> = ({
  username,
  isVip,
  dot = "",
  lastActive_text,
  lastActive,
}) => {
  return (
    <div className="DateMeQuickViewStatus cursor-pointer">
      <div className="flex">
        <p className="text-custom-black-500 text-[22px] font-semibold">
          {username}
        </p>
        {isVip && (
          <div className="ml-3 relative -top-2">
            <img
              height={32}
              width={40}
              src="/app/assets/vipcard.png"
              alt="VIP member icon"
            />
          </div>
        )}
      </div>
      <div>
        <p className="text-sm text-custom-black-200">
          {lastActive_text + ": "}

          {dot && <Dot dotColor={dot} />}
          {lastActive}
        </p>
      </div>
    </div>
  );
};

function Dot({ dotColor }: { dotColor: string }) {
  switch (dotColor) {
    case "green":
      return (
        <div
          data-testid="online-circle"
          className="inline-block ml-1 mr-2 bg-custom-status-green w-[11px] h-[11px] rounded-full"
        />
      );

    case "orange":
      return (
        <div
          data-testid="online-circle"
          className="inline-block ml-1 mr-2 bg-custom-orange w-[11px] h-[11px] rounded-full"
        />
      );

    default:
      return null;
  }
}

export default DateMeQuickViewStatus;
