import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Feedback extends Api {
  public async postFeedback(body: {
    note_question1: string;
    note_question2: string;
    comment: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/feedback", body);
  }

  public async postNotNow(): Promise<HTTPResponseWithDataMessage> {
    return this.post("/feedback/not-now", {});
  }
}
