import { Transition } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import { closeIconAlt } from "../floatingNotification/FloatingNotification";
import useTranslation from "../../context/TranslationContext";
import { LiveAlert, SocketIoContext } from "../../context/SocketIoContext";

type FeedbackNotificationProps = {
  onClick(): void;
};
export default function FeedbackNotification({
  onClick,
}: FeedbackNotificationProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { i } = useTranslation();
  const { activeSocket } = useContext(SocketIoContext);

  const handleClick = () => {
    setIsVisible(false);
    onClick();
  };

  useEffect(() => {
    if (!activeSocket) return;
    const messengerAlertHandler = (event: LiveAlert) => {
      if (event.category !== "popup" || event.subcategory !== "feedback")
        return;

      setIsVisible(true);
    };

    activeSocket.on("notification", messengerAlertHandler);

    return () => {
      activeSocket.removeListener("notification", messengerAlertHandler);
    };
  }, [activeSocket]);

  return (
    <div className="NotificationContainer fixed right-3 bottom-5 md:bottom-7 sm:m-auto z-[60]">
      <Transition
        show={isVisible}
        data-testid={`floating-notification-feedback`}
        className="MessageFloatingNotification text-white w-[275px] xs:w-[350px] h-fit bg-gradient-to-r from-custom-transparent-lightpurple to-custom-transparent-purple rounded-[4px] flex justify-between py-4 px-5 mb-5"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="cursor-pointer flex" onClick={handleClick}>
          <img
            src="/app/assets/floatingNotificationIcons/coloredThumbsUpIcon.svg"
            alt={closeIconAlt}
            className="pr-3 w-10"
            onClick={() => setIsVisible(false)}
          />
          <p>
            <span className="underline underline-offset-2 pr-1">
              {i("Let us know how you are liking our site!")}
            </span>
            {i("Your feedback helps us keep making improvements")}
          </p>
        </div>
        <img
          src="/app/assets/whiteDeleteIcon.svg"
          alt={closeIconAlt}
          className="py-1 pl-2"
          onClick={() => setIsVisible(false)}
        />
      </Transition>
    </div>
  );
}
