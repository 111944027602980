import { NextRouter } from "next/router";
import { Subject, delay, exhaustMap, of, retry, tap, throwError } from "rxjs";

export default function goToMailSettings(router: NextRouter) {
  goToMailSettings$.next(router);
}

const goToMailSettings$ = new Subject<NextRouter>();

goToMailSettings$
  .asObservable()
  .pipe(
    tap((router) => {
      if (router.pathname !== "/profile/my-profile")
        router.push("/profile/my-profile");
    }),
    exhaustMap(() =>
      of(null).pipe(
        delay(350),
        exhaustMap(() => {
          const container =
            document.querySelector<HTMLDivElement>("#mail-settings");

          if (!container) {
            return throwError(
              () => new Error("Unable to find element for mail settings")
            );
          }
          container.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          const EditButton = document.querySelector(
            ".MyProfileMailSettingsContainer .MyProfileEditButton"
          ) as HTMLButtonElement | null;
          if (!EditButton) {
            console.log("no edit button");
            return throwError(
              () => new Error("Unable to find element for mail settings")
            );
          }
          EditButton.click();
          container.dataset.focusAttention = "1";

          setTimeout(() => {
            container.removeAttribute("data-focus-attention");
          }, 8000);
          return of(null);
        }),
        retry({ count: 20, delay: 250 })
      )
    )
  )
  .subscribe();
