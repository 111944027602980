import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  deletedPhoto,
  addedPhoto,
  State,
} from "../redux/slices/completeProfileProgressionSlice";

type UseCompleteProfileProgression = () => State & {
  deletedPhoto: () => void;
  addedPhoto: () => void;
};

const useCompleteProfileProgression: UseCompleteProfileProgression = () => {
  const nextStep = useSelector(
    (state: RootState) => state.completeProfileProgression.nextStep
  );

  const topBarMessage = useSelector(
    (state: RootState) => state.completeProfileProgression.topBarMessage
  );

  const actions = useActions({ deletedPhoto, addedPhoto });

  return { ...actions, nextStep, topBarMessage };
};

export default useCompleteProfileProgression;
