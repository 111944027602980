import { FC } from "react";
import { Switch } from "@headlessui/react";
import classNames from "../../utils/twClassNames";

type Props = {
  checked: boolean;
  handleChange: () => void;
  label?: string;
  children?: React.ReactNode;
  disableMargin?: boolean;
};

const StyledToggle: FC<Props> = ({
  checked,
  handleChange,
  label,
  children,
  disableMargin,
}) => {
  return (
    <Switch.Group as="div" className="StyledToggle flex items-start">
      <Switch
        checked={checked}
        onChange={handleChange}
        className={classNames(
          checked ? "bg-custom-light-blue" : "bg-custom-gray",
          "relative inline-flex flex-shrink-0 rounded-full cursor-pointer transition-colors ease-in-out duration-200",
          disableMargin
            ? "h-[22px] p-[3px] w-[42px]"
            : "mr-2 h-[26px] p-[3px] w-[47px]"
        )}
        data-testid={`toggle-button-for-${label}`}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
            disableMargin ? "w-4 h-4" : "w-5 h-5"
          )}
        />
      </Switch>
      {children
        ? children
        : label && (
            <Switch.Label as="span" className="truncate">
              <span className="text-base font-semibold text-gray-900">
                {label}
              </span>
            </Switch.Label>
          )}
    </Switch.Group>
  );
};

export default StyledToggle;
