import Api, { HTTPResponse } from "./Api";

export default class Captcha extends Api {
  public async getCaptcha(): Promise<
    HTTPResponse & {
      data: {
        captchaInfos: {
          captchaId: string;
          captcha: number;
        };
      };
    }
  > {
    return this.get("/captcha");
  }
}
