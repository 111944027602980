import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  close,
  start,
  setCurrentStep,
  setFirstImageSrc,
  setSecondImageSrc,
  State,
  certificationSlice,
  setIsPendingCertification,
  setChosenMethod,
} from "../redux/slices/certificationSlice";

type UseCertification = () => State &
  typeof certificationSlice.actions & { start: () => void };

const useCertification: UseCertification = () => {
  const isOpen = useSelector((state: RootState) => state.certification.isOpen);

  const currentStep = useSelector(
    (state: RootState) => state.certification.currentStep
  );

  const isCertified = useSelector(
    (state: RootState) => state.certification.isCertified
  );

  const isPendingCertification = useSelector(
    (state: RootState) => state.certification.isPendingCertification
  );

  const firstImageSrcNoWebcam = useSelector(
    (state: RootState) => state.certification.firstImageSrcNoWebcam
  );

  const secondImageSrcNoWebcam = useSelector(
    (state: RootState) => state.certification.secondImageSrcNoWebcam
  );

  const imageSrcWebcam = useSelector(
    (state: RootState) => state.certification.imageSrcWebcam
  );

  const webcamImageModele = useSelector(
    (state: RootState) => state.certification.webcamImageModele
  );

  const chosenMethod = useSelector(
    (state: RootState) => state.certification.chosenMethod
  );

  const submittedImagePhoto1 = useSelector(
    (state: RootState) => state.certification.submittedImagePhoto1
  );

  const submittedImagePhoto2 = useSelector(
    (state: RootState) => state.certification.submittedImagePhoto2
  );

  const submittedImageWebcam = useSelector(
    (state: RootState) => state.certification.submittedImageWebcam
  );

  const actions = useActions({
    close,
    start,
    setCurrentStep,
    setFirstImageSrc,
    setSecondImageSrc,
    setIsPendingCertification,
    setChosenMethod,
  });

  return {
    isOpen,
    currentStep,
    isCertified,
    isPendingCertification,
    firstImageSrcNoWebcam,
    secondImageSrcNoWebcam,
    imageSrcWebcam,
    webcamImageModele,
    chosenMethod,
    submittedImagePhoto1,
    submittedImagePhoto2,
    submittedImageWebcam,

    ...actions,
  };
};

export default useCertification;
