import { FC, Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import LoadingSpinner from "../loadingSpinner";

import dynamic from "next/dynamic";

import classNames from "../../utils/twClassNames";

// nicely done kublir (simon)

type Props = {
  onChange: (value: string) => void;
  disabled?: boolean;
};

const EmojiPicker: FC<Props> = ({ onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const DynamicPicker = dynamic(() => import("emoji-picker-react"), {
    loading: () => <LoadingSpinner />,
  });

  const onEmojiClick = (event: any, emojiObject: any) => {
    if (emojiObject.emoji) {
      handleChange(emojiObject.emoji);
    }
  };

  const handleChange = (value: string) => {
    onChange(value);
  };

  useEffect(() => {
    const handleClose = (event: any) => {
      if (event.target.closest(".EmojiPicker")) return;
      setIsOpen(false);
    };

    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <div className="EmojiPicker flex items-end justify-end">
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        className="border-none bg-transparent flex relative w-fit cursor-default focus:outline-none items-center justify-end tooltip"
      >
        <div className="absolute -left-1 -top-12">
          <p className="tooltiptext w-16">emoji</p>
        </div>
        <span className="block text-custom-black text-left sm:text-right hover:cursor-pointer">
          <img
            data-testid="messenger-emoji-picker"
            src="/app/assets/smileIcon.svg"
            alt="Emoji picker"
            className={classNames(disabled ? "opacity-50" : "")}
          />
        </span>
      </button>
      <div
        id="emoji-picker"
        className="absolute bottom-16 lg:bottom-20 -right-6"
      >
        {isOpen && <DynamicPicker onEmojiClick={onEmojiClick} />}
      </div>
    </div>
  );
};

export default EmojiPicker;
