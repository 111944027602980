import { FC } from "react";
import MessageActiveActionsMenu from "../messageActiveActionsMenu";
import MessageChatBox from "../messageChatBox";

import LoadingSpinner from "../loadingSpinner";
import useMessenger from "../../hooks/useMessenger";
import BackButton from "../backButton";
import MessageActiveUserInfoContainer from "../messageActiveUserInfoContainer";
import GreetingActiveConvo from "../greetingActiveConvo";
import MessageActiveConvo from "../messageActiveConvo/MessageActiveConvo";
import BodyAdContainerFlexible from "../bodyAdContainerFlexible";

type Props = {
  onBackClick: () => void;
  shouldDisplayAd?: boolean;
};

const MessageActiveConvoContainerMobile: FC<Props> = ({
  onBackClick,
  shouldDisplayAd,
}) => {
  const { currentConversation, greetings, currentGreeting, deleteGreeting } =
    useMessenger();

  function onDeleteGreetingClick(greetingId?: number) {
    if (!greetingId) return;

    deleteGreeting(greetingId);
    onBackClick();
  }

  if (
    !currentConversation?.isLoading &&
    !currentConversation?.conversationInfo
  ) {
    return <></>;
  }

  if (greetings.isActiveConversation) {
    return greetings.isLoading || !currentGreeting.infos ? (
      <div className="flex w-full justify-center mt-5">
        <LoadingSpinner />
      </div>
    ) : (
      <div
        className="flex flex-col MessageActiveConvoContainerMobile h-full max-w-full"
        data-testid="messenger-greeting-active-convo"
      >
        {shouldDisplayAd && (
          <div className="min-h-[200px] max-h-[200px] w-full p-2 z-10 overflow-y-scroll">
            <BodyAdContainerFlexible />
          </div>
        )}
        <div className="flex flex-row justify-between h-[65px] min-h-[65px] items-center w-full px-5">
          <div className="flex justify-start items-center">
            <BackButton onClick={onBackClick} />
          </div>
          <button
            onClick={() =>
              onDeleteGreetingClick(currentGreeting.infos?.greetingId)
            }
          >
            <img
              alt="delete greeting"
              src="/app/assets/messages/trashIcon.svg"
            />
          </button>
        </div>

        <div className="flex flex-col grow box-shadow overflow-hidden">
          <div className="pb-[10px] h-fit pt-[13px] px-5 items-center">
            <MessageActiveUserInfoContainer
              discussionInfo={currentGreeting.infos}
            />
          </div>
          <span className="h-[1px] w-full bg-custom-light-border" />
          <GreetingActiveConvo currentGreeting={currentGreeting} />

          <div className="pb-[18px] pt-[24px] px-[27px]">
            <MessageChatBox />
          </div>
        </div>
      </div>
    );
  }

  return currentConversation.isLoading ||
    !currentConversation.conversationInfo ? (
    <div className="flex w-full justify-center mt-5">
      <LoadingSpinner />
    </div>
  ) : (
    <div
      data-discussion-id={currentConversation.conversationInfo.discussionId}
      className="flex flex-col MessageActiveConvoContainerMobile h-full max-w-full"
    >
      {shouldDisplayAd && (
        <div className="min-h-[200px] max-h-[200px] w-full p-2 z-10 overflow-y-auto">
          <BodyAdContainerFlexible />
        </div>
      )}
      <div className="flex flex-row justify-between h-[35px] min-h-[35px] items-center w-full px-5">
        <div className="flex justify-start items-center">
          <BackButton onClick={onBackClick} />
        </div>
        {currentConversation.conversationInfo.userId != -1 && (
          <MessageActiveActionsMenu
            isMobile={true}
            isFavorited={currentConversation.conversationInfo.isFavorited}
            discussionId={currentConversation.conversationInfo.discussionId.toString()}
          />
        )}
      </div>

      <div className="flex flex-col grow box-shadow overflow-hidden">
        <div className="pb-[10px] h-fit pt-[13px] px-5 items-center">
          <MessageActiveUserInfoContainer
            discussionInfo={currentConversation.conversationInfo}
          />
        </div>
        <span className="h-[1px] w-full bg-custom-light-border" />
        <div className="h-full overflow-hidden">
          <MessageActiveConvo messages={currentConversation.items} />
        </div>
        {currentConversation.conversationInfo.userId != -1 && (
          <div className="pb-[18px] h-fit pt-[12px] px-5">
            <MessageChatBox />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageActiveConvoContainerMobile;
