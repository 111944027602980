import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  UserInfo,
  LoginNotification,
  CertificationProcess,
} from "../../backendAPI/Authentication";

import { logout, createAccount, getLoginInfo, login } from "./authSlice";
import { Languages } from "../../context/TranslationContext";
import { HomeCta } from "../../components/welcomeBackSection/WelcomeBackSection";

export interface State {
  topBannerVisibility: boolean;
  topBannerText: string;
  userData: UserInfo;
  notifications: LoginNotification;
  certificationProcess: CertificationProcess;
  homeCta: HomeCta;
  feedback: {
    showFeedback: boolean;
    disableFeedback: boolean;
  };
}

const initialState: State = {
  topBannerVisibility: true,
  topBannerText: "",
  userData: {} as UserInfo,
  notifications: {} as LoginNotification,
  certificationProcess: {} as CertificationProcess,
  homeCta: { type: "dateMe" },
  feedback: {
    showFeedback: false,
    disableFeedback: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTopBannerVisibility: (state, action: PayloadAction<boolean>) => {
      state.topBannerVisibility = action.payload;
    },

    clearWarnings: (state) => {
      state.userData.warnings = [];
    },

    removeNotification: (
      state,
      action: PayloadAction<keyof LoginNotification>
    ) => {
      state.notifications[action.payload] = false;
    },

    setFirstName: (state, action: PayloadAction<string>) => {
      state.userData.firstname = action.payload;
    },

    setShowAddImagesPopupRules: (state, action: PayloadAction<boolean>) => {
      state.userData.showPopupRuleWhenAddPhoto = action.payload;
    },

    setDotNotification: (
      state,
      action: PayloadAction<keyof LoginNotification>
    ) => {
      state.notifications[action.payload] = true;
    },
    setPrefferedDisplayLanguage: (state, action: PayloadAction<Languages>) => {
      state.userData.preferredDisplayLanguage = action.payload;
    },
    clearDotNotification: (
      state,
      action: PayloadAction<keyof LoginNotification>
    ) => {
      state.notifications[action.payload] = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createAccount.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") {
        return;
      }

      state.userData = action.payload.data.infosProfile;
      state.topBannerText = action.payload.data.orangeBarMessage;
      state.notifications = action.payload.data.notifications;
      state.certificationProcess = action.payload.data.certificationProcess;
    });

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") {
        return;
      }
      state.userData = action.payload.data.infosProfile;
      state.homeCta = action.payload.data?.blueContainerContent ?? {
        type: "dateMe",
      };
      state.topBannerText = action.payload.data.orangeBarMessage;
      state.notifications = action.payload.data.notifications;
      state.certificationProcess = action.payload.data.certificationProcess;
      if (shouldAskForFeedback(action.payload)) {
        state.feedback.showFeedback = true;
      }
    });

    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") {
        return;
      }
      state.userData.preferredDisplayLanguage =
        action.payload.data.preferredDisplayLanguage;
    });

    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

export const {
  setTopBannerVisibility,
  removeNotification,
  setFirstName,
  setDotNotification,
  clearDotNotification,
  setShowAddImagesPopupRules,
  clearWarnings,
  setPrefferedDisplayLanguage,
} = userSlice.actions;

export default userSlice.reducer;

function shouldAskForFeedback(payload: {
  data: { infosProfile: { warnings: string[] } };
}) {
  return payload.data.infosProfile.warnings.includes("SHOW_FEEDBACK");
}
