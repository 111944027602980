import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../backendAPI";

import { RootState } from "../store";

export interface State {
  nextStep: "LOADING" | "ADD_PHOTO" | "CERTIFY_PROFILE" | null;
  topBarMessage: string;
}

import { logout, getLoginInfo } from "./authSlice";
import { setIsPendingCertification } from "./certificationSlice";

const initialState: State = {
  nextStep: "LOADING",
  topBarMessage: "",
};

export const deletedPhoto = createAsyncThunk(
  "completeProfileProgression/deletedPhoto",
  async (_, { getState }): Promise<{ deletedLastPicture: boolean }> => {
    const userName = (getState() as RootState)?.user?.userData?.NomUsager;

    if (!userName) throw new Error("no username found");

    const myProfileResponse = await api.editProfile.getMyProfile(userName);

    return {
      deletedLastPicture: myProfileResponse.data.photos.length === 0,
    };
  }
);

export const completeProfileProgressionSlice = createSlice({
  name: "completeProfileProgression",
  initialState,
  reducers: {
    setTopBarMessage: (state, action: PayloadAction<string>) => {
      state.topBarMessage = action.payload;
    },

    addedPhoto: (state) => {
      state.nextStep = "CERTIFY_PROFILE";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deletedPhoto.fulfilled, (state, action) => {
      if (action.payload.deletedLastPicture === false) return;

      state.nextStep = "ADD_PHOTO";
    });

    builder.addCase(setIsPendingCertification, (state, action) => {
      if (action.payload === false) return;

      state.nextStep = null;
    });

    builder.addCase(logout.fulfilled, () => initialState);

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") {
        return;
      }

      const message = action.payload.data.orangeBarMessage;

      state.topBarMessage = message;

      if (message.includes("data-server-action='my-profile'")) {
        state.nextStep = "ADD_PHOTO";
        return;
      }

      if (message.includes("data-server-action='certify-profile'")) {
        state.nextStep = "CERTIFY_PROFILE";
        return;
      }

      state.nextStep = null;
    });
  },
});

export const { addedPhoto } = completeProfileProgressionSlice.actions;

export default completeProfileProgressionSlice.reducer;
