import { Dialog } from "@headlessui/react";
import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const PopUpSubheader: FC<Props> = ({ children, className = "" }) => (
  <div className={classNames("PopUpSuheader", className)}>
    <Dialog.Description className="text-lg text-black">
      {children}
    </Dialog.Description>
  </div>
);

export default PopUpSubheader;
