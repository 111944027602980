import React, { FC } from "react";

type Props = { enabled: boolean; onClick: () => void };

const StyledCarouselPrevButton: FC<Props> = ({ enabled, onClick }) => (
  <button
    className="absolute flex disabled:cursor-default disabled:opacity-30 bg-white/50 w-[42px] h-[108px] border-0 outline-0 cursor-pointer touch-manipulation rounded-r-[20px] z-[1] p-0 items-center justify-center top-[50%] translate-y-[-50%] left-0"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg
      width="12"
      height="23"
      viewBox="0 0 12 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46774 8.01767L9.14636 0.477684C9.4598 0.17173 9.8838 0 10.3258 0C10.7677 0 11.1917 0.17173 11.5052 0.477684C11.662 0.630394 11.7864 0.812078 11.8713 1.01226C11.9563 1.21243 12 1.42714 12 1.644C12 1.86086 11.9563 2.07557 11.8713 2.27574C11.7864 2.47592 11.662 2.65761 11.5052 2.81032L3.8098 10.3339C3.65301 10.4866 3.52855 10.6683 3.44362 10.8684C3.35869 11.0686 3.31496 11.2833 3.31496 11.5002C3.31496 11.717 3.35869 11.9318 3.44362 12.1319C3.52855 12.3321 3.65301 12.5138 3.8098 12.6665L11.5052 20.1901C11.8202 20.4972 11.998 20.9147 11.9996 21.3506C12.0012 21.7865 11.8263 22.2052 11.5135 22.5145C11.2007 22.8238 10.7756 22.9984 10.3317 23C9.88775 23.0015 9.46138 22.8298 9.14636 22.5227L1.46774 14.9827C0.527901 14.0587 2.05013e-07 12.8061 2.05013e-07 11.5002C2.05013e-07 10.1942 0.527901 8.94169 1.46774 8.01767V8.01767Z"
        fill="#2030F2"
      />
    </svg>
  </button>
);

export default StyledCarouselPrevButton;
