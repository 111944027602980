import React, { FC, useEffect } from "react";
import { useRouter } from "next/router";
import PageContainer from "../pageContainer";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import { completeBootstrap } from "../../redux/slices/bootstrapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  LoadingIndicatorLocks,
  hideLoadingIndicator,
  showLoadingIndicator,
} from "../loadingIndicator/LoadingIndicator";
import useTranslation from "../../context/TranslationContext";

type Props = { children: React.ReactNode; offlineComponent?: React.ReactNode };

const AuthGuard: FC<Props> = ({ children, offlineComponent }) => {
  const { i } = useTranslation();
  const {
    isAuth,
    needsToCertify,
    needsToAnswerQuestions,
    profileNotCreated,
    needsToConfirmEmail,
  } = useAuth();
  const bootstrapComplete = useSelector(
    (state: RootState) => state._bootstrap.complete
  );

  const shouldUnload = useSelector(
    (state: RootState) => state._bootstrap.shouldUnload
  );
  const router = useRouter();

  useEffect(() => {
    if (!bootstrapComplete) return;
    if (shouldUnload) {
      router.push("/logout");
      return;
    }
    if (!isAuth) {
      // Save the intended URL to localStorage
      if (router.asPath !== "/logout") {
        sessionStorage.setItem("redirectAfterLogin", router.asPath);
      }
      router.push("/logout");
      // window.location.href = i("https://www.vidamora.com/sign-in");
      return;
    } else if (needsToCertify) {
      showLoadingIndicator(LoadingIndicatorLocks.Redirection);
      router
        .push("/profile/certify")
        .then((_) => hideLoadingIndicator(LoadingIndicatorLocks.Redirection));
      return;
    } else if (profileNotCreated) {
      showLoadingIndicator(LoadingIndicatorLocks.Redirection);
      router
        .push("/registration")
        .then((_) => hideLoadingIndicator(LoadingIndicatorLocks.Redirection));
      return;
    } else if (needsToAnswerQuestions) {
      showLoadingIndicator(LoadingIndicatorLocks.Redirection);
      router
        .push("/update-registration")
        .then((_) => hideLoadingIndicator(LoadingIndicatorLocks.Redirection));
    } else if (needsToConfirmEmail) {
      showLoadingIndicator(LoadingIndicatorLocks.Redirection);
      router.push("/confirm-email").then((_) => {
        hideLoadingIndicator(LoadingIndicatorLocks.Redirection);
      });
    } else {
      // Check if there's a redirect URL and navigate to it
      const redirectUrl = sessionStorage.getItem("redirectAfterLogin");
      if (redirectUrl) {
        showLoadingIndicator(LoadingIndicatorLocks.Redirection);
        sessionStorage.removeItem("redirectAfterLogin"); // Clear the URL once used
        router.push(redirectUrl).then((_) => {
          hideLoadingIndicator(LoadingIndicatorLocks.Redirection);
        });
      }
    }
  }, [bootstrapComplete, isAuth]);

  return isAuth ? (
    <div className="AuthGuard">{children}</div>
  ) : (
    <>
      {offlineComponent ? (
        <PageContainer pagesWithNotification={[]} currentPage="Offline">
          {offlineComponent}
          {/* <div className="flex flex-col items-center relative">
            <div className="flex h-full w-full lg:my-0 my-auto bg-custom-salmon-pink lg:px-[90px] xl:px-[150px] lg:pt-[80px] lg:pb-[70px] rounded-lg">
              <LoginPageForm size="desktop" />
            </div>
          </div> */}
        </PageContainer>
      ) : null}
    </>
  );
};

export default AuthGuard;
