import { FC, useContext } from "react";
import { DetailedMemberInfo } from "../../backendProxies/members/DetailedMember";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileStatsItem from "../profileStatsItem";
import ProfileStatsRow from "../profileStatsRow";
import StyledContainer from "../styledContainer";
import StyledContainerHeader from "../styledContainerHeader";

type Props = {
  info: DetailedMemberInfo;
};

const ProfileStatsContainer: FC<Props> = ({ info }) => {
  const { i } = useContext(TranslationContext);
  return (
    <StyledContainer className="ProfileStatsContainer py-[30px]">
      <StyledContainerHeader className="sm:px-[29px] px-[15px]">
        {i("A little more")}
      </StyledContainerHeader>
      <div className="divide-y flex flex-col space-y-[21px] sm:px-[21px] px-[15px] pb-[21px] pt-[7px]">
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Current children")}
              description={info.enfantActuel}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Desired children")}
              description={info.enfantDesire}
            />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Smoker")} description={info.fumeur} />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Alcohol")} description={info.alcool} />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Drugs")} description={info.drogue} />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Occupation")}
              description={info.occupation}
            />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <ProfileStatsItem
            label={i("Ideal first date")}
            description={info.firstDate}
          />
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Ethnicity")}
              description={info.ethnie}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Religion")}
              description={info.religion}
            />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Zodiac sign")}
              description={info.signeZodiaque}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem label={i("Car")} description={info.voiture} />
          </div>
        </ProfileStatsRow>
        <ProfileStatsRow>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Willing to move for love")}
              description={info.readyToRelocate}
            />
          </div>
          <div className="flex md:w-1/2">
            <ProfileStatsItem
              label={i("Profession")}
              description={info.profession}
            />
          </div>
        </ProfileStatsRow>
      </div>
    </StyledContainer>
  );
};

export const EMPTY_STAT_DESCRIPTION = "Désire ne pas spécifier";

export const descriptionOrDefault = (description?: string): string => {
  const { i } = useContext(TranslationContext);
  return description || i("Prefer not to say");
};

export default ProfileStatsContainer;
