import { ComponentProps } from "react";
import ErrorLabel from "./ErrorLabel";

export const docProps: ComponentProps<typeof ErrorLabel> = {
  testId: "test-id",
  errorMessage: "Invalid Credentials!",
  show: true,
};

export default ErrorLabel;
