import { FC, useRef, useEffect } from "react";
import useUser from "../../hooks/useUser";

type Props = {
  googleid?: string;
  type?: "ad-vidamora" | "ad-clickio";
  clickioId?: string;
};

/**
 * Component for body ads. Make sure to wrap this component with an AdWrapper component to hide for premium users.
 * @param googleid Id for google ad slot
 * @param type type
 * @param clickioId Id for click io
 * @returns
 */
const BodyAdContainerFlexible: FC<Props> = ({
  googleid,
  type = "ad-vidamora",
  clickioId = "ad-click-io",
}) => {
  const hasPushedAdRef = useRef(false);

  const {
    userData: { isPremiumNoAds },
  } = useUser();

  useEffect(() => {
    if (isPremiumNoAds) return;
    if (type == "ad-clickio") return;
    if (hasPushedAdRef.current) return;
    hasPushedAdRef.current = true;

    const timeoutid = setTimeout(() => {
      try {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      } catch (_e) {}
    }, 350);

    return () => clearTimeout(timeoutid);
  }, [isPremiumNoAds, type]);

  if (isPremiumNoAds) return null;

  return (
    <div
      data-testid="flexible-google-ads-container"
      className="BodyAdContainerFlexible rounded-md bg-transparent w-full h-full max-w-full"
    >
      {type == "ad-vidamora" ? (
        <ins
          {...(googleid === "7677836850"
            ? {
                "data-ad-carbonia-type": "messenger",
                className: "adsbygoogle profilecentrebigger-respons",
              }
            : {
                className: "adsbygoogle",
              })}
          style={{ display: "block", width: "100%", height: "100%" }}
          data-ad-client="ca-pub-6005108637849729"
          data-ad-slot={googleid || "6183297913"}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      ) : (
        <ins
          id={clickioId}
          className="ad-clickio text-center clear-both w-full h-full"
        ></ins>
      )}
    </div>
  );
};

export default BodyAdContainerFlexible;
