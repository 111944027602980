import BackendProxy from "../utils/BackendProxy";
import Notification from "./Notification";

export type Endpoint = "notifications";

export type QueryParams = {
  pageSize: number;
  show: "all" | "who_wants_to_meet_me" | "perfect_mach"
};

class NotificationList extends BackendProxy<Notification, Endpoint, QueryParams> {
  constructor(endpoint: Endpoint, config: QueryParams) {
    super(endpoint, config, Notification);
  }
}
export default NotificationList;
