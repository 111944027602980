import React, { FC } from "react";

type Props = {
  direction: "left" | "right";
};

const DateMeNavArrow: FC<Props> = ({ direction }) => {
  return (
    <div className="DateMeNavArrow bg-custom-semi-black rounded-xl flex justify-center items-center h-11 w-12">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {direction === "left" && (
          <path
            data-testid="date-me-nav-left-arrow"
            d="M21.6667 11.6663L5 11.6663L10.4833 17.1497C10.6395 17.3046 10.7635 17.4889 10.8482 17.692C10.9328 17.8951 10.9763 18.113 10.9763 18.333C10.9763 18.553 10.9328 18.7709 10.8482 18.974C10.7635 19.177 10.6395 19.3614 10.4833 19.5163C10.1711 19.8267 9.74864 20.001 9.30833 20.001C8.86802 20.001 8.44561 19.8267 8.13333 19.5163L0.983334 12.3497C0.357354 11.7274 0.00372132 10.8823 0 9.99966C0.00811098 9.12282 0.361407 8.28449 0.983334 7.66633L8.13333 0.499662C8.28873 0.345358 8.473 0.223175 8.67562 0.140085C8.87824 0.0569954 9.09523 0.0146275 9.31423 0.0154018C9.53322 0.0161762 9.74991 0.0600777 9.95194 0.144596C10.154 0.229116 10.3374 0.3526 10.4917 0.507998C10.646 0.663395 10.7682 0.847664 10.8512 1.05028C10.9343 1.2529 10.9767 1.4699 10.9759 1.68889C10.9752 1.90788 10.9313 2.12457 10.8467 2.3266C10.7622 2.52863 10.6387 2.71202 10.4833 2.86633L5 8.33299L21.6667 8.33299C22.1087 8.33299 22.5326 8.50859 22.8452 8.82115C23.1577 9.13371 23.3333 9.55763 23.3333 9.99966C23.3333 10.4417 23.1577 10.8656 22.8452 11.1782C22.5326 11.4907 22.1087 11.6663 21.6667 11.6663Z"
            fill="white"
          />
        )}

        {direction === "right" && (
          <path
            data-testid="date-me-nav-right-arrow"
            d="M2.33333 8.33367H19L13.5167 2.85034C13.3605 2.69541 13.2365 2.51107 13.1518 2.30797C13.0672 2.10487 13.0237 1.88703 13.0237 1.66701C13.0237 1.44699 13.0672 1.22915 13.1518 1.02605C13.2365 0.822951 13.3605 0.638616 13.5167 0.483677C13.8289 0.173259 14.2514 -0.000976562 14.6917 -0.000976562C15.132 -0.000976562 15.5544 0.173259 15.8667 0.483677L23.0167 7.65034C23.6426 8.27259 23.9963 9.11771 24 10.0003C23.9919 10.8772 23.6386 11.7155 23.0167 12.3337L15.8667 19.5003C15.7113 19.6546 15.527 19.7768 15.3244 19.8599C15.1218 19.943 14.9048 19.9854 14.6858 19.9846C14.4668 19.9838 14.2501 19.9399 14.0481 19.8554C13.846 19.7709 13.6626 19.6474 13.5083 19.492C13.354 19.3366 13.2318 19.1523 13.1488 18.9497C13.0657 18.7471 13.0233 18.5301 13.0241 18.3111C13.0248 18.0921 13.0687 17.8754 13.1533 17.6734C13.2378 17.4714 13.3613 17.288 13.5167 17.1337L19 11.667H2.33333C1.8913 11.667 1.46738 11.4914 1.15482 11.1789C0.842257 10.8663 0.666664 10.4424 0.666664 10.0003C0.666664 9.55831 0.842257 9.13439 1.15482 8.82183C1.46738 8.50927 1.8913 8.33367 2.33333 8.33367Z"
            fill="white"
          />
        )}
      </svg>
    </div>
  );
};

export default DateMeNavArrow;
