import Api, { HTTPResponseWithDataMessage, HTTPResponse, ROOT } from "./Api";

export default class Notification extends Api {
  public async getAll(queryParams: {
    pageIndex: number;
    pageSize: number;
    show: "all" | "who_wants_to_meet_me" | "perfect_match";
  }): Promise<HTTPResponseWithDataMessage> {
    return this.get("/notifications", queryParams);
  }

  public async deleteOne(body: {
    id: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/notifications", body);
  }

  public async deleteAll(): Promise<HTTPResponseWithDataMessage> {
    return this.delete("/notifications", { id: "all" });
  }
}
