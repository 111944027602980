import React from "react";

const INDICATOR_ID = "app-loading-indicator";
const MIN_TIME = 700;

export enum LoadingIndicatorLocks {
  Translations = "translations",
  Redirection = "redirection",
  Login = "login",
}

/**
 * Global Loading indicator used for application and critical data loads, such as user data for any authenticated routes.
 * Use the associated functions to hide/show the indicator
 * @component
 */
export default function LoadingIndicator() {
  return (
    <div
      id={INDICATOR_ID}
      className="fixed w-screen h-screen bg-white grid place-items-center z-100 top-0"
      data-translations="1"
      data-login="1"
    >
      <svg
        height="50px"
        viewBox="0 0 270 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "scale(0)",
          animation:
            "1.25s linear 0.1s infinite normal forwards running heartbeat",
        }}
      >
        <path
          d="M108.646 33.6629C90.6868 14.2144 72.1443 38.6409 85.4475 51.6628C121.643 86.6787 142.008 105.916 178.353 140.106L244.325 76.0099C258.258 60.5184 277.783 77.66 265.518 92.2747L188.443 167.127C181.702 172.887 176.366 173.27 168.91 167.33L67.558 70.1219C27.8602 28.2103 91.7604 -33.3289 134.874 22.9126V59.4898L108.646 33.6629Z"
          fill="url(#paint0_linear_3_47)"
        />
        <path
          d="M160.75 33.6629C178.709 14.2144 197.252 38.6409 183.948 51.6628C147.753 86.6787 127.388 105.916 91.0428 140.106L25.0703 76.0099C11.137 60.5184 -8.38784 77.66 3.87744 92.2747L80.9523 167.127C87.6935 172.887 93.0293 173.27 100.486 167.33L201.838 70.1219C241.536 28.2103 177.636 -33.3289 134.522 22.9126V59.4898L160.75 33.6629Z"
          fill="url(#paint1_linear_3_47)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3_47"
            x1="60.176"
            y1="17.7394"
            x2="210.62"
            y2="171.589"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D3444E" />
            <stop offset="0.428587" stopColor="#D66049" />
            <stop offset="0.538091" stopColor="#802E21" />
            <stop offset="0.626774" stopColor="#8B2E2C" />
            <stop offset="0.685189" stopColor="#D5554D" />
            <stop offset="0.727791" stopColor="#D6584C" />
            <stop offset="0.810774" stopColor="#DA6E45" />
            <stop offset="0.899227" stopColor="#C7735B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3_47"
            x1="64.7994"
            y1="14.7334"
            x2="271.584"
            y2="107.222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D3444E" />
            <stop offset="0.423517" stopColor="#D66049" />
            <stop offset="0.666902" stopColor="#DA6E45" />
            <stop offset="1" stopColor="#D07A61" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export function showLoadingIndicator(...lock: LoadingIndicatorLocks[]) {
  const element = document.getElementById(INDICATOR_ID) as HTMLDivElement;
  lock.forEach((e) => (element.dataset[e] = "1"));
  element.dataset["timestamp"] ||= String(new Date().getTime() + MIN_TIME);
  if (!!Object.keys(element.dataset).filter((x) => x !== "timestamp").length) {
    element.style.display = "";
  }
}

export function hideLoadingIndicator(...lock: LoadingIndicatorLocks[]) {
  const element = document.getElementById(INDICATOR_ID) as HTMLDivElement;
  lock.forEach((e) => delete element.dataset[e]);
  if (!Object.keys(element.dataset).filter((x) => x !== "timestamp").length) {
    setTimeout(() => {
      element.style.display = "none";
      delete element.dataset["timestamp"];
    }, Math.max(Number(element.dataset["timestamp"]) - new Date().getTime(), 0));
  }
}
