import { FC, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import CardMemberProfileBadge from "../cardMemberProfileBadge";
import CardMemberProfileLocation from "../cardMemberProfileLocation";
import CardMemberProfileName from "../cardMemberProfileName";

import CardMemberProfileActionRemove from "../cardMemberProfileActionRemove";
import { Member } from "../../backendProxies";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

// todo (simon) cleanup needed

type Props = {
  member: Member;
  onRemove?: () => void;
};

const SmallCardMemberProfile: FC<Props> = ({ member, onRemove }) => {
  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }

    setIsDeleted(true);
  };
  const [isDeleted, setIsDeleted] = useState(false);

  const selfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(() => {
        selfRef!.current!.remove();
      }, 250);
    }
  }, [isDeleted]);

  return (
    <div
      ref={selfRef}
      style={{
        backgroundImage:
          member && !isDeleted
            ? `url(${member?.info?.mainImage})`
            : "url(/app/assets/greyBackGround.PNG)",
      }}
      className={`SmallCardMemberProfile col-1 bg-center m-auto max-w-[170px] min-h-[224px] rounded-2xl overflow-hidden bg-cover relative w-full ${
        member?.info?.mainImage && !isDeleted ? "" : "wave-load"
      }`}
    >
      {!isDeleted && onRemove && (
        <div className="absolute sm:top-3.5 top-2.5 sm:right-3.5 right-2.5 sm:left-3.5 left-2.5 z-10 pointer-events-none">
          <div className="w-full pointer-events-none justify-end flex flex-row">
            {<CardMemberProfileActionRemove onClick={handleRemove} />}
          </div>
        </div>
      )}
      <OpenProfileLink username={member.info.userName}>
        <div className="flex flex-col justify-end cursor-pointer items-end sm:py-3.5 py-2.5 sm:px-3.5 px-2.5 h-[224px] w-full bg-gradient-to-t via-transparent from-gray-500 to-transparent">
          {member && member.info && (
            <div className="flex flex-row justify-between items-center w-full relative">
              <div className="pb-1 ">
                <CardMemberProfileName
                  name={member.info?.userName}
                  age={member.info?.age}
                />
                <div className="flex flex-row justify-between items-center">
                  <CardMemberProfileLocation
                    member={member}
                    location={member.info.city}
                    showBadge={false}
                  />
                </div>
              </div>
              {member.info?.isOnline && (
                <div
                  data-testid="online-circle"
                  className="bg-custom-status-green w-[9px] h-[9px] mr-1.5 rounded-full mt-1 sm:mt-0 mb-1 relative -top-3"
                />
              )}
            </div>
          )}
        </div>
      </OpenProfileLink>
    </div>
  );
};

export default SmallCardMemberProfile;
