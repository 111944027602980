import React, { FC } from "react";
import SayHelloCustom from "../sayHelloCustom";
import SayHelloScrollbar from "../sayHelloScrollbar";
import SayHelloIcon from "../sayHelloIcon";
import SayHelloCaption from "../sayHelloCaption";
import useTranslation from "../../context/TranslationContext";
import useToast from "../../hooks/useToast";
import useSayHi from "../../context/SayHiContext";
import classNames from "../../utils/twClassNames";
import api from "../../backendAPI";

const bgColor = [
  "bg-custom-light-orange",
  "bg-custom-light-purple",
  "bg-custom-light-green",
  "bg-custom-light-pink",
  "bg-custom-light-teal",
  "bg-custom-light-violet",
];

type Props = {
  recipientImg: string;
  recipientUsername: string;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
  onSettled?: () => void;
};

const SayHelloLong: FC<Props> = ({
  recipientImg,
  recipientUsername,
  setCurrentPageIndex,
  onSettled,
}) => {
  const { i } = useTranslation();

  const { openToast, openTimedToast } = useToast();

  const { closePopup, recipientUserID, longResponses } = useSayHi();

  const handleSumbitLongMessage = async (
    greetingId: number,
    message: string
  ) => {
    const payload = {
      userId: recipientUserID,
      greetingId: greetingId,
      Personnaliser_imageId: 0,
      personnaliser_message: message,
    };

    const response = await api.greetings.postGreeting(payload);

    if (response.status === "SUCCESS") {
      closePopup();
      if (onSettled) onSettled();
      openTimedToast({
        type: "success",
        title: i("Success"),
        description: response.data.message,
      });
    }

    if (response.status === "ERROR") {
      if (onSettled) onSettled();
      closePopup();
      openToast({
        type: "error",
        title: i("Error"),
        description: response.message,
      });
    }
  };

  return (
    <SayHelloCustom
      recipientImg={recipientImg}
      recipientUsername={recipientUsername}
      setCurrentPageIndex={setCurrentPageIndex}
    >
      <SayHelloScrollbar className="min-h-[330px] sm:min-h-[400px] mt-5 sm:mt-7">
        <div className="SayHelloLongPrompts pr-4 sm:pr-6">
          {longResponses.map((item, index) => (
            <button
              key={item.greetingId}
              className="w-full text-left"
              onClick={() => {
                handleSumbitLongMessage(item.greetingId, item.message);
              }}
            >
              <div
                className={classNames(
                  "flex items-center justify-center mb-2 rounded-[4px] px-4 sm:pr-[35px]",
                  bgColor[index % bgColor.length]
                )}
              >
                <SayHelloIcon />
                <div className="SayHelloLongSingle w-full text-[18px] h-full mt-[16px] mb-[18px] max-w-[490px]">
                  <SayHelloCaption text={item.message} />
                </div>
              </div>
            </button>
          ))}
        </div>
      </SayHelloScrollbar>
    </SayHelloCustom>
  );
};

export default SayHelloLong;
