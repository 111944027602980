import { FC, useEffect, useState } from "react";
import Link, { LinkProps } from "next/link";

import NavBarIcons from "../navBarIcons/NavBarIcons";
import NavBarLinks from "../navBarLinks/NavBarLinks";
import NavBarLogo from "../navBarLogo/NavBarLogo";
import useUser from "../../hooks/useUser";
import useTranslation from "../../context/TranslationContext";
import Drawer from "@mui/material/Drawer";
import { LoginNotification } from "../../backendAPI/Authentication";
import isVidamora from "../../utils/isVidamora";
import CustomScrollBar from "../CustomScrollbar/CustomScrollBar";
import useAuth from "../../hooks/useAuth";

type Props = {
  hasNotifications: boolean;
  customOptions?: Options[];
  guestUser?: boolean;
};

type Options = {
  linkHref: string;
  linkName: string;
  imageSrc: string;
  alt: string;
  onClick?: () => void;
  notificationIndicator?: keyof LoginNotification;
};

const NavBar: FC<Props> = ({ hasNotifications, customOptions, guestUser }) => {
  const { i } = useTranslation();
  const { userData } = useUser();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  useEffect(() => {
    const closeNav = (event: Event) => {
      const target = event.target as HTMLElement;

      if (
        target.closest('[href="/app/forum/nouveauxmessages"]') ||
        target.closest('[href="/app/forum/mesParticipations"]') ||
        target.closest('[href="/app/forum/discussionFavorites"]')
      ) {
        setIsDrawerOpen(false);
      }
    };

    window.addEventListener("click", closeNav);

    return () => {
      window.removeEventListener("click", closeNav);
    };
  }, []);

  return (
    <nav className="Navbar bg-white shadow">
      <>
        <div className="mx-auto px-[10px] xs:px-[17px] lg:px-[40px] max-w-[1400px]">
          <div className="flex justify-between h-16 w-full items-center">
            <div className="lg:w-1/4 w-1/2 h-full">
              <Link
                href={guestUser ? i("https://www.vidamora.com/sign-in") : "/"}
              >
                <a>
                  <NavBarLogo />
                </a>
              </Link>
            </div>
            <div className="lg:w-2/4 w-0 h-full">
              <NavBarLinks />
            </div>
            <div className="lg:w-1/4 w-1/2 h-full">
              <NavBarIcons
                hasNotifications={hasNotifications}
                open={isDrawerOpen}
                showHamburgerMenu={userData.userid ? true : false}
                toggleDrawer={setIsDrawerOpen}
              />
            </div>
          </div>
        </div>

        <Drawer
          open={isDrawerOpen}
          anchor="right"
          onClose={toggleDrawer(false)}
        >
          <HamburgerMenuContent
            customOptions={customOptions}
            hasNotifications={hasNotifications}
            close={() => setIsDrawerOpen(false)}
          />
        </Drawer>
      </>
    </nav>
  );
};
export default NavBar;

function HamburgerMenuContent({
  customOptions,
  hasNotifications,
  close,
}: {
  customOptions?: Options[];
  hasNotifications: boolean;
  close(): void;
}) {
  const { i } = useTranslation();
  const { notifications, userData } = useUser();
  const { role } = useAuth();

  const defaultOptions = [
    {
      linkHref: "/messages",
      linkName: "Messages",
      imageSrc: "/app/assets/messageIcon.svg",
      alt: "Your Inbox",
      notificationIndicator: "haveNewMessage",
    },
    {
      linkHref: "/visited-your-profile",
      linkName: "Visited your profile",
      imageSrc: "/app/assets/smileFaceIcon.svg",
      alt: "Members that visited you profile",
      notificationIndicator: "haveNewVisite",
    },
    {
      linkHref: "/who-wants-to-meet-me",
      linkName: "Wants to meet you",
      imageSrc: "/app/assets/userHeartIcon.svg",
      alt: "Members that are looking to meet you",
      notificationIndicator: "haveNewWhoWantsToMeetMe",
    },
    {
      linkHref: "/perfect-match",
      linkName: "Perfect match",
      imageSrc: "/app/assets/heartIcon.svg",
      alt: "Your perfect matches",
      notificationIndicator: "haveNewPerfectMatch",
    },
    {
      linkHref: "/favorites",
      linkName: "Favorites",
      imageSrc: "/app/assets/starIcon.svg",
      alt: "Your favorites",
      notificationIndicator: false,
    },
  ];

  if (role !== "") {
    defaultOptions.push({
      linkHref: "/search-by-username",
      linkName: "Search by username",
      imageSrc: "/app/assets/searchIcon_blue.svg",
      alt: "Search by username",
      notificationIndicator: false,
    });
  }

  return (
    <div
      id="top-nav-hamburger-menu-container"
      className="w-full min-w-[330px] bg-white h-full  flex flex-col justify-start  relative "
    >
      <CustomScrollBar>
        <div className="pl-5 pt-4 pb-[77px] pr-14">
          <button
            onClick={close}
            data-testid="navbar-close-mobile-menu"
            className="w-10 h-10 text-custom-black font-bold text-2xl pt-2 absolute right-0 z-50"
          >
            <img src="/app/assets/deleteIcon.svg" alt="Delete icon" />
          </button>
          {/** Header */}
          <div className="flex h-16 items-center gap-2">
            <img
              className="relative rounded-xl h-full max-w-[35%]"
              src={
                userData?.mainImage ??
                "https://cdn.celibatairesduweb.com/img/Vignettes/AucuneImageHomme.gif"
              }
              alt="Profile Picture"
            />
            <div className="flex h-full justify-center flex-col">
              <div className="grid gap-0">
                <div className="relative">
                  <p className="whitespace-nowrap text-base font-bold text-custom-blue max-w-[160px] truncate">
                    {userData && userData.NomUsager ? userData.NomUsager : ""}
                  </p>
                  <div className="absolute flex gap-1 -top-2/3 -right-2">
                    {userData.isVIP && (
                      <img
                        src="/app/assets/vipcard.png"
                        className="w-[22px]"
                        alt="VIP Icon"
                      />
                    )}
                    {userData.isPremiumNoAds && (
                      <img
                        src="/app/assets/noads.svg"
                        className="w-[22px]"
                        alt="Premium No ads Icon"
                      />
                    )}
                  </div>
                </div>

                <Link href={"/profile/my-profile"}>
                  <a className="text-custom-blue text-xs">
                    {i("Modify my profile")}
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="pt-2 pb-3 space-y-1 mb-3">
            <NavigationLink href="/online-now" label={i("Online now")} />
            <NavigationLink href="/search" label={i("Search")} />
            <NavigationLink href="/date-me" label={i("Date me")} />
            <NavigationLink href="/dating-advice" label={i("Dating advice")} />
            {!isVidamora() && (
              <NavigationLink href="/forum" label={i("Forum")} />
            )}
          </div>
          <div className="pt-4 pb-3 border-t border-opacity-20 border-custom-light-blue space-y-1">
            {customOptions
              ? customOptions.map((option: Options, index: number) => {
                  return (
                    <div
                      onClick={() => {
                        if (option.onClick) {
                          close();
                          option.onClick();
                        }
                      }}
                      key={index}
                    >
                      <NavigationLink
                        href={option.linkHref}
                        label={option.linkName}
                        hasNotification={
                          true && option.linkName === i("Latest posts")
                        }
                        iconSrc={option.imageSrc}
                        iconAlt={option.alt}
                      />
                    </div>
                  );
                })
              : defaultOptions.map((option) => {
                  return (
                    <NavigationLink
                      key={option.linkHref}
                      href={option.linkHref}
                      label={option.linkName}
                      iconSrc={option.imageSrc}
                      iconAlt={option.alt}
                      hasNotification={
                        option.notificationIndicator &&
                        notifications[option.notificationIndicator]
                      }
                    />
                  );
                })}
          </div>
          <div className="border-t border-opacity-20 border-custom-light-blue">
            <div className="mt-3 space-y-1">
              <NavigationLink
                href="/settings"
                label={i("Settings")}
                iconSrc="/app/assets/cogIcon.svg"
                iconAlt="Modify your settings"
              />
              <NavigationLink
                href="/logout"
                label={i("Logout")}
                iconSrc="/app/assets/signoutIcon.svg"
                iconAlt="Logout from the application"
              />
            </div>
          </div>
        </div>
      </CustomScrollBar>
    </div>
  );
}

type NavigationLinkProps = {
  href: LinkProps["href"];
  label: string;
  iconSrc?: string;
  iconAlt?: string;
  hasNotification?: boolean;
};
const NavigationLink = ({
  href,
  label,
  iconSrc,
  iconAlt,
  hasNotification,
}: NavigationLinkProps) => {
  const isMessageRoute = href.toString().includes("messages");

  if (isMessageRoute) {
    return (
      <a
        href={"/app" + href.toString()}
        className="px-4 py-2 flex text-base font-medium text-custom-blue  hover:bg-custom-light-blue hover:bg-opacity-20 rounded-l-[10px]"
      >
        <NavLinkContent
          iconSrc={iconSrc}
          iconAlt={iconAlt}
          hasNotification={hasNotification}
          label={label}
        />
      </a>
    );
  }

  return (
    <Link href={href}>
      <a className="px-4 py-2 flex text-base font-medium text-custom-blue  hover:bg-custom-light-blue hover:bg-opacity-20 rounded-l-[10px]">
        <NavLinkContent
          iconSrc={iconSrc}
          iconAlt={iconAlt}
          hasNotification={hasNotification}
          label={label}
        />
      </a>
    </Link>
  );
};

function NavLinkContent({
  iconSrc,
  iconAlt,
  hasNotification,
  label,
}: {
  iconSrc?: string;
  iconAlt?: string;
  hasNotification?: boolean;
  label: string;
}) {
  const { i } = useTranslation();
  return (
    <>
      {iconSrc && (
        <div className="h-6 w-5 flex items-center mr-2 relative">
          <img src={iconSrc} alt={iconAlt} />
          {hasNotification && (
            <div className="absolute border-white border-2 rounded-full -right-1 top-0">
              <div className="border-4 rounded-full border-custom-gradient-orange w-2 h-2"></div>
            </div>
          )}
        </div>
      )}

      {i(label)}
    </>
  );
}
