import { FC } from "react";
type Props = {
  handleChange: (event: string) => void;
};

const SettingsFeedbackTextField: FC<Props> = ({ handleChange }) => (
  <div className="SettingsFeedbackTextField">
    <textarea
      onChange={(e) => handleChange(e.target.value)}
      data-testid="settings-feedback-text-field"
      className="form-control block text-base text-custom-black w-full h-36 border border-custom-gray rounded"
    ></textarea>
  </div>
);

export default SettingsFeedbackTextField;
