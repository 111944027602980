import { useSelector } from "react-redux";
import { createSelector, PayloadAction } from "@reduxjs/toolkit";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  fetchResults,
  setSearchFilter,
  reset,
  State,
} from "../redux/slices/visitedMyProfileSlice";
import { Member } from "../backendProxies";

type UseVisitedMyProfile = () => State & {
  fetchResults: (shouldReset: boolean) => void;
  setSearchFilter: (searchFilter: "all" | "criteria") => void;
  reset: () => void;
  members: Member[];
};

const useVisitedMyProfile: UseVisitedMyProfile = () => {
  const selectResults = (state: RootState) => state.visitedMyProfile.results;

  const selectMembers = createSelector([selectResults], (results) => {
    return results.map((result) => new Member(result));
  });

  const members = useSelector(selectMembers);

  const hasMore = useSelector(
    (state: RootState) => state.visitedMyProfile.hasMore
  );

  const isLoading = useSelector(
    (state: RootState) => state.visitedMyProfile.isLoading
  );

  const searchFilter = useSelector(
    (state: RootState) => state.visitedMyProfile.searchFilter
  );

  const actions = useActions({ fetchResults, setSearchFilter, reset });

  return { members, hasMore, isLoading, searchFilter, ...actions };
};

export default useVisitedMyProfile;
