import { useState, useEffect } from "react";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [width, setWidth] = useState<number | undefined>(undefined);

  const [height, setHeight] = useState<number | undefined>(undefined);

  function handleResize() {
    if (typeof window === "undefined") return;

    if (window.innerWidth === width && window.innerHeight === height) return;

    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, []);
  return {
    windowWidth: width,
    windowHeight: height,
    triggerResize: handleResize,
  };
}

export default useWindowSize;
