import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class Help extends Api {
  public sendContactForm(body: {
    email: string;
    subject: string;
    message: string;
    captchaId: string;
    captcha: number;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/help/sendContactForm", body);
  }

  public checkConfirmationCode(body: {
    code: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/help/checkConfirmationCode", body);
  }

  public sendConfirmationEmail(body: {
    email: string;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.post("/help/sendConfirmationEmail", body);
  }

  public checkInfosConfirmationEmail(): Promise<
    HTTPResponse & { data: { message: string; forceConfirmation: boolean } }
  > {
    return this.get("/help/checkInfosConfirmationEmail");
  }

  public getHelp(): Promise<
    HTTPResponse & {
      data: {
        items: number;
        total: number;
        list: { id: number; question: string; reponse: "string" }[];
      };
    }
  > {
    return this.get("/help");
  }
}
