import React, { FC, useContext, useEffect, useRef, useState } from "react";
import useMessenger from "../../hooks/useMessenger";
import api from "../../backendAPI";
import { TranslationContext } from "../../context/TranslationContext";
import EmojiPicker from "../emojiPicker";

// todo (simon) -> cleanup

type Props = {
  disableLiveAlerts?: boolean;
  isGreetings?: boolean;
  setChatMessage(message: string): void;
  chatMessage: string;
  onSendClick: () => void;
  preventSubmitting?: boolean;
  triggeredLastMessageHasBeenViewed: boolean;
  setTriggeredLastMessageHasBeenViewed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const MessageChatBoxText: FC<Props> = ({
  disableLiveAlerts,
  isGreetings,
  setChatMessage,
  chatMessage,
  onSendClick,
  preventSubmitting,
  triggeredLastMessageHasBeenViewed,
  setTriggeredLastMessageHasBeenViewed,
}) => {
  const { i } = useContext(TranslationContext);
  const { currentConversation } = useMessenger();

  let timeout = useRef<ReturnType<typeof setTimeout>>();

  const [isTyping, setIsTyping] = useState(false);

  const [stoppedTyping, setStoppedTyping] = useState(false);

  const [textAreaHeight, setTextAreaHeight] = useState(50);

  useEffect(() => {
    if (disableLiveAlerts) return;
    if (!currentConversation.conversationInfo) return;

    if (isTyping) {
      api.liveAlerts.postLiveAlerts({
        userId: currentConversation.conversationInfo.userId,
        discussionId: currentConversation.conversationInfo.discussionId,
        action: "isTyping",
      });
    }
  }, [isTyping]);

  useEffect(() => {
    if (disableLiveAlerts) return;
    if (!stoppedTyping || !timeout.current) return;
    if (!currentConversation.conversationInfo) return;

    api.liveAlerts.postLiveAlerts({
      userId: currentConversation.conversationInfo.userId,
      discussionId: currentConversation.conversationInfo.discussionId,
      action: "stopTyping",
    });

    setStoppedTyping(false);
  }, [stoppedTyping]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    isTypingHandler(event);

    if (newLineHandler(event)) return;

    if (event.key === "Enter") {
      event.preventDefault();
      onSendClick();
    }
  };

  const isTypingHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key !== "Enter") {
      setIsTyping(true);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        setIsTyping(false);
        setStoppedTyping(true);
      }, 1500);
    }
  };

  const newLineHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.shiftKey) {
      if (event.key === "Enter") {
        event.stopPropagation();
        // setChatMessage((prevState) => prevState + "\n");
        return true;
      }
    }

    return false;
  };

  const handleIsReading = async () => {
    if (disableLiveAlerts) return;
    if (!currentConversation.conversationInfo) return;

    if (triggeredLastMessageHasBeenViewed) return;

    api.liveAlerts.postLiveAlerts({
      userId: currentConversation.conversationInfo.userId,
      discussionId: currentConversation.conversationInfo.discussionId,
      action: "isReading",
    });

    setTriggeredLastMessageHasBeenViewed(true);
  };

  const computeHeight = (message: string): number => {
    const numberOfLineBreaks = (message.match(/\n/g) || []).length;

    const MAX_CHAR_PER_LINE = 80;

    const numberOfChars = message.length;

    const numberOfFullLines = Math.floor(numberOfChars / MAX_CHAR_PER_LINE);

    return 50 + (numberOfLineBreaks + numberOfFullLines) * 15;
  };

  useEffect(() => {
    const height = computeHeight(chatMessage);

    setTextAreaHeight(height);
  }, [chatMessage]);

  return (
    <div className="MessageChatBoxText w-full relative">
      <div className="overflow-x-hidden flex items-stretch max-h-64">
        <textarea
          style={{ height: isGreetings ? undefined : textAreaHeight }}
          data-testid="messenger-textarea"
          id="messenge-textarea"
          onClick={handleIsReading}
          disabled={preventSubmitting}
          rows={isGreetings ? 5 : 1}
          value={chatMessage}
          onKeyDown={handleKeyDown}
          onChange={(event) => setChatMessage(event.target.value)}
          className={`messageBox relative w-full outline-none border  border-custom-light-gray-600 rounded-[4px] ${
            isGreetings ? "resize-none h-full" : "h-[50px]"
          } p-3.5 pt-2.5 pl-[22px] pr-10 bg-white text-base text-custom-black placeholder-custom-black-500/50 focus:outline-none focus:shadow-outline `}
          placeholder={i("Type your message...")}
        />
      </div>
      {!isGreetings && (
        <span className="absolute right-6 top-3 z-10 h-full flex items-start">
          <EmojiPicker
            onChange={(e) => {
              setChatMessage(chatMessage + e);
            }}
            disabled={preventSubmitting}
          />
        </span>
      )}
    </div>
  );
};

export default MessageChatBoxText;
