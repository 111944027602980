import api from "../../backendAPI";
import { store } from "../../redux/store";
import { ROOT } from "../../backendAPI/Api";
import { Languages } from "../../context/TranslationContext";

export type MemberInfo = {
  type: "user" | "promotion";
  promotion?: {
    type: "blue" | "red" | "custom";
    html: string;
    custom?: {
      category: string;
      image: string;
      title: string;
      description: string;
      buttonText: string;
      url: string;
    };
  };
  profilId: string;
  userId: string;
  id: string;
  prenom: string;
  sexeId: "1" | "2";
  genre: string;
  mainImage: string;
  age: number;
  city: string;
  userName: string;
  isOnline: boolean;
  LastUpdate: number;
  LastUpdate_text: string;
  isVIP: boolean;
  isFavorited: boolean;
  isDateMeVoted: boolean;
  isCertified: boolean;
  totalVoteProfil: number;
  lastOnlineTime: number;
  typeRecherche?: string;
  lastVisitedDate: string;
  NbVisitedMyProfile: string;
  nbPhotos: string;
};

class Member {
  public info: MemberInfo;
  public isFavorited: boolean;
  public isDateMeVoted: boolean;

  private token = window.localStorage.getItem("token")!;

  constructor(config: MemberInfo) {
    this.info = config;
    this.isFavorited = config?.isFavorited || false;
    this.isDateMeVoted = config?.isDateMeVoted || false;
  }

  public sayHi = () => {
    openToast({
      title: "Message",
      description: "hi",
      type: "success",
    });
  };

  public sendMessage = () => {};

  public addToFavorites = async () => {
    const userId = +this.info.userId;

    const response = await api.favorites.postFavorite({
      userId,
    });

    if (response.status === "ERROR") {
      openToast({
        title: "There was an error",
        description: "Please try again or contact support",
        type: "error",
      });
      return;
    }

    this.isFavorited = true;

    return this.isFavorited;
  };

  public removeFromFavorites = async () => {
    const userId = +this.info.userId;

    const response = await api.favorites.deleteFavorite({
      userId,
    });

    if (response.status === "ERROR") {
      openToast({
        title: "Error",
        description: `${this.info.userName} was removed from your favorites.`,
        type: "error",
      });
      return;
    }

    this.isFavorited = false;

    return this.isFavorited;
  };

  public toggleFavorite = async () => {
    if (this.isFavorited) {
      return this.removeFromFavorites();
    }

    return this.addToFavorites();
  };

  // todo cleanup
  public toggleDateMeVoted = async () => {
    if (!this.isDateMeVoted) {
      const response = await this.acceptDateMe();

      if (response?.status === "SUCCESS") {
        this.isDateMeVoted = true;
        return this.isDateMeVoted;
      } else {
        // handle error

        return false;
      }
    }

    const response = await this.declineDateMe();

    if (response?.status === "SUCCESS") {
      this.isDateMeVoted = false;
      return this.isDateMeVoted;
    }

    return false;
  };

  public like = () => {};

  public declineDateMe = async () => {
    const userId = this.info.userId;
    const profilId = this.info.profilId;

    const response = await api.dateMe.postDateMeAction({
      userId: +userId,
      userProfileId: +profilId,
      choix: "no",
    });

    return response;
  };

  public acceptDateMe = async () => {
    const userId = this.info.userId;
    const profilId = this.info.profilId;

    const response = await api.dateMe.postDateMeAction({
      userId: +userId,
      userProfileId: +profilId,
      choix: "yes",
    });

    return response;
  };

  public deleteFromDateMe = async () => {
    const id = this.info.id;

    const response = await api.dateMe.deleteFromDateMe({
      id: +id,
      page: "who_i_want_to_meet",
    });
  };

  public async addToBlockList() {
    const response = await api.blocked.postBlocked({
      userId: +this.info.userId,
    });

    if (response.status === "SUCCESS") {
      setTimeout(() => {
        window.location.reload();
      }, 350);
    }
  }

  public removeFromBlockedList = async () => {
    const userId = +this.info.userId;

    const response = await api.blocked.deleteBlocked({
      userId,
    });

    return response;
  };

  public deleteFromHistory = async () => {
    const userIdToDelete = +this.info.userId;

    if (!this.token) return;

    const response = await fetch(ROOT + "/history", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({
        userId: userIdToDelete,
      }),
    });

    const jsonResponse = await response.json();

    return jsonResponse;
  };

  public addVote = async () => {
    const userId = +this.info.userId;

    const response = await api.votes.postVote({
      userId,
    });
  };

  public removeVote = async () => {
    const userId = +this.info.userId;

    const response = await api.votes.deleteVote({
      userId,
    });
  };

  public removeFromVisitedMyProfile = async () => {
    const userId = +this.info.userId;

    const response = await api.visitedMyProfile.deleteVisitedMyProfile({
      userId,
    });
  };
}
export default Member;

function openToast({
  title,
  description,
  type,
  href,
  hrefText,
}: {
  title: string;
  description: string;
  type: "success" | "error" | "warning";
  href?: string;
  hrefText?: string;
}) {
  store.dispatch({
    type: "toast/openToast",
    payload: {
      title,
      description,
      type,
      href,
      hrefText,
    },
  });
}
