import { FC } from "react";

type Props = {
  index: number;
  question: string;
  answer: string;
};

const ProfileInterviewItem: FC<Props> = ({ index, question, answer }) => {
  return (
    <div data-testid={`profile-interview-index-${index}`}>
      <p className="text-base leading-[22px] font-bold text-custom-black-500 pb-[6px]">
        {question}
      </p>
      <p className="text-base leading-[22px] font-normal text-custom-black-500">
        {answer}
      </p>
    </div>
  );
};

export default ProfileInterviewItem;
