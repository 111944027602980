import { FC } from "react";
import { Member } from "../../backendProxies";
import CardMemberProfileBadge from "../cardMemberProfileBadge";

type Props = {
  member: Member;
  location: string;
  showBadge?: boolean;
};

// TODO (simon) -> Split into two components, location and badges

const CardMemberProfileLocation: FC<Props> = ({
  member,
  location,
  showBadge = true,
}) => {
  return (
    <div className="CardMemberProfileLocation flex flex-row justify-between items-center w-full">
      <p className="text-white grow overflow-hidden text-base truncate">
        {location}
      </p>
      {showBadge && (
        <div className="flex grow-none w-[35px] justify-end">
          {!member.info.isVIP && member.info.isCertified && (
            <CardMemberProfileBadge badge="Certified" />
          )}
          {member.info.isVIP && <CardMemberProfileBadge badge="VIP" />}
        </div>
      )}
    </div>
  );
};

export default CardMemberProfileLocation;
