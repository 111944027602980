import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { NavigationProvider } from "../context/NavigationContext";
import { NotificationProvider } from "../context/NotificationContext";
import { Provider } from "react-redux";
import { store } from "../redux/store";

import Toast from "../components/toast";
import Certification from "../components/certification";
import { ConfirmDialogProvider } from "../context/ConfirmDialogContext";

import { SayHiProvider } from "../context/SayHiContext";
import { TranslationProvider } from "../context/TranslationContext";
import { NotificationDialogProvider } from "../context/NotificationDialogContext";
import { RegistrationQuestionsProvider } from "../context/RegistrationContext";
import { VIPPopupProvider } from "../context/VIPPopupContext";
import { SendMessagePopupProvider } from "../context/SendMessagePopupContext";
import { ReportPopupProvider } from "../context/ReportPopupContext";
import SocketIoProvider from "../context/SocketIoContext";
import NotificationContainer from "../components/notificationContainer";
import { AlertMessageProvider } from "../context/AlertMessageContext";

import { useEffect, useRef, useState } from "react";

import { GoogleAnalytics } from "nextjs-google-analytics";

import Script from "next/script";
import { FeedbackContextProvider } from "../context/FeedbackContext";
import { bootstrap } from "../redux/slices/bootstrapSlice";
import GoogleMapProvider from "../context/GoogleMapContext";
import VideoChatProvider from "../videochat/contexts/VideoChatContext";
import ProfileModal from "../components/profileModal/ProfileModal";
import InertRemover from "../components/InertRemover";

declare global {
  interface Window {
    gtag: any;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const [gtagId, setGtagId] = useState("");

  function initGoogleAdsOberserver() {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.target instanceof HTMLElement &&
          (mutation.target.dataset.pageName === "messenger" ||
            mutation.target.dataset.pageName === "videochat" ||
            mutation.target.classList.contains(
              "MessageActiveConvoContainerMobile"
            ) ||
            mutation.target.classList.contains("ignore-google-ads-observer"))
        ) {
          const target = mutation.target as HTMLElement;

          // Clear specific styles if conditions are met
          target.style.height = "";
          target.style.minHeight = "";
        }
      }
    });
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
      childList: true,
      subtree: true, // Include all descendants
    });
    return observer;
  }

  useEffect(() => {
    store.dispatch(bootstrap());
    if (!window) return;

    window.openProfileModal = function (username: string) {
      //@ts-ignore
      let url = new URL(window.location);
      url.searchParams.set("profile", username);
      history.pushState({}, "", url);
    };

    const isVidamora = /vidamora/.test(window.location.href);
    const googleAdsObserver = initGoogleAdsOberserver();

    setGtagId(isVidamora ? "UA-117770838-1" : "UA-9350737-1");

    //@ts-ignore
    (function () {
      function signalGooglefcPresent() {
        if (!window.frames["googlefcPresent"]) {
          if (document.body) {
            const iframe = document.createElement("iframe");
            iframe.style =
              "width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;";
            iframe.style.display = "none";
            iframe.name = "googlefcPresent";
            document.body.appendChild(iframe);
          } else {
            setTimeout(signalGooglefcPresent, 0);
          }
        }
      }
      signalGooglefcPresent();
    })();

    return () => {
      googleAdsObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Script
        id="resize-observer-polyfill"
        src="/app/assets/js/resize-observer.js"
        strategy="beforeInteractive"
      />
      <Script
        id="adblockblocker"
        nonce="gB2K8JL3fWuXS0caYyxhug"
        src="https://fundingchoicesmessages.google.com/i/pub-6005108637849729?ers=1"
        strategy="afterInteractive"
      ></Script>

      <Provider store={store}>
        <TranslationProvider>
          <AlertMessageProvider>
            <SocketIoProvider>
              <RegistrationQuestionsProvider>
                <NavigationProvider>
                  <NotificationProvider>
                    <ConfirmDialogProvider>
                      <SendMessagePopupProvider>
                        <VIPPopupProvider>
                          <SayHiProvider>
                            <ReportPopupProvider>
                              <FeedbackContextProvider>
                                <NotificationDialogProvider>
                                  <VideoChatProvider>
                                    <GoogleMapProvider>
                                      {gtagId && (
                                        <GoogleAnalytics
                                          trackPageViews
                                          gaMeasurementId={gtagId}
                                        />
                                      )}
                                      <div id="headlessui-portal-root">
                                        <InertRemover />
                                        <Component {...pageProps} />
                                        <Toast />
                                      </div>
                                      <ProfileModal />
                                    </GoogleMapProvider>
                                  </VideoChatProvider>
                                  <NotificationContainer />
                                </NotificationDialogProvider>
                              </FeedbackContextProvider>
                            </ReportPopupProvider>
                          </SayHiProvider>
                        </VIPPopupProvider>
                      </SendMessagePopupProvider>
                    </ConfirmDialogProvider>

                    <Certification />
                  </NotificationProvider>
                </NavigationProvider>
              </RegistrationQuestionsProvider>
            </SocketIoProvider>
          </AlertMessageProvider>
        </TranslationProvider>
      </Provider>
    </>
  );
}

export default MyApp;
