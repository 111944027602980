import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "../../utils/twClassNames";
import VerifPopupImage from "../verifPopupImage";

type Props = {
  buttonText: string;
  imageSrc: string;
  imageAlt: string;
  editImageText: string
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File>>;
};

const VerifPopupPictureContainer: FC<Props> = ({
  buttonText,
  imageAlt,
  imageSrc,
  editImageText,
  setImage,
  setFile,
}) => {
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [loaded, setLoaded] = useState(false);

  const loadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (typeof reader.result !== "string") return;

        setFile(event.target.files[0]);
        setImage(reader.result);
        setLoaded(true);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div className="VerifPopupPictureContainer w-full max-w-[250px] flex flex-col items-center">
      <VerifPopupImage imageAlt={imageAlt} imageSrc={imageSrc} />
      {loaded ? (
        <button
          onClick={() => setLoaded(false)}
          className="font-semibold text-custom-blue h-[50px]"
        >
          {editImageText}
        </button>
      ) : (
        <>
          <label
            htmlFor="image-input"
            className={classNames(
              "ButtonBGBlue w-full inline-flex justify-center items-center rounded h-[50px] border-2 shadow-sm px-4 py-2  text-lg font-medium text-white hover:bg-blue-700",
              loaded
                ? "border-custom-light-blue bg-custom-light-blue"
                : "border-custom-blue bg-custom-blue"
            )}
          >
            {buttonText}
          </label>
          <input
            onChange={loadImage}
            type="file"
            id="image-input"
            className="hidden"
          />
        </>
      )}
    </div>
  );
};

export default VerifPopupPictureContainer;
