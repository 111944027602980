import { RootState } from "../store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../backendAPI";

import { checkIfHasMore } from "../utils";

import { login, logout } from "./authSlice";

import { searchResultsChangedSubject$ } from "../../context/NavigationContext";

export interface State {
  results: any[];
  pageIndex: number;
  hasMore: boolean;
  isLoading: boolean;
  error: Error | null;
  username: string;
}
const initialState: State = {
  results: [],
  pageIndex: 1,
  hasMore: true,
  isLoading: false,
  error: null,
  username: "",
};

export const fetchResults = createAsyncThunk(
  "searchByUsername/fetchResults",

  async (shouldReset: boolean = false, { getState }) => {
    const state = getState() as RootState;

    const pageIndex = shouldReset ? 1 : state.searchByUsername.pageIndex;

    const username = state.searchByUsername.username;

    const response = await api.searchByUsername.getSearchByUsername({
      pageIndex: pageIndex,
      pageSize: 24,
      username: username,
    });

    return response;
  }
);

export const searchByUsernameSlice = createSlice({
  name: "searchByUsername",
  initialState,
  reducers: {
    reset: () => initialState,

    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      const reset = action.meta.arg;

      state.pageIndex += 1;

      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;
      if (reset) {
        state.results = action.payload.data.list;
      } else {
        state.results = state.results.concat(action.payload.data.list);
      }

      searchResultsChangedSubject$.next();
    });

    builder.addCase(fetchResults.pending, (state, action) => {
      const reset = action.meta.arg;

      if (reset) {
        state.pageIndex = 1;
        state.results = [];
      }
      state.isLoading = true;
    });

    builder.addCase(fetchResults.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as Error; // fix
    });

    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setUsername, reset } = searchByUsernameSlice.actions;

export default searchByUsernameSlice.reducer;
