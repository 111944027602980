import { FC, useContext } from "react";
import { TranslationContext } from "../../context/TranslationContext";

const FooterSocial: FC = () => {
  const { i } = useContext(TranslationContext);
  return (
    <div className="flex w-full lg:w-1/2 justify-start lg:justify-end items-center ">
      <a
        rel="noreferrer"
        target={"_blank"}
        href="https://www.facebook.com/profile.php?id=100063501283722"
      >
        <div className="cursor-pointer w-19 bg-custom-blue flex h-8 items-center justify-center space-x-1 lg:ml-4 rounded">
          <div className="bg-white pt-1 m-1 rounded-sm">
            <img
              src="/app/assets/facebook.svg"
              width={18}
              className="relative -bottom-0.5 mx-0.5"
              alt="Facebook icon"
            />
          </div>
          <p className="pr-4 text-white">{i("Like")}</p>
        </div>
      </a>
    </div>
  );
};

export default FooterSocial;
