import React, { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import MembershipBenefits from "../components/membershipBenefits";
import Popup from "../components/popup";
import ButtonBGBlue from "../components/buttonBGBlue";
import useTranslation, { TranslationContext } from "./TranslationContext";
import { ContentJson, LiveAlert, SocketIoContext } from "./SocketIoContext";
import { Transition } from "@headlessui/react";
import api from "../backendAPI";
import { ServerAction } from "../components/navBarBanner/NavBarBanner";
import ButtonBlueBGWhite from "../components/buttonBlueBGWhite";
import StyledPopup from "../components/styledPopup";

interface Context {
  openPopup: () => void;
}

export const VIPPopupContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function VIPPopupProvider({ children }: Props) {
  const { i } = useContext(TranslationContext);
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  const openPopup = () => {
    setIsOpen(true);
  };

  return (
    <VIPPopupContext.Provider
      value={{
        openPopup,
      }}
    >
      {children}
      <StyledPopup open={isOpen} onClick={setIsOpen} noPadding>
        <MembershipBenefits />
        <div className="flex bg-custom-gray/20 justify-center">
          <div className="mb-6">
            <ButtonBGBlue
              innerText={i("Becoming VIP")}
              onClick={() => {
                setIsOpen(false);
                router.push("/settings?tab=vip");
              }}
            />
          </div>
        </div>
      </StyledPopup>
      <VIPNotification />
    </VIPPopupContext.Provider>
  );
}

const VIPNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState<"vip" | "noads">("vip");
  const [content, setContent] = useState<ContentJson | null>(null);
  const { activeSocket } = useContext(SocketIoContext);

  useEffect(() => {
    if (!activeSocket) return;
    const messengerAlertHandler = (event: LiveAlert) => {
      if (event.category !== "popup" || event.subcategory !== "promote") return;
      if (event.type !== "vip" && event.type !== "noads") return;
      const content =
        event.content_json && (JSON.parse(event.content_json) as ContentJson);
      if (!content) return;
      setType(event.type);
      setContent(content);
      setIsVisible(true);
    };

    activeSocket.on("notification", messengerAlertHandler);

    return () => {
      activeSocket.removeListener("notification", messengerAlertHandler);
    };
  }, [activeSocket]);

  return (
    <div className="NotificationContainer fixed right-3 bottom-5 md:bottom-7 sm:m-auto z-[60]">
      <Transition
        show={isVisible}
        data-testid={`floating-notification-feedback`}
        className="MessageFloatingNotification text-white w-[275px] xs:w-[350px] h-fit bg-gradient-to-r from-custom-transparent-lightpurple to-custom-transparent-purple rounded-[4px] flex justify-between py-4 px-5 mb-5"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="cursor-pointer items-center flex flex-col sm:flex-row w-full">
          <img
            src={content?.icon}
            alt="Subscription Icon"
            className="pr-3 w-16"
          />
          <div className="w-full flex-col justify-center items-center">
            <div
              className="w-full text-center sm:text-left"
              dangerouslySetInnerHTML={{ __html: content?.content ?? "" }}
            />
            {!!content?.serverActions.length && (
              <div className="w-full flex flex-col-reverse sm:flex-row items-center gap-3 sm:gap-8 mt-2 sm:mt-0 py-1">
                {content.serverActions.map((action) => (
                  <ServerActionButton
                    key={`${type}-${action.action}`}
                    type={type}
                    {...action}
                    onClick={() => setIsVisible(false)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

type ServerActionButtonProps = {
  action: ServerAction | "not-now";
  text: string;
  type: "vip" | "noads";
  onClick(): void;
};

const ServerActionButton = ({
  action,
  text,
  type,
  onClick,
}: ServerActionButtonProps) => {
  const handleNotNow = () => {
    api.general.post("/promote/not-now", { type });
    onClick();
  };

  if (action === "not-now")
    return (
      <button
        className="no-underline hover:underline text-sm"
        onClick={handleNotNow}
      >
        {text}
      </button>
    );
  return (
    <ButtonBlueBGWhite
      innerText={text}
      serverAction={action}
      className="px-2 py-1 w-fit h-auto"
      onClick={onClick}
      style={{
        textDecorationLine: "none",
      }}
    />
  );
};
