import { FC, useContext } from "react";
import useMessenger from "../../hooks/useMessenger";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  hour_tz: string;
  index: number;
  selected: boolean;
};

const MessageBubbleTimestamp: FC<Props> = ({ hour_tz, index, selected }) => {
  const { currentConversation } = useMessenger();

  const { i } = useContext(TranslationContext);

  const currentMessage = currentConversation.items[index];
  const previousMessage = currentConversation.items[index + 1];

  if (
    currentMessage?.userId === previousMessage?.userId &&
    currentMessage?.timestamp - previousMessage?.timestamp < 600 &&
    !selected
  )
    return <></>;

  return (
    <div className="flex justify-end">
      {currentMessage?.messageHasBeenEdited && (
        <div className="flex items-center text-sm leading-5 text-custom-dark-gray pb-1">
          {i("Edited")}{" "}
          <span
            className="bg-custom-dark-gray"
            style={{
              height: 2,
              width: 2,
              borderRadius: "100%",
              margin: "0 8px",
            }}
          >
            {" "}
          </span>
        </div>
      )}
      <div className="MessageBubbleTimestamp">
        <p className="text-custom-dark-gray text-xs leading-5 text-right pb-1">
          {hour_tz}
        </p>
      </div>
    </div>
  );
};

export default MessageBubbleTimestamp;
