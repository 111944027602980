import React, { CSSProperties } from "react";
import Scrollbar from "react-scrollbars-custom";

type SayHelloScrollbarProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  containerStyle?: CSSStyleSheet;
  className?: string;
  children?: React.ReactNode;
};

export default function SayHelloScrollbar({
  height = "100%",
  width = "100%",
  className,
  containerStyle,
  children,
}: SayHelloScrollbarProps) {
  return (
    <Scrollbar
      className={className}
      style={{ width, height, ...containerStyle }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="trackY w-3"
              style={{ ...style, background: "#e9eafe", width: 8 }}
            />
          );
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="thumbY"
              style={{
                ...style,
                background: "#636ef6",
                position: "absolute",
              }}
            />
          );
        },
      }}
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="wrapper"
              style={{ ...style, width }}
            />
          );
        },
      }}
      contentProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="wrapper"
              style={{ ...style, width, display: "flex" }}
            />
          );
        },
      }}
    >
      {children}
    </Scrollbar>
  );
}
