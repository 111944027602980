import { Subject } from "rxjs";
import { assertLanguage } from "../context/TranslationContext/utils";

export const DOMAIN = process.env.NEXT_PUBLIC_API_ROOT || "";

export const ROOT = DOMAIN + "/api/webapp/v1";

export interface HTTPResponse {
  status: "SUCCESS" | "ERROR";
  http_code: number;
  message: string;
}

export interface HTTPResponseWithDataMessage extends HTTPResponse {
  data: {
    message: string;
  };
}

declare global {
  interface Window {
    httpError$: Subject<number>;
    profileDeleted$: Subject<void>;
    appOutOfDate$: Subject<void>;
    invalidConversation$: Subject<void>;
  }
}

export default class Api {
  public getLocale() {
    return assertLanguage();
  }

  protected getRoot() {
    document.dispatchEvent(new Event("api-call"));
    return ROOT;
  }

  protected getToken() {
    return window.localStorage.getItem("token") || "";
  }

  public async get(
    endpoint: string,
    queryParamsMap?: any,
    id?: string,
    force?: boolean
  ) {
    // TODO: Not sure this does anything but block unless there is a token. If not needed can remove force param here and in General.getTranslations
    if (!force && (!this.getToken() || !this.getLocale())) {
      // handle
      return;
    }

    let url = this.getRoot() + endpoint;

    if (id) {
      url += "/" + id;
    }

    url += "?";
    for (let key in queryParamsMap) {
      url += `${key}=${queryParamsMap[key]}&`;
    }

    //
    url += `locale=${this.getLocale()}`;

    const response = await fetch(url, {
      headers: {
        Authorization: window.localStorage.getItem("token")!,
      },
    });

    const currentAppVersion = window.localStorage.getItem("x-app-version");
    const apiAppVersion = response.headers.get("x-app-version");

    if (
      currentAppVersion &&
      currentAppVersion !== response.headers.get("x-app-version") &&
      !window.__CYPRESS
    ) {
      window?.appOutOfDate$?.next();
    }

    if (!currentAppVersion && apiAppVersion !== null) {
      window.localStorage.setItem("x-app-version", apiAppVersion);
    }

    const jsonResponse = await response.json();

    if (
      window.location.href.includes("messages?conversation") &&
      !window.location.href.includes("profile=") &&
      jsonResponse.http_code > 399
    ) {
      window.invalidConversation$.next();
    }
    if (jsonResponse.message === "Token expected" && window.httpError$) {
      window.httpError$.next(jsonResponse.http_code);
    }

    if (jsonResponse.message === "PROFILE_DELETED" && window.profileDeleted$) {
      window.profileDeleted$.next();
    }

    return jsonResponse;
  }

  private async fetchWithBody(
    method: "POST" | "DELETE" | "PATCH" | "PUT",
    endpoint: string,
    body: any
  ) {
    if (!this.getToken()) return; // todo handle

    const response = await fetch(
      endpoint.startsWith("http") ? endpoint : this.getRoot() + endpoint,
      {
        method,
        headers: {
          "Content-type": "application/json",
          Authorization: this.getToken()!,
        },
        body: JSON.stringify({ locale: this.getLocale(), ...body }),
      }
    );

    const currentAppVersion = window.localStorage.getItem("x-app-version");

    if (
      currentAppVersion &&
      currentAppVersion !== response.headers.get("x-app-version") &&
      !window.__CYPRESS
    ) {
      window?.appOutOfDate$?.next();
    }

    const jsonResponse = await response.json();

    if (jsonResponse.message === "Token expected" && window.httpError$) {
      window.httpError$.next(jsonResponse.http_code);
    }

    if (jsonResponse.message === "PROFILE_DELETED" && window.profileDeleted$) {
      window.profileDeleted$.next();
    }

    return jsonResponse;
  }

  public async post(endpoint: string, body: any) {
    return this.fetchWithBody("POST", endpoint, body);
  }

  public async delete(endpoint: string, body: any) {
    return this.fetchWithBody("DELETE", endpoint, body);
  }
  public async patch(endpoint: string, body: any) {
    return this.fetchWithBody("PATCH", endpoint, body);
  }

  public async put(endpoint: string, body: any) {
    return this.fetchWithBody("PUT", endpoint, body);
  }
}
