import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

type Props = {
  username: string;
  age: number;
  isVip: boolean;
  city: string;
  isCertified: boolean;
};

const DateMeProfileInfo: FC<Props> = ({
  username,
  age,
  isVip,
  city,
  isCertified,
}) => {
  const router = useRouter();

  if (/profile/.test(router.pathname)) {
    return null;
  }

  return (
    <div className="DateMeProfileInfo sm:-ml-10 -ml-[30px] ">
      <div className="flex items-center w-auto bg-white/50 relative rounded-r pl-8">
        <OpenProfileLink username={username}>
          <span className="text-custom-blue text-[22px] w-auto whitespace-nowrap text-left font-semibold px-1 cursor-pointer">
            {`${username}, ${age}`}
          </span>
        </OpenProfileLink>
        {isVip && (
          <div className="ml-3 w-[40px] relative top-0.5 mr-3">
            <img
              height={32}
              width={40}
              src="/app/assets/vipcard.png"
              alt="VIP member icon"
            />
          </div>
        )}
        {isCertified && !isVip && (
          <div className="relative w-[40px] top-1 ml-1">
            <img
              className="w-[40px] h-[35px]"
              src="/app/assets/coloredCertifiedBadgeIcon.svg"
              alt="VIP member icon"
            />
          </div>
        )}
      </div>
      <div>
        <p className="w-fit font-normal pl-9 pr-3 text-left rounded-br bg-white/50">
          {city}
        </p>
      </div>
    </div>
  );
};

export default DateMeProfileInfo;
