import { useEffect, useContext } from "react";
import { createContext } from "react";

import LocationPickerList from "../components/locationPickerHelper/LocationPickerList";
import useTranslation, { assertLanguage } from "./TranslationContext";

const GOOGLE_API_KEY = "AIzaSyD-HbZqCi3FLRr6SFKjKf3jcUCSmlGPXPE";

declare global {
  interface Window {
    gmapCb: () => void;
  }
}

type Context = {};

export const GoogleMapContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export default function GoogleMapProvider({ children }: Props) {
  const { locale } = useTranslation();
  useEffect(() => {
    document.addEventListener("input", (event) => {
      const target = event.target as HTMLInputElement;
      const locationFilter = document.getElementById(
        "location-helper-filter"
      ) as HTMLInputElement;

      const inlineStyles = document.getElementById(
        "location-helper-inline-styles"
      )!;

      if (target.id !== "location-helper-filter") return;

      const { value } = locationFilter;

      if (!value || value === "") {
        inlineStyles.textContent = "";
        return;
      }

      inlineStyles.textContent = `

     [data-location-description] {
        display: none;
      }
    
      [data-location-description^="${value}" i] {
        display:block!important;
      }
    `;
    });

    let script: HTMLScriptElement;

    if (!document.getElementById("google-autocomplete")) {
      script = document.createElement("script");
      script.id = "google-autocomplete";

      window.gmapCb = () => {};

      script.src = `https://maps.googleapis.com/maps/api/js?types=(cities)&language=${locale}&key=${GOOGLE_API_KEY}&libraries=places&callback=gmapCb`;

      script.async = true;

      document.body.append(script);
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <GoogleMapContext.Provider value={{}}>
      {children}

      <LocationPickerList />
    </GoogleMapContext.Provider>
  );
}
