import React, { FC } from "react";

type Props = {
  iconSrc: string;
  iconAlt: string;
};

const SayHelloEmojiLarge: FC<Props> = ({ iconSrc, iconAlt }) => (
  <div className="SayHelloEmojiLarge">
    <img src={iconSrc} alt={iconAlt} className="h-[40px] sm:h-[70px]" />
  </div>
);

export default SayHelloEmojiLarge;
