import React, { useEffect, useState, createContext } from "react";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import { io, Socket } from "socket.io-client";
import { DOMAIN } from "../backendAPI/Api";

import useVisitedMyProfile from "../hooks/useVisitedMyProfile";
import playSound from "../utils/playSound";
import { ServerAction } from "../components/navBarBanner/NavBarBanner";
import useMessenger from "../hooks/useMessenger";
import useTranslation from "./TranslationContext";

declare global {
  interface Window {
    socket: Socket | undefined;
  }
}

type Context = {
  notification: LiveAlert | undefined;
  setNotification: React.Dispatch<React.SetStateAction<LiveAlert | undefined>>;
  activeSocket: Socket | undefined;
};

export const SocketIoContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export default function SocketIoProvider({ children }: Props) {
  const { accessToken } = useAuth();
  const { setDotNotification, clearDotNotification } = useUser();

  const { fetchResults } = useVisitedMyProfile();
  const { i } = useTranslation();

  const [activeSocket, setActiveSocket] =
    useState<Socket | undefined>(undefined);

  useEffect(() => {
    if (!activeSocket) return;

    window.socket = activeSocket;
  }, [activeSocket]);

  const [notification, setNotification] = useState<LiveAlert>();

  const { addMessageFromLiveAlert, addGreetingsFromLiveAlert } = useMessenger();

  useEffect(() => {
    const token = accessToken || window.localStorage.getItem("token");

    if (!token) {
      if (!window.socket) return;
      window.socket?.offAny();
      window.socket?.disconnect();
      window.socket = undefined;
      setActiveSocket(undefined);
      return;
    }

    window.socket = io(`${DOMAIN}/`, {
      auth: { token },
      transports: ["websocket", "polling", "flashsocket"],
    });

    window.socket.on("connect", () => {
      setActiveSocket(window.socket);
    });

    window.socket.on("notification", (event: LiveAlert) => {
      if (event.subcategory === "feedback") return;
      if (event.subcategory === "promote") return;
      if (event.category === "call") return;

      if (event.subcategory === "both" || event.subcategory === "alert") {
        switch (event.type) {
          case "greeting":
            setDotNotification("haveNewGreetings");
            setDotNotification("haveNewMessage");
            break;
          case "message":
            setDotNotification("haveNewMessage");
            break;
          case "visite":
            setDotNotification("haveNewVisite");

            break;
          case "perfectMatch":
            setDotNotification("haveNewPerfectMatch");
            break;
          case "want_to_meet_me":
            setDotNotification("haveNewWhoWantsToMeetMe");
            break;
          case "no-message":
            clearDotNotification("haveNewMessage");
            break;
        }
      }

      if (
        addGreetingsFromLiveAlert &&
        event.category === "notification" &&
        event.type === "greeting"
      ) {
        addGreetingsFromLiveAlert({
          ...event,
          age: `${event.age.toString()} ${i("years old")}`,
        });
      }

      if (
        addMessageFromLiveAlert &&
        event.category === "message" &&
        (event.message || event.message_image)
      ) {
        addMessageFromLiveAlert({
          ...event,
          dateMessage: i("Now"),
          age: `${event.age.toString()} ${i("years old")}`,
        });
      }

      if (
        event.type !== "message" &&
        event.type !== "no-message" &&
        event.playSound
      ) {
        playSound(event.type === "refused" ? "error" : "notification");
      }

      if (event.type === "message" && event.message === "") return;
      if (event.type === "no-message") return;

      if (event.discussionId) {
        const currentConvo = document.querySelector(
          ".MessageActiveConvoContainer,.MessageActiveConvoContainerMobile"
        ) as HTMLElement | null;

        if (currentConvo != null && currentConvo.dataset.discussionId) {
          const currentConvoId = currentConvo.dataset.discussionId;

          if (event.discussionId == currentConvoId) return;
          else if (event.playSound) playSound("message");
        } else {
          if (event.playSound) {
            playSound("message");
          }
        }
      }

      setNotification(event);
    });
  }, [accessToken]);

  return (
    <SocketIoContext.Provider
      value={{ notification, setNotification, activeSocket }}
    >
      {children}
    </SocketIoContext.Provider>
  );
}

export type ContentJson = {
  icon: string;
  content: string;
  serverActions: {
    text: string;
    action: ServerAction | "not-now";
  }[];
};

type MyProfileAlert = {
  category: "my-profile";
  subcategory: "photo" | "profile" | "description" | "certification";
  type: "approved" | "refused";
};

export type LiveAlert = {
  id: number;
  username: string;
  dateAlert: number;
  category: "notification" | "message" | "popup" | "feedback" | "call";
  subcategory: "both" | "alert" | "dot" | "feedback" | "promote";
  type:
    | "message"
    | "visite"
    | "vote"
    | "greeting"
    | "perfectMatch"
    | "want_to_meet_me"
    | "favorite"
    | "no-message"
    | "feedback"
    | "vip"
    | "noads";
  discussionId: string | number;
  message: string;
  isTyping: boolean;
  stopTyping: boolean;
  isReading: boolean;
  message_image: string;
  message_image_thumbnail: string;
  isVIP: number;
  dateMessage: string;
  imageProfile: string;
  age: number;
  prenom: string;
  location: string;
  isCertified: number;
  fkusager?: number;
  fkusager_from?: string;
  playSound?: boolean;
  content_json?: string;
} & (
  | {
      category: "notification" | "message" | "popup" | "feedback" | "call";
      subcategory: "both" | "alert" | "dot" | "feedback" | "promote";
      type:
        | "message"
        | "visite"
        | "vote"
        | "greeting"
        | "perfectMatch"
        | "want_to_meet_me"
        | "favorite"
        | "no-message"
        | "feedback"
        | "vip"
        | "noads";
    }
  | MyProfileAlert
);

// Alert types

/*

  if the subcategory is both or alert, you need to display it on the top right corner 
  You need to replace {username} with the value username from the LiveAlert



  1) type="message" (clicking on Read the message should open the messenger)

    username sent you a new message. Read the message


  2) type="visite"

    username is visiting your profile right now

  3) type="favorite"

    username added you to his favorites

  4) type="perfectMatch" (clicking on See the match should open the perfect match page)

    You have a new perfect match with username. See the match 


  5) type="want_to_meet_me" (clicking on See the request should open the who wants to meet me page )

    username wants to meet you. See the request

  
  6) type="greeting"

    username has send you a greeting 


  7) type="vote" (clicking on see his profile should bring you the the profile)

    username has given your profile a vote. See his profile.




*/
