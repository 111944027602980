import { ComponentProps } from "react";
import SmallCardMemberProfile from "./SmallCardMemberProfile";

export const docProps: ComponentProps<typeof SmallCardMemberProfile> = {
  member: {
    info: {
      profilId: "1",
      userId: "123",
      prenom: "John",
      sexeId: "2",
      mainImage: "/assets/matchedUserPic2.png",
      age: 29,
      city: "Montreal",
      userName: "Anne",
      isOnline: true,
      LastUpdate: 1202343,
      isVIP: true,
      isCertified: false,
      totalVoteProfil: 10,
      lastOnlineTime: 102020,
    },
  },
};

export default SmallCardMemberProfile;
