import { Languages } from "../context/TranslationContext";
import {
  DisableDeleteProfileInfo,
  EditProfileInfo,
  MyProfileInfo,
} from "../types";
import Api, { HTTPResponse, HTTPResponseWithDataMessage } from "./Api";

export default class EditProfile extends Api {
  public async putProfile(
    body: EditProfileInfo
  ): Promise<HTTPResponseWithDataMessage> {
    return this.put("/editProfile/profile", body);
  }

  public async disableDeleteProfile(
    body: DisableDeleteProfileInfo
  ): Promise<HTTPResponseWithDataMessage> {
    return this.patch("/editProfile/disableDeleteProfile", body);
  }

  public async getMyProfile(
    username: string
  ): Promise<HTTPResponse & { data: MyProfileInfo }> {
    return this.get("/editProfile/my-profile", { username: username }); // TODO: Remove username (API)
  }

  public async patchEditPassword(body: {
    password: string;
    newpassword: string;
    confirmNewpassword: string;
  }): Promise<
    HTTPResponse & {
      data: {
        message: string;
        tokens: { access_token: string; refresh_token: string };
      };
    }
  > {
    return this.patch("/editProfile/editPassword", body);
  }

  public async patchAlertEmail(body: {
    optListeEnvoi: boolean;
    optproximite: boolean;
    //optfavorite: boolean;
    optnotification: boolean;
    optAlerteMessage: boolean;
  }): Promise<HTTPResponseWithDataMessage> {
    return this.patch("/editProfile/alertEmail", body);
  }

  public async getAlertEmail(): Promise<HTTPResponse & { data: any }> {
    return this.get("/editProfile/alertEmail");
  }

  public async postShowAddImagesPopupRules(
    showAddImagesPopupRules: boolean
  ): Promise<HTTPResponseWithDataMessage> {
    return this.patch("/editProfile/showAddImagesPopupRules", {
      showAddImagesPopupRules: showAddImagesPopupRules,
    });
  }

  public async getCertificationInformations(): Promise<
    HTTPResponse & {
      data: {
        certificationProcess: {
          status: "unCertified" | "certified" | "pending";
          chosenMethod: "" | "webcam" | "photos";
          webcamImageModele: string;
          submittedImageWebcam: string;
          submittedImagePhoto1: string;
          submittedImagePhoto2: string;
          addPhotoFirst: boolean;
        };
      };
    }
  > {
    return this.get("/editProfile/certificationInformations");
  }

  public async updateDisplayLang(new_lang: Languages): Promise<HTTPResponse> {
    return this.patch("/editProfile/updateDisplayLang", { new_lang });
  }
  public async updateVideoChatEnabled(
    enableVideoChat: boolean
  ): Promise<HTTPResponse> {
    return this.patch("/editProfile/updateVideoChatEnabled", {
      enableVideoChat,
    });
  }
}
