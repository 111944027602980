import { FC, useCallback, useContext, useEffect, useState } from "react";
import api from "../../backendAPI";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import ButtonBGBlue from "../buttonBGBlue";
import Popup from "../popup";
// todo export the alt texts for cleaner testing

import useMessenger from "../../hooks/useMessenger";
import useToast from "../../hooks/useToast";
import classNames from "../../utils/twClassNames";
import { TranslationContext } from "../../context/TranslationContext";
import StyledPopup from "../styledPopup";
import { useVideoChat } from "../../videochat/contexts/VideoChatContext";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  discussionId: string;
  isFavorited: boolean;
  isMobile?: boolean;
};

const MessageActiveActionsMenu: FC<Props> = ({
  discussionId,
  isFavorited,
  isMobile,
}) => {
  const { i } = useContext(TranslationContext);
  const { openTimedToast, openToast } = useToast();

  const isSystemMessage = discussionId == "-1" || discussionId == "-2";

  const {
    addConversationToFavorite,
    removeConversationFromFavorite,
    currentConversation,
    deleteConversation,
    unreadConversation,
    reset,
  } = useMessenger();

  const handleReportConversation = async () => {
    // if (reportMessage.length < 10) return;

    if (!currentConversation.conversationInfo) return;

    const response = await api.messages.postMessageAction({
      discussionId: currentConversation.conversationInfo.discussionId,
      action: "addReport",
      raison: reportMessage,
      userId: currentConversation.conversationInfo.userId,
    });

    if (response.status === "ERROR") return;

    openTimedToast({
      title: i("Message reported"),
      description: response.data.message,
      type: "warning",
    });

    setOpenReportModal(false);
  };

  const handleUnReportConversation = async () => {
    // if (reportMessage.length < 10) return;

    if (!currentConversation.conversationInfo) return;

    const response = await api.messages.deleteMessageAction({
      action: "deleteReport",
      discussionId: currentConversation.conversationInfo.discussionId,
    });

    if (response.status === "ERROR") {
      // handle error
    }

    openTimedToast({
      title: i("Report removed"),
      description: response.data.message,
      type: "warning",
    });

    setOpenReportModal(false);
  };

  const handleDeleteConversation = async () => {
    if (!currentConversation.conversationInfo) return;
    deleteConversation(
      currentConversation.conversationInfo.discussionId.toString()
    );
  };

  const [open, setOpen] = useState(false);
  const [videoCallMessage, setVideoCallMessage] =
    useState<string | undefined>(undefined);
  const [videoCallMessageOpen, setVideoCallMessageOpen] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [reportMessage, setReportMessage] = useState("");
  const { openPopup, setIconDescription } = useConfirmDialog();
  const { call, active } = useVideoChat();
  const handleClick = useCallback(() => {
    if (!currentConversation.conversationInfo) return;
    call(
      currentConversation.conversationInfo.userId,
      currentConversation.conversationInfo.discussionId,
      currentConversation.conversationInfo.userName,
      currentConversation.conversationInfo.profileMainPhoto,
      (message: string) => {
        setVideoCallMessage(message);
        setVideoCallMessageOpen(true);
      }
    );
  }, [currentConversation, call]);

  const debouncedClick = useDebouncedCallback(handleClick, 1000, {
    leading: true,
    trailing: false,
  });

  useEffect(() => {
    if (videoCallMessage) setVideoCallMessageOpen(true);
  }, [videoCallMessage]);

  const handleDeleteConversationClick = (discussionId: string) => {
    openPopup({
      description: i("Are you sure you want to delete this conversation?"),
      confirmText: i("Yes I do"),
      confirmAction: () => {
        handleDeleteConversation();
      },
    });
    setIconDescription({
      iconSrc: "/app/assets/messages/deleteTrashIcon.svg",
      iconAlt: "delete icon",
    });
  };

  const handleBlockUser = () => {
    openPopup({
      description: currentConversation.headerInfos?.isBlocked
        ? i("Are you sure you want to unblock this user?")
        : i("Are you sure you want to block this user?"),
      confirmText: i("Yes I do"),
      confirmAction: async () => {
        if (!currentConversation.headerInfos?.isBlocked) {
          const response = await api.blocked.postBlocked({
            userId: currentConversation.conversationInfo?.userId,
          });

          if (response.status === "SUCCESS") {
            openToast({
              title: i("Member blocked"),
              description: response.data.message,
              type: "warning",
            });
          }
        } else {
          const response = await api.blocked.deleteBlocked({
            userId: currentConversation.conversationInfo?.userId,
          });

          if (response.status === "SUCCESS") {
            openToast({
              title: i("Member unblocked"),
              description: response.data.message,
              type: "warning",
            });
          }
        }
      },
    });
  };

  const handMarkMessageAsUnread = () => {
    openPopup({
      description: i(
        "Are you sure you want to mark this conversation as unread?"
      ),
      confirmText: i("Yes I do"),
      confirmAction: async () => {
        if (!currentConversation?.conversationInfo?.discussionId) return;

        api.messages
          .postMessageAction({
            discussionId: +currentConversation.conversationInfo.discussionId,

            action: "unread",
          })
          .then((res) => {
            if (!currentConversation?.conversationInfo?.discussionId) return;

            unreadConversation(
              currentConversation.conversationInfo.discussionId
            );
          });
      },
    });
    setIconDescription({
      iconSrc: "/app/assets/messageIcon.svg",
      iconAlt: "delete icon",
    });
  };

  return (
    <div className="MessageActiveActionsMenu select-none w-fit pl-3">
      <StyledPopup open={openReportModal} onClick={close} hideCloseButton>
        <div className="items-end w-full flex flex-col p-6 px-1 h-fit justify-center">
          {!currentConversation.headerInfos?.isReported ? (
            <>
              <button
                onClick={() => {
                  setOpenReportModal(!openReportModal);
                }}
              >
                <img src="/app/assets/deleteIcon.svg" alt="cancel button" />
              </button>
              <div className="w-full h-full flex flex-col justify-center  text-center">
                <img
                  src="/app/assets/reportIcon.svg"
                  alt="cancel button"
                  className="h-20 mb-6"
                />
                <p className="text-custom-black text-2xl font-semibold w-full">
                  {i("Report conversation")}
                </p>
                <p className="text-custom-black text-md  w-full mt-3">
                  {i(
                    "Tell us the reason for the report in order to help us in the verification."
                  )}
                </p>
                <div className="relative">
                  <textarea
                    data-testid="messenger-report-discussion-textarea"
                    onChange={(event) => setReportMessage(event.target.value)}
                    className="w-full resize-none my-5 border-custom-gray rounded"
                    rows={5}
                  />
                  {reportMessage.length < 10 && (
                    <p className="absolute bottom-8 right-3">
                      {reportMessage.length}/10
                    </p>
                  )}
                </div>
                <div className="flex justify-center">
                  <div className="w-[200px]">
                    <ButtonBGBlue
                      testid="messenger-report-discussion-confirm"
                      innerText={i("Confirm")}
                      onClick={handleReportConversation}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-full flex flex-col items-end">
              {" "}
              <button
                onClick={() => {
                  setOpenReportModal(!openReportModal);
                }}
              >
                <img src="/app/assets/deleteIcon.svg" alt="cancel button" />
              </button>{" "}
              <div className="flex flex-col items-center justify-center m-auto py-8 text-center">
                <img
                  src="/app/assets/messages/orangeReportIcon.svg"
                  alt="cancel button"
                  className="h-20"
                />
                <p className="text-custom-black text-xl font-semibold w-full mt-5">
                  {i("Remove report on this discussion")}
                </p>
                <p className="text-custom-black text-lg w-full sm:w-3/4 pb-6 pt-2">
                  {i(
                    "Are you sure you want to remove the report on this conversation?"
                  )}
                </p>
                <div className="w-[200px]">
                  <ButtonBGBlue
                    testid="messenger-report-discussion-confirm"
                    innerText={i("Confirm")}
                    onClick={handleUnReportConversation}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </StyledPopup>
      <div
        className={`${
          open ? "bg-opacity-100" : "bg-opacity-0"
        }  bg-custom-light-purple rounded-lg ${
          isMobile ? "" : "sm:rounded-xl sm:h-[45px]"
        } h-[30px] flex flex-row-reverse`}
      >
        <button
          style={{ display: active ? "none" : undefined }}
          data-testid="messenger-actions-toggle-button"
          className={`${
            open ? "bg-custom-light-purple-600" : "bg-custom-light-purple"
          } relative w-[40px] ${
            isMobile ? "" : "sm:min-w-[45px] sm:w-[45px] sm:rounded-xl"
          } rounded-lg flex justify-center items-center`}
          onClick={() => setOpen(!open)}
        >
          <img
            className={`absolute h-[20px] ${isMobile ? "" : "sm:h-[25px]"}`}
            alt={open ? i("Show less") : i("Show more")}
            src="/app/assets/messages/verticalDotsIcon.svg"
          />
        </button>
        {open ? (
          <div
            style={{ display: active ? "none" : undefined }}
            className="flex h-full"
            onMouseOver={() => {
              setOpen(true);
            }}
          >
            {!currentConversation.headerInfos?.disableControls && (
              <button
                data-testid="messenger-block-user"
                className="mr-3 tooltip pl-3"
                onClick={() => {
                  handleBlockUser();
                }}
              >
                <img
                  alt={
                    !currentConversation.headerInfos?.isBlocked
                      ? i("Block this user")
                      : i("Unblock this user")
                  }
                  src={
                    !currentConversation.headerInfos?.isBlocked
                      ? "/app/assets/messages/blockIcon2.svg"
                      : "/app/assets/messages/orangeBlockIcon.svg"
                  }
                  className="h-6  min-w-[24px]"
                />
                <p
                  className={classNames(
                    "tooltiptext",
                    !currentConversation.headerInfos?.isReported
                      ? "w-40"
                      : "w-[175px]"
                  )}
                >
                  {!currentConversation.headerInfos?.isBlocked
                    ? i("Block conversation")
                    : i("Unblock conversation")}
                </p>
              </button>
            )}
            {
              <button
                className={classNames(
                  currentConversation.conversationInfo?.isUserDeleted ||
                    isSystemMessage
                    ? "ml-3"
                    : "",
                  "mr-3 tooltip"
                )}
                data-testid="messenger-delete-conversation"
                onClick={() => {
                  handleDeleteConversationClick(discussionId);
                }}
              >
                <img
                  alt="Delete this conversation"
                  src="/app/assets/Icons/trash.svg"
                  className="h-6 min-w-[24px]"
                />{" "}
                <p className="tooltiptext w-40">{i("Delete conversation")}</p>
              </button>
            }
            {!currentConversation.headerInfos?.disableControls && (
              <button
                className="mr-3 tooltip"
                data-testid="messenger-report-conversation"
                onClick={() => setOpenReportModal(true)}
              >
                <img
                  alt={
                    !currentConversation.headerInfos?.isReported
                      ? i("Report conversation")
                      : i("Remove report")
                  }
                  src={
                    !currentConversation.headerInfos?.isReported
                      ? "/app/assets/messages/reportIcon.svg"
                      : "/app/assets/messages/orangeReportIcon.svg"
                  }
                  className="h-6  min-w-[24px]"
                />
                <p
                  className={classNames(
                    "tooltiptext",
                    !currentConversation.headerInfos?.isReported
                      ? "w-40"
                      : "w-32"
                  )}
                >
                  {!currentConversation.headerInfos?.isReported
                    ? i("Report conversation")
                    : i("Remove report")}
                </p>
              </button>
            )}
            {!currentConversation.headerInfos?.disableControls && (
              <button
                className="mr-3 tooltip"
                data-testid="messenger-report-conversation"
                onClick={() => handMarkMessageAsUnread()}
              >
                <img
                  alt="Report this conversation"
                  src="/app/assets/messages/messageIcon.svg"
                  className="h-6  min-w-[24px]"
                />{" "}
                <p className="tooltiptext w-32">{i("Mark as unread")}</p>
              </button>
            )}
          </div>
        ) : (
          <>
            <StyledPopup
              open={videoCallMessageOpen && videoCallMessage !== undefined}
              onClick={() => {
                setVideoCallMessageOpen(false);
              }}
              hideCloseButton
            >
              <div className="items-end w-full flex flex-col px-1">
                <button
                  onClick={() => {
                    setVideoCallMessageOpen(false);
                  }}
                >
                  <img src="/app/assets/deleteIcon.svg" alt="cancel button" />
                </button>
                <div className="w-full h-full pb-5 flex items-center flex-col justify-center">
                  <div className="w-full flex justify-center">
                    <img
                      src="/app/assets/messages/videoIcon.svg"
                      alt="vidamora camera icon"
                      className="w-12 object-cover"
                    />
                  </div>
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: videoCallMessage ?? "",
                      }}
                      data-testid="messenger-video-chat-message"
                      className="text-custom-black text-lg w-full text-center mt-4"
                    ></p>
                  </div>
                </div>
              </div>
            </StyledPopup>
            {!currentConversation.headerInfos?.disableControls &&
              currentConversation.conversationInfo && (
                <button
                  style={{ display: active ? "none" : undefined }}
                  className="mx-6 tooltip relative"
                  data-testid="messenger-video-chat-button"
                  onClick={debouncedClick}
                >
                  <img
                    alt="video chat"
                    src="/app/assets/messages/videoIcon.svg"
                    className=" min-w-[24px]"
                  />
                  <div className="absolute left-3 top-10">
                    <p className="w-24">{i("Video chat")}</p>
                  </div>
                </button>
              )}

            {!currentConversation.headerInfos?.disableControls && (
              <button
                className="tooltip relative"
                onClick={() => {
                  if (isFavorited) removeConversationFromFavorite(discussionId);
                  else addConversationToFavorite(discussionId);
                }}
              >
                <img
                  data-testid="messenger-active-convo-favorite-toggle-button"
                  className="h-[22px] min-w-[23px] w-[23px]"
                  alt={
                    isFavorited
                      ? i("Favorited conversation")
                      : i("Non favorited conversation")
                  }
                  src={
                    isFavorited
                      ? "/app/assets/messages/favoriteIcon.svg"
                      : "/app/assets/messages/starIcon.svg"
                  }
                />
                <div className="absolute top-10 left-0">
                  <p className="tooltiptext w-20">{i("Favorite")}</p>
                </div>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MessageActiveActionsMenu;
