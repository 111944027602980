import Favorites from "./Favorites";
import Authentication from "./Authentication";
import Blocked from "./Blocked";
import VisitedMyProfile from "./VisitedMyProfile";
import Forum from "./Forum";
import General from "./General";
import Profile from "./Profile";
import DateMe from "./DateMe";
import EditProfile from "./EditProfile";
import SearchByUsername from "./SearchByUsername";
import Votes from "./Votes";
import Search from "./Search";
import Help from "./Help";
import HelpCenter from "./HelpCenter";
import History from "./History";
import Image from "./Image";
import Registration from "./Registration";
import NewMembers from "./NewMembers";
import Notification from "./Notification";
import Messages from "./Messages";
import Greetings from "./Greetings";
import Report from "./Report";
import Captcha from "./Captcha";
import LiveAlerts from "./LiveAlerts";
import OnlineNow from "./OnlineNow";
import Subscription from "./Subscription";
import DatingAdvice from "./DatingAdvice";
import KeepAlive from "./KeepAlive";
import Feedback from "./Feedback";

const api = {
  profile: new Profile(),
  dateMe: new DateMe(),
  blocked: new Blocked(),
  visitedMyProfile: new VisitedMyProfile(),
  authentication: new Authentication(),
  favorites: new Favorites(),
  forum: new Forum(),
  general: new General(),
  editProfile: new EditProfile(),
  searchByUsername: new SearchByUsername(),
  votes: new Votes(),
  search: new Search(),
  help: new Help(),
  helpCenter: new HelpCenter(),
  history: new History(),
  image: new Image(),
  registration: new Registration(),
  newMembers: new NewMembers(),
  notification: new Notification(),
  messages: new Messages(),
  report: new Report(),
  captcha: new Captcha(),
  greetings: new Greetings(),
  liveAlerts: new LiveAlerts(),
  onlineNow: new OnlineNow(),
  subscription: new Subscription(),
  datingAdvice: new DatingAdvice(),
  keepAlive: new KeepAlive(),
  feedback: new Feedback(),
};

export default api;

(async function () {
  // test
})();
