import React, { FC, useEffect, useState } from "react";
import { AlertMessage } from "../../types";

type Props = { skip: () => void; alertMessage: AlertMessage };

const backgroundColor = {
  info: "bg-blue-100",
  error: "bg-red-100",
  success: "bg-teal-100",
  warning: "bg-yellow-100",
};

const progressBarColor = {
  info: "bg-blue-500",
  error: "bg-red-500",
  success: "bg-teal-500",
  warning: "bg-yellow-500",
};

const textColor = {
  info: "text-blue-900",
  error: "text-red-900",
  success: "text-teal-900",
  warning: "text-yellow-900",
};

const AlertMessageContainer: FC<Props> = ({ skip, alertMessage }) => {
  const [progress, setProgress] = useState(0);

  // TODO: Change color depending on alert type

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 1);
    }, (alertMessage.time || 2000) / 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      skip();
    }
  }, [progress]);

  useEffect(() => {
    setProgress(0);
  }, [alertMessage]);

  return (
    <div
      className={`AlertMessageContainer max-w-[1200px] mx-auto fixed left-4 right-4 bottom-4 w-auto z-100 ${
        backgroundColor[alertMessage.type]
      } h-fit rounded-b ${textColor[alertMessage.type]} shadow-custom-shadow`}
      role="alert"
    >
      <div className="relative px-4 py-3">
        <div
          style={{ width: progress + "%" }}
          className={`h-2 top-0 left-0 right-0 absolute ${
            progressBarColor[alertMessage.type]
          } w-0`}
        ></div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col w-full">
            <p className="font-semibold">{alertMessage.title}</p>
            <p className="text-sm">{alertMessage.message}</p>
          </div>
          <button
            type="button"
            className={`ml-auto -mx-1.5 -my-1.5 ${
              textColor[alertMessage.type]
            } rounded-lg p-1.5 inline-flex h-8 w-8`}
            data-dismiss-target="#alert-border-3"
            aria-label="Close"
            onClick={skip}
          >
            <span className="sr-only">Dismiss</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertMessageContainer;
