import React, { FC } from "react";

type Props = { enabled: boolean; onClick: () => void };

const StyledCarouselNextButton: FC<Props> = ({ enabled, onClick }) => (
  <button
    className="absolute flex disabled:cursor-default disabled:opacity-30 bg-white/50 w-[42px] h-[108px] border-0 outline-0 cursor-pointer touch-manipulation rounded-l-[20px] z-[1] p-0 items-center justify-center top-[50%] translate-y-[-50%] right-0"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5323 8.61818L2.85364 0.990102C2.5402 0.680573 2.1162 0.506836 1.67424 0.506836C1.23228 0.506836 0.808281 0.680573 0.494842 0.990102C0.338043 1.1446 0.213589 1.3284 0.128658 1.53092C0.0437271 1.73344 0 1.95066 0 2.17004C0 2.38943 0.0437271 2.60665 0.128658 2.80917C0.213589 3.01169 0.338043 3.19549 0.494842 3.34999L8.1902 10.9615C8.34699 11.1159 8.47145 11.2998 8.55638 11.5023C8.64131 11.7048 8.68504 11.922 8.68504 12.1414C8.68504 12.3608 8.64131 12.578 8.55638 12.7805C8.47145 12.983 8.34699 13.1668 8.1902 13.3213L0.494842 20.9328C0.179828 21.2435 0.00197388 21.6659 0.000405233 22.1069C-0.00116342 22.5479 0.173683 22.9714 0.486479 23.2844C0.799274 23.5973 1.2244 23.774 1.66832 23.7756C2.11225 23.7771 2.53862 23.6034 2.85364 23.2927L10.5323 15.6646C11.4721 14.7298 12 13.4626 12 12.1414C12 10.8202 11.4721 9.553 10.5323 8.61818V8.61818Z"
        fill="#2030F2"
      />
    </svg>
  </button>
);

export default StyledCarouselNextButton;
