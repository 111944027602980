import React, { useEffect } from "react";
import MessageActiveConvoContainerMobile from "../../components/messageActiveConvoContainerMobile";
import { useVideoChat } from "../contexts/VideoChatContext";
import BodyAdContainerFlexible from "../../components/bodyAdContainerFlexible";
import { Drawer } from "@mui/material";
import useMessenger from "../../hooks/useMessenger";

export default function ChatBox() {
  const { active, showChat, toggleChat, convo } = useVideoChat();
  const {
    setUsernameFilter,
    currentConversation,
    fetchConversations,
    fetchMessages,
  } = useMessenger();

  useEffect(() => {
    if (!showChat || !active || !convo) return;
    const initMessages = async () => {
      if (
        currentConversation.conversationInfo?.discussionId ===
        convo.discussionId
      )
        return;
      fetchMessages({
        conversationId: String(convo.discussionId),
        resetPageSize: true,
        fromLiveAlert: false,
        setQueryArg: false,
      });
      setUsernameFilter(convo.username);
      fetchConversations({ resetPageSize: true }).finally(() => {
        setUsernameFilter("");
      });
    };
    initMessages();
  }, [showChat, active, convo]);

  return (
    <Drawer anchor="right" open={showChat} onClose={toggleChat}>
      <div
        style={{ maxWidth: "100vw" }}
        className="flex flex-col max-h-screen overflow-hidden"
      >
        <MessageActiveConvoContainerMobile
          shouldDisplayAd
          onBackClick={toggleChat}
        />
      </div>
    </Drawer>
  );
}
