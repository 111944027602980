import React, { FC, useContext, useState } from "react";
import api from "../../backendAPI";
import { AlertMessageContext } from "../../context/AlertMessageContext";
import { TranslationContext } from "../../context/TranslationContext";
import ProfileActionButton from "../profileActionButton";

type Props = {
  userId: number;
  initiallyFavorited: boolean;
};

const FavoriteActionButton: FC<Props> = ({ userId, initiallyFavorited }) => {
  const { i } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState(initiallyFavorited);
  const { pushAlert } = useContext(AlertMessageContext);

  const onClick = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const current = isFavorited;
    setIsFavorited((prev) => !prev);
    const response = current
      ? await api.favorites.deleteFavorite({
          userId,
        })
      : await api.favorites.postFavorite({
          userId,
        });

    if (response.http_code !== 200) {
      setIsFavorited((prev) => !prev);
      pushAlert({
        title: "Error",
        message:
          "Failed to add this profile to your favorites. Please try again later!",
        type: "error",
        time: 2000,
      });
    }
    setIsLoading(false);
  };

  return (
    <ProfileActionButton
      onClick={onClick}
      icon={{
        alt: i("Favorite"),
        src: isFavorited
          ? "/app/assets/filledActionStarIcon.svg"
          : "/app/assets/actionStarIcon.svg",
      }}
    />
  );
};

export default FavoriteActionButton;
