import { Dialog } from "@headlessui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../backendAPI";
import ButtonBGBlue from "../components/buttonBGBlue";

import useToast from "../hooks/useToast";

import StyledPopup from "../components/styledPopup";
import { TranslationContext } from "../context/TranslationContext";

// todo (simon) -> refactor, multiple responsabilities

interface Context {
  openPopup: (
    profileId: number,
    userName: string,
    type: "picture" | "profile",
    skip?: boolean
  ) => void;
  type: "profile" | "picture";
  profileId: number;
  username: string;
}

export const ReportPopupContext = createContext({} as Context);

type Props = {
  children: React.ReactNode;
};

export function ReportPopupProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const [profileId, setProfileId] = useState(0);
  const [username, setUsername] = useState("");
  const [type, setType] = useState<"profile" | "picture">("profile");

  const openPopup = (
    profileId: number,
    userName: string,
    type: "picture" | "profile" = "profile"
  ) => {
    setProfileId(profileId);
    setUsername(userName);
    setIsOpen(true);
    setType(type);
  };

  return (
    <ReportPopupContext.Provider
      value={{
        profileId,
        type,
        username,
        openPopup,
      }}
    >
      {children}
      <StyledPopup open={isOpen} onClick={setIsOpen}>
        <ReportPopupContent
          profileId={profileId}
          type={type}
          username={username}
          setIsOpen={setIsOpen}
        />
      </StyledPopup>
    </ReportPopupContext.Provider>
  );
}

export function ReportPopupContent({
  profileId,
  type,
  username,
  setIsOpen,
}: {
  profileId: number;
  type: "picture" | "profile";
  username: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { openToast } = useToast();
  const [reason, setReason] = useState("");
  const handleReport = async () => {
    if (type === "picture") {
      const response = await api.report.reportPicture({
        profileId,
        username,
        raison: reason,
      });

      if (response.status === "SUCCESS") {
        setIsOpen?.(false);
        openToast({
          title: i("Success"),
          description: response.data.message,
          type: "success",
        });
      } else {
        // todo handle error
      }

      return;
    }

    const response = await api.report.reportProfile({
      profileId,
      username,
      raison: reason,
    });

    if (response.status === "SUCCESS") {
      setIsOpen?.(false);
      openToast({
        title: i("Success"),
        description: response.data.message,
        type: "success",
      });
    } else {
      // TODO handle error
    }
  };
  const { i } = useContext(TranslationContext);

  useEffect(() => {
    setReason("");
  }, [profileId, type]);

  return (
    <div
      data-testid="report-popup"
      className="flex w-full justify-center items-center"
    >
      <div className="w-full">
        <div className="mx-auto flex items-center justify-center">
          <img
            src="/app/assets/reportIcon.svg"
            alt="report icon"
            className="h-16 w-16"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="mb-4">
            <Dialog.Description className="text-lg text-black">
              {i("Are you sure you want to report [target]", {
                target: (
                  <span
                    key={9998} // react is having a hard time with this one
                    className="font-bold"
                  >
                    {type === "profile" ? username : i("this picture")}
                  </span>
                ),
              })}
            </Dialog.Description>
          </div>

          <div className="w-full h-full flex flex-col items-center">
            <div className="flex w-full mb-10 relative">
              <textarea
                onChange={(event) => {
                  setReason(event.target.value);
                }}
                maxLength={3000}
                rows={6}
                className="text-area w-full h-full border border-custom-gray rounded-[4px] p-4 placeholder:text-base placeholder:text-custom-black-200 resize-none"
                placeholder={i("Please specify the reason")}
              ></textarea>
            </div>

            <div className="mb-6 sm:mb-0 w-full sm:max-w-[378px]">
              <ButtonBGBlue
                innerText={i("Report")}
                onClick={() => handleReport()}
                testid="confirm-report"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
